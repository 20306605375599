import React from 'react';
import { memo, useState } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Checkbox,
    Size
} from '@material-ui/core'
import { 
    Skeleton
} from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';
import { FixMeLater } from '../assessments/components/GlobalErrorMessage';

const useStyles = makeStyles((theme : FixMeLater) => ({
    rowStyle: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    selectCell: {
        width: 24,
        margin: 0,
        paddingLeft: 4,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    selectAllCell: {
        width: 24,
        margin: 0,
        paddingLeft: 11,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    selectedHeader: {
        fontWeight: 600,
        cursor: 'default'
    },
    clickableHeader: {
        fontWeight: 600,
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    defaultHeader: {
        fontWeight: 300,
        cursor: 'default'
    }
}));

const getValue = (value : FixMeLater, p : FixMeLater) : string => {
    if (p.func) {
        //console.log('func value', p.func(value))
        return p.func(value) || ''
    }
    const tmp = p.property.split('.')
    let current = value
    tmp.forEach((prop : FixMeLater) => {
        if (current) {
            current = current[prop]
        }
    })
    return current || ''
}


function renderTableRow(idx : number, row : FixMeLater, selectedRows : FixMeLater, config : FixMeLater, classes: FixMeLater, setSelectedRows: FixMeLater, onClick: FixMeLater, style?: FixMeLater) {
    const idProp = config.id
    let selected = selectedRows.filter((r : FixMeLater) => { return r === row[idProp];}).length > 0;
    return (
        <TableRow style={{ ...style, height: 45 }} className={classes.rowStyle} key={'_' + idx + '.' + row[idProp]}>
            {config.selectable ? (
                <TableCell key={'_check'} className={classes.selectCell} >
                    <Checkbox color="primary" checked={selected} onChange={() => { 
                        if (selected) {
                            let tmp = selectedRows.filter((r : FixMeLater) => { return r !== row[idProp]});
                            setSelectedRows(tmp);
                        } else {
                            let tmp = selectedRows.concat(row[idProp]);
                            //console.log(tmp);
                            setSelectedRows(tmp);
                        }   
                    }}
                    />
                </TableCell>
            ) : null}
            {config.properties.map((p: FixMeLater) => (
                <TableCell onClick={onClick} key={p.property}>{p.render ? p.render(getValue(row, p)) : getValue(row, p)}</TableCell>
            ))}
        </TableRow>)
}

const toggleAll = (selectedRows: FixMeLater, setSelectedRows: FixMeLater, data: FixMeLater, config: FixMeLater) => {
    if (selectedRows.length < data.length) {
        let tmp = data.map((i : FixMeLater) => i[config.id]);
        setSelectedRows(tmp);
    } else {
        setSelectedRows([]);
    }
}

function areEqual(prevProps : FixMeLater, nextProps : FixMeLater) {
    return prevProps.loading === nextProps.loading && prevProps.data === nextProps.data
  }

const XTable = memo(function XTable(props: FixMeLater) {
    const { loading, tableConfig, onRowClick, size, onHeaderClicked } = props
    const _size : string = size || 'small'
    const classes = useStyles();
    let [selectedRows, setSelectedRows] = useState([]);
    let onSelectionChanged : FixMeLater = props.onSelectionChanged;
   //    console.log('rerender');
    let data = props.data; //.slice(0, 10);
    let handleSelectionChanged = (_s : FixMeLater) => {        
        setSelectedRows(_s);
        if (onSelectionChanged) {
            onSelectionChanged(_s);
        }
    };
    if (!data) {
        return null;
    }
    console.log(tableConfig)
    //onSelectionChanged(selectedRows);
    //console.log(selectedRows, data.length);
    return (
        <React.Fragment>
            <Table size={_size as (Size | undefined)}>
                <TableHead>
                    <TableRow>
                    {tableConfig.selectable ? (<TableCell className={classes.selectAllCell}><Checkbox checked={selectedRows.length === data.length} onChange={() => { toggleAll(selectedRows, handleSelectionChanged, data, tableConfig) }} className={classes.selectCell} /></TableCell>) : null}
                    {tableConfig.properties.map((p : FixMeLater) => (
                        <TableCell key={p.header}
                            className={p.headerId ? (p.headerId === tableConfig.highlightHeader ? classes.selectedHeader : classes.clickableHeader) : classes.defaultHeader} 
                            onClick={() => onHeaderClicked ? onHeaderClicked(p) : null} 
                            variant='head'>
                                {p.headerId === tableConfig.highlightHeader ? '- ' : null}
                                {p.header}
                                {p.headerId === tableConfig.highlightHeader ? ' -' : null}
                        </TableCell>))}
                    </TableRow>
                </TableHead>            
                <TableBody >
                    {!loading && data.map((r: FixMeLater, idx: number) => renderTableRow(idx, r, selectedRows, tableConfig, classes, handleSelectionChanged, () => onRowClick && onRowClick(r)))}
                </TableBody>
            </Table>
        { Boolean(loading) && <Skeleton /> }
        </React.Fragment>
    )
}, areEqual);

export default XTable;

//data.map((r, idx) => renderTableRow(idx, r, selectedRows, tableConfig, classes, handleSelectionChanged, () => onRowClick && onRowClick(r)))