import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Grid,
    Button
} from '@material-ui/core'
import { sendToQA, updateGlobal } from '../../redux/editAssessment/editAssessmentActions'
import { AssessmentCriteria } from '../../redux/reducers/editAssessmentRed'


import categoryEngine, { Categories } from '../../service/categoryEngine'

import IndledendeVurderingView from './readonlyviews/IndledendeVurderingView'
import ATilbagegangIBestandView from './readonlyviews/A_TilbagegangIBestandView'
import BGeografiskUdbredelseView from './readonlyviews/B_GeografiskUdbredelseView'
import CBestandsstorrelseView from './readonlyviews/C_BestandsstorrelseView'
import DKvantitativAnalyseView from './readonlyviews/D_KvantitativAnalyseView'
import EOpNedKategoriseringView from './readonlyviews/E_OpNedKategoriseringView'
import FLevestedsAngivelseView from './readonlyviews/F_LevestedsAngivelseView'
import SupplerendeOplysningerView from './readonlyviews/SupplerendeOplysningerView'
import GTruslerView from './readonlyviews/G_TruslerView'
import { oddRowStyle } from '../components/styles'
import { kvitteringQuestions } from './questions'
import { possiblyREEnum, useArterDkDataEnum } from './enums/enums'
import { UseArterDkDataHelp, PossiblyReHelp } from './help/kvitteringHelp'
import BooleanRadioGroup from '../components/BooleanRadioGroup'
import GlobalErrorMessage, { FixMeLater } from '../components/GlobalErrorMessage'
import {
    extractPropertyValidationResult
} from './validators/sectionValidators'
import RadioGroupReference from '../components/RadioGroupReference'
import { RedlistStore } from '../../redux/reducers'
const convertCategory = (category : FixMeLater) => {
    return {
        category: categoryEngine.Categories.adjust(category.category, category.modification).name,
        categoryRoute: category.routes,
        categoryModifier: category.modification,
        categoryRaw: category.category.name
    }
}



const defaultSections : AssessmentCriteria = {
    preliminary: {},
    reduction: {},
    geography: {
        areaOfOccupancy: null,
        extentOfOccurrence: null,
        extentLimitedToNoOfLocations: null,
        extremeFluctuations: null,
        continuedReduction: null,
        extentOfTheSpeciesIsFragmented: null,
        geographicExtentRemark: null,
        geographySnapshotId: null
    },
    population: {},
    quantitativeAnalysis: {},
    adjustment: {},
    global: {}
}

const CRPossiblyRE = (props : FixMeLater) => {
    const { validation, value, onChange } = props
    console.log(validation)
    return (
        <Grid style={oddRowStyle} item xs={12}>                
            <BooleanRadioGroup
                label={kvitteringQuestions.possiblyRE.label}                
                help={() => <PossiblyReHelp />}                
                value={value}
                validation={extractPropertyValidationResult(validation, 'possiblyRE')}
                onChange={value => onChange(value)}
                yesLabel={possiblyREEnum.values().ja.label} 
                noLabel={possiblyREEnum.values().nej.label} />
        </Grid>)
}


const UseArterDkData = (props : FixMeLater) => {
    const { validation, value, onChange } = props
    console.log({validation, value})
    return (
        <Grid style={oddRowStyle} item xs={12}>                
            <RadioGroupReference
                label={kvitteringQuestions.useArterDkData.label}                
                help={() => <UseArterDkDataHelp />}
                name="useArterDkData"
                value={value}
                values={useArterDkDataEnum.asList()}
                validation={extractPropertyValidationResult(validation, 'useArterDkData')}
                onChange={(value : FixMeLater) => onChange(value)} />
        </Grid>)
}


const I_Kvittering = (props : FixMeLater) => {
    const dispatch = useDispatch()
    const { threatDefinition } = useSelector((state : RedlistStore) => state.ekspertEntities)
    const {
        assessmentId,
        category,
        criteriaValidationResult,
        categoryChangeValidationResult,
        threat
    } = useSelector((state : RedlistStore) => state.editAssessment)
    const {
        preliminary,
        reduction,
        geography,
        population,
        quantitativeAnalysis,
        adjustment,
        global
    } = useSelector((state : RedlistStore) => state.editAssessment.assessmentCriteria || defaultSections)
    const levested = useSelector((state : RedlistStore)  => state.editLevested)
    const supplerendeOplysninger = useSelector((state : RedlistStore)  => state.editSupplerendeOplysninger)

    const validationResult = { ...criteriaValidationResult, ...categoryChangeValidationResult }
    validationResult.ok = validationResult.ok && categoryChangeValidationResult.categoryChange2010?.ok && categoryChangeValidationResult.categoryChange2019?.ok

    console.log({ criteriaValidationResult })
    const askForPossiblyRE = category?.category?.value === Categories.CR.value    
    //console.log('hovedmiljoer', hovedmiljoer)
    return (
        <Grid container spacing={2}>
            <GlobalErrorMessage validationResult={criteriaValidationResult.globalSection} /> 
            { 
                (askForPossiblyRE || criteriaValidationResult.globalSection.properties?.possiblyRE) ? 
                    <CRPossiblyRE validation={criteriaValidationResult.globalSection} value={global?.possiblyRE}  
                        onChange={(value : FixMeLater) => dispatch(updateGlobal('possiblyRE', value))} /> 
                : 
                null
            }
            {
                (geography.areaOfOccupancy || geography.extentOfOccurrence) || criteriaValidationResult.globalSection.properties?.useArterDkData
                ? 
                <UseArterDkData validation={criteriaValidationResult.globalSection} value={global && global.useArterDkData ? global.useArterDkData : 'null' }  
                        onChange={(value : FixMeLater) => dispatch(updateGlobal('useArterDkData', value || null))} /> 
                : null
            }
            
            <IndledendeVurderingView showtitle preliminary={preliminary} />
            <ATilbagegangIBestandView showtitle reduction={reduction} />
            <BGeografiskUdbredelseView showtitle showMap={false} geography={geography} />
            <CBestandsstorrelseView showtitle population={population} />
            <DKvantitativAnalyseView showtitle quantitativeAnalysis={quantitativeAnalysis} />
            <EOpNedKategoriseringView showtitle adjustment={adjustment} />
            <FLevestedsAngivelseView showtitle levested={levested} />
            <GTruslerView showtitle threat={threat} threatDefinition={threatDefinition} />
            <SupplerendeOplysningerView showtitle supplerendeOplysninger={supplerendeOplysninger} />

            <Grid item xs={12}>
                <Button
                    disabled={validationResult.ok !== true}
                    color='primary'
                    variant='contained'
                    onClick={() => assessmentId !== null && dispatch(sendToQA(assessmentId, convertCategory(category), {}))} >
                    Send til kvalitetssikring
                </Button>
            </Grid>
        </Grid>
    )
}

export default I_Kvittering