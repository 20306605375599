import React from 'react';
import { useState } from 'react';
import {
    Button, TextField, Typography
} from '@material-ui/core';
import Title from '../../mui/Title'

const ManageAssessmentsConfirmDelete = (props) => {
    const [ deleteComment, setDeleteComment ] = useState('');
    
    return (
        <div style={{ minWidth: 320, margin: 48 }}>            
            <Title>Slet vurderinger</Title>
            <Typography variant='body2'>Angiv årsag</Typography>
            <TextField fullWidth value={deleteComment} onChange={evt => setDeleteComment(evt.target.value)} />
            <div style={{ marginTop: 32 }}>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button fullWidth color='inherit' variant='contained' onClick={() => props.onClose()}>Annuller</Button>
                </div>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button disabled={!deleteComment} fullWidth color='secondary' variant='contained' onClick={() => props.onConfirm(deleteComment)}>Slet</Button>
                </div>
            </div>
        </div>
    )
}

export default ManageAssessmentsConfirmDelete