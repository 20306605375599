export const isEmptyArray = (arr) => {
    return !arr || arr.length === 0
}

export const groupBy = (xs, keyfn) =>
xs.reduce((rv, x) => {
    const key = keyfn(x);
    if (!key) {
        return rv
    }
    (rv[key] = rv[key] || []).push(x);
    return rv
}, {})

export const objectMap = (obj, mapfn) => {
let rv = []
for (var key in obj) {
    rv.push(mapfn(key, obj[key]))
}
return rv
}

export const emptyArray = (size, initialValue) => {
const tmp = []
for (let i = 0;i < size;i++) {
    tmp.push(initialValue)
}
return tmp
}

export const objectKeyMap = (obj, mapfn) => {
let rv = {}
for (var key in obj) {
    rv[key] = mapfn(key, obj[key])
}
return rv
}
