import RedlistApi from '../../service/redlistApi'

import {
    showSaving,
    hideSaving,
    showMessage,
    showError
} from "../actions/uiActions";

import {
    UPDATE_PROPERTY,
    UPDATE_ADJUSTMENT,
    UPDATE_ADJUSTMENT_VISITING,
    UPDATE_ADJUSTMENT_BREEDING,
    setAssessmentCriteria,
    saveSection,   
    SAVE_SECTION_PRELIMINARY,
    SAVE_SECTION_REDUCTION,
    SAVE_SECTION_GEOGRAPHY,
    SAVE_SECTION_POPULATION,
    SAVE_SECTION_ANALYSIS,
    SAVE_SECTION_ADJUSTMENT,
    initEditAssessmentPage,
    SAVE_SECTION_GLOBAL
} from './editAssessmentActions';

import {
    apiRequest
} from '../api/apiRequest'
import {
    put,
    select,
    takeEvery,
    throttle
} from "redux-saga/effects";


function* processUpdateAdjustmentBreeding(action) {
    try {
        const { assessmentId } = yield select(state => state.editAssessment)
        let prev = yield select(state => state.editAssessment.assessmentCriteria)
        if (!prev) {
            prev = {
                adjustment: {}
            }
        }
        const t = {
            affectedByNeighbours: prev.adjustment.affectedByNeighbours,
            preliminaryAdjustment: prev.adjustment.preliminaryAdjustment,
            immigrantPropagulesReproducing: prev.adjustment.immigrantPropagulesReproducing,
            decreaseInPropagulesExpected: prev.adjustment.decreaseInPropagulesExpected,
            adjustmentRemark: prev.adjustment.adjustmentRemark
        }
        let {
            property,
            value
        } = action.payload
        if (property === 'immigrantPropagulesReproducing') {
            t.decreaseInPropagulesExpected = null
            t.regionalSink = null
        } else if (property === 'decreaseInPropagulesExpected') {
            t.regionalSink = null
        }
        t[property] = value        
        const copy = {
            ...prev
        }
        copy[action.payload.section] = t
        yield put(setAssessmentCriteria(copy))
        yield put(showSaving())
        yield put(saveSection(action.payload.section, assessmentId, t))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processUpdateAdjustmentVisiting(action) {

    try {
        const { assessmentId } = yield select(state => state.editAssessment)
        let prev = yield select(state => state.editAssessment.assessmentCriteria)
        if (!prev) {
            prev = {
                adjustment: {}
            }
        }
        const t = {
            affectedByNeighbours: prev.adjustment.affectedByNeighbours,
            preliminaryAdjustment: prev.adjustment.preliminaryAdjustment,
            deterioratingConditionsInsideRegion: prev.adjustment.deterioratingConditionsInsideRegion,
            deterioratingConditionsOutsideRegion: prev.adjustment.deterioratingConditionsOutsideRegion,
            adjustmentRemark: prev.adjustment.adjustmentRemark
        }
        let {
            property,
            value
        } = action.payload
        if (property === 'deterioratingConditionsOutsideRegion') {
            t.deterioratingConditionsInsideRegion = null
            t.sourceSinkDynamicsAcrossBorders = null
        } else if (property === 'deterioratingConditionsInsideRegion') {
            t.sourceSinkDynamicsAcrossBorders = null
        }
        t[property] = value
        const copy = {
            ...prev
        }
        copy[action.payload.section] = t
        yield put(setAssessmentCriteria(copy))
        yield put(showSaving())
        yield put(saveSection(action.payload.section, assessmentId, t))
    } catch (error) {
        yield put(showError(error))
    }
}


function* processUpdateAdjustment(action) {
    try {
        //console.log('processUpdateAdjustment', action)
        const { assessmentId } = yield select(state => state.editAssessment)
        let prev = yield select(state => state.editAssessment.assessmentCriteria)
        //console.log('processUpdateAdjustment prev', prev)
        if (!prev) {
            prev = {
                adjustment: {}
            }
        }
        //console.log('processUpdateAdjustment prev.adjustment', prev.adjustment)

        let t = {
            affectedByNeighbours: prev.adjustment.affectedByNeighbours,
            preliminaryAdjustment: prev.adjustment.preliminaryAdjustment,
            adjustmentRemark: prev.adjustment.adjustmentRemark
        }

        //console.log('processUpdateAdjustment t', t)
        let {
            property,
            value
        } = action.payload

        if (property === 'adjustmentRemark') {
            for (let key in prev.adjustment) {
                t[key] = prev.adjustment[key]
            }
        }

        t[property] = value
        //console.log('processUpdateAdjustment t after', t)
        const copy = {
            ...prev
        }
        copy[action.payload.section] = t
        yield put(setAssessmentCriteria(copy))
        yield put(showSaving())
        yield put(saveSection(action.payload.section, assessmentId, t))
    } catch (error) {
        yield put(showError(error))
    }
}

export function* processUpdateProperty(action) {
    try {
           //console.log('updating property', action)
        let prev = yield select(state => state.editAssessment.assessmentCriteria)
        if (!prev) {
            prev = {
                preliminary: {},
                reduction: {},
                geography: {},
                population: {},
                adjustment: {},
                quantitativeAnalysis: {},
                global: {}
            }
        }
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const copy = {
            ...prev
        }
        const t = {
            ...prev[action.payload.section]
        }
        t[action.payload.property] = action.payload.value

        copy[action.payload.section] = t
        //console.log('put action', setAssessmentCriteria(copy))
        yield put(setAssessmentCriteria(copy))
        yield put(showSaving())
        yield put(saveSection(action.payload.section, assessmentId, t))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSaveSection(action) {
    try {
        const {
            section,
            assessmentId,
            body
        } = action.payload
        const { category: categoryResult } = yield select(state => state.editAssessment)
        const { modifiedCategory } = categoryResult
        const categoryData = { 
            categoryRaw: categoryResult.category.name,
            category: modifiedCategory.name,
            categoryModifier: categoryResult.modification,
            categoryRoute: categoryResult.routes
        }
        const _body = { patch: body, category: categoryData }
        //console.log('Saving section', section)
        yield apiRequest(RedlistApi, RedlistApi.updateSection, [assessmentId, section, _body], null, showMessage('Fejl', 'Kunne ikke gemme værdi.', initEditAssessmentPage(assessmentId)))
        yield put(hideSaving())
    } catch (error) {
        yield put(hideSaving())
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeEvery(UPDATE_PROPERTY, processUpdateProperty)
    yield throttle(1500, SAVE_SECTION_GLOBAL, processSaveSection)
    yield throttle(1500, SAVE_SECTION_PRELIMINARY, processSaveSection)
    yield throttle(1500, SAVE_SECTION_REDUCTION, processSaveSection)
    yield throttle(1500, SAVE_SECTION_GEOGRAPHY, processSaveSection)
    yield throttle(1500, SAVE_SECTION_POPULATION, processSaveSection)
    yield throttle(1500, SAVE_SECTION_ANALYSIS, processSaveSection)
    yield throttle(1500, SAVE_SECTION_ADJUSTMENT, processSaveSection)

    yield takeEvery(UPDATE_ADJUSTMENT, processUpdateAdjustment)
    yield takeEvery(UPDATE_ADJUSTMENT_BREEDING, processUpdateAdjustmentBreeding)
    yield takeEvery(UPDATE_ADJUSTMENT_VISITING, processUpdateAdjustmentVisiting)
}