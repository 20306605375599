import React, { ReactElement } from 'react'
import {
    Typography,
    Slider, 
    Grid
} from '@material-ui/core'
import { isNullOrEmpty } from '../assessments/sections/validators/sectionValidators'
import { FixMeLater } from '../assessments/components/GlobalErrorMessage'


export const listConverter = (converter : FixMeLater) => {
    return (value : FixMeLater) => {
        if (!value) {
            return null
        }
        return value.map((v : FixMeLater) => converter(v)).join(', ')
    }
}

/*
export const booleanConverter = value => {
    if (value === true) {
        return 'Ja'
    }
    if (value === false) {
        return 'Nej'
    }
    return 'Ikke angivet'
}*/

export const booleanConverterHideNull = (value : FixMeLater) => {
    if (value === true) {
        return 'Ja'
    }
    if (value === false) {
        return 'Nej'
    }
    return null
}


export const booleanConverterWithDefault = (defaultValue: string) => (value : (boolean | null)) => {
    if (value === true) {
        return 'Ja'
    }
    if (value === false) {
        return 'Nej'
    }
    return defaultValue
}


export const optionsConverter = (options : FixMeLater, showDefault? : FixMeLater) => {
    const hideDefault = !Boolean(showDefault)
    return (value : FixMeLater) => {
        if (!value) {
            return null
        }
        if (hideDefault && value === options[0].value) {
            return null
        }
        const option = options.find((o : FixMeLater) => o.value === value)
        if (option) {
            return option.label
        }
        return null
    }
}

export const enumConverter = (_enum : FixMeLater) => {
    return (value : FixMeLater) => {
        if (!value) {
            return null
        }
        const evalue = _enum.fromValue(value)        
        if (!evalue) {
            console.log('got null from ' + value)
            return null
        }
        if (evalue.isNull) {
            return null
        }
        return evalue.label
    }
}

export const LabelledList = (props : FixMeLater) => {
    const { label, values, converter, fullWidth } = props
    //console.log('lv', label, value)
    const _values = converter ? converter(values) : values
    if (!_values) {
        return null
    }
    return (
        <Grid style={{ paddingBottom: 8 }} item xs={12} sm={fullWidth ? 12 : 6}>
            <Typography variant='caption'>{label}</Typography>
            {_values.map((e : FixMeLater) => (
                <React.Fragment key={e.label}>
                    <Typography variant='body1'>{e.label}: {e.value}</Typography>
                </React.Fragment>
            ))}
        </Grid>
    )
}

export interface LabelledValueProps<E> {
    showNull?: boolean;
    label: string;
    value: E;
    converter?: (v : E) => (string | null)
    fullWidth?: boolean;
}

export function LabelledValue<E> (props : LabelledValueProps<E>) {
    const { showNull, label, value, converter, fullWidth } = props
    //console.log('lv', label, value)
    let _value : (string | E | null) = converter ? converter(value) : value
    if (isNullOrEmpty(_value)) {
        if (showNull) {
            _value = 'Ikke angivet'
        } else {
            return null
        }
    }
    return (
        <Grid style={{ paddingBottom: 8 }} item xs={12} sm={fullWidth ? 12 : 6}>
            <Typography variant='caption'>{label}</Typography>
            <Typography variant='body1'>{_value}</Typography>
        </Grid>
    )
}

export const IntervalValue = ({ label, marks, minValue, maxValue, value, backgroundColor } : FixMeLater) => {
    if (!value) {
        return (<Grid style={{ backgroundColor, paddingLeft: 8, paddingTop: 8, marginBottom: 8, paddingBottom: 8 }} item xs={12}>
            <Typography variant='body2'>{label}: Ikke angivet</Typography>
        </Grid>
            )
    }
    return (
        <Grid style={{ backgroundColor, paddingLeft: 8, paddingTop: 8, marginBottom: 8 }} item xs={12}>
            <div style={{paddingBottom: 32 }}>
            <Typography variant='body2'>{label}</Typography>
            </div>
            <div style={{ width: '80%', margin: 'auto'}}>           
            <Slider
                min={minValue}
                max={maxValue}
                disabled={true}
                value={[value.min, value.max]}
                onChange={() => {}}                
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="on"
                marks={marks}
                orientation='horizontal'
            /> 
            </div>
        </Grid>
    )
}

export interface SectionProps {
    title?: string;
    children?: ReactElement[];
}

export const Section = (props : SectionProps) => {
    return (
        <React.Fragment>
            {props.title && <Grid item xs={12}><Typography variant='h6'>{props.title}</Typography></Grid>}
            {props.children}
        </React.Fragment>)
}
