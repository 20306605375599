import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Typography,
    Grid,
    Button,
    TextField,
    IconButton,
    InputAdornment,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Check';

import IndledendeVurderingView from '../assessments/sections/readonlyviews/IndledendeVurderingView'
import ATilbagegangIBestandView from '../assessments/sections/readonlyviews/A_TilbagegangIBestandView'
import BGeografiskUdbredelseView from '../assessments/sections/readonlyviews/B_GeografiskUdbredelseView'
import CBestandsstorrelseView from '../assessments/sections/readonlyviews/C_BestandsstorrelseView'
import DKvantitativAnalyseView from '../assessments/sections/readonlyviews/D_KvantitativAnalyseView'
import EOpNedKategoriseringView from '../assessments/sections/readonlyviews/E_OpNedKategoriseringView'
import FLevestedsAngivelseView from '../assessments/sections/readonlyviews/F_LevestedsAngivelseView'
import GTruslerView from '../assessments/sections/readonlyviews/G_TruslerView'
import SupplerendeOplysningerView from '../assessments/sections/readonlyviews/SupplerendeOplysningerView'
import CategoryChangeView from '../assessments/sections/readonlyviews/X_CategoryChangeView'

import { formatUnixtimestamp } from '../utils/dateUtils'

import { sections } from '../service/assessmentSections'

import {
    createComment,
    updateComment,
    deleteComment,
    reject,
    approve,
    initAssessmentQaPage
} from '../redux/ekspert/assessmentQAPageActions'
import { setTitle } from '../redux/actions/uiActions'
import { FixMeLater } from '../assessments/components/GlobalErrorMessage'
import { RedlistStore } from '../redux/reducers'
import { CategoryChangesType } from '../redux/reducers/editAssessmentRed'

const Comment = (props : FixMeLater) => {
    const { user, comment } = props
    const [mode, setMode] = useState('read')
    const [text, setText] = useState(comment.comment)
    const dispatch = useDispatch()
    return (
        <Grid item xs={12} style={{ backgroundColor: '#eee', padding: 8, margin: 4, borderRadius: 2 }}>
            { mode === 'read' ?
                <React.Fragment>
                    <Typography style={{ display: 'block', marginRight: 8, color: '#777' }} variant='body1'>{formatUnixtimestamp(comment.created)} - {comment.user}</Typography>
                    <Typography style={{ display: 'inline-block' }} variant='body1'>{comment.comment}</Typography>
                    {comment.userId === user.id &&
                        (<div style={{ float: 'right', color: 'black' }}>
                            <Tooltip title="Rediger">
                                <IconButton size='small' color='inherit' onClick={() => setMode('edit')}><EditIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title='Slet'>
                                <IconButton size='small' color='inherit' onClick={() => dispatch(deleteComment(comment.assessmentId, comment.id))}><DeleteIcon /></IconButton>
                            </Tooltip>
                        </div>)
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    <div style={{ width: '90%', display: 'inline-block' }}>
                        <TextField value={text}
                            onKeyPress={evt => {
                                if (evt.key === 'Enter' && !evt.shiftKey) {
                                    console.log('evt', evt)
                                    dispatch(updateComment(comment.assessmentId, comment.id, text))
                                    setText('')
                                    setMode('read')
                                }
                            }}
                            onChange={evt => setText(evt.target.value)} fullWidth multiline />
                    </div>
                    <div style={{ float: 'right', color: 'black' }}>
                        <Tooltip title="Fortryd">
                            <IconButton size='small' color='inherit' onClick={() => { setText(comment.comment); setMode('read') }}><CancelIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title='Gem'>
                            <IconButton size='small' color='inherit' onClick={() => { dispatch(updateComment(comment.assessmentId, comment.id, text)); setMode('read') }}><SaveIcon /></IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            }
        </Grid>
    )
}

const Comments = (props : FixMeLater) => {
    const { user, comments, section: sectionObject, onSave } = props
    const section = sectionObject.section
    const [comment, setComment] = useState('')

    if (!comments) {
        return (<Grid item xs={12}>...</Grid>)
    }
    const _comments = comments.filter((c : FixMeLater) => c.section === section)
    _comments.sort((a : FixMeLater, b : FixMeLater) => b.created - a.created)

    return (
        <Grid item xs={12}>
            <Typography variant='subtitle1'>Kommentarer</Typography>
            {_comments.map((c : FixMeLater) => <Comment key={c.id} user={user} comment={c} />)}
            <TextField
                label={`Kommentar`}
                multiline
                fullWidth
                variant='outlined'
                value={comment}
                onChange={evt => setComment(evt.target.value)}
                onKeyPress={evt => {
                    if (evt.key === 'Enter' && !evt.shiftKey) {
                        evt.preventDefault()
                        onSave({ section, comment })
                        setComment('')
                    }
                }}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="start">
                            <IconButton onClick={() => { setComment(''); onSave({ section, comment }) }} color='inherit'><SendIcon /></IconButton>
                        </InputAdornment>
                }}
            />

        </Grid>
    )
}

const AssessmentQAPage = (props : FixMeLater) => {
    const { title } = props
    const dispatch = useDispatch()
    const { threatDefinition } = useSelector((state : RedlistStore) => state.ekspertEntities)
    const user = useSelector((state : RedlistStore) => state.app.user)
    const { id: _id } = useParams<{id : string}>()
    const id = parseInt(_id)
    const {
        assessment,
        phase,
        comments
    } = useSelector((state : RedlistStore) => state.assessmentQaPage)
    const { assessmentCriteria } = assessment || {}
    const _categoryChangeReasons = assessment?.categoryChangeReasons || []
    const categoryChangeReason2010 = _categoryChangeReasons.find(c => c.vurderingsrundeId === 2010) 
    const categoryChangeReason2019 = _categoryChangeReasons.find(c => c.vurderingsrundeId === 2019) 
    
    const categoryChangeReasons : CategoryChangesType = {
        '2010' : categoryChangeReason2010,
        '2019' : categoryChangeReason2019
    }


    useEffect(() => {
        dispatch(initAssessmentQaPage(id))
    }, [dispatch, id])

    useEffect(() => {        
        if (assessment && assessment.speciesInformation) {
            const category = assessment.category || ''            
            dispatch(setTitle(title + ' - ' + assessment.speciesInformation.scientificName + ' (' + category + ')'))
        }
    }, [ dispatch, title, assessment])

    const {
        preliminary,
        reduction,
        geography,
        population,
        quantitativeAnalysis,
        adjustment
    } = assessmentCriteria || {}
    const {
        supplerendeOplysninger,
        levested,
        threat
    } = assessment || {}

    console.log({assessment})

    console.log({comments})
    //console.log(assessment)
    //console.log(threatDefinition)
    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Indledende vurdering</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <IndledendeVurderingView showNull preliminary={preliminary} />
                        <Comments user={user} comments={comments} section={sections.preliminary} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Tilbagegang i bestand</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <ATilbagegangIBestandView showNull reduction={reduction} />
                        <Comments user={user} comments={comments} section={sections.reduction} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Geografisk udbredelse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <BGeografiskUdbredelseView showNull showMap geography={geography} />
                        <Comments user={user} comments={comments} section={sections.geography} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Bestandsstørrelse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CBestandsstorrelseView showNull population={population} />
                        <Comments user={user} comments={comments} section={sections.population} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Kvantitativ analyse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <DKvantitativAnalyseView showNull quantitativeAnalysis={quantitativeAnalysis} />
                        <Comments user={user} comments={comments} section={sections.quantitativeAnalysis} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Op/ned-kategorisering</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <EOpNedKategoriseringView showNull adjustment={adjustment} />
                        <Comments user={user} comments={comments} section={sections.adjustment} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Levestedsangivelse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <FLevestedsAngivelseView showNull levested={levested} />
                        <Comments user={user} comments={comments} section={sections.levesteder} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Trusler</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <GTruslerView showNull threat={threat} threatDefinition={threatDefinition}  />
                        <Comments user={user} comments={comments} section={sections.trusler} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Supplerende oplysninger</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <SupplerendeOplysningerView showNull supplerendeOplysninger={supplerendeOplysninger} />
                        <Comments user={user} comments={comments} section={sections.supplerendeOplysninger} onSave={(comment  : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Evt. årsag til kategoriskift siden 2010</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CategoryChangeView categoryChanges={categoryChangeReasons} vurderingsrundeId={2010}/>
                        <Comments user={user} comments={comments} section={sections.kategoriskift2010} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Evt. årsag til kategoriskift siden 2019</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CategoryChangeView categoryChanges={categoryChangeReasons} vurderingsrundeId={2019}/>
                        <Comments user={user} comments={comments} section={sections.kategoriskift2019} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Paper style={{ paddingLeft: 16, paddingTop: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Generelle kommentarer</Typography>
                    </Grid>
                    <Comments user={user} comments={comments} section={{ section: 'general' }} onSave={(comment : FixMeLater) => dispatch(createComment(id, phase, comment))} />
                    {phase && phase.canApprove &&
                        (<Grid item xs={12}>
                            <Button
                                disabled={comments.length === 0}
                                color='secondary'
                                variant='contained'
                                onClick={() => dispatch(reject(id))}>
                                Send til genvurdering
                        </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => dispatch(approve(id))}>
                                Godkend
                        </Button>
                        </Grid>)
                    }
                </Grid>
            </Paper>
        </div>
    )
}

export default AssessmentQAPage