import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
  CREATE_ASSESSMENTS_PAGE_SET_FILTER,
  CREATE_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
} from './createAssessmentsPageActions'

const initialState = {
  filter: {
    page: 1,
    limit: 200,
    noAssessmentsOnly: true,
    assessorId: null,
    vurderingsrundeId: null,
    speciesName: '',
    speciesFamily: '',
  },
  queryId: 0,
  assessments: [],
  pages: 1,
  count: 0,
}

const createAssessmentsPageReducer = (state = initialState, action : FixMeLater) => {
  switch (action.type) {
    case CREATE_ASSESSMENTS_PAGE_SET_ASSESSMENTS:
      return {
        ...state,
        queryId: new Date().getTime(),
        assessments: action.payload.assessments,
        count: action.payload.count,
        pages: action.payload.pages,
      }
    case CREATE_ASSESSMENTS_PAGE_SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    default:
      return state
  }
}

export default createAssessmentsPageReducer
