import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { ImportStatus, TaxonPositionInfo, TaxonRecordDetails } from '../../service/arterDkModels'
import { ConvexHullArea } from '../../utils/hullHelper'
import {
  AddPointAction,
  GeographySetLoadingAction,
  GeographySetYearAction,
  GEOGRAPHY_ADD_POINT,
  GEOGRAPHY_CLOSE_SHOW_RECORD_DETAILS,
  GEOGRAPHY_RESET,
  GEOGRAPHY_SET_CELLS,
  GEOGRAPHY_SET_CONVEX_HULL,
  GEOGRAPHY_SET_LOADING,
  GEOGRAPHY_SET_POINTS,
  GEOGRAPHY_SET_RECORD_DETAILS,
  GEOGRAPHY_SET_TAXON_STATUS,
  GEOGRAPHY_SET_YEAR,
  SetCellsAction,
  SetConvexHullAction,
  SetPointsAction,
  SetRecordDetailsAction,
  SetTaxonStatusAction,
} from '../editAssessment/geographyMapActions'

export interface GeographyMapStore {
  points: TaxonPositionInfo[]
  cells: TaxonPositionInfo[]
  convexHull: ConvexHullArea | null
  year: number
  recordDetails: TaxonRecordDetails[]
  showRecordDetails: boolean
  importing: boolean
  loading: boolean
  importStatus: ImportStatus | null
  cellSize: number
}

const initialGeographyState: GeographyMapStore = {
  points: [],
  cells: [],
  convexHull: null,
  year: 1950,
  showRecordDetails: false,
  recordDetails: [],
  importing: false,
  loading: false,
  cellSize: 2,
  importStatus: null
}

const geographyMap = (state = initialGeographyState, action: FixMeLater): GeographyMapStore => {
  switch (action.type) {
    case GEOGRAPHY_RESET:
      return initialGeographyState
    case GEOGRAPHY_SET_YEAR:
      const setYearAction : GeographySetYearAction = action
      return { ...state, year: setYearAction.payload.year }
    case GEOGRAPHY_SET_TAXON_STATUS:
      const setTaxonStatusAction : SetTaxonStatusAction = action
      return { ...state, importStatus: setTaxonStatusAction.payload.status }
    case GEOGRAPHY_CLOSE_SHOW_RECORD_DETAILS:
      return {
        ...state,
        recordDetails: [],
        showRecordDetails: false,
      }
    case GEOGRAPHY_SET_RECORD_DETAILS:
      const setRecordDetailsAction = action as SetRecordDetailsAction
      return {
        ...state,
        recordDetails: setRecordDetailsAction.payload.recordDetails,
        showRecordDetails: true,
      }
    case GEOGRAPHY_SET_POINTS:
      const setPointsAction = action as SetPointsAction
      return {
        ...state,
        points: setPointsAction.payload.points,
      }
    case GEOGRAPHY_ADD_POINT:
      const addPointAction = action as AddPointAction
      return {
        ...state,
        points: state.points.concat([addPointAction.payload.point]),
      }
    case GEOGRAPHY_SET_CELLS:
      const setCellsAction = action as SetCellsAction
      return {
        ...state,
        cells: setCellsAction.payload.cells,
      }
    case GEOGRAPHY_SET_CONVEX_HULL:
      const setConvexHullAction = action as SetConvexHullAction
      return {
        ...state,
        convexHull: setConvexHullAction.payload.convexHull,
      }
    case GEOGRAPHY_SET_LOADING:
      const setLoadingAction = action as GeographySetLoadingAction
      return {
        ...state,
        loading: setLoadingAction.payload.loading,
      }
    default:
      return state
  }
}

export default geographyMap
