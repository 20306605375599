//import React from "react"

import { ReactElement } from "react"

const colorMap : Record<string, string> = {
    'inherit': '#999999',
    'primary': '#0000ff'
}

const getColor = (colorName : string) : string => {
    if (colorName.startsWith('#')) {
        console.log(colorName)
        return colorName
    }
    if (!colorMap[colorName]) {
        return colorMap.inherit
    }
    return colorMap[colorName]
}

export interface WithIconProps {
    title?: string
    disabled?: boolean
    color: string
    size?: number
}

export interface IconProps {
    title?: string
    disabled?: boolean
    style: any
    width: number
    height: number
}

const withIcon = (child : (props: IconProps) => ReactElement) => (props : WithIconProps)  => {
    const title = props.title
    const disabled = Boolean(props.disabled)
    const colorName = disabled ? 'disabled' : props.color
    const color = getColor(colorName)
    const width = props.size || 32
    const height = props.size || 32
    const style = { fill: color }

    return child({ title, style, width, height})
}

export default withIcon