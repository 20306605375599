import React from 'react'
import {
    Button,
    Grid
} from '@material-ui/core'
import {
    NavLink
} from 'react-router-dom'

const Footer = (props : any) => {
    const { onClose, onNext } = props
    return (
        <Grid item xs={12}>
            <Button variant="contained" color='secondary' component={NavLink} to='/vurderinger' onClick={() => onClose()}>Afslut</Button>
            <Button style={{ float: 'right' }} variant="contained" color='primary' onClick={() => onNext()}>Videre</Button>
        </Grid>
    )
}

export default Footer