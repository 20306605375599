import React from 'react'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Grid, Typography, Switch
} from '@material-ui/core';
import DelayedTextField from '../../mui/DelayedTextField';
import Select from 'react-select';
import AsyncSelect from "react-select/async";
import RedlistApi from '../../service/redlistApi'
import { setFilter } from '../../redux/admin/createAssessmentsPageActions'

const toOption = (e) => ({ id: e.id, value: e.id, label: e.name })
const idToOption = (id, options) => {
    if (!id) {
        return null;
    }
    return options.find(o => o.value === id)
}
const customStyles = {
    control: (provided) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        height: 36
    })
}

const loadFamilyOptions = async (inputValue, callback, assessorId, vurderingsrundeId, speciesName) => {
    const data = await RedlistApi.loadSpeciesFamilies(inputValue, assessorId, vurderingsrundeId, speciesName)
    return Promise.resolve(
        data.map((item) => { return { id: item.id, label: item.name, name: item.name, value: item.id } })
    )
}

function areEqual(prevProps, nextProps) {
    return prevProps.users === nextProps.users && prevProps.runder === nextProps.runder;
}

const CreateAssessmentsHeader = memo(function CreateAssessmentsHeader(props) {
    const dispatch = useDispatch()
    const { filter } = useSelector(state => state.createAssessmentsPage)
    const { vurderingsrunder, assessors } = useSelector(state => state.adminEntities);

    const { noAssessmentsOnly, assessorId, speciesName, vurderingsrundeId } = filter

    let speciesFamilyKey = '_sf' + speciesName;
    if (assessorId) {
        speciesFamilyKey += "_u_" + assessorId;
    }
    if (vurderingsrundeId) {
        speciesFamilyKey += "_vr_" + vurderingsrundeId;
    }

    const updateFilter = (value, property) => {
        const _f = { ...filter, page: 1 }
        _f[property] = value
        if (property === 'assessorId') {
            _f.speciesFamily = ''
        }
        console.log(value, property, _f)
        dispatch(setFilter(_f))
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={1} style={{ padding: 0 }}>
                Vis kun arter der ikke har en 2030 vurdering.
                <Switch
                    checked={noAssessmentsOnly}
                    onChange={evt => updateFilter(evt.target.checked, 'noAssessmentsOnly')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <div style={{ marginTop: 16 }}>
                    <div style={{ marginBottom: 16 }}>
                        <Select
                            isClearable
                            value={idToOption(filter.vurderingsrundeId, vurderingsrunder.map(toOption))}
                            options={vurderingsrunder.map(toOption)}
                            onChange={(value) => updateFilter(value?.id, 'vurderingsrundeId')}
                            styles={customStyles} placeholder={'Vurderingsrunde'} />
                    </div>
                    <div>
                        <Select
                            isClearable
                            value={idToOption(filter.assessorId, assessors.map(toOption))}
                            options={assessors.map(toOption)}
                            styles={customStyles}
                            onChange={(value) => updateFilter(value?.id, 'assessorId')}
                            placeholder={"Vurderet af..."} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <div>
                    <DelayedTextField
                        key={'artsnavn'}
                        fullWidth
                        delay={175}
                        id="standard-name"
                        label="Artsnavn"
                        margin="normal"
                        variant="outlined"
                        value={speciesName}
                        onChange={(value) => updateFilter(value, 'speciesName')}
                    />
                    <Typography color="textSecondary" gutterBottom>
                        Familie
                    </Typography>
                    <AsyncSelect
                        key={speciesFamilyKey}
                        value={filter.speciesFamily ? ({ value: filter.speciesFamily, label: filter.speciesFamily }) : null}
                        cacheOptions
                        loadOptions={(inputValue, callback) => loadFamilyOptions(inputValue, callback, assessorId, vurderingsrundeId, speciesName)}
                        defaultOptions
                        isClearable={true}
                        onChange={(value) => updateFilter(value?.name, 'speciesFamily')}
                    />
                </div>
            </Grid>
        </Grid>)
}, areEqual);

export default CreateAssessmentsHeader;