import React from 'react';
import { useState } from 'react';
import {
    Button   
} from '@material-ui/core';
import DialogSelect from '../../mui/DialogSelect'


const toOption = (e) => {
    if (!e) { return null }
    return { id: e.id, value: e.id, label: e.name }
}

const valuesToCreateRequest = (assessor, approver, technicalApprover, usergroup, vurderingsrunde) => {
    return {
        assessorId: assessor.id,
        approverId: approver?.id,
        technicalApproverId: technicalApprover?.id,
        usergroupId: usergroup.id,
        vurderingsrundeId: vurderingsrunde.id
    }
}
const CreateAssessmentsDialog = (props) => {
    const { defaultAssessor, assessors, usergroups, vurderingsrunder } = props;
    let [ assessor, setAssessor ] = useState(toOption(defaultAssessor));
    const [ technicalApprover, setTechnicalApprover ] = useState()
    const [ usergroup, setUsergroup ] = useState()
    let [ approver, setApprover ] = useState();
    let [ vurderingsrunde, setVurderingsrunde ] = useState(toOption(vurderingsrunder[vurderingsrunder.length - 1]))

    let valid = usergroup && assessor && vurderingsrunde;
    return (
        <div style={{ minWidth: 320, margin: 48 }}>
            <DialogSelect defaultValue={assessor} onChange={value => { console.log(value); setAssessor(value)}} items={assessors.map(toOption)} title={'Assessor'} />
            <DialogSelect onChange={value => setTechnicalApprover(value)} items={assessors.map(toOption)} title={'Technical Approver'} />
            <DialogSelect onChange={value => setApprover(value)} items={assessors.map(toOption)} title={'Approver'} />
            <DialogSelect onChange={value => setUsergroup(value)} items={usergroups.map(toOption)} title={'Gruppe'} />
            <DialogSelect defaultValue={vurderingsrunde} onChange={value => setVurderingsrunde(value)} items={vurderingsrunder.map(toOption)} title={'Vurderingsrunde'} />
            <div style={{ display: 'inline-block', width: '50%'}}>
                <Button fullWidth color='secondary' variant='contained' onClick={() => props.onClose()}>Annuller</Button>
            </div>
            <div style={{ display: 'inline-block', width: '50%'}}>
                <Button fullWidth disabled={!valid} color='primary' variant='contained' onClick={() => props.onSave(valuesToCreateRequest(assessor, approver, technicalApprover, usergroup, vurderingsrunde))}>Opret</Button>
            </div>
        </div>
    )
}

export default CreateAssessmentsDialog