import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
    SET_SUPPLERENDE_OPLYSNINGER
} from '../actions/editSupplerendeOplysningerActions'
import { ReduxAction2 } from '../reduxModel'

export interface EditSupplerendeOplysningerStore extends FixMeLater {
    firstRecord: string
    latestRecord: string
}

const initialState : EditSupplerendeOplysningerStore = {
    firstRecord: '',
    latestRecord: '',
    antalLokaliteter: '',
    trend: 'ikke_angivet',
    historiskTrend: '',
    geographicExtentAcrossDenmark: null,
    isNative: null,
    nationalForekomst: '',
    totaludbredelse: '',
    nomenklatur: '',
    litteratur: '',
    remarkSupplerendeOplysninger: '',
    additionalComments: ''
}

const supplerendeOplysninger = (state = initialState, action : ReduxAction2<FixMeLater>) : EditSupplerendeOplysningerStore => {
    //console.log('action', action);
    switch (action.type) {
        case SET_SUPPLERENDE_OPLYSNINGER:
            return {
                ...action.payload.value
            }
        default:
            return state
    }
}

export default supplerendeOplysninger