import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Grid,
    Typography,
    TextField,
    IconButton,
    Drawer
} from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import {
    manageUserPageInit,
    createUser,
    updateUser,
    showCreateDialog,
    hideCreateDialog,
    showEditDialog,
    hideEditDialog
} from '../../redux/admin/manageUserPageActions'
import XTable from '../../mui/XTable'

import CreateUserDialog from './CreateUserDialog'
import EditUserDialog from './EditUserDialog'

const tableConfig = {
    id: 'username',
    properties: [
        { header: 'Navn', property: 'name' },
        { header: 'Username', property: 'username' },
        { header: 'Email', property: 'email' },
        { header: 'Role', property: 'role' }
    ],
    selectable: false
}

const ManageUserPage = props => {
    const dispatch = useDispatch()
    const { assessors, users, usergroups } = useSelector(state => state.adminEntities)
    const { selectedUser, showEditUser, showCreateUser, queryId } = useSelector(state => state.manageUserPage)
    const [nameFilter, setNameFilter] = useState('')

    useEffect(() => {
        dispatch(manageUserPageInit())
    }, [dispatch])

    const _users = users.filter(u => {
        if (!nameFilter) {
            return true
        }
        if (nameFilter.startsWith('*')) {
            return u.name.toLowerCase().includes(nameFilter.substring(1))
        }
        return u.name.toLowerCase().startsWith(nameFilter.toLowerCase())
    })
    return (
        <Grid container spacing={2}>
            <Drawer open={showCreateUser} anchor='right' onClose={() => { dispatch(hideCreateDialog()) }} >
                <CreateUserDialog
                    onClose={() => dispatch(hideCreateDialog())}
                    onSave={(value) => { dispatch(createUser(value)) }}
                />
            </Drawer>
            <Drawer open={Boolean(showEditUser && selectedUser)} anchor='right' onClose={() => { dispatch(hideEditDialog()) }} >
                <EditUserDialog
                    user={selectedUser}
                    assessors={assessors}
                    usergroups={usergroups}
                    onClose={() => dispatch(hideEditDialog())}
                    onSave={(value) => { dispatch(updateUser(selectedUser.username, value)) }}
                />
            </Drawer>
            <Grid item xs={12}>
                <Typography variant='h5'>Adminstrer brugere</Typography>
            </Grid>
            <Grid item xs={12}>
                <IconButton onClick={() => {
                    dispatch(showCreateDialog())
                }} size='medium' variant='contained' color='primary'><AddCircleRoundedIcon fontSize="large" /></IconButton>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Navn'
                    value={nameFilter}
                    onChange={evt => setNameFilter(evt.target.value)} />
            </Grid>
            <XTable
                key={queryId}
                data={_users}
                tableConfig={tableConfig}
                onRowClick={value => dispatch(showEditDialog(value))}
            />
        </Grid>
    )
}

export default ManageUserPage