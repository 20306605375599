import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Typography,
    Grid
} from '@material-ui/core'
import TextAreaReference from '../components/TextAreaReference'
import TextFieldWithHelp from '../components/TextfieldWithHelp'
import BooleanRadioGroup from '../components/BooleanRadioGroup'
import { oddRowStyle } from '../components/styles'
import RadioGroupReference from '../components/RadioGroupReference'
import { updatePopulation, saveSection } from '../../redux/editAssessment/editAssessmentActions'
import Footer from '../components/Footer'
import GlobalErrorMessage, { FixMeLater } from '../components/GlobalErrorMessage'
import {
    ModneIndividerHelpElement,
    VedvarendeTilbagegangHelpElement,
    SubPopulationHelpElement,
    PercentMatureIndividualsHelpElement,
    EkstremeUdsvingHelpElement,
    Max3DelbestandeHelpElement
} from './help/populationHelp'
import { extractPropertyValidationResult } from './validators/sectionValidators'
import { populationQuestions } from './questions'
import { RedlistStore } from '../../redux/reducers'

export const populationReductionValues = [
    { value: '0', label: "Ikke angivet" },
    { value: '1', label: "25 % over 3 år eller 1 generation" },
    { value: '2', label: "20 % over 5 år eller 2 generationer" },
    { value: '4', label: "10 % over 10 år eller 3 generationer" },
    { value: '8', label: "5 % over 10 år eller 3 generationer" }
]

export const subpopulationSizes = [
    { value: '0', label: "Ikke angivet" },
    { value: '50', label: "50" },
    { value: '250', label: "250" },
    { value: '1000', label: "1000" },
    { value: '2000', label: "2000" }
]

export const percentMatureIndividualsValues = [
    { value: '0', label: "Ikke angivet" },
    { value: '90', label: "90 %" },
    { value: '95', label: "95 %" },
    { value: '100', label: "100 %" }
]


const C_Bestandsstorrelse = (props : FixMeLater) => {
    const dispatch = useDispatch()
    const assessmentId = useSelector((state : RedlistStore) => state.editAssessment.assessmentId)
    const currentCriteria = useSelector((state : RedlistStore) => state.editAssessment.assessmentCriteria)
    const currentPopulation = (currentCriteria && currentCriteria.population) || {}
    const { referenceArt, onNext, onClose } = props
    const referencePopulation = referenceArt && referenceArt.assessmentCriteria && referenceArt.assessmentCriteria.population

    const noOfSexuallyMatureIndividuals = currentPopulation.noOfSexuallyMatureIndividuals || ''
    const estimatedContinuingReduction = currentPopulation.estimatedContinuingReduction || populationReductionValues[0].value
    const subpopulationSizeMatureIndividuals = currentPopulation.subpopulationSizeMatureIndividuals || subpopulationSizes[0].value
    const percentMatureIndividuals = currentPopulation.percentMatureIndividuals || percentMatureIndividualsValues[0].value
    const {
        extremeFluctuationsInNumberOfMatureIndivduals,
        noOfSubpopulationsIsMax3
    } = currentPopulation
    const populationSizeRemark = currentPopulation.populationSizeRemark || ''
    const validationResult = useSelector((state : RedlistStore) => state.editAssessment.criteriaValidationResult?.population)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    Bestandsstørrelse
                </Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithHelp
                    type='number'
                    help={() => <ModneIndividerHelpElement />}
                    validation={extractPropertyValidationResult(validationResult, 'noOfSexuallyMatureIndividuals')}
                    referenceValue={referencePopulation && referencePopulation.noOfSexuallyMatureIndividuals}
                    value={noOfSexuallyMatureIndividuals}
                    onChange={value => dispatch(updatePopulation('noOfSexuallyMatureIndividuals', value))}
                    title={populationQuestions.noOfSexuallyMatureIndividuals.title}
                    label={populationQuestions.noOfSexuallyMatureIndividuals.label}
                />
            </Grid>
            <Grid item xs={12} style={oddRowStyle}>
                <RadioGroupReference
                    help={() => <VedvarendeTilbagegangHelpElement />}
                    label={populationQuestions.estimatedContinuingReduction.label}
                    referenceValue={referencePopulation ? referencePopulation.estimatedContinuingReduction : null}
                    name="beregnet_tilbagegang"
                    value={estimatedContinuingReduction}
                    onChange={(value : FixMeLater) => dispatch(updatePopulation('estimatedContinuingReduction', value))}
                    values={populationReductionValues} />
            </Grid>
            <Grid item xs={12}>
                <RadioGroupReference
                    help={() => <SubPopulationHelpElement />}
                    label={populationQuestions.subpopulationSizeMatureIndividuals.label}
                    referenceValue={referencePopulation ? referencePopulation.subpopulationSizeMatureIndividuals : null}
                    name="subpopulation_individer"
                    value={subpopulationSizeMatureIndividuals}
                    onChange={(value : FixMeLater) => dispatch(updatePopulation('subpopulationSizeMatureIndividuals', value))}
                    values={subpopulationSizes} />
            </Grid>
            <Grid item xs={12} style={oddRowStyle}>
                <RadioGroupReference
                    help={() => <PercentMatureIndividualsHelpElement />}
                    label={populationQuestions.percentMatureIndividuals.label}
                    referenceValue={referencePopulation ? referencePopulation.percentMatureIndividuals : null}
                    name="procent_modne_individer"
                    value={percentMatureIndividuals}
                    onChange={(value : FixMeLater) => dispatch(updatePopulation('percentMatureIndividuals', value))}
                    values={percentMatureIndividualsValues} />
            </Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    help={() => <EkstremeUdsvingHelpElement />}
                    ghost={referencePopulation && referencePopulation.extremeFluctuationsInNumberOfMatureIndivduals}
                    value={extremeFluctuationsInNumberOfMatureIndivduals}
                    onChange={value => dispatch(updatePopulation('extremeFluctuationsInNumberOfMatureIndivduals', value))}
                    label={populationQuestions.extremeFluctuationsInNumberOfMatureIndivduals.label}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <BooleanRadioGroup
                    help={() => <Max3DelbestandeHelpElement />}
                    ghost={referencePopulation && referencePopulation.noOfSubpopulationsIsMax3}
                    value={noOfSubpopulationsIsMax3}
                    onChange={value => dispatch(updatePopulation('noOfSubpopulationsIsMax3', value))}
                    label={populationQuestions.noOfSubpopulationsIsMax3.label}
                />
            </Grid>
            <TextAreaReference
                validation={extractPropertyValidationResult(validationResult, 'populationSizeRemark')}
                title={populationQuestions.populationSizeRemark.title}
                value={populationSizeRemark}
                onChange={value => dispatch(updatePopulation('populationSizeRemark', value))}
                referenceValue={referencePopulation && referencePopulation.populationSizeRemark}
                referenceTitle='Referenceart'
            />
            <Footer
                onNext={() => {
                    dispatch(saveSection('population', assessmentId as number, currentPopulation))
                    onNext()
                }}
                onClose={() => onClose()} />        </Grid>
    )
}

export default C_Bestandsstorrelse