import React from 'react'
import { TextHelpElement } from '../help'
//import { TextHelpParagraph, TextHelpWithSectionsElement, TextHelpSection } from '../help'

export const FugtighedHelpElement = props => {
    return (
        <TextHelpElement title='Fugtighed'>
            
        </TextHelpElement>
    )
}

export const PhHelpElement = props => {
    return (
        <TextHelpElement title='pH'>
            
        </TextHelpElement>
    )
}

export const LysHelpElement = props => {
    return (
        <TextHelpElement title='Lys'>
            
        </TextHelpElement>
    )
}

export const NutrientRichnessHelpElement = props => {
    return (
        <TextHelpElement title='Næringsstatus'>
            
        </TextHelpElement>
    )
}

export const ForstyrrelsesIntensitetHelpElement = props => {
    return (
        <TextHelpElement title='Forstyrrelsesintensitet'>
            
        </TextHelpElement>
    )
}

export const ForstyrrelsesFrekvensHelpElement = props => {
    return (
        <TextHelpElement title='Forstyrrelsesfrekvens'>
            
        </TextHelpElement>
    )
}

export const PositionIVandsoejlenHelpElement = () => {
    return (
        <TextHelpElement title='Position i vandsøjlen'>
            
        </TextHelpElement>
    )
}

export const SalinitetHelpElement = props => {
    return (
        <TextHelpElement title='Salinitet'>
            
        </TextHelpElement>
    )
}

export const VanddybdeHelpElement = () => {
    return (
        <TextHelpElement title='Vanddybde'>
            
        </TextHelpElement>
    )
}