import {
    integerInterval
} from "../../../utils/validators"
import {
    isNullOrEmpty,
    validationResultFromProperties
} from './sectionValidators'
import { preliminaryValuesAssessed } from "../1_IndledendeVurdering"
import {
    populationReductionValues,
    subpopulationSizes,
    percentMatureIndividualsValues
} from '../C_Bestandsstorrelse'

export const populationEmpty = population => {
    const {
        noOfSexuallyMatureIndividuals,
        estimatedContinuingReduction,
        subpopulationSizeMatureIndividuals,
        percentMatureIndividuals,
        extremeFluctuationsInNumberOfMatureIndivduals,
        noOfSubpopulationsIsMax3,
        populationSizeRemark
    } = population
    return (
        (noOfSexuallyMatureIndividuals !== 0 && !noOfSexuallyMatureIndividuals) 
        && (estimatedContinuingReduction === null || estimatedContinuingReduction === populationReductionValues[0].value)
        && (subpopulationSizeMatureIndividuals === null || subpopulationSizeMatureIndividuals === subpopulationSizes[0].value)
        && (percentMatureIndividuals === null || percentMatureIndividuals === percentMatureIndividualsValues[0].value)
        && extremeFluctuationsInNumberOfMatureIndivduals === null
        && noOfSubpopulationsIsMax3 === null
        && !populationSizeRemark 
    )
}

export const populationValidator = criteria => {
    const { population, preliminary } = criteria
    const empty = populationEmpty(population)
    if (preliminary.preliminaryAssessment !== preliminaryValuesAssessed) {
        if (!empty) {
            return {
                ok: false,
                global: true,
                message: 'Bestandsstørrelse må ikke udfyldes når der er angivet en indledende vurdering, der gør at arten ikke skal vurderes'
            }
        }
    }

    const {
        noOfSexuallyMatureIndividuals,       
        populationSizeRemark
    } = population
    const properties = {}
    let tmp = integerInterval(1, false, true)(noOfSexuallyMatureIndividuals)
    if (tmp.ok === false) {
        properties.noOfSexuallyMatureIndividuals = {
            message: tmp.message
        }
    }
    if (!empty && isNullOrEmpty(noOfSexuallyMatureIndividuals)) {
        properties.noOfSexuallyMatureIndividuals = {
            message: 'Hvis der er udfyldt noget i denne sektion skal "Antal modne individer" udfyldes'
        }
    }
    if (!empty && !populationSizeRemark) {
        properties.populationSizeRemark = {
            message: 'Hvis der er udfyldt noget i denne sektion skal der være skrevet noget i dokumentationsfeltet'
        }
    }
    return validationResultFromProperties(properties, empty)
}