import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const CRUD_INIT = '[crud] init'
export const CRUD_LIST_SET_FILTER = '[crud] set filter'

export const REQUEST_CRUD_CREATE = '[crud] create'
export const CRUD_CREATE_OK = '[crud] create ok'
export const CRUD_CREATE_ERROR = '[crud] create error'

export const REQUEST_CRUD_UPDATE = '[crud] update'
export const CRUD_UPDATE_OK = '[crud] update ok'
export const CRUD_UPDATE_ERROR = '[crud] update error'

export const CRUD_SET_ITEMS = '[crud] set species'
export const CRUD_SET_SELECTED_ITEMS = '[crud] set selected species'

export const CRUD_SHOW_CREATE_DIALOG = '[crud] show create'
export const CRUD_HIDE_CREATE_DIALOG = '[crud] hide create'

export const CRUD_SHOW_EDIT_DIALOG = '[crud] show edit'
export const CRUD_HIDE_EDIT_DIALOG = '[crud] hide edit'

export const CRUD_DELETE_ITEM = '[crud] delete'

export const CRUD_NEXT_PAGE = '[crud] next page'
export const CRUD_PREV_PAGE = '[crud] prev page'
export const CRUD_SET_PAGE = '[crud] set page'

export const crudPageInit = (crudConfig: FixMeLater) => ({
    type: CRUD_INIT,
    payload: {
        config: crudConfig
    }
})

export const setFilter = (filter : FixMeLater) => ({
    type: CRUD_LIST_SET_FILTER,
    payload: {
        filter
    }
})

export const createItem = (item : FixMeLater) => ({
    type: REQUEST_CRUD_CREATE,
    payload: {
        item
    }
})

export const updateItem = (id : FixMeLater, item : FixMeLater) => ({
    type: REQUEST_CRUD_UPDATE,
    payload: {
        id,
        item
    }
})

export const deleteItem = (id : FixMeLater) => ({
    type: CRUD_DELETE_ITEM,
    payload: { id }
})

export const showCreateDialog = () => ({
    type: CRUD_SHOW_CREATE_DIALOG
})

export const hideCreateDialog = () => ({
    type: CRUD_HIDE_CREATE_DIALOG
})

export const showEditDialog = (item : FixMeLater) => ({
    type: CRUD_SHOW_EDIT_DIALOG,
    payload: {
        item
    }
})

export const hideEditDialog = () => ({
    type: CRUD_HIDE_EDIT_DIALOG
})

export const nextPage = () => ({
    type: CRUD_NEXT_PAGE
})

export const prevPage = () => ({
    type: CRUD_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: CRUD_SET_PAGE,
    payload: { page }
})