import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

type Margin = 'none' | 'dense' | 'normal';
type Variant = 'standard' | 'outlined' | 'filled'
export interface DelayedTextFieldProps {
    value: string
    delay: number
    onChange : (v : string) => void
    endAdornment? : any
    disabled?: boolean
    helperText?: string
    error?: boolean
    multiline?: boolean
    rows?: number
    color? : 'primary' | 'secondary'
    type?: string
    fullWidth?: boolean
    id?: any
    label?: string
    key?: any
    margin?: Margin
    variant?: Variant
}

const DelayedTextField = (props : DelayedTextFieldProps) => {
    let { value, delay, onChange, endAdornment } = props;
    let [_value, set_Value] = useState<string>(value ? value : '');
    let [timer, setTimer] = useState<NodeJS.Timeout | null>();
    const textfieldRef = useRef<(HTMLDivElement | null)>(null)

    useEffect(() => {
        if (!textfieldRef || !textfieldRef.current) {
            return
        }
        //console.log(textfieldRef.current)
        const current = textfieldRef.current
        const handleWheel = (e: any) => { 
            if (document.activeElement === e.target) { 
                e.preventDefault(); 
                e.target.blur();
            }
        }
        textfieldRef.current.addEventListener("wheel", handleWheel);
    
        return () => {
          current.removeEventListener("wheel", handleWheel);
        };
      }, [textfieldRef]);

    useEffect(() => {
        set_Value(value)
    }, [ value, set_Value ])

    let handleChange = (_v : string) => {     
        set_Value(_v);
        if (timer) {
            clearTimeout(timer);            
        } 
        let t = setTimeout(() => {
            onChange(_v);
            setTimer(null);
        }, delay);
        setTimer(t);
        return (() => {
            if (timer) {
                clearTimeout(timer);
            }
        })
    };
    let opacity = (timer && value) ? 0.5 : 0;
    return (
        <TextField
            InputProps={{
                endAdornment: 
                <InputAdornment position="start">
                    <EditIcon style={ { opacity }} color={'primary'} fontSize={'small'} />
                    {endAdornment}
                </InputAdornment>
            }}
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            type={props.type}
            multiline={props.multiline}            
            fullWidth={props.fullWidth}
            id={props.id}
            label={props.label}
            margin={props.margin}
            variant={props.variant}
            value={_value}
            color={props.color}
            ref={textfieldRef}
           
            onChange={(evt) => handleChange(evt.target.value)} />
    )
}

export default DelayedTextField