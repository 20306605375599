import React from 'react'
import { LabelledValue, optionsConverter, Section } from '../../../mui/Section'
import { FixMeLater } from '../../components/GlobalErrorMessage'
import { kvantitativValues } from '../D_KvantitativAnalyse'

import { quantitativeQuestions } from '../questions'

const D_KvantitativAnalyseView = (props : FixMeLater) => {
    const quantitativeAnalysis = props.quantitativeAnalysis || {}
    const {showNull, showtitle} = props
    return (
        <Section title={showtitle && 'Kvantitativ analyse'}>
            <LabelledValue showNull={showNull}
                label={quantitativeQuestions.probabilityOfExtinctionByQuantitativeAnalysis.label}
                value={quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis}
                converter={optionsConverter(kvantitativValues)} />
            <LabelledValue showNull={showNull} fullWidth label={quantitativeQuestions.quantitativeAnalysisRemark.label} value={quantitativeAnalysis.quantitativeAnalysisRemark} />
        </Section>
    )
}

export default D_KvantitativAnalyseView