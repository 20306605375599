import RedlistApi from '../../service/redlistApi'

import {
    put,
    takeEvery,
    takeLatest
} from 'redux-saga/effects'

import {
    apiRequest2
} from '../api/apiRequest'

import {
    ASSESSMENT_FETCHED, SET_ASSESSMENT_COMMENTS, FETCH_ASSESSMENT_COMMENTS,
    EDIT_ASSESSMENT_CREATE_COMMENT,
    fetchAssessmentComments,
    SET_PHASE
} from './editAssessmentActions';
import { groupBy } from '../../utils/collectionUtils';
import { phaseFromAssessment } from '../../service/assessmentPhase';
import { showError, showMessage } from '../actions/uiActions';


function* processFetchAssesmentOk(action) {
    try {
        console.log('fetchOk', action)
        const {
            item: assessment
        } = action.payload
        const phase = phaseFromAssessment(assessment)            
        yield put({ type: SET_PHASE, payload: { phase } })
        yield put({ type: SET_ASSESSMENT_COMMENTS, payload: { comments: null } })
        if (phase) {
            console.log('item', assessment)
            yield put(fetchAssessmentComments(assessment.id, phase))
        }

    } catch (error) {
        yield put(showError(error))
    }
}

function* processFetchAssessmentComments(action) {
    try {
        const {
            assessmentId, 
            phase
        } = action.payload
        const data = yield apiRequest2(RedlistApi, RedlistApi.fetchCommentsForAssessment, [assessmentId], {
            "error": showMessage('Fejl', 'Kunne ikke hente kommentarer')
        })
        if (data) {
            const comments = groupBy(data.items.filter(i => phase.commentPhases.find(p => p === i.phase)), e => e.section)
            yield put({ type: SET_ASSESSMENT_COMMENTS, payload: { comments } })
        }
    } catch (error) {
        yield put(showError(error))
    }
}

function* processCreateCommentResponse(action) {
    try {
        const { assessmentId, phase, comment } = action.payload
        //const data = { section, comment }
        yield apiRequest2(RedlistApi, RedlistApi.createAssessmentComment, [assessmentId, phase.slug, comment], {
            "error": showMessage('Fejl', 'Kunne ikke gemme kommentar')
        })
        yield put(fetchAssessmentComments(assessmentId, phase))
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeEvery(ASSESSMENT_FETCHED, processFetchAssesmentOk)
    yield takeLatest(FETCH_ASSESSMENT_COMMENTS, processFetchAssessmentComments)
    yield takeEvery(EDIT_ASSESSMENT_CREATE_COMMENT, processCreateCommentResponse)
}