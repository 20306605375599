import React from 'react'
import { Section, LabelledValue, booleanConverterHideNull, optionsConverter, enumConverter } from '../../../mui/Section'
import {
    additionalQuestions
} from '../questions'
import { trends, isNativeEnum } from '../enums/supplerendeOplysningerEnums' 
import { FixMeLater } from '../../components/GlobalErrorMessage'

const SupplerendeOplysningerView = (props : FixMeLater) => {
    const supplerendeOplysninger = props.supplerendeOplysninger || {}
    const {
        firstRecord,
        latestRecord,
        antalLokaliteter,
        trend,
        historiskTrend,
        geographicExtentAcrossDenmark,
        isNative,
        nationalForekomst,
        totaludbredelse,
        nomenklatur,
        litteratur,
        remarkSupplerendeOplysninger,
        additionalComments
     } = supplerendeOplysninger
     const {showNull, showtitle} = props
     return (
        <Section title={showtitle && 'Supplerende oplysninger'}>
            <LabelledValue showNull={showNull} label={additionalQuestions.firstRecord.label} value={firstRecord} />
            <LabelledValue showNull={showNull} label={additionalQuestions.latestRecord.label} value={latestRecord} />
            <LabelledValue showNull={showNull} label={additionalQuestions.antalLokaliteter.label} value={antalLokaliteter} />
            <LabelledValue showNull={showNull} label={additionalQuestions.trend.label} value={trend} converter={optionsConverter(trends)} />
            <LabelledValue showNull={showNull} label={additionalQuestions.historiskTrend.label} value={historiskTrend} />
            <LabelledValue showNull={showNull} label={additionalQuestions.geographicExtentAcrossDenmark.label} value={geographicExtentAcrossDenmark} converter={booleanConverterHideNull}/>
            <LabelledValue showNull={showNull} label={additionalQuestions.isNative.label} value={isNative} converter={enumConverter(isNativeEnum)}/>
            <LabelledValue showNull={showNull} label={additionalQuestions.nationalForekomst.label} value={nationalForekomst} />
            <LabelledValue showNull={showNull} label={additionalQuestions.totaludbredelse.label} value={totaludbredelse} />                
            <LabelledValue showNull={showNull} label={additionalQuestions.nomenklatur.label} value={nomenklatur} />                
            <LabelledValue showNull={showNull} fullWidth label={additionalQuestions.litteratur.label} value={litteratur} />                
            <LabelledValue showNull={showNull} fullWidth label={additionalQuestions.remarkSupplerendeOplysninger.label} value={remarkSupplerendeOplysninger} />                
            <LabelledValue showNull={showNull} fullWidth label={additionalQuestions.additionalComments.label} value={additionalComments} />                
        </Section>
    )
}

export default SupplerendeOplysningerView