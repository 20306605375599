import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import {
      CRUD_LIST_SET_FILTER,
      CRUD_CREATE_OK,
      CRUD_CREATE_ERROR,      
      CRUD_SET_ITEMS,
      CRUD_SET_SELECTED_ITEMS,
      CRUD_SHOW_CREATE_DIALOG,
      CRUD_HIDE_CREATE_DIALOG,
      CRUD_SHOW_EDIT_DIALOG,
      CRUD_HIDE_EDIT_DIALOG,   
      CRUD_INIT
} from "./crudActions"

const initialState = {
      key: '',
      queryId: '0',
      showCreate: false,
      createItemsError: null,
      editItemError: null,
      showEdit: false,
      selectedItem: null,
      items: [],
      count: 0,
      pages: 1,
      filter: {
            page: 1,
            limit: 50            
      }
}


const crudPageReducer = (state = initialState, action : FixMeLater) => {
      switch (action.type) {
            case CRUD_INIT: 
                  return { ...state, key: action.payload.config.key }
            case CRUD_CREATE_ERROR:
                  return {
                        ...state, createItemsError: action.payload
                  }
            case CRUD_CREATE_OK:
                  return {
                        ...state, createItemsError: null
                  }
            case CRUD_SHOW_CREATE_DIALOG:
                  return {
                        ...state, showCreate: true
                  }
            case CRUD_HIDE_CREATE_DIALOG:
                  return {
                        ...state, showCreate: false, createItemsError: null
                  }
            case CRUD_SHOW_EDIT_DIALOG:
                  return {
                        ...state, selectedItem: action.payload.item, showEdit: true
                  }
            case CRUD_HIDE_EDIT_DIALOG:
                  return {
                        ...state, showEdit: false, editItemError: null, selectedItem: null
                  }
            case CRUD_SET_SELECTED_ITEMS:
                  return { ...state, selectedItem: action.payload.item }            
            case CRUD_LIST_SET_FILTER:
                  return {
                        ...state, filter: action.payload.filter
                  }
            case CRUD_SET_ITEMS:
                  return {
                        ...state, count: action.payload.count, pages: action.payload.pages, items: action.payload.items, queryId: action.payload.queryId
                  }
            default:
                  return state
      }
}

export default crudPageReducer