import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeLatest,
    takeEvery,
    select,
    takeLeading
} from 'redux-saga/effects'
import {
    showSpinner,
    hideSpinner,
    showError
} from '../actions/uiActions'

import {
    VIEW_ASSESSMENTS_PAGE_SET_FILTER,
    VIEW_ASSESSMENTS_PAGE_FETCH,
    VIEW_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
    VIEW_ASSESSMENTS_PAGE_INIT,
    VIEW_ASSESSMENTS_PREV_PAGE,
    VIEW_ASSESSMENTS_NEXT_PAGE,
    VIEW_ASSESSMENTS_SET_PAGE,
    fetchAssessments,
    setPage,
    setFilter,
    setAssessors,
    VIEW_ASSESSMENTS_PAGE_SET_SORT_ORDER,
    setSortOrder,
    VIEW_ASSESSMENTS_PAGE_REQUEST_SORT_ORDER
} from './viewAssessmentsPageActions'
//import { fetchAdminEntities } from '../admin/adminEntitiesActions'

function* processInit(action) {
    try {
        const filter = yield select(state => state.viewAssessmentsPage.filter)
        if (filter.assessorId) {
            yield put(fetchAssessments(filter))
            return
        }
        const {
            user
        } = action.payload
        const data = yield apply(RedlistApi, RedlistApi.loadAssessorsByUserId, [user.id])
        const assessors = data.items
        yield put(setAssessors(assessors))
        if (assessors.length === 0) {
            // 
        } else {
            yield put(setFilter({
                ...filter,
                assessorId: assessors[0].id
            }))
        }
    } catch (error) {
        if (error && error.error) {        
            yield put(showError(error.error))
        } else {
            yield put(showError(error))
        }
    }
}

function* processFetchAssessments(action) {
    try {
        const filter = { ...action.payload.filter }
        if (filter.listAll) {
            delete filter.statusList 
            delete filter.listAll
        }
        const sortOrder = yield select(state => state.viewAssessmentsPage.sortOrder)
        yield put(showSpinner())
        const data = yield apply(RedlistApi, RedlistApi.loadAssessments, [filter, sortOrder])
        yield put({
            type: VIEW_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
            payload: {
                assessments: data.items,
                pages: data.pages,
                count: data.count
            }
        })
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}

function* processSetNextPage(action) {
    try {
        const filter = yield select(state => state.viewAssessmentsPage.filter)
        yield put(setPage(filter.page + 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPrevPage(action) {
    try {
        const filter = yield select(state => state.viewAssessmentsPage.filter)
        yield put(setPage(filter.page - 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPage(action) {
    try {
        const {
            filter,
            pages
        } = yield select(state => state.viewAssessmentsPage)
        let page = action.payload.page
        if (page > pages) {
            page = pages
        } else if (page < 0) {
            page = 0
        }
        yield put(setFilter({
            ...filter,
            page
        }))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetFilter(action) {
    try {
        yield put(fetchAssessments(action.payload))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processRequestSortOrder(action) {
    try {
        const prevSortOrder = yield select(state => state.viewAssessmentsPage.sortOrder)
        console.log({ prevSortOrder, newSortOrder: action.payload})
        if (prevSortOrder === action.payload) {
            return 
        }
        yield put(setSortOrder(action.payload))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetSortOrder(action) {
    try {
      
        const filter = yield select(state => state.viewAssessmentsPage.filter)
        yield put(fetchAssessments(filter))
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLeading(VIEW_ASSESSMENTS_PAGE_INIT, processInit)
    yield takeEvery(VIEW_ASSESSMENTS_PAGE_SET_FILTER, processSetFilter)
    yield takeEvery(VIEW_ASSESSMENTS_PAGE_REQUEST_SORT_ORDER, processRequestSortOrder)
    yield takeEvery(VIEW_ASSESSMENTS_PAGE_SET_SORT_ORDER, processSetSortOrder)
    yield takeLatest(VIEW_ASSESSMENTS_PAGE_FETCH, processFetchAssessments)
    yield takeLeading(VIEW_ASSESSMENTS_SET_PAGE, processSetPage)
    yield takeLeading(VIEW_ASSESSMENTS_NEXT_PAGE, processSetNextPage)
    yield takeLeading(VIEW_ASSESSMENTS_PREV_PAGE, processSetPrevPage)
}