import React from 'react'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import { ThemeProvider } from 'styled-components'
import CheckboxButton from '../../mui/CheckboxButton'

const renderLevestedstype = (levestedstype, checked, onSelectedChanged) => {
    let chk = checked ? true : false;
    return (
        <Grid key={levestedstype.slug} item xs={12} sm={6} md={4}>
            <CheckboxButton 
                fullwidth 
                label={levestedstype.name} 
                checked={chk} 
                onChange={(value) => { onSelectedChanged(value) }}
            />
        </Grid>)
}

const buttonTheme = {
    background: '#a5d6a7',
    color: '#000',
    borderColor: '#c8e6c9',
    checked: {
        background: '#4caf50',
        color: '#000',
        borderColor: '#388e3c'
    }
}

const Levestedstyper = (props) => {
    let { value, selectedHovedmiljoer, levestedstyper, onSelectedChanged, onProceed } = props;

    let onSelectedTypeChanged = (levestedstype, checked) => {
        let tmp = value.filter(s => s.slug !== levestedstype.slug);
        if (checked) {
            tmp.push(levestedstype)
        }
        onSelectedChanged(tmp);
    }

    let canProceed = selectedHovedmiljoer.length > 0 && value.length > 0;

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} lg={11} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Arten lever fortrinsvis i/på</Typography>
                </Grid>
                <ThemeProvider theme={buttonTheme}>
                    {levestedstyper.map(l =>
                        renderLevestedstype(l, value.filter(s => s.slug === l.slug).length > 0, (checked) => onSelectedTypeChanged(l, checked))
                    )}
                </ThemeProvider>
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }}
                        variant='contained'
                        color='primary'
                        disabled={!canProceed}
                        onClick={() => onProceed()}>Videre</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Levestedstyper