import React from 'react'

import {
    Typography
} from '@material-ui/core'

import AsyncSelect from 'react-select/async'
import RedlistApi from '../../service/redlistApi'

const vernacularName = speciesInformation => {
    if (!speciesInformation.vernacularName) {
        return ''
    }
    return ' - ' + speciesInformation.vernacularName
}


const loadSpecies = async (inputValue) => {
    const data = await RedlistApi.loadAssessments({ limit: 20, speciesName: inputValue, statusList: ['godkendt'] })
    const species = data.items.map(i => {
        return {
            id: i.id,
            label: i.speciesInformation.scientificName + vernacularName(i.speciesInformation) + ' (' + i.vurderingsrundeId + ')',
            value: i.id
        }
    })
    return Promise.resolve(species)
    //callback(() => { console.log('callback', species); return species })
}


const loadAssessment = id => {
    return RedlistApi.loadAssessmentById(id)
        .then(json => {
            return Promise.resolve(json.item)
        })
}


const ReferenceArt = (props) => {
    const { onChange } = props
    const speciesSelected = (value) => {
        if (!value) {
            onChange(null)
            return;
        }
        loadAssessment(value.value)
            .then(data => {
                onChange(data)
            })
    }
    return (
        <div style={{ paddingLeft: 8, paddingBottom: 8, paddingRight: 8, backgroundColor: '#f8f8f8' }}>
            <Typography variant='subtitle2'>Referenceart</Typography>
            <AsyncSelect
                placeholder='Søg referenceart...'
                isClearable
                cacheOptions
                defaultOptions
                loadOptions={loadSpecies}
                onChange={(value) => { speciesSelected(value) }} />
        </div>
    )
}

export default ReferenceArt