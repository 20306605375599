import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const VIEW_ASSESSMENTS_PAGE_INIT = '[view assessments page] init'
export const VIEW_ASSESSMENTS_PAGE_FETCH = '[view assessments page] fetch'
export const VIEW_ASSESSMENTS_PAGE_SET_ASSESSMENTS = '[view assessments page] set assessments'
export const VIEW_ASSESSMENTS_PAGE_SET_ASSESSORS = '[vuew assessments page] set assessors'
export const VIEW_ASSESSMENTS_PAGE_SET_FILTER = '[view assessments page] set filter'
export const VIEW_ASSESSMENTS_PAGE_REQUEST_SORT_ORDER = '[view assessments page] request sort order'
export const VIEW_ASSESSMENTS_PAGE_SET_SORT_ORDER = '[view assessments page] set sort order'
export const VIEW_ASSESSMENTS_SET_PAGE = '[viewAssesmentsPage] set page'
export const VIEW_ASSESSMENTS_NEXT_PAGE = '[viewAssesmentsPage] set next page'
export const VIEW_ASSESSMENTS_PREV_PAGE = '[viewAssesmentsPage] set prev page'

export const init = (user : FixMeLater) => (
    {
         type: VIEW_ASSESSMENTS_PAGE_INIT,
         payload: { user }
    }
)

export const fetchAssessments = (filter : FixMeLater) => ({ type: VIEW_ASSESSMENTS_PAGE_FETCH, payload: { filter }})

export const setAssessors = (assessors : FixMeLater) => ({ type: VIEW_ASSESSMENTS_PAGE_SET_ASSESSORS, payload: { assessors }})

export const setFilter = (filter :FixMeLater) => ({
    type: VIEW_ASSESSMENTS_PAGE_SET_FILTER,
    payload: filter
})

export const setSortOrder = (column : string) => ({
    type: VIEW_ASSESSMENTS_PAGE_SET_SORT_ORDER,
    payload: column
})


export const requestSortOrder = (column : string) => ({
    type: VIEW_ASSESSMENTS_PAGE_REQUEST_SORT_ORDER,
    payload: column
})


export const nextPage = () => ({
    type: VIEW_ASSESSMENTS_NEXT_PAGE
})

export const prevPage = () => ({
    type: VIEW_ASSESSMENTS_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: VIEW_ASSESSMENTS_SET_PAGE,
    payload: { page }
})
