import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeLeading,
    takeLatest,
} from 'redux-saga/effects'
import {
    hideSaving,
    showError,
    showSaving
} from '../actions/uiActions'
import { 
    MANAGE_ASSESSORS_PAGE_INIT, 
    MANAGE_ASSESSORS_PAGE_REQUEST_UPDATE, 
    MANAGE_ASSESSORS_PAGE_SELECT_ASSESSOR, 
    MANAGE_ASSESSORS_PAGE_SET_SELECTED_ASSESSOR, 
    MANAGE_ASSESSORS_PAGE_UPDATE_OK, 
    MANAGE_ASSESSORS_PAGE_REQUEST_CREATE,
    selectAssessor,
    MANAGE_ASSESSORS_PAGE_CREATE_OK,
    manageAssessorsPageInit
} from './manageAssessorsPageActions'

import {
    fetchAdminEntities
} from './adminEntitiesActions'


function* processInit(action) {
    try {
        yield put(fetchAdminEntities())
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSelectAssessor(action) {
    try {
        const { assessorId } = action.payload
        if (assessorId === null) {
            yield put({ type: MANAGE_ASSESSORS_PAGE_SET_SELECTED_ASSESSOR, payload: null})    
            return
        }
        const data = yield apply(RedlistApi, RedlistApi.loadAssessorById, [ assessorId ])
        console.log({assessorData: data})
        yield put({ type: MANAGE_ASSESSORS_PAGE_SET_SELECTED_ASSESSOR, payload: data.item })
    } catch (error) {        
        yield put(showError(error))
    }
}


function* processUpdateAssessor(action) {
    try {
        yield put(showSaving())
        console.log({payload: action.payload })
        const { assessorId, data } = action.payload
        const patch = {
            userIds: data.users.map(u => u.id),
            name: data.name || null
        }
        console.log(patch)
        yield apply(RedlistApi, RedlistApi.updateAssessor, [ assessorId, patch ])
        yield put({ type: MANAGE_ASSESSORS_PAGE_UPDATE_OK})
        yield put(fetchAdminEntities('assessors'))
        yield put(selectAssessor(null))        
    } catch (error) {
        yield put(showError(error))
    } finally {
        yield put(hideSaving())
    }
}

function* processCreateAssessor(action) {
    try {
        console.log('create assessor', action.payload)
        const request = action.payload
        yield put(showSaving())
        const data = yield apply(RedlistApi, RedlistApi.createAssessor, [ request ])
        console.log(data)
        yield put({ type: MANAGE_ASSESSORS_PAGE_CREATE_OK, payload: data.id})
        yield put(manageAssessorsPageInit())
        yield put(hideSaving())
    } catch (error) {
        yield put(showError(error))
    } finally {
        yield put(hideSaving())
    }
}

export default function* watcher() {
    yield takeLeading(MANAGE_ASSESSORS_PAGE_INIT, processInit)
    yield takeLatest(MANAGE_ASSESSORS_PAGE_SELECT_ASSESSOR, processSelectAssessor)
    yield takeLatest(MANAGE_ASSESSORS_PAGE_REQUEST_UPDATE, processUpdateAssessor)
    yield takeLatest(MANAGE_ASSESSORS_PAGE_REQUEST_CREATE, processCreateAssessor)
}