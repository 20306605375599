import React from 'react'
import {
    Grid,
    Button,
    Typography, TextField, LinearProgress
} from '@material-ui/core'
import { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { useSelector } from 'react-redux'
import RedlistApi from '../../service/redlistApi'

const nonEmptyValidator = value => {
    if (!value) {
        return { ok: false, message:'Der skal indtastes en værdi' }
    }
    return { ok: true}
} 



const loadOptions = (inputValue, callback) => {
    RedlistApi.loadSpeciesFamiliesByName(inputValue)
        .then(data => {
            console.log('fetched', data)
            callback(data.map(sf => ({ value: sf.id, label: sf.name })))
        })
};


export const loadGroups  = (inputValue, callback) => {
    console.log('loadGroups called')
    RedlistApi.loadSpeciesGroups(inputValue)
        .then(data => {
            console.log('fetched', data)
            callback(data.items.map(sf => ({ value: sf.id, label: sf.name })))
        })
};

const CreateSpeciesDialog = props => {
    const { onClose, onSave } = props
    const { createSpeciesError } = useSelector(state => state.manageSpeciesPage)
    const { saving } = useSelector(state => state.ui)
    const [scientificName, setScientificName] = useState('')
    const [vernacularName, setVernacularName] = useState('')
    const [population, setPopulation] = useState('')
    const [author, setAuthor] = useState('')
    const [arterDkId, setArterDkId] = useState('')
    const [speciesFamilyId, setSpeciesFamilyId] = useState(null)
    const [speciesFamilyName, setSpeciesFamilyName] = useState(null)
    const [speciesGroupId, setSpeciesGroupId] = useState(null)
    const [speciesGroupName, setSpeciesGroupName] = useState(null)

    const scientificNameValid = nonEmptyValidator(scientificName)
    const populationValid = nonEmptyValidator(population)

    const valid = scientificNameValid.ok && populationValid.ok && speciesFamilyId !== null
    
    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Opret art</Typography>
            <LinearProgress variant={ saving ? 'indeterminate' : 'determinate'} value={0} />
            <Grid container spacing={2}>
                <Grid item xs={12}></Grid>               
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Videnskabeligt navn'
                        value={scientificName}
                        error={!scientificNameValid.ok}
                        helperText={scientificNameValid.message}
                        onChange={evt => setScientificName(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Almindeligt navn'
                        value={vernacularName}
                        onChange={evt => setVernacularName(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Population'
                        error={Boolean(!populationValid.ok)}
                        helperText={populationValid.message}
                        value={population}
                        onChange={evt => setPopulation(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Author'
                        value={author}
                        onChange={evt => setAuthor(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='arter.dk ID'
                        value={arterDkId}
                        onChange={evt => setArterDkId(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <AsyncSelect
                        value={{ value: speciesFamilyId, label: speciesFamilyName}}
                        defaultOptions
                        loadOptions={loadOptions}                        
                        onChange={e => { setSpeciesFamilyId(e.value); setSpeciesFamilyName(e.label)}}
                         />
                </Grid>
                <Grid item xs={12}>
                    <AsyncSelect
                        value={{ value: speciesGroupId, label: speciesGroupName}}
                        defaultOptions
                        loadOptions={loadGroups}                        
                        onChange={e => { setSpeciesGroupId(e.value); setSpeciesGroupName(e.label)}}
                         />
                </Grid>
                { createSpeciesError ? <Grid item xs={12}>
                    <Typography variant='body1' color='error'>Fejl under oprettelse af art</Typography>
                </Grid> : null }
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onSave({scientificName, vernacularName, population, author, speciesFamilyId, arterDkId, speciesGroupId })} disabled={!valid} fullWidth variant='contained' color='primary'>Opret</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateSpeciesDialog