import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, TextField, Drawer, Chip, IconButton } from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import XTable from '../../mui/XTable'
import EditAssessorDialog from './EditAssessorDialog'
import CreateAssessorDialog from './CreateAssessorDialog'

import { 
  createAssessor, 
  hideCreateDialog, 
  manageAssessorsPageInit, 
  selectAssessor, 
  showCreateDialog, 
  udpateAssessor 
} from '../../redux/admin/manageAssessorsPageActions'

const maxUsersShownInColumn = 4

const tableConfig = {
  id: 'username',
  properties: [
    { header: 'Navn', property: 'name' },
    { header: 'Alias', property: 'alias' },
    {
      header: 'Brugere',
      property: 'users',
      render: (p) => {
        return (
          <React.Fragment>
            {p && p.slice(0, maxUsersShownInColumn).map((u) => {
              return <Chip key={u.username} label={u.name}></Chip>
            })}
            {p.length > maxUsersShownInColumn && <Chip label='...' />}
          </React.Fragment>
        )
      },
    },
  ],
  selectable: false,
}

const ManageAssessorsPage = () => {
  const dispatch = useDispatch()
  const { queryId, assessors, users } = useSelector((state) => state.adminEntities)
  const { showCreateAssessor, selectedAssessor } = useSelector((state) => state.manageAssessorsPage)
  const [nameFilter, setNameFilter] = useState('')
  

  useEffect(() => {
    dispatch(manageAssessorsPageInit())
  }, [dispatch])

  const _assessors = assessors.filter((a) => {
    if (!nameFilter) {
      return true
    }
    if (nameFilter.startsWith('*')) {
      return a.name.toLowerCase().includes(nameFilter.toLowerCase().substring(1))
    }
    return a.name.toLowerCase().startsWith(nameFilter.toLowerCase())
  })
  const showEditAssessor = selectedAssessor !== null
  return (
    <Grid container spacing={2}>
      <Drawer open={showCreateAssessor} anchor='right' onClose={() => dispatch(hideCreateDialog())} >
        <CreateAssessorDialog
          onClose={() => dispatch(hideCreateDialog())}
          onSave={(value) => { dispatch(createAssessor(value)) }}
        />
      </Drawer>
      <Drawer
        open={Boolean(showEditAssessor && selectedAssessor)}
        anchor="right"
        onClose={() => dispatch(selectAssessor(null))}
      >
        <EditAssessorDialog
          assessor={selectedAssessor}
          users={users}
          onClose={() => dispatch(selectAssessor(null))}
          onSave={(value) => {
            dispatch(udpateAssessor(selectedAssessor.id, value))
          }}
        />
      </Drawer>
      <Grid item xs={12}>
        <Typography variant="h5">Adminstrer assessors</Typography>
      </Grid>
      <Grid item xs={12}>
                <IconButton onClick={() => {
                    dispatch(showCreateDialog())
                }} size='medium' variant='contained' color='primary'><AddCircleRoundedIcon fontSize="large" /></IconButton>
            </Grid>
      <Grid item xs={12}>
        <TextField label="Navn" value={nameFilter} onChange={(evt) => setNameFilter(evt.target.value)} />
      </Grid>
      <XTable
        key={queryId}
        data={_assessors}
        onRowClick={(assessor) => dispatch(selectAssessor(assessor.id))}
        tableConfig={tableConfig}
      />
    </Grid>
  )
}

export default ManageAssessorsPage
