import React from 'react'
import { useState } from 'react'
import AsyncSelect from 'react-select/async'

import {
    Grid,
    Button,
    Typography, TextField
} from '@material-ui/core'

import RedlistApi from '../../service/redlistApi'

const loadOptions = (speciesFamilyId, speciesFamilyName) => (inputValue, callback) => {
    const defaultValue = [ { value: speciesFamilyId, label: speciesFamilyName } ]
    RedlistApi.loadSpeciesFamiliesByName(inputValue)
        .then(data => {
            console.log('fetched', data)
            callback(data.map(sf => ({ value: sf.id, label: sf.name })).concat(defaultValue))
        })
};


export const loadGroups  = (speciesGroupId, speciesGroupName) => (inputValue, callback) => {
    const defaultValue = [ { value: speciesGroupId, label: speciesGroupName } ]
    RedlistApi.loadSpeciesGroups(inputValue)
        .then(data => {
            console.log('fetched', data)
            callback(data.items.map(sf => ({ value: sf.id, label: sf.name })).concat(defaultValue))
        })
};


const EditSpeciesDialog = props => {
    const { species, onDelete, onClose, onSave } = props
    const [scientificName, setScientificName] = useState(species.scientificName)
    const [vernacularName, setVernacularName] = useState(species.vernacularName || '')
    const [population, setPopulation] = useState(species.population)
    const [speciesFamilyId, setSpeciesFamilyId] = useState(species.speciesFamilyId)
    const [speciesFamilyName, setSpeciesFamilyName] = useState(species.speciesFamilyName)
    const [author, setAuthor] = useState(species.author || '')
    const [arterDkId, setArterDkId] = useState(species.arterDkId || '')
    const [speciesGroupId, setSpeciesGroupId] = useState(species.speciesGroupId)
    const [speciesGroupName, setSpeciesGroupName] = useState(species.speciesGroupName || '')

    

    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Rediger {species.scientificName}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Videnskabeligt navn'
                        value={scientificName}
                        onChange={evt => setScientificName(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Almindeligt navn'
                        value={vernacularName}
                        onChange={evt => setVernacularName(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Population'
                        value={population}
                        onChange={evt => setPopulation(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Autor'
                        value={author}
                        onChange={evt => setAuthor(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='arter.dk id'
                        value={arterDkId}
                        onChange={evt => setArterDkId(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <AsyncSelect
                        value={{ value: speciesFamilyId, label: speciesFamilyName}}
                        defaultOptions
                        loadOptions={loadOptions(species.speciesFamilyId, species.speciesFamilyName)}                        
                        onChange={e => { setSpeciesFamilyId(e.value); setSpeciesFamilyName(e.label)}}
                         />
                </Grid>
                 <Grid item xs={12}>
                    <AsyncSelect
                        value={{ value: speciesGroupId, label: speciesGroupName }}
                        defaultOptions
                        loadOptions={loadGroups(species.speciesGroupId, species.speciesGroupName)}                        
                        onChange={e => { setSpeciesGroupId(e.value); setSpeciesGroupName(e.label)}}
                         />                         
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                    <Button onClick={() => onDelete(species.id)} fullWidth variant='contained' color='default'>Slet</Button>                
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={3}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                    <Button onClick={() => onSave({ scientificName, speciesFamilyId, vernacularName, author, population, arterDkId, speciesGroupId })} fullWidth variant='contained' color='primary'>Gem</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default EditSpeciesDialog