import { SET_ADM_ENTITY } from './adminEntitiesActions'

import { statusEnum } from '../../service/assessmentStatusEnum'
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { EnumValue } from '../../assessments/sections/enums/enums'

export interface AdminEntitiesStore {
  statuses: EnumValue[];
  assessors: FixMeLater[];
  usergroups: FixMeLater[];
  vurderingsrunder: FixMeLater[];
  users: FixMeLater[];
  queryId: string;
}

export type MetaEntity = 'assessors' | 'usergroups' | 'vurderingsrunder' | 'users'

const initialState = {
  statuses: statusEnum.asList(),
  assessors: [],
  usergroups: [],
  vurderingsrunder: [],
  users: [],
  queryId: "0"
}

const adminEntities = (state = initialState, action : FixMeLater) => {
    switch (action.type) {  
      case SET_ADM_ENTITY: 
        const newState = { ...state }
        newState[action.meta.entity as MetaEntity] = action.payload
        newState.queryId = new Date().getTime() + ""
        return newState
    default:
        return state
    }
  }
  
  export default adminEntities