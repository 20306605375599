import {
    nullOrNullValue,
    isNullOrEmpty,
    validationResultFromProperties
} from './sectionValidators'
import {
    preliminaryValuesAssessed
} from '../1_IndledendeVurdering'
import {
    integerInterval
} from '../../../utils/validators'
import { reductionTimespanEnum, reductionDerivedFromEnum } from '../enums/enums'

export const reductionIsEmpty = reduction => {
    const {
        reductionInPercent,
        causesOfReductionAreReversible,
        causesOfReductionAreKnown,
        causesOfReductionHaveStopped,
        reductionHasBeenObserved,
        reductionTimespan,
        reductionIsDerivedFrom,
        reductionRemark
    } = reduction
    return (
        isNullOrEmpty(reductionInPercent) 
        && causesOfReductionAreReversible === null
        && causesOfReductionAreKnown === null 
        && causesOfReductionHaveStopped === null 
        && reductionHasBeenObserved === null 
        && nullOrNullValue(reductionTimespan, reductionTimespanEnum.getNullValue().value)
        && nullOrNullValue(reductionIsDerivedFrom, reductionDerivedFromEnum.getNullValue().value)
        && !reductionRemark
    )
}

export const reductionValidator = criteria => {
    const {
        preliminary,
        reduction
    } = criteria
    const {
        reductionInPercent,
        causesOfReductionAreReversible,
        causesOfReductionAreKnown,
        causesOfReductionHaveStopped,
        reductionHasBeenObserved,
        reductionTimespan,
        reductionIsDerivedFrom,
        reductionRemark
    } = reduction
    const empty = reductionIsEmpty(reduction)
    if (preliminary.preliminaryAssessment !== preliminaryValuesAssessed) {
        if (!empty) {
            return {
                ok: false,
                global: true,
                empty: false,
                message: 'Tilbagegang i bestand må ikke udfyldes når der er angivet en indledende vurdering, der gør at arten ikke skal vurderes'
            }
        }
    }
    if (empty) {
        return {
            ok: true,
            empty: true
        }
    }
    const properties = {}
    if (!reductionInPercent) {
        properties['reductionInPercent'] = {
            message: 'Der skal angives en procentvis tilbagegang, hvis der er angivet andet under tilbagegang i bestand'
        }
    } else if (integerInterval(1, 100)(reductionInPercent).ok === false) {
        properties['reductionInPercent'] = {
            message: 'Procentvis tilbagegang skal være et tal mellem 1 og 100'
        }
    }
    if (!reductionRemark) {
        properties['reductionRemark'] = {
            message: 'Dokumentation skal angives når der er angivet en tilbagegang'
        }
    }
    //console.log({ causesOfReductionAreReversible})
    if (causesOfReductionAreReversible === null) {
        properties['causesOfReductionAreReversible'] = {
            message: 'Der skal angives om årsagen er reversibel når der er angivet en tilbagegang'
        }
    }
    if (causesOfReductionAreKnown === null) {
        properties['causesOfReductionAreKnown'] = {
            message: 'Der skal angives om årsagen er kendt når der er angivet en tilbagegang'
        }
    }
    if (causesOfReductionHaveStopped === null) {
        properties['causesOfReductionHaveStopped'] = {
            message: 'Der skal angives om årsagen er stoppet når der er angivet en tilbagegang'
        }
    }
    if (reductionHasBeenObserved === null) {
        properties['reductionHasBeenObserved'] = {
            message: 'Der skal angives om årsagen er observeret når der er angivet en tilbagegang'
        }
    }
    if (reductionTimespan === null || reductionTimespan === reductionTimespanEnum.getNullValue().value) {
        properties['reductionTimespan'] = {
            message: 'Der skal angives hvornår tilbagegangen er sket når der er angivet en tilbagegang'
        }
    }
    if (reductionIsDerivedFrom === null || reductionIsDerivedFrom === reductionDerivedFromEnum.getNullValue().value) {
        properties['reductionIsDerivedFrom'] = {
            message: 'Der skal angives hvad tilbagegangen er baseret på når der er angivet en tilbagegang'
        }
    }
    return validationResultFromProperties(properties, empty)
}