import { Typography } from "@material-ui/core";


function Copyright(props : { version: string }) {
    const { version } = props
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Redlist'}
            {' '}
            {`v${version}`}
        </Typography>
    );
}

export default Copyright