import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const ASSESSMENT_QA_PAGE_INIT = '[assessmentQAPage] init'

export const ASSESSMENT_QA_FETCH_ASSESSMENT =  '[assessmentQAPage] fetch assessment'
export const ASSESSMENT_QA_SET_ASSESSMENT =    '[assessmentQAPage] set assessment'
export const ASSESSMENT_QA_SET_PHASE =         '[assessmentQAPage] set phase'

export const ASSESSMENT_QA_FETCH_COMMENTS =    '[assessmentQAPage] fetch comments'
export const ASSESSMENT_QA_SET_COMMENTS =      '[assessmentQAPage] set comments'
export const ASSESSMENT_QA_CREATE_COMMENT =    '[assessmentQAPage] create comment'
export const ASSESSMENT_QA_CREATE_COMMENT_OK = '[assessmentQAPage] create comment ok'
export const ASSESSMENT_QA_UPDATE_COMMENT =    '[assessmentQAPage] update comment'
export const ASSESSMENT_QA_DELETE_COMMENT =    '[assessmentQAPage] delete comment'

export const ASSESSMENT_QA_APPROVE = '[assessmentQAPage] approve'
export const ASSESSMENT_QA_APPROVE_OK = '[assessmentQAPage] approve ok'
export const ASSESSMENT_QA_REJECT = '[assessmentQAPage] reject'
export const ASSESSMENT_QA_REJECT_OK = '[assessmentQAPage] reject ok'

export const initAssessmentQaPage = (assessmentId : number) => (
    {
         type: ASSESSMENT_QA_PAGE_INIT,
         payload: { assessmentId }
    }
)

export const fetchComments = (assessmentId : number, phase : FixMeLater) => ({
    type: ASSESSMENT_QA_FETCH_COMMENTS,
    payload: {assessmentId, phase}
})

export const createComment = (assessmentId : number, phase : FixMeLater, comment : FixMeLater) => ({
    type: ASSESSMENT_QA_CREATE_COMMENT,
    payload: {assessmentId, phase, comment}
})

export const deleteComment = (assessmentId : number, commentId : number) => ({
    type: ASSESSMENT_QA_DELETE_COMMENT,
    payload: { assessmentId, commentId }
})

export const updateComment = (assessmentId : number, commentId : number, text : FixMeLater) => ({
    type: ASSESSMENT_QA_UPDATE_COMMENT,
    payload: { assessmentId, commentId, text }
})

export const fetchCurrentAssessment = (assessmentId : number) => ({
    type: ASSESSMENT_QA_FETCH_ASSESSMENT,
    payload: { assessmentId }
})

export const approve = (assessmentId : number) => ({
    type: ASSESSMENT_QA_APPROVE,
    payload: { assessmentId }
})

export const reject = (assessmentId : number) => ({
    type: ASSESSMENT_QA_REJECT,
    payload: { assessmentId }
})