import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Typography,
    Switch,
    Slider
} from '@material-ui/core';
import { updateGradient } from '../../redux/actions/editLevestedActions';
import SectionTitle from '../../mui/SectionTitle'
import { levestedQuestions } from '../sections/questions'
import { FugtighedHelpElement, PhHelpElement, NutrientRichnessHelpElement, LysHelpElement, ForstyrrelsesFrekvensHelpElement, ForstyrrelsesIntensitetHelpElement, PositionIVandsoejlenHelpElement, SalinitetHelpElement, VanddybdeHelpElement } from '../sections/help/levesteder/gradientHelp';

function valuetext(value) {
    return `${value}`;
}

const Gradient = props => {
    const { label, labels, value, off, onChange, onDisable, onEnable, help } = props
    return (
        <div style={{ backgroundColor: '#eee', borderRadius: 4, paddingTop: 16, paddingBottom: 8, paddingLeft: 80, paddingRight: 80, marginTop: 16, marginBottom: 32 }}>
            <SectionTitle label={label} help={help} />
            <Switch color='primary' checked={!off} onChange={evt => {
                if (evt.target.checked) {
                    onEnable()
                } else {
                    onDisable()
                }
            }}/>
            <Slider
                min={0}
                max={100}
                disabled={off}
                delay={500}
                defaultValue={[50,50]}
                value={value}
                onChange={(evt, value) => onChange(value)}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={labels}
                orientation='horizontal'
                color='primary'
            />
        </div>
    )
}

const GradientWrapper = props => {
    const { assessmentId, gradienter, gradientQuestion, help } = props
    const dispatch = useDispatch()
    let minValue = gradienter[gradientQuestion.property + 'Min']
    let maxValue = gradienter[gradientQuestion.property + 'Max']
    const off = !(minValue || minValue === 0)
    if (!minValue && minValue !== 0) {
        minValue = !maxValue && maxValue !== 0 ? 50 : maxValue
    }
    if (!maxValue && maxValue !== 0) {
        maxValue = !minValue && minValue !== 0 ? 50 : minValue
    }
    return (
        <Gradient label={gradientQuestion.label} labels={gradientQuestion.marks}
            onEnable={() => dispatch(updateGradient(assessmentId, gradientQuestion.property, [50, 50]))}
            onDisable={() => dispatch(updateGradient(assessmentId, gradientQuestion.property, [null, null]))}
            off={off}
            help={help}
            value={[ minValue, maxValue]}
            onChange={value => dispatch(updateGradient(assessmentId, gradientQuestion.property, value))} />
    )
}

const Gradienter = props => {
    const { assessmentId } = useSelector(state => state.editAssessment)
    //const { gradienter: gradientDefinitioner } = useSelector(state => state.levestedsDefinition)
    const gradientQuestions = levestedQuestions.gradienter
    const { gradienter } = useSelector(state => state.editLevested)
    //console.log(gradienter)
    return (
        <div>
            <Typography variant='h6' gutterBottom>
                {gradientQuestions.header}
            </Typography>
            <GradientWrapper assessmentId={assessmentId}
                help={() => <FugtighedHelpElement />}
                gradientQuestion={gradientQuestions.fugtighed}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <PhHelpElement />}
                gradientQuestion={gradientQuestions.ph}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <NutrientRichnessHelpElement />}
                gradientQuestion={gradientQuestions.nutrientRichness}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <LysHelpElement />}
                gradientQuestion={gradientQuestions.lys}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <ForstyrrelsesFrekvensHelpElement />}
                gradientQuestion={gradientQuestions.forstyrrelsesFrekvens}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <ForstyrrelsesIntensitetHelpElement />}
                gradientQuestion={gradientQuestions.forstyrrelsesIntensitet}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <PositionIVandsoejlenHelpElement />}
                gradientQuestion={gradientQuestions.positionIVandsoejlen}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <SalinitetHelpElement />}
                gradientQuestion={gradientQuestions.salinitet}
                gradienter={gradienter}
            />
            <GradientWrapper assessmentId={assessmentId}
                help={() => <VanddybdeHelpElement />}
                gradientQuestion={gradientQuestions.vanddybde}
                gradienter={gradienter}
            />
        </div>
    )
}

export default Gradienter