import React from 'react'
import {
    Typography
} from '@material-ui/core'
import { FixMeLater } from '../assessments/components/GlobalErrorMessage'

const ClippedTextbox = (props : FixMeLater) => {
    let { text, lines } = props
    if (!lines) {
        lines = 4
    }
    return (
        <div style={{ clear: 'both'}}>
        <div style={{ textAlign: 'justify', marginRight: 8, height: 1.25 * lines + 'rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography variant='body2'>
                {text}
            </Typography>
        </div>...
        </div>
    )
}

export default ClippedTextbox