import React, { ReactElement } from 'react';

import Dashboard from './mui/Dashboard';
import CreateAssessmentsPage from './admin/createAssessments/CreateAssessmentsPage'
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import EditAssessmentPage from './assessments/EditAssessmentPage'
import ViewAssessmentsPage from './assessments/ViewAssessmentsPage'
import { useEffect } from 'react';
import { checkLogin, logout, login } from './redux/actions/loginActions';
import Login from './mui/Login';
import { CircularProgress } from '@material-ui/core';
import ViewQAAssessmentsPage from './assessmentQA/ViewQAAssessmentsPage';
import { confirmActionRejected, confirmActionAccepted, hideMessage } from './redux/actions/uiActions';

import { Button, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ManageSpeciesPage from './admin/manageSpecies/ManageSpeciesPage'
import ManageUserPage from './admin/manageUsers/ManageUserPage'
import ManageAssessmentsPage from './admin/manageAssessments/ManageAssessmentsPage';
import UserProfilePage from './userProfile/UserProfilePage';
import AssessmentQAPage from './assessmentQA/AssessmentQAPage';
import ManageSpeciesFamily from './admin/manageSpecies/ManageSpeciesFamily';
import ManageSpeciesGroup from './admin/manageSpecies/ManageSpeciesGroup';
import ManageSpeciesOrder from './admin/manageSpecies/ManageSpeciesOrder';
import ViewFagligKommenteringPage from './fagligKommentering/ViewFagligKommenteringPage';
import { statusEnum } from './service/assessmentStatusEnum';
import ManagePubliceringerPage from './admin/managePubliceringer/ManagePubliceringerPage';
import ManageAssessorsPage from './admin/manageAssessors/ManageAssessorsPage';
import ManageUserGroup from './admin/manageSpecies/ManageUserGroup';
import { RedlistStore } from './redux/reducers';
import { AppStore, UserType } from './redux/reducers/appReducer';
import { UIStore } from './redux/reducers/uiRed';

export interface AdminOnlyProps {
  user: UserType | null
  children: ReactElement
}

const AdminOnly = (props : AdminOnlyProps) => {
  const { user } = props
  if (user === null) {
    return (<Redirect to='/' />)
  }
  if (user.role !== 'ADMIN') {
    return (<Redirect to='/' />)
  }
  return props.children
}

function App() {
  const dispatch = useDispatch()
  const apiError = useSelector<RedlistStore>(state => state.ui.apiError)
  const goBack = useSelector<RedlistStore>(state => state.ui.goBack);
  const title = useSelector<RedlistStore>(state => state.ui.title);
  const saving = useSelector<RedlistStore>(state => state.ui.saving)
  const loading = useSelector<RedlistStore>(state => state.ui.loading);
  const showMenu = useSelector<RedlistStore>(state => state.ui.showMenu);
  const { version, loggedIn, user, error } = useSelector<RedlistStore>(state => state.app) as AppStore
  const { confirmAction, message } = useSelector<RedlistStore>(state => state.ui) as UIStore
  
  useEffect(() => {
    //dispatch(login('kavi', '1234'))
    dispatch(checkLogin())
  }, [dispatch])

  if (loggedIn === 'pending') {
    return (<CircularProgress />)
  }
  if (loggedIn === false) {
    return (<Login version={version} errorMessage={error} onSubmit={(username, password, rememberMe) => dispatch(login(username, password, rememberMe))} />)
  }

  // console.log('ui.loading', loading);
  return (
    <React.Fragment>
      <Dialog
        open={Boolean(message)}
        onClose={() => dispatch(hideMessage())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message?.title}</DialogTitle>
        <DialogContent>
          <Typography>{message?.text}</Typography>
        </DialogContent>
        <DialogActions>          
          <Button onClick={() => dispatch(hideMessage(message?.action))} variant='outlined' color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(confirmAction)}
        onClose={() => dispatch(confirmActionRejected())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmAction?.header}</DialogTitle>
        <DialogContent>
          {confirmAction?.contentList.map((text : string, idx : number) => (<Typography key={idx}>{text}</Typography>))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(confirmActionRejected())} variant='outlined' color="secondary" autoFocus>
            Fortryd
          </Button>
          <Button onClick={() => dispatch(confirmActionAccepted(confirmAction.actions))} variant='outlined' color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dashboard
        version={version}
        appError={Boolean(apiError)}
        userrole={user?.role}
        userFullname={user?.name}
        goBackEnabled={goBack}
        showMenu={showMenu}
        saving={saving}
        loading={loading}
        title={title}
        notificationCount={0}
        onSignOut={() => { dispatch(logout()) }}>
        <Switch>
          <Route path="/" exact>
            <div />
          </Route>
          <Route path='/notfound' exact>
              <div>Page not found</div>
          </Route>
          <Route path="/profil" exact>
              <UserProfilePage />
          </Route>
          <Route path='/admin/brugere' exact>
            <AdminOnly user={user}>
              <ManageUserPage />
            </AdminOnly>
          </Route>
          <Route path='/admin/brugergrupper' exact>
            <AdminOnly user={user}>
              <ManageUserGroup />
            </AdminOnly>
          </Route>
          <Route path='/admin/assessors' exact>
            <AdminOnly user={user}>
              <ManageAssessorsPage />
            </AdminOnly>
          </Route>
          <Route path="/admin/vurderinger" exact>
            <AdminOnly user={user}>
              <CreateAssessmentsPage />
            </AdminOnly>
          </Route>
          <Route path="/admin/redigerVurderinger" exact>
            <AdminOnly user={user}>
              <ManageAssessmentsPage />
            </AdminOnly>
          </Route>    
          <Route path='/admin/familier' exact>
            <AdminOnly user={user}>
              <ManageSpeciesFamily />
            </AdminOnly>
          </Route>
          <Route path='/admin/artsgrupper' exact>
            <AdminOnly user={user}>
              <ManageSpeciesGroup />
            </AdminOnly>
          </Route>
          <Route path='/admin/ordener' exact>
            <AdminOnly user={user}>
              <ManageSpeciesOrder />
            </AdminOnly>
          </Route>
          <Route path='/admin/arter' exact>
            <AdminOnly user={user}>
              <ManageSpeciesPage />
            </AdminOnly>
          </Route>
          <Route path='/admin/publiceringer' exact>
            <AdminOnly user={user}>
              <ManagePubliceringerPage />
            </AdminOnly>
          </Route>
          <Route path="/qa" exact>
            <ViewQAAssessmentsPage />
          </Route>  
          <Route path="/qa/:id" exact>
            <AssessmentQAPage title='Kvalitetssikring'/>
          </Route>    
          <Route path="/kommentering" exact>
            <ViewFagligKommenteringPage title='Faglig Kommentering' path='kommentering' status={statusEnum.values().kommentering} />
          </Route>  
          <Route path="/kommentering/:id" exact>
            <AssessmentQAPage title='Faglig Kommentering' />
          </Route>    
          <Route path="/godkendelse" exact>
            <ViewFagligKommenteringPage title='Godkendelse' path='godkendelse' status={statusEnum.values().endelig_kvalitetssikring} />
          </Route>  
          <Route path="/godkendelse/:id" exact>
            <AssessmentQAPage title='Godkendelse' />
          </Route>    
          <Route path="/vurderinger" exact>
            <ViewAssessmentsPage />
          </Route>      
          <Route path="/vurderinger/:id">
            <EditAssessmentPage />
          </Route>
          <Route path="/:any">
            <div>Siden blev ikke fundet</div>
          </Route>

        </Switch>
      </Dashboard>
    </React.Fragment>
  );
}

//export default connect(mapStateToProps, mapDispatchToProps)(App);
export default App;
