import React from 'react'
import { LabelledValue, enumConverter, booleanConverterHideNull, Section } from '../../../mui/Section'
import { FixMeLater } from '../../components/GlobalErrorMessage'
import { adjustmentPreliminaryEnum } from '../enums/adjustmentEnums'

import { adjustmentQuestions } from '../questions'

const E_OpNedKategoriseringView = (props : FixMeLater) => {
    const adjustment = props.adjustment || {}
    const {showNull, showtitle} = props
    return (
        <Section title={showtitle && 'Op/Ned-kategorisering'}>
            <LabelledValue showNull={showNull} label={adjustmentQuestions.affectedByNeighbours.label} value={adjustment.affectedByNeighbours} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.preliminaryAdjustment.label} value={adjustment.preliminaryAdjustment} converter={enumConverter(adjustmentPreliminaryEnum)} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.immigrantPropagulesReproducing.label} value={adjustment.immigrantPropagulesReproducing} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.decreaseInPropagulesExpected.label} value={adjustment.decreaseInPropagulesExpected} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.regionalSink.label} value={adjustment.regionalSink} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.deterioratingConditionsOutsideRegion.label} value={adjustment.deterioratingConditionsOutsideRegion} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.deterioratingConditionsInsideRegion.label} value={adjustment.deterioratingConditionsInsideRegion} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={adjustmentQuestions.sourceSinkDynamicsAcrossBorders.label} value={adjustment.sourceSinkDynamicsAcrossBorders} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} fullWidth label={adjustmentQuestions.adjustmentRemark.label} value={adjustment.adjustmentRemark} />
        </Section>
    )
}

export default E_OpNedKategoriseringView