import {
  ASSESSMENT_FETCHED,
  SET_ASSESSMENT_CRITERIA,
  SET_PREVIOUS_ASSESSMENTS,
  SET_CRITERIA_VALIDATION,
  SET_PHASE,
  SET_ASSESSMENT_COMMENTS,
  SET_THREATS,
  SET_OVERALL_THREATS,
  SET_CATEGORY_CHANGE,
  SET_CATEGORY_CHANGE_VALIDATION,
  SET_THREATMETA,
} from '../editAssessment/editAssessmentActions'
import { SET_CATEGORY } from '../actions/categoryActions'
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { UseArterDkEnumType } from '../../assessments/sections/enums/enums'


export interface CommentType {
  id: number;
  created: number;
  comment: string;
  user: string;
}


export interface CommentObject extends Record<string, CommentType[]> {

}

export type CategoryChangeReasonRunde = '2010' | '2019'

export type CategoryChangeReasonType = {
  value?: string;
  label?: string;
  sortOrder: number;
  object: Record<string, string>;
  data: null | Record<string, string>;
  reason?: string;
}

export type CategoryChangeType = {
  id?: number;
  vurderingsrundeId?: number;
  comment: string;
  reasons: CategoryChangeReasonType[];
}

export type CategoryChangesType = {
  '2010'? : CategoryChangeType
  '2019'? : CategoryChangeType  
}

export type SpeciesInformation = {
  arterDkId: string | null
  author?: string
  id: number
  population: string
  scientificName: string
  vernacularName: string | null
}

export type GeographyCriteria = {
  extentOfOccurrence: number | null;
  areaOfOccupancy: number | null;
  extentOfTheSpeciesIsFragmented: boolean | null;
  extentLimitedToNoOfLocations: number | null;
  continuedReduction: FixMeLater;
  extremeFluctuations: boolean | null;
  geographicExtentRemark: string | null;
  geographySnapshotId: string | null;
}

export type AssessmentCriteria = {
  assessmentId?: number;
  preliminary: FixMeLater;
  reduction: FixMeLater;
  geography: GeographyCriteria;
  population: FixMeLater;
  quantitativeAnalysis: FixMeLater;
  adjustment: FixMeLater;
  global: {
    possiblyRE?: boolean;
    useArterDkData?: UseArterDkEnumType;
  }
}

export interface EditAssessmentStore {
  assessmentId: number | null
  assessorId: number | null
  category: FixMeLater | null
  speciesInformation: SpeciesInformation | null
  assessmentCriteria: AssessmentCriteria | null
  threat: FixMeLater | null
  criteriaValidationResult: FixMeLater | null
  categoryChangeValidationResult: FixMeLater | null
  categoryChangeReasons: CategoryChangesType
  previousAssessments: FixMeLater[]
  phase: string | null
  comments: FixMeLater[] | null | CommentObject
}

const initialState: EditAssessmentStore = {
  assessmentId: null,
  assessorId: null,
  category: null,
  speciesInformation: null,
  assessmentCriteria: null,
  threat: null,
  criteriaValidationResult: null,
  categoryChangeValidationResult: null,
  categoryChangeReasons: {},
  previousAssessments: [],
  phase: null,
  comments: null,
}

const assessment = (state = initialState, action: FixMeLater) => {
  switch (action.type) {
    case SET_CATEGORY:
      return { ...state, category: action.payload.category }
    case SET_ASSESSMENT_CRITERIA:
      return { ...state, assessmentCriteria: action.payload }
    case SET_CATEGORY_CHANGE_VALIDATION:
      return { ...state, categoryChangeValidationResult: action.payload }
    case SET_OVERALL_THREATS:
      return { ...state, threat: { ...state.threat, overallThreats: action.payload.overallThreats } }
    case SET_THREATS:
      return { ...state, threat: { ...state.threat, threats: action.payload.threats } }
    case SET_THREATMETA:
      return { ...state, threat: { ...state.threat, meta: action.payload.threatMeta } }
    case SET_CRITERIA_VALIDATION:
      return { ...state, criteriaValidationResult: action.payload }
    case ASSESSMENT_FETCHED:
      const { permissions } = action.payload
      const { id, assessorId, speciesInformation, assessmentCriteria, threat } = action.payload.item
      return { ...state, assessorId, assessmentId: id, speciesInformation, assessmentCriteria, threat, permissions }
    case SET_PREVIOUS_ASSESSMENTS:
      return { ...state, previousAssessments: action.payload.assessments }
    case SET_CATEGORY_CHANGE:
      return { ...state, categoryChangeReasons: action.payload.categoryChangeReasons }
    case SET_PHASE:
      return { ...state, phase: action.payload.phase }
    case SET_ASSESSMENT_COMMENTS:
      return { ...state, comments: action.payload.comments }
    default:
      return state
  }
}

export default assessment
