import React from 'react'
import { TextHelpElement, TextHelpParagraph, TextHelpWithSectionsElement, TextHelpSection } from './help'
import { adjustmentQuestions } from '../questions'
import { adjustmentPreliminaryObject } from '../enums/adjustmentEnums' 

export const AffectedByNeighboursHelpElement = props => {
    return (
        <TextHelpElement title={adjustmentQuestions.affectedByNeighbours.label}>
            Svar ja, hvis bestande udenfor landets grænser kan påvirke de danske bestande eller hvis de danske kan påvirke bestande i udlandet. Svar ja, hvis du er i tvivl. 
            Svar nej, hvis det er helt sikkert, at det ikke er relevant at op/nedkategorisere den pågældende art. Hvis arten f.eks. ikke forekommer i
            umiddelbar nærhed af Danmarks grænser og således ikke kan påvirke de danske bestande, eller hvis udenlandske bestande under ingen
            omstændigheder har mulighed for at udveksle spredningsenheder med de danske.
        </TextHelpElement>
    )
}


export const PreliminaryAdjustmentHelpElement = props => (
    <TextHelpWithSectionsElement title={adjustmentQuestions.preliminaryAdjustment.label}>
        <TextHelpSection title={adjustmentPreliminaryObject.kolonisering.label}>
            <TextHelpParagraph>
                Hvis bestanden er i fremgang i nabolandene, men endnu er meget lille i Danmark, er der oftest tale om en koloniseringsfase
                for den pågældende art. I så fald skal arten nedkategoriseres to trin for at afspejle den nedsatte risiko for at uddø i
                dette scenarie. Husk også at dokumentere tilstanden i nabolandene.
            </TextHelpParagraph>
        </TextHelpSection>
        <TextHelpSection title={adjustmentPreliminaryObject.demografiskUstabil.label}>
            <TextHelpParagraph>
                Vælg, hvis der forventes en tilbagegang i artens bestand(e) i nabolandene og den danske bestand er demografisk ustabil,
                dvs. der er udsving i demografiske faktorer såsom dødsrate, fødselsrate, emigration og immigration, der negativt påvirker
                den nationale bestands sandsynlighed for at uddø. Husk også at dokumentere tilstanden i nabolandene.
            </TextHelpParagraph>
        </TextHelpSection>
        <TextHelpSection title={adjustmentPreliminaryObject.visiting.label}>
            <TextHelpParagraph>
                Hvis arten regelmæssigt gæster Danmark fx som rasteplads eller for at udnytte ressourcer i landet, vælges dette svar.
                Det gælder f.eks. for fugle som besøger landet regelmæssigt, men ikke yngler her. Bemærk, at der er forskel på arter, der findes i landet i form af
                strejfende individer, og arter der regelmæssigt gæster landet. Førstnævnte, skal henføres til kategorien NA i indledende vurdering.
            </TextHelpParagraph>
        </TextHelpSection>
        <TextHelpSection title={adjustmentPreliminaryObject.breeding.label}>
            <TextHelpParagraph>
                Hvis arten yngler i landet vælges dette svar. Det gælder også trækfugle som regelmæssigt yngler her. Hvis arten kun yngler i særligt gunstige år og 
                kun relativt sjældent, men i øvrigt kommer her regelmæssigt svares i stedet ja til at arten er en regelmæssig gæst (se ovenfor).
            </TextHelpParagraph>
        </TextHelpSection>
    </TextHelpWithSectionsElement>
)

export const Q3aHelpElement = props => (
    <TextHelpElement title={adjustmentQuestions.immigrantPropagulesReproducing.label}>
        Svar ja, hvis der findes udenlandske bestande af samme art, hvis spredningsenheder kan nå landets bestande. Det gælder f.eks.,
        hvis den danske bestand er en del af en større metapopulation med udenlandske levesteder. Det gælder også, hvis arten spreder sig
        over lange afstande. En forudsætning for at svare ja er, at man ved at spredningsenheder fra udenlandske bestande kan overleve og
        tilpasse sig lokale forhold. En anden forudsætning for at svare ja er, at der er uudnyttede levesteder tilgængelige for de udenlandske spredningsenheder,
        så de kan give ophav til nye bestande i Danmark. Hvis ikke forudsætningerne gør sig gældende, svares nej. Svar nej, hvis der forekommer effektive barrierer,
        der forhindrer en spredning til og fra udenlandske bestande. Ved tvivl, svar ved ikke.
    </TextHelpElement>
)

export const Q3bHelpElement = props => (
    <TextHelpElement title={adjustmentQuestions.decreaseInPropagulesExpected.label}>
        Svar ja, hvis det forventes, at tilførslen af spredningsenheder aftager i fremtiden.
        Til besvarelse af dette spørgsmål kan det være nyttigt at overveje følgende:
        Hvor almindelig er arten i naboregionerne? Er bestandene dér stabile, i fremgang eller tilbagegang?
        Er der væsentlige negative påvirkninger mod disse bestande? Er det muligt for dem at producere væsentlige mængder af spredningsenheder,
        og vil de vedblive hermed inden for en overskuelig fremtid?
    </TextHelpElement>
)

export const Q3cHelpElement = props => (
    <TextHelpElement title={adjustmentQuestions.regionalSink.label}>
        Svar nej, hvis de nationale bestande er selvopretholdende med positiv formeringsrate over en årrække.
        Svar ja, hvis de er afhængige af indvandring for at kunne overleve i længden.
    </TextHelpElement>
)

export const Q3dHelpElement = props => (
    <TextHelpElement title={adjustmentQuestions.deterioratingConditionsOutsideRegion.label}>
        Svar ja, hvis artens levevilkår forringes eller forventes at blive det indenfor dens yngleområde eller i andre områder, som arten benytter som ressource. 
        Se definition samt eksempler på forringet habitatkvalitet under "Tilbagegangen er skønnet/beregnet/forventet/udledt/formodet og baseret på" i sektionen 
        "Tilbagegang i bestand".
    </TextHelpElement>
)

export const Q3eHelpElement = props => (
    <TextHelpElement title={adjustmentQuestions.deterioratingConditionsInsideRegion.label}>
        Svar ja, hvis artens levevilkår forringes eller forventes at blive det i Danmark. Se definition samt eksempler på forringet habitatkvalitet under 
        "Tilbagegangen er skønnet/beregnet/forventet/udledt/formodet og baseret på" i sektionen "Tilbagegang i bestand".
    </TextHelpElement>
)

export const Q3fHelpElement = props => (
    <TextHelpElement title={adjustmentQuestions.sourceSinkDynamicsAcrossBorders.label}>
        Hvis ynglebestanden(e) udenfor landet er meget lille/små eller indskrænke(t)(ede), er det usandsynligt at den/de vil være i stand til at redde den danske population. 
        På den anden side, hvis ynglebestanden(e) udenfor landet er betragtelig(e), og dens/deres forhold ikke er under forringelse, er der en større chance for, at den/de 
        kan redde den danske bestand, og sandsynligheden for udryddelse er derfor mindre i Danmark. Man skal eksempelvis svare nej her, hvis den globale
        bestand af arten er rødlistet efter kriterie D, dvs. at man næsten altid skal svare ja til dette spørgsmål.
    </TextHelpElement>
)