import RedlistApi, { LoginItem, RefreshUserResponse, SingleItemResponseWithStatus } from '../../service/redlistApi'
import RedlistPublishApi from '../../service/redlistPublishApi'
import ArterDkApi from '../../service/arterDkApi'
import CrudProvider from '../../genericCrud/provider/crudProvider'
import Cookies from 'universal-cookie'
import {
    takeEvery,
    call,
    put,
    apply,
    takeLeading,
    delay
} from 'redux-saga/effects'
import {
    LOGIN_FAILED,
    REQUEST_LOGIN,
    LOGIN_OK,
    REQUEST_LOGOUT,
    LOGOUT_OK,
    CHECK_LOGIN,
    LOGOUT_FAILED,
    REFRESH_TOKEN,
    SET_USER,
    refreshToken,
    LOGIN_UNAUTHORIZED
} from '../actions/loginActions'
import { showError, showMessage } from '../actions/uiActions'
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'

const cookies = new Cookies();

function* requestLogin(action : FixMeLater) {
    const {
        rememberMe,
        username,
        password
    } = action.payload
    try {
        const data : SingleItemResponseWithStatus<LoginItem> = yield apply(RedlistApi, RedlistApi.login, [username, password])
        if (!data) {
            yield put({
                type: LOGIN_FAILED
            })
            return
        }
        if (data.status === 401) {
            yield put({
                type: LOGIN_UNAUTHORIZED                
            })
            return
        }
        if (data.status >= 400) {
            yield put({
                type: LOGIN_FAILED,
                message: 'Login fejlede med status ' + data.status 
            })
        }
        //console.log(data)
        yield put({
            type: LOGIN_OK,
            payload: {
                rememberMe,
                user: data.body.item
            }
        })


    } catch (error : unknown) {
        const _e = error as Error || { message: ''}
        console.log({_message: _e.message})
        yield put({
            type: LOGIN_FAILED,
            payload: {
                error,
                message: 'Login mislykkedes - uventet fejl: ' + _e.message
            }
        })
    }
}

function* loginOk(action : FixMeLater) {
    try {
        const {
            user,
            rememberMe
        } = action.payload

        const token = user.token
        const jwtToken = user.shareableToken

        if (rememberMe) {
            yield apply(cookies, cookies.set, ["redlist2.au.dk", token])
            yield apply(cookies, cookies.set, ["redlist2.au.dk.user", JSON.stringify({
                ...user,
                token: undefined
            })])
        }

        yield call(() => RedlistApi.setToken(token))
        yield call(() => RedlistPublishApi.setToken(jwtToken))
        yield call(() => ArterDkApi.setRedlistToken(jwtToken))
        yield call(() => CrudProvider.setToken(token))
        yield delay(1000)
        yield put(refreshToken())
    } catch (error) {
        console.log(error)
        yield put(showMessage("Fejl i login-kode", "Uventet fejl", undefined))
    }
}

function* requestLogout() {
    try {
        cookies.remove('redlist2.au.dk')
        cookies.remove('redlist2.au.dk.user')
        yield call(() => RedlistApi.logout())
        RedlistPublishApi.setToken(undefined)
        RedlistApi.setToken(undefined)
        ArterDkApi.setRedlistToken(undefined)
        CrudProvider.setToken(undefined)
        yield put({
            type: LOGOUT_OK
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: LOGOUT_FAILED,
            payload: error
        })
    }
}

function* processLogoutFailed() {
    try {
        yield window.history.pushState(null, '', window.location.pathname)
        yield window.history.go()
    } catch (error) {
        console.log("Not good!")
        yield put(showError(error))
    }
}

function* processCheckLogin() {
    try {
        const authCookie = cookies.get('redlist2.au.dk')
        const userCookie = cookies.get("redlist2.au.dk.user")
        if (authCookie && userCookie) {
            const user = userCookie
            yield put({
                type: LOGIN_OK,
                payload: {
                    user: {
                        ...user,
                        token: authCookie
                    },
                    rememberMe: true
                }
            })
        } else {
            yield put({
                type: LOGOUT_OK
            })
        }
    } catch (error) {
        yield put(showMessage('Fejl i login-kode', 'Kunne ikke logge ind', undefined))
    }
}

function* processRefreshToken() {
    try {
        const data : RefreshUserResponse = yield apply(RedlistApi, RedlistApi.refreshToken, [])
        if (!data || data.item === null) {
            yield put({
                type: LOGIN_FAILED
            })
            return
        }
        yield put({
            type: SET_USER,
            payload: {            
                user: data.item
            }
        })
        
        const jwtToken = data.item.shareableToken;
        yield call(() => RedlistPublishApi.setToken(jwtToken))
        yield call(() => ArterDkApi.setRedlistToken(jwtToken))
        yield delay(50 * 60 * 1000)
        yield put(refreshToken())
    } catch (error) {
        yield put(showMessage('Error refreshing token', 'An unexpected error occurred', undefined))
    }
}

export default function* watcher() {
    yield takeLeading(REQUEST_LOGIN, requestLogin)
    yield takeEvery(LOGIN_OK, loginOk)
    yield takeLeading(REQUEST_LOGOUT, requestLogout)
    yield takeLeading(CHECK_LOGIN, processCheckLogin)
    yield takeEvery(LOGOUT_FAILED, processLogoutFailed)
    yield takeEvery(REFRESH_TOKEN, processRefreshToken)
}