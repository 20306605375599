import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Grid
} from '@material-ui/core'

import Select from 'react-select'

import DelayedTextField from '../../mui/DelayedTextField'
import { setFilter } from '../../redux/admin/manageAssessmentsPageActions'

const toOption = (e) => ({ id: e.id, value: e.id, label: e.name })

const idToOption = (id, options) => {
    if (!id) {
        return null;
    }
    return options.find(o => o.value === id)
}

const valuesToOptions = (list, options) => {
    if (!list) {
        return []
    }
    return list.map(v => options.find(o => o.value === v))
}

const ManageAssessmentsHeader = props => {
    const dispatch = useDispatch()
    const { filter } = useSelector(state => state.manageAssessmentsPage)
    const { vurderingsrunder, statuses, assessors, usergroups, onFilterChanged } = props

    const updateFilter = (value, property) => {
        const _f = { ...filter }
        _f[property] = value
        console.log(value, property, _f)
        dispatch(setFilter(_f))
        onFilterChanged(_f)
    }
    if (!vurderingsrunder) {
        return <></>
    }
    console.log(valuesToOptions(filter.statusList, statuses))
    return (
        <Grid style={{ padding: 16, paddingBottom: 0 }} item alignItems='baseline' container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
                <Select
                    value={valuesToOptions(filter.statusList, statuses)}
                    isClearable
                    isMulti
                    placeholder='Status'
                    onChange={values => updateFilter(values ? values.map(v => v.value) : null, 'statusList')}
                    options={statuses} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Select
                    value={idToOption(filter.usergroupId, usergroups.map(toOption))}
                    isClearable
                    placeholder='Grupper'
                    onChange={value => updateFilter(value?.id, 'usergroupId')}
                    options={usergroups.map(toOption)} />
            </Grid>
            <Grid item xs={false} sm={4} md={4}>
                <Select
                    value={idToOption(filter.vurderingsrundeId, vurderingsrunder.map(toOption))}
                    placeholder='Vurderingsrunde'
                    onChange={value => updateFilter(value?.id, 'vurderingsrundeId')}
                    options={vurderingsrunder.map(toOption)} />

            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Select
                    value={idToOption(filter.assessorId, assessors.map(toOption))}
                    isClearable
                    placeholder='Vurderet af'
                    onChange={value => updateFilter(value?.id, 'assessorId')}
                    options={assessors.map(toOption)} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Select
                    value={idToOption(filter.technicalApproverId, assessors.map(toOption))}
                    isClearable placeholder='Technical Approver'
                    onChange={value => updateFilter(value?.id, 'technicalApproverId')}
                    options={assessors.map(toOption)} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Select
                    value={idToOption(filter.approverId, assessors.map(toOption))}
                    isClearable placeholder='Approver'
                    onChange={(value : FixMeLater) => updateFilter(value?.id, 'approverId')}
                    options={assessors.map(toOption)} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DelayedTextField 
                    delay={1000}
                    fullWidth label='Artsnavn' value={filter.speciesName} onChange={value => updateFilter(value, 'speciesName')} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DelayedTextField delay={1000} fullWidth label='Familie' value={filter.speciesFamily} onChange={value => updateFilter(value, 'speciesFamily')} />
            </Grid>
            <Grid item xs={12} />
        </Grid>
    )
}

export default ManageAssessmentsHeader