import RedlistApi from '../../service/redlistApi'

import {
    put,
    select,
    apply,
    takeLeading,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects'
import {
    showSaving,
    hideSaving,
    showMessage,
    showError
} from '../actions/uiActions'

import {
    REQUEST_CREATE_SPECIES,
    DELETE_SPECIES,
    MANAGE_SPECIES_PAGE_CREATE_OK,
    MANAGE_SPECIES_PAGE_CREATE_ERROR,
    MANAGE_SPECIES_PAGE_SET_SPECIES,
    MANAGE_SPECIES_PAGE_INIT,
    hideCreateDialog,
    manageSpeciesPageInit,
    MANAGE_SPECIES_PAGE_SHOW_EDIT_DIALOG,
    MANAGE_SPECIES_PAGE_UPDATE_OK,
    MANAGE_SPECIES_PAGE_UPDATE_ERROR,
    MANAGE_SPECIES_PAGE_SET_FILTER,
    hideEditDialog,
    REQUEST_UPDATE_SPECIES,
    setFilter,
    setPage,
    MANAGE_SPECIES_PAGE_NEXT_PAGE,
    MANAGE_SPECIES_PAGE_PREV_PAGE,
    MANAGE_SPECIES_PAGE_SET_PAGE,
    REQUEST_SET_SPECIES_GROUP_FOR_SPECIES_LIST,
    MANAGE_SPECIES_SET_SPECIES_GROUP_FOR_SPECIES_LIST_OK,
    MANAGE_SPECIES_SET_SPECIES_GROUP_FOR_SPECIES_LIST_ERROR
} from './manageSpeciesPageActions'

function* processCreateSpecies(action) {
    try {
        yield put(showSaving())
        const data = yield apply(RedlistApi, RedlistApi.adminCreateSpecies, [action.payload.species])
        console.log(data)
        yield put({
            type: MANAGE_SPECIES_PAGE_CREATE_OK
        })
        yield put(manageSpeciesPageInit())
        yield put(hideCreateDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({
            type: MANAGE_SPECIES_PAGE_CREATE_ERROR,
            payload: 'Fejl'
        })
        yield put(showError(error))
    }
}

function* processUpdateSpecies(action) {
    try {
        yield put(showSaving())
        const id = action.payload.id
        const {
            scientificName,
            vernacularName,
            author,
            population,
            speciesFamilyId,
            arterDkId,
            speciesGroupId
        } = action.payload.species
        const patch = {
            scientificName,
            vernacularName: vernacularName || null,
            author: author || null,
            arterDkId: arterDkId || null,
            population,
            speciesFamilyId,
            speciesGroupId
        }
        const data = yield apply(RedlistApi, RedlistApi.adminUpdateSpecies, [id, patch])
        console.log(data)
        yield put({
            type: MANAGE_SPECIES_PAGE_UPDATE_OK
        })
        yield put(manageSpeciesPageInit())
        yield put(hideEditDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({
            type: MANAGE_SPECIES_PAGE_UPDATE_ERROR,
            payload: 'Fejl'
        })
        yield put(showError(error))
    }
}


function* processSetSpeciesGroupForSpeciesList(action) {
    try {
        yield put(showSaving())
        const { ids, speciesGroupId } = action.payload
        const patch = {
            speciesIds: ids,
            speciesGroupId
        }
        const data  = yield apply(RedlistApi, RedlistApi.adminBulkUpdateSpeciesGroup, [ patch ])
        console.log(data)
        yield put({
            type: MANAGE_SPECIES_SET_SPECIES_GROUP_FOR_SPECIES_LIST_OK
        })
        yield put(manageSpeciesPageInit())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({
            type: MANAGE_SPECIES_SET_SPECIES_GROUP_FOR_SPECIES_LIST_ERROR,
            payload: 'Fejl'
        })
        yield put(showError(error))
    }
}

function* processDeleteSpecies(action) {
    try {
        yield put(showSaving())
        const { id } = action.payload
        yield apply(RedlistApi, RedlistApi.adminDeleteSpecies, [id])       
        yield put(manageSpeciesPageInit())
        yield put(hideEditDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showMessage('Fejl', 'Kunne ikke slette art'))
    }
}

function* processShowEditSpecies(action) {
    try {
        const {
            species
        } = action.payload
        console.log(species)
        //const data = yield apply(RedlistApi, RedlistApi.loadSpeciesById, [ species.id ])
        //yield put({ type: MANAGE_SPECIES_PAGE_SET_SELECTED_SPECIES, payload: { species: data.item }})
    } catch (error) {
        yield put(showError(error))
    }
}

function* processInit(action) {
    try {
        //yield put(fetchAdminEntities())
        const filter = yield select(state => state.manageSpeciesPage.filter)
        yield put({
            type: MANAGE_SPECIES_PAGE_SET_FILTER,
            payload: {
                filter
            }
        })
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processNextPage(action) {
    try {
        const filter = yield select(state => state.manageSpeciesPage.filter)
        let page = filter.page + 1
        yield put(setPage(page))
    } catch (error) {
        console.log(error)
        yield put(showError(error))
    }
}


function* processPrevPage(action) {
    try {
        const filter = yield select(state => state.manageSpeciesPage.filter)
        let page = filter.page - 1
        if (page >= 1) {
            yield put(setPage(page))
        }
    } catch (error) {
        console.log(error)
        yield put(showError(error))
    }
}

function* processSetPage(action) {
    try {
        const {
            page
        } = action.payload
        const filter = yield select(state => state.manageSpeciesPage.filter)
        yield put(setFilter({
            ...filter,
            page
        }))
    } catch (error) {
        console.log(error)
        yield put(showError(error))
    }
}

function* processSetFilter(action) {
    try {
        const {
            filter
        } = action.payload
        const data = yield apply(RedlistApi, RedlistApi.loadSpeciesByFilter, [filter])
        const {
            items,
            count,
            pages
        } = data
        yield put({
            type: MANAGE_SPECIES_PAGE_SET_SPECIES,
            payload: {
                species: items,
                pages,
                count,
                queryId: new Date().getTime() + ''
            }
        })
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLatest(MANAGE_SPECIES_PAGE_NEXT_PAGE, processNextPage)
    yield takeLatest(MANAGE_SPECIES_PAGE_PREV_PAGE, processPrevPage)
    yield takeLatest(MANAGE_SPECIES_PAGE_SET_PAGE, processSetPage)
    yield takeLatest(MANAGE_SPECIES_PAGE_SET_FILTER, processSetFilter)
    yield takeLeading(MANAGE_SPECIES_PAGE_SHOW_EDIT_DIALOG, processShowEditSpecies)
    yield takeEvery(REQUEST_CREATE_SPECIES, processCreateSpecies)
    yield takeLatest(DELETE_SPECIES, processDeleteSpecies)
    yield takeLeading(REQUEST_UPDATE_SPECIES, processUpdateSpecies)
    yield takeLeading(MANAGE_SPECIES_PAGE_INIT, processInit)
    yield takeLeading(REQUEST_SET_SPECIES_GROUP_FOR_SPECIES_LIST, processSetSpeciesGroupForSpeciesList)
}