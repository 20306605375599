import { FixMeLater } from "../assessments/components/GlobalErrorMessage"

export type Token = (string | undefined)


const getData = async (response : FixMeLater) => {
    try {
        return await response.json()
    } catch (error) {
        return {}
    }
}

const call = async (url : FixMeLater, config : FixMeLater) => {
    const response = await fetch(url, config)
    try {
        const data = await getData(response)
        if (response.status >= 400) {
            return Promise.reject({
                status: response.status,
                error: data
            })
        }
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject({
            status: response.status,
            error
        })
    }
}

const getConfig = async (method : string, getToken : () => (string | undefined), i = 0) : Promise<FixMeLater> => {
    if (i > 50) {
        return Promise.reject('Cannot return config, no token')
    }
    const token = getToken()
    if (token) {
        return Promise.resolve({
            method,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    } else {
        console.log('attempt #', i)
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(getConfig(method, getToken, i + 1)), 10);
        })
    }
}

const getPostConfig = async (body : FixMeLater, getToken : () => (string | undefined)) => {
    const config : FixMeLater = await getConfig('POST', getToken)
    config.headers['Content-Type'] = 'application/json'
    config.body = JSON.stringify(body)   
    return Promise.resolve(config)
}

const getPutConfig = async (body : FixMeLater, getToken : () => (string | undefined)) => {
    const config = await getConfig('PUT', getToken)
    config.headers['Content-Type'] = 'application/json'
    config.body = JSON.stringify(body)   
    return Promise.resolve(config)
}

const getPatchConfig = async (body : FixMeLater, getToken : () => (string | undefined)) => {
    const config = await getConfig('PATCH', getToken)
    config.headers['Content-Type'] = 'application/json'
    config.body = JSON.stringify(body)   
    return Promise.resolve(config)
}

const getDeleteConfigWithBody = async (body : FixMeLater, getToken : () => (string | undefined)) => {
    const config = await getConfig('DELETE', getToken)
    config.headers['Content-Type'] = 'application/json'
    config.body = JSON.stringify(body)   
    return Promise.resolve(config)
}

const getDeleteConfig = async (getToken : () => (string | undefined)) => {
    const config = await getConfig('DELETE', getToken)
    return Promise.resolve(config)
}


const queryOptionsToParams = (queryOptions : FixMeLater) => {
    if (!queryOptions) {
        return '';
    }
    let parameters = [];
    for (const [key, value] of Object.entries(queryOptions)) {
        if (value && value !== null) {
            parameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(value as string));
        }
    }
    if (parameters.length === 0) {
        return '';
    }
    return '?' + parameters.join('&');
}

export const get = async (baseUrl : string, url : string, queryOptions : (Record<string, any> | null), getToken : () => (Token | undefined)) => {
    const config = await getConfig('GET', getToken)
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return call(_url, config)
}

export const getWithRetry = async (baseUrl : string, url : string, queryOptions : FixMeLater, getToken : () => (Token | undefined), attempt: number, maxAttempts: number) : Promise<FixMeLater> => {
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    if (attempt > maxAttempts) {
        throw new Error("Error fetching " + _url)
    }
    try {
        const config = await getConfig('GET', getToken)    
        let response = await call(_url, config)
        return response
    } catch (error) {
        console.log('error', error)
        return getWithRetry(baseUrl, url, queryOptions, getToken, attempt + 1, maxAttempts)
    }
}


export const getNoToken = async (baseUrl : string, url : string, queryOptions : FixMeLater) => {
    const config = {
        method: 'GET' 
    }
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return call(_url, config)
}

export const postNoToken = async (baseUrl : string, url : string, queryOptions : FixMeLater, body: any) => {
    const config : { method: string, headers: Record<string, string>, body?: string } = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }        
    }
    config.body = JSON.stringify(body)
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return call(_url, config)
}


export const deleteNoToken = async (baseUrl : string, url : string, queryOptions : FixMeLater) => {
    const config = {
        method: 'DELETE' 
    }
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return call(_url, config)
}

export const putNoToken = async <E> (baseUrl : string, url : string, body : E) : Promise<FixMeLater> => {
    const config = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }    
    const _url = baseUrl + url
    return call(_url, config)
}

export const callByConfig = async (baseUrl : string, url : string, config : FixMeLater, queryOptions? : FixMeLater) => {
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)    
    return call(_url, config)
}


export const put = async (baseUrl : string, url : string, body : FixMeLater, getToken : () => (string | undefined)) => {
    const config = await getPutConfig(body, getToken)     
    const _url = baseUrl + url
    return call(_url, config)
}


export const patch = async (baseUrl : string, url : string, body : FixMeLater, getToken : () => (string | undefined)) => {
    const config = await getPatchConfig(body, getToken)     
    const _url = baseUrl + url
    return call(_url, config)
}

export const post = async (baseUrl : string, url : string, body : Record<string, any>, getToken : () => (string | undefined)) => {
    const config = await getPostConfig(body, getToken) 
    const _url = baseUrl + url
    return call(_url, config)
}

export const del = async (baseUrl : string, url : string, getToken : () => (string | undefined)) => {
    const config = await getDeleteConfig(getToken) 
    const _url = baseUrl + url
    return call(_url, config)
}

export const delWithBody = async (baseUrl : string, url : string, body : FixMeLater, getToken: () => (string | undefined)) => {
    const config = await getDeleteConfigWithBody(body, getToken) 
    const _url = baseUrl + url
    return call(_url, config)
}