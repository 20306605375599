import React from 'react'

import ListPage from '../../genericCrud/ListPage'

const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Navn', property: 'name' }       
    ],
    selectable: false
}

const crudConfig = {
    key: 'speciesGroup',
    createTitle: 'Opret artsgruppe',
    filter: [
        { label: 'Navn', property: 'name' }
    ],
    properties: [
        { label: 'Navn', property: 'name', type: 'string', required: true }
    ]
}

const ManageSpeciesGroup = props => {
    return (
        <ListPage pageTitle='Administrer artsgrupper' crudConfig={crudConfig} tableConfig={tableConfig} />
    )
}

export default ManageSpeciesGroup