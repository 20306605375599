import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    ListItem,
    ListItemText
} from '@material-ui/core'

import { submenuStyle, submenuActiveStyle } from './components/styles'
import { setCurrentSubmenu } from '../redux/editAssessment/editAssessmentMenuActions'

const menu = (id, title) => ({ id, title })

export const menus = [
    menu(0, 'Hovedmiljøer'),
    menu(1, 'Levestedstyper'),
    menu(2, 'Økosystemer'),
    menu(3, 'Habitater'),
    menu(4, 'Overordnede substrater'),
    menu(5, 'Substrater'),
    menu(6, 'Funktioner'),
    menu(7, 'Lever af/på/i slægter/arter')
]
// rgba(0, 0, 0, 0.08)


export const filterAvailableMenus = (submenu, selectedHovedmiljoer, selectedLevestedstyper, selectedEcosystems, selectedOverordnedeSubstrater, selectedSubstrater, substrater) => {
    if (submenu.id === 0) {
        return true
    }
    const hasHabitats = selectedLevestedstyper.reduce((p, c) => {
        return p || c.hasHabitats
    }, false)
    const hasSubstrates = selectedLevestedstyper.reduce((p, c) => {
        return p || c.hasSubstrates
    }, false)
    if (submenu.id === 2) {
        return hasHabitats && selectedHovedmiljoer.length > 0
    }
    if (submenu.id === 3) {
        return hasHabitats && selectedEcosystems.length > 0
    }
    if (submenu.id === 4) {
        return hasSubstrates && selectedHovedmiljoer.length > 0
    }
    if (submenu.id === 5) {
        return hasSubstrates && selectedOverordnedeSubstrater.length > 0
    }
    if (submenu.id === 6) {
        return hasSubstrates && selectedSubstrater.length > 0
    }
    if (submenu.id === 7) {
        if (!hasSubstrates) {
            return false
        }
        if (selectedSubstrater.length === 0) {
            return false
        }
        const substraterWithLeverAf = selectedSubstrater.filter(s => Boolean(s.funktion.find(f => f === 'lever_af' || f === 'lever_paa' || f === 'lever_i')))
        const _substrater = substraterWithLeverAf.map(ss => substrater.find(s => s.id === ss.id))
        return _substrater.filter(s => s.hasDietGenus || s.hasDietSpecies).length > 0
    }
    return true
}


const LevestedSubmenu = (props) => {
    const dispatch = useDispatch()
    const {substrater} = useSelector(state => state.ekspertEntities.levestedsDefinition)
    const selectedHovedmiljoer = useSelector(state => state.editLevested.hovedmiljoer)
    const selectedLevestedstyper = useSelector(state => state.editLevested.levestedstyper)
    const selectedEcosystems = useSelector(state => state.editLevested.ecosystems)
    const selectedOverordnedeSubstrater = useSelector(state => state.editLevested.overordnedeSubstrater)
    const selectedSubstrater = useSelector(state => state.editLevested.substrater)
    const activeSubmenu = useSelector(state => state.editAssessmentMenu.currentSubmenu)
    return (
        <React.Fragment>
            {
                menus.filter(m => filterAvailableMenus(m, selectedHovedmiljoer, selectedLevestedstyper, selectedEcosystems, selectedOverordnedeSubstrater, selectedSubstrater, substrater)).map(m => {
                    return (
                        <ListItem
                            key={m.id}
                            style={activeSubmenu === m.id ? submenuActiveStyle : submenuStyle}
                            onClick={() => dispatch(setCurrentSubmenu(m.id))}
                            button>
                            <ListItemText primary={m.title} />
                        </ListItem>
                    )
                })
            }
        </React.Fragment>
    )
}

export default LevestedSubmenu
