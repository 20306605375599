import React from 'react'
import AsyncSelect from 'react-select/async'
import { Grid, Button, Typography, TextField, LinearProgress } from '@material-ui/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { propertyStateToPatch } from './EditDialog'
import crudProvider from './provider/crudProvider'

const nonEmptyValidator = (value) => {
  if (!value) {
    return { ok: false, message: 'Der skal indtastes en værdi' }
  }
  return { ok: true }
}

const validatePropertyState = (propertyState, properties) => {
  const validation = {}
  properties.forEach((p) => {
    if (p.required) {
      if (p.type === 'string') {
        validation[p.property] = nonEmptyValidator(propertyState[p.property])
      } else if (p.type === 'list') {
        validation[p.property] = nonEmptyValidator(propertyState[p.property].id)
      }
    }
  })
  return validation
}

const createEmptyPropertyState = (properties) => {
  const propertyState = {}
  properties.forEach((p) => {
    if (p.type === 'string') {
      propertyState[p.property] = ''
    } else if (p.type === 'list') {
      propertyState[p.property] = {
        id: '',
        value: '',
      }
    }
  })
  return propertyState
}

const StringProperty = (props) => {
  const { p, propertyState, setPropertyState, validation } = props
  let ok = true
  let message = ''
  if (p.required) {
    ok = validation.ok
    message = validation.message
  }
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        label={p.label}
        error={Boolean(!ok)}
        helperText={message}
        value={propertyState[p.property]}
        onChange={(evt) => {
          let newPropertyState = { ...propertyState }
          newPropertyState[p.property] = evt.target.value
          setPropertyState(newPropertyState)
        }}
      />
    </Grid>
  )
}


const MultiValueListProperty = (props) => {
  const { p, propertyState, setPropertyState, validation } = props
  let ok = true
  let message = ''
  if (p.required) {
    ok = validation.ok
    message = validation.message
  }
  const loadOptions = () => (inputValue, callback) => {
    const filter = {}
    const idProperty = p.reference.idProperty
    const valueProperty = p.reference.valueProperty
    filter[valueProperty] = inputValue
    crudProvider.loadByFilter(p.reference.key, filter).then((data) => {
      console.log('fetched', data)
      callback(data.items.map((i) => ({ value: i[idProperty], label: i[valueProperty] })))
    })
  }
  const selected = propertyState[p.property]
  console.log({selected})
  return (
    <Grid item xs={12}>
      {!ok && (
        <Typography variant="subtitle2" color="error">
          {message}
        </Typography>
      )}
      <AsyncSelect
        value={selected}
        defaultOptions
        loadOptions={loadOptions()}
        isMulti
        onChange={(e) => {
          const newPropertyState = { ...propertyState }
          newPropertyState[p.property] = e
          setPropertyState(newPropertyState)
        }}
      />
    </Grid>
  )
}

const ListProperty = (props) => {
  const { p, propertyState, setPropertyState, validation } = props
  let ok = true
  let message = ''
  if (p.required) {
    ok = validation.ok
    message = validation.message
  }
  const loadOptions = () => (inputValue, callback) => {
    const filter = {}
    const idProperty = p.reference.idProperty
    const valueProperty = p.reference.valueProperty
    filter[valueProperty] = inputValue
    crudProvider.loadByFilter(p.reference.key, filter).then((data) => {
      console.log('fetched', data)
      callback(data.items.map((i) => ({ value: i[idProperty], label: i[valueProperty] })))
    })
  }
  const value = propertyState[p.property].id
  const label = propertyState[p.property].value
  console.log('selected', value, label)
  return (
    <Grid item xs={12}>
      {!ok && (
        <Typography variant="subtitle2" color="error">
          {message}
        </Typography>
      )}
      <AsyncSelect
        value={{ value, label }}
        defaultOptions
        loadOptions={loadOptions()}
        onChange={(e) => {
          const newPropertyState = { ...propertyState }
          newPropertyState[p.property] = {
            id: e.value,
            value: e.label,
          }
          setPropertyState(newPropertyState)
        }}
      />
    </Grid>
  )
}

const renderProperty = (p, propertyState, setPropertyState, validation) => {
  if (p.type === 'string') {
    return (
      <StringProperty
        key={p.property}
        p={p}
        propertyState={propertyState}
        setPropertyState={setPropertyState}
        validation={validation}
      />
    )
  } else if (p.type === 'list') {
    return (
      <ListProperty
        key={p.property}
        p={p}
        propertyState={propertyState}
        setPropertyState={setPropertyState}
        validation={validation}
      />
    )
  } else if (p.type === 'multilist') {
    return (
      <MultiValueListProperty
        key={p.property}
        p={p}
        propertyState={propertyState}
        setPropertyState={setPropertyState}
        validation={validation} />
    )
  } else {
    return null
  }
}

const CreateDialog = (props) => {
  const { title, properties, onClose, onSave } = props
  const { createItemsError } = useSelector((state) => state.crudPage)
  const { saving } = useSelector((state) => state.ui)
  const [propertyState, setPropertyState] = useState(createEmptyPropertyState(properties))
  const validation = validatePropertyState(propertyState, properties)
  let valid = true
  for (let key in validation) {
    if (validation[key].ok === false) {
      valid = false
    }
  }
  return (
    <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
      <Typography variant="h6">{title}</Typography>
      <LinearProgress variant={saving ? 'indeterminate' : 'determinate'} value={0} />
      <Grid container spacing={2}>
        <Grid item xs={1} />
        {properties.map((p) => renderProperty(p, propertyState, setPropertyState, validation[p.property]))}
        <Grid item xs={12}></Grid>
        {createItemsError ? (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              Fejl under oprettelse
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <Button onClick={() => onClose()} fullWidth variant="contained" color="secondary">
            Luk
          </Button>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <Button
            onClick={() => onSave(propertyStateToPatch(propertyState, properties))}
            disabled={!valid}
            fullWidth
            variant="contained"
            color="primary"
          >
            Opret
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateDialog
