import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import Footer from '../components/Footer'

import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Tooltip
} from '@material-ui/core'
import { 
    Skeleton
} from '@material-ui/lab'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { addOverallThreat, removeOverallThreat, addThreat, removeThreat, saveThreatMeta } from '../../redux/editAssessment/editAssessmentActions'
import { ThreatsUnknownHelp } from './help/threatHelp'
import SectionTitle from '../../mui/SectionTitle'

const tagToOption = tag => ({ id: tag, value: tag, label: tag })

const filterThreats = selectedtags => threat => selectedtags.length === 0 || threat.tags.find(tag => selectedtags.find(selected => selected.value === tag))

const Threat = props => {
    const { threat, selectedThreats, onSelectThreat, onRemoveThreat } = props
    return (
        <Tooltip title={threat.description || threat.name} interactive={false} placement='top'>
            <FormControlLabel
                aria-label={threat.name}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                    <Checkbox onChange={evt => evt.target.checked ? onSelectThreat(threat) : onRemoveThreat(threat)} checked={Boolean(selectedThreats.find(s => s.threatDefinitionId === threat.id))}
                    />}
                label={threat.name}
            />
        </Tooltip>
    )
}

const OverallThreatPanel = props => {
    const {
        selectedOverallThreats,
        overallThreat,
        selectedThreats,
        threats,
        onSelectOverallThreat,
        onRemoveOverallThreat,
        onSelectThreat,
        onRemoveThreat,
        isUsingTags
    } = props
    const _threats = threats.filter(t => t.overallThreatDefinitionId === overallThreat.id)
    const [expanded, setExpanded] = useState(false)
    const [forceClosed, setForceClosed] = useState(false)
    const shouldExpand = isUsingTags && _threats.length > 0
    const controlExpanded = value => {
        if (shouldExpand) {
            setForceClosed(!forceClosed)
            setExpanded(value)
        } else {
            setExpanded(value)
        }
    }


    //console.log('hovedtrussel', isUsingTags && _threats.length > 0)
    return (
        <Accordion expanded={!forceClosed && (shouldExpand || expanded)} onChange={(evt, value) => controlExpanded(value)}>
            <AccordionSummary
                style={{ backgroundColor: '#eee' }}
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
            >
                <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    onChange={evt => evt.target.checked ? onSelectOverallThreat(overallThreat.id) : onRemoveOverallThreat(overallThreat.id)}
                    control={<Checkbox checked={Boolean(selectedOverallThreats.find(s => s.overallThreatDefinitionId === overallThreat.id))} />}
                    label={overallThreat.name + ' (' + _threats.length + ')'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    {_threats.map(threat => <Threat onRemoveThreat={onRemoveThreat} onSelectThreat={onSelectThreat} key={threat.id} selectedThreats={selectedThreats} threat={threat} />)}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    )
}

const G_Trusler = props => {
    const { onNext, onClose } = props
    const dispatch = useDispatch()
    const validationResult = useSelector(state => state.editAssessment.criteriaValidationResult?.threat)

    const { threats, tags, overallThreats } = useSelector(state => state.ekspertEntities.threatDefinition)
    const assessmentId = useSelector(state => state.editAssessment.assessmentId)
    const selectedThreats = useSelector(state => state.editAssessment.threat.threats)
    const selectedOverallThreats = useSelector(state => state.editAssessment.threat.overallThreats)
    const meta = useSelector(state => state.editAssessment.threat.meta)
    const [selectedTags, setSelectedTags] = useState([])

    if (!threats || !tags || !overallThreats) {
        return (
            <Skeleton />
        )
    }

    console.log('meta', meta)

    const filteredThreats = threats.filter(filterThreats(selectedTags))
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>Trusler</Typography>
            </Grid>
            <Grid item xs={12}>            
                     {validationResult.warning && <Typography color='secondary'>{validationResult.message}</Typography>}
                     {validationResult.error && <Typography color='secondary'>{validationResult.message}</Typography>}                
            </Grid>
            <Grid item xs={8}>
                {overallThreats.map(overallThreat => <OverallThreatPanel
                    key={overallThreat.id}
                    isUsingTags={selectedTags.length > 0}
                    onSelectOverallThreat={overallThreatId => { dispatch(addOverallThreat(assessmentId, overallThreatId)) }}
                    onRemoveOverallThreat={overallThreatId => { dispatch(removeOverallThreat(assessmentId, overallThreatId)) }}
                    onSelectThreat={threat => { dispatch(addThreat(assessmentId, threat)) }}
                    onRemoveThreat={threat => { dispatch(removeThreat(assessmentId, threat)) }}
                    selectedOverallThreats={selectedOverallThreats}
                    selectedThreats={selectedThreats}
                    overallThreat={overallThreat}
                    threats={filteredThreats} />)}
            </Grid>
            <Grid item xs={4}>
                <Typography variant='h6'>Filtrér viste trusler med stikord</Typography>
                <Select
                    isMulti
                    value={selectedTags}
                    onChange={value => setSelectedTags(value || [])}
                    options={tags.map(tagToOption)} />
            </Grid>
            <Grid item xs={8}>       
                <SectionTitle label={'Truslerne for denne art er dårligt kendte'} help={ThreatsUnknownHelp} />     
                <FormControlLabel control={<Checkbox checked={meta && meta.threatsUnknown}
                    onChange={(evt, value) => dispatch(saveThreatMeta(assessmentId, {threatsUnknown: value}))}
                    name="threatsUnknown" />} label="Truslerne for denne art er dårligt kendte"
                />
                
            </Grid>
            <Footer
                onNext={() => {
                    onNext()
                }}
                onClose={() => onClose()} />
        </Grid >
    )
}

export default G_Trusler