export const greenAmber = {
  "breakpoints": {
    "keys": ["xs", "sm", "md", "lg", "xl"],
    "values": {
      "xs": 0,
      "lg": 1280,
      "sm": 600,
      "xl": 1920,
      "md": 960
    }
  },
  "text": {
    "primary": "rgba(0, 0, 0, 0.87)",
    "secondary": "rgba(0, 0, 0, 0.54)",
    "disabled": "rgba(0, 0, 0, 0.38)",
    "hint": "rgba(0, 0, 0, 0.38)"
  },
  "divider": "rgba(0, 0, 0, 0.9)",
  palette: {
    "secondary": {
      "main": "#F57C00",
      "light": "rgb(247, 150, 51)",
      "dark": "rgb(171, 86, 0)",
      "contrastText": "#FFE0B2"
    },
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "error": {
      "light": "#e57373",
      "main": "#f44336",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    "primary": {
      "main": "#2E7D32",
      "light": "rgb(87, 151, 91)",
      "dark": "rgb(32, 87, 35)",
      "contrastText": "#fff"
    }
  }
}

export const blueAmber = {    
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  },
  palette: {
    "secondary": {
      "main": "#F57C00",
      "light": "rgb(247, 150, 51)",
      "dark": "rgb(171, 86, 0)",
      "contrastText": "#fff"
    }    
  }
}

//      "contrastText": "#FFE0B2"
