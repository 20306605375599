import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Grid,
    Typography
} from '@material-ui/core'
import RadioGroupReference from '../components/RadioGroupReference'
//import { booleanValidator } from '../../utils/validators'
import TextAreaReference from '../components/TextAreaReference'
import Footer from '../components/Footer'
import GlobalErrorMessage, { FixMeLater } from '../components/GlobalErrorMessage'

import { updatePrelimary, saveSection } from '../../redux/editAssessment/editAssessmentActions'

import {
    extractPropertyValidationResult
} from './validators/sectionValidators'

import { preliminaryQuestions } from './questions'
import { PreliminaryHelpElement } from './help/preliminaryHelp'
import { RedlistStore } from '../../redux/reducers'


const option = (value : string, label : string) => {
    return {
        value, label
    }
}

export const preliminaryValuesAssessed = 'Assessed'
export const preliminaryValuesNA = 'IrrelevantToAssess'
export const preliminaryValuesNull = 'Null'
export const preliminaryValues = [
    option(preliminaryValuesNull, "Ikke angivet"),
    option("IrrelevantToAssess", 'NA, det er ikke relevant at bedømme denne art'),
    option("RegionallyExtinct", 'RE, arten er forsvundet fra DK'),
    option("InsufficientData", 'DD, data er ikke tilstrækkelige til at rødlistevurdere arten'),
    option('AbsolutelyNotThreatened', 'LC, arten er HELT SIKKERT livskraftig og ikke i nærheden af at være truet'),
    option("NotEvaluated", 'NE, arten findes ikke i DK eller skal af andre årsager ikke vurderes (brug kun denne EFTER AFTALE!)'),
    option(preliminaryValuesAssessed, 'Ingen af ovenstående (arten skal vurderes nærmere)')
]

const IndledendeVurdering = (props : FixMeLater) => {
    const dispatch = useDispatch()
    const assessmentId = useSelector((state : RedlistStore) => state.editAssessment.assessmentId)
    const currentCriteria = useSelector((state : RedlistStore) => state.editAssessment.assessmentCriteria)
    const { referenceArt, onNext, onClose } = props
    const currentPreliminary = currentCriteria && currentCriteria.preliminary
    const referencePreliminary = referenceArt && referenceArt.assessmentCriteria && referenceArt.assessmentCriteria.preliminary
    const preliminaryAssessment = (currentPreliminary && currentPreliminary.preliminaryAssessment) || 'Null'
    const preliminaryAssessmentRemark = (currentPreliminary && currentPreliminary.preliminaryAssessmentRemark) || ''

    const handleChange = (value : FixMeLater) => {
        dispatch(updatePrelimary('preliminaryAssessment', value))
    }
    
    const validationResult = useSelector((state : RedlistStore) => state.editAssessment.criteriaValidationResult?.preliminary)
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant='h5'>Indledende vurdering</Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>
            <Grid item xs={12}>
                <RadioGroupReference
                    label={preliminaryQuestions.preliminaryAssessment.label}
                    help={() => <PreliminaryHelpElement />}
                    values={preliminaryValues}
                    value={preliminaryAssessment}
                    onChange={handleChange}
                    referenceValue={referencePreliminary && referencePreliminary.preliminaryAssessment}
                />
            </Grid>
            <Grid item xs={12}>               
                <TextAreaReference
                    disabled={ preliminaryAssessment === preliminaryValuesNull || preliminaryAssessment === preliminaryValuesAssessed}
                    title={preliminaryQuestions.preliminaryAssessmentRemark.title}
                    referenceTitle='Referenceart'
                    referenceValue={referencePreliminary && referencePreliminary.preliminaryAssessmentRemark}
                    validation={extractPropertyValidationResult(validationResult, 'preliminaryAssessmentRemark')}
                    value={preliminaryAssessmentRemark}
                    onChange={value => dispatch(updatePrelimary('preliminaryAssessmentRemark', value))}
                />
            </Grid>
            <Footer
                onNext={() => {
                    dispatch(saveSection('preliminary', assessmentId as number, currentPreliminary))
                    onNext()
                }}
                onClose={() => onClose()} />
        </Grid>
    )
}

export default IndledendeVurdering