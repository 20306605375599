import { delay, put, select, takeLatest, takeLeading } from 'redux-saga/effects'

import { apiRequest2, ResponseGenerator } from '../api/apiRequest'
import {
  addPoint,
  FetchRecordsBy2x2GridAction,
  fetchRecordsByPosition,
  FetchRecordsByPositionAction,
  FetchTaxonStatusAction,
  GeographyAddRecordAction,
  GeographyRemoveCellAction,
  GeographyRemoveRecordAction,
  GeographyStartImportJobAction,
  GEOGRAPHY_ADD_RECORD,
  GEOGRAPHY_FETCH_BY_2x2GRID,
  GEOGRAPHY_FETCH_BY_POSITION,
  GEOGRAPHY_FETCH_TAXON_STATUS,
  GEOGRAPHY_REMOVE_CELL,
  GEOGRAPHY_REMOVE_RECORD,
  GEOGRAPHY_SELECT_CELL,
  GEOGRAPHY_SELECT_POSITION,
  GEOGRAPHY_START_IMPORT_JOB,
  SelectCellAction,
  SelectPositionAction,
  setCells,
  setConvexHull,
  setPoints,
  setRecordDetails,
  setTaxonStatus,
  startLoading,
  stopLoading,
} from './geographyMapActions'
import { hideSaving, showError, showMessage, showSaving } from '../actions/uiActions'
import arterDkApi from '../../service/arterDkApi'
import { ImportCreateResponse, ImportStatusListResponse, ImportStatusResponse, TaxonPositionInfo, TaxonPositionResponse, TaxonRecordDetails, TaxonRecordDetailsResponse } from '../../service/arterDkModels'
import { getConvexHull, recalcHullAddItem, recalcHullRemoveItem } from '../../utils/hullHelper'
import { latlonToAOOGrid, recordsToGridCells } from '../../utils/gridHelper'
import { RedlistStore } from '../reducers'
import geohash from 'ngeohash'

function* processAddRecord(action: GeographyAddRecordAction) {
  try {
    const { taxonId, position } = action.payload
    const coordinates = [position.longitude, position.latitude]
    const positionHash = geohash.encode(position.latitude, position.longitude, 10)

    const newRecord: TaxonPositionInfo = {
      id: positionHash,
      positionHash,
      cellId: latlonToAOOGrid({
        type: 'Point',
        coordinates,
      }).id,
      position: {
        type: 'Point',
        coordinates: coordinates,
      },
      year: 2022,
      deleted: false,
      taxonId: taxonId,
    }


    const { convexHull, points, cells } = yield select((state: RedlistStore) => state.geographyMap)

    const existingPoint = points.find(( p : TaxonPositionInfo) => p.positionHash?.substring(0, 8) === positionHash.substring(0, 8))
    if (existingPoint) {
        console.log('Existing point', { existingPoint})
        yield put(showMessage('Indsæt punkt', 'Der eksisterer allerede et punkt på denne position', null))
        return
    }
    yield put(showSaving())

    const newConvexHull = recalcHullAddItem(newRecord, convexHull, points)
    if (newConvexHull !== convexHull) {
      yield put(setConvexHull(newConvexHull))
    }

    yield put(addPoint(newRecord))

    if (!cells.find((c: TaxonPositionInfo) => c.cellId === newRecord.cellId)) {
      const newRecords = points.concat([newRecord])
      yield put(setCells(recordsToGridCells(newRecords)))
    }

    const response: ResponseGenerator = yield apiRequest2(arterDkApi, arterDkApi.createRecord, [taxonId, { position }])
    yield put(hideSaving())
    const item: TaxonRecordDetails = response as TaxonRecordDetails
    console.log('created', item)
  } catch (error) {
    yield put(hideSaving())
    yield put(showError(error, 'Uventet fejl i opret observation'))
  }
}

function* processRemoveRecord(action: GeographyRemoveRecordAction) {
  try {
    const { taxonId, positionHash } = action.payload
    const { convexHull, points } = yield select((state: RedlistStore) => state.geographyMap)

    const record: TaxonPositionInfo | undefined = points.find((c: TaxonPositionInfo) => c.positionHash === positionHash)
    if (!record || record.deleted) {
      // Record not found or already deleted
      return
    }

    record.deleted = true
    const newRecords = points.filter((c: TaxonPositionInfo) => c.positionHash !== positionHash)
    yield put(showSaving())
    yield apiRequest2(arterDkApi, arterDkApi.deleteRecordsByPosition, [taxonId, positionHash ])

    const newConvexHull = recalcHullRemoveItem(record, convexHull, newRecords)
    if (newConvexHull !== convexHull) {
      yield put(setConvexHull(newConvexHull))
    }
    yield put(setPoints(newRecords))
    yield put(setCells(recordsToGridCells(newRecords)))
    yield put(hideSaving())
  } catch (error) {
    yield put(hideSaving())
    yield put(showError(error, 'Uventet fejl i opret observation'))
  }
}

function* processRemoveCell(action: GeographyRemoveCellAction) {
  try {
    const { taxonId, cellId } = action.payload
    const { convexHull, points } = yield select((state: RedlistStore) => state.geographyMap)

    const record: TaxonPositionInfo | undefined = points.find((c: TaxonPositionInfo) => c.cellId === cellId)
    if (!record || record.deleted) {
      // Record not found or already deleted
      return
    }

    record.deleted = true
    const newRecords = points.filter((c: TaxonPositionInfo) => c.cellId !== cellId)
    yield put(showSaving())
    yield apiRequest2(arterDkApi, arterDkApi.deleteRecordsByCell, [taxonId, cellId ])

    const newConvexHull = recalcHullRemoveItem(record, convexHull, newRecords)
    if (newConvexHull !== convexHull) {
      yield put(setConvexHull(newConvexHull))
    }
    yield put(setPoints(newRecords))
    yield put(setCells(recordsToGridCells(newRecords)))
    yield put(hideSaving())
  } catch (error) {
    yield put(hideSaving())
    yield put(showError(error, 'Uventet fejl i opret observation'))
  }
}

function* processSelectCell(action: SelectCellAction) {
  try {
    const { taxonId, cellId, year } = action.payload
    const data: TaxonRecordDetailsResponse = yield apiRequest2(arterDkApi, arterDkApi.getRecordsByCell, [
      taxonId,
      cellId,
      year,
    ])
    const items: TaxonRecordDetails[] = data.items
    yield put(setRecordDetails(items))
  } catch (error) {
    yield put(showError(error, 'Uventet fejl i vælg position'))
  }
}
function* processSelectPosition(action: SelectPositionAction) {
  try {
    const { taxonId, positionHash, year } = action.payload
    const data: TaxonRecordDetailsResponse = yield apiRequest2(arterDkApi, arterDkApi.getRecordsByPosition, [
      taxonId,
      positionHash,
      year,
    ])
    const items: TaxonRecordDetails[] = data.items
    yield put(setRecordDetails(items))
  } catch (error) {
    yield put(showError(error, 'Uventet fejl i vælg position'))
  }
}

function* processFetchByPosition(action: FetchRecordsByPositionAction) {
  try {
    const { taxonId, year } = action.payload
    yield put(startLoading())
    const data: TaxonPositionResponse = yield apiRequest2(arterDkApi, arterDkApi.getRecordsGroupedByPosition, [
      taxonId,
      year,
    ])
    const items: TaxonPositionInfo[] = data.items
    const hull = getConvexHull(items)
    yield put(setConvexHull(hull))
    yield put(setCells(recordsToGridCells(items)))
    yield put(setPoints(items))
    yield put(stopLoading())
  } catch (error) {
    yield put(stopLoading())
    yield put(showError(error, 'Uventet fejl i hent fund'))
  }
}

function* processFetchBy2x2Grid(action: FetchRecordsBy2x2GridAction) {
  try {
    const { taxonId, year } = action.payload
    yield put(startLoading())
    const data: TaxonPositionResponse = yield apiRequest2(arterDkApi, arterDkApi.get2x2Cells, [
      taxonId,
      year,
    ])
    console.log('fetch cell data', data.items.length)
    const items: TaxonPositionInfo[] = data.items
    const hull = getConvexHull(items)
    yield put(setConvexHull(hull))
    yield put(setCells(recordsToGridCells(items)))
    yield put(setPoints(items))
    yield put(stopLoading())
  } catch (error) {
    yield put(stopLoading())
    yield put(showError(error, 'Uventet fejl i hent 2x2 grid'))
  }
}

function* processStartImportJob(action: GeographyStartImportJobAction) {
    try {
        const { taxonId } = action.payload
        let response : ResponseGenerator = yield apiRequest2(arterDkApi, arterDkApi.importTaxon, [taxonId])
        yield put(startLoading())
        const data : ImportCreateResponse = response as ImportCreateResponse
        yield put(setTaxonStatus({
            id: data.id,
            taxonId: taxonId,
            status: 'started',
            importStartTime: new Date().toISOString()
        }))
        yield delay(1000)
        response = yield apiRequest2(arterDkApi, arterDkApi.getImportStatus, [data.id])
        let statusResponse : ImportStatusResponse = response as ImportStatusResponse        
        while (statusResponse.item === null || statusResponse.item.status === 'started') {
            yield delay(1000)
            response = yield apiRequest2(arterDkApi, arterDkApi.getImportStatus, [data.id])
            statusResponse = response as ImportStatusResponse                
        }
        yield put(setTaxonStatus(statusResponse.item))
        yield put(stopLoading())
        yield put(showMessage('Import færdig', null, undefined))
    } catch (error) {
        console.log(error)
        yield put(stopLoading())
        yield put(showError(error, 'Uventet fejl i start import job'))
    }
}



function* processFetchTaxonStatus(action: FetchTaxonStatusAction) {
    try {
        const { taxonId } = action.payload
        const year : number = yield select((state : RedlistStore) => state.geographyMap.year)
        let response : ImportStatusListResponse = yield apiRequest2(arterDkApi, arterDkApi.getImportStatusByTaxonId, [taxonId])        
        const data = response as ImportStatusListResponse
        const item = data.items.length >= 0 ? data.items[0] : null
        if (item && item.status === 'done') {
          yield put(fetchRecordsByPosition(taxonId, year))
        }
        yield put(setTaxonStatus(item))
    } catch (error) {
        yield put(stopLoading())
        yield put(showError(error, 'Uventet fejl i start import job'))
    }
}



export default function* watcher() {
  yield takeLatest(GEOGRAPHY_SELECT_POSITION, processSelectPosition)
  yield takeLatest(GEOGRAPHY_SELECT_CELL, processSelectCell)
  yield takeLatest(GEOGRAPHY_FETCH_BY_POSITION, processFetchByPosition)
  yield takeLatest(GEOGRAPHY_FETCH_BY_2x2GRID, processFetchBy2x2Grid)
  yield takeLatest(GEOGRAPHY_ADD_RECORD, processAddRecord)
  yield takeLatest(GEOGRAPHY_REMOVE_RECORD, processRemoveRecord)
  yield takeLatest(GEOGRAPHY_REMOVE_CELL, processRemoveCell)
  yield takeLeading(GEOGRAPHY_START_IMPORT_JOB, processStartImportJob)
  yield takeLatest(GEOGRAPHY_FETCH_TAXON_STATUS, processFetchTaxonStatus)
}
