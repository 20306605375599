import { Feature } from "../ol/OlFeatureCollection";
import proj4, { InterfaceCoordinates, InterfaceProjection } from "proj4";
import { TaxonPositionInfo } from "../service/arterDkModels";
import { Geometry } from "@turf/helpers";

export interface GridDefinition {
  xMin: number;
  xMax: number;
  yMin: number;
  gridWidth: number;
  gridHeight: number;
}

export interface GridCell {
  row: number;
  column: number;
  id: number;
  bbox: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  };
}

export const simpleWgs84toEpsg25832 = (coordinates: number[]): number[] => {
  proj4.defs(
    "EPSG:25832",
    "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
  );
  const source: InterfaceProjection = proj4.Proj("EPSG:4326");
  const dest: InterfaceProjection = proj4.Proj("EPSG:25832");
  const point: InterfaceCoordinates = proj4.toPoint([
    coordinates[0],
    coordinates[1],
  ]);
  const res = proj4.transform(source, dest, point);
  return [res.x, res.y];
};

export const epsg25832toEpsg4326 = (location: number[]): number[] => {
  proj4.defs(
    "EPSG:25832",
    "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
  );
  const source: InterfaceProjection = proj4.Proj("EPSG:25832");
  const dest: InterfaceProjection = proj4.Proj("EPSG:4326");
  const point: InterfaceCoordinates = proj4.toPoint([location[0], location[1]]);
  const res = proj4.transform(source, dest, point);
  return [res.x, res.y];
};

export const recordsToGridCells = (records: TaxonPositionInfo[]) : TaxonPositionInfo[] => {
  const result : TaxonPositionInfo[] = []

  const dict : Record<string, TaxonPositionInfo> = {}
  records.filter((r : TaxonPositionInfo) => !r.deleted).forEach((r : TaxonPositionInfo) => {
    if (!dict[r.cellId]) {
      dict[r.cellId] = r
      result.push(r)
    }
  })
  
  return result
}

const pointToGridId = (
  point: Geometry,
  grid: GridDefinition
): GridCell => {
  const offsetPoint = {
    x: (point.coordinates[0] as number) - grid.xMin,
    y: (point.coordinates[1] as number) - grid.yMin,
  };
  const maxColumns = Math.ceil((grid.xMax - grid.xMin) / grid.gridWidth);
  const column = Math.floor(offsetPoint.x / grid.gridWidth);
  const row = Math.floor(offsetPoint.y / grid.gridHeight);
  const id = row * maxColumns + column;
  const bbox = {
    x1: column * grid.gridWidth + grid.xMin,
    x2: (column + 1) * grid.gridWidth + grid.xMin,
    y1: row * grid.gridHeight + grid.yMin,
    y2: (row + 1) * grid.gridHeight + grid.yMin,
  };
  return { id, row, column, bbox };
};


export const latlonToAOOGrid = (point: Geometry): GridCell => {
  const utmpoint = simpleWgs84toEpsg25832(point.coordinates as number[])
  return pointToAOOGrid({ 'type': 'Point', coordinates: utmpoint });
};


export const pointToAOOGrid = (point: Geometry): GridCell => {
  const xMin = 440000;
  const xMax = 899983;
  const yMin = 6040001;
  const gridWidth = 2000;
  const gridHeight = 2000;
  return pointToGridId(point, { xMin, xMax, yMin, gridWidth, gridHeight });
};
  
export const gridCellToFeature = (cell : GridCell) : Feature => {
    const p1 = epsg25832toEpsg4326([ cell.bbox.x1, cell.bbox.y1 ] )
    const p2 = epsg25832toEpsg4326([ cell.bbox.x2, cell.bbox.y1 ] )
    const p3 = epsg25832toEpsg4326([ cell.bbox.x2, cell.bbox.y2 ] )
    const p4 = epsg25832toEpsg4326([ cell.bbox.x1, cell.bbox.y2 ] )
    
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [ p1[0], p1[1] ],
          [ p2[0], p2[1] ],
          [ p3[0], p3[1] ],
          [ p4[0], p4[1] ],
          [ p1[0], p1[1] ]          
        ]],    
      },
      properties: { id : cell.id, row: cell.row, column: cell.column }
    }
  }