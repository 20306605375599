import * as turf from "@turf/turf";
import { TaxonPositionInfo } from "../service/arterDkModels";

export interface ConvexHullArea {
  hull: turf.Polygon;
  area: number;
}

export const recalcHullAddItem = (item: TaxonPositionInfo, prevHull : (ConvexHullArea | null), allCells: TaxonPositionInfo[]) : (ConvexHullArea | null) => {
  if (prevHull === null) {
    return getConvexHull(allCells)
  } 
  const point = turf.point(item.position.coordinates as number[])
  if (turf.booleanPointInPolygon(point, prevHull.hull)) {
    return prevHull    
  }
  const features1 : turf.Feature[][] = prevHull.hull.coordinates.map((cs: turf.Position[]): turf.Feature[] => {
    return cs.map((c : turf.Position) => turf.point(c))
  })
  const features : turf.Feature[] = features1.flat().concat([point])
  console.log({features})

  // TODO calc new hull
  return calcHull(features)
}

export const recalcHullRemoveItem = (item: TaxonPositionInfo, prevHull : (ConvexHullArea | null), allCells: TaxonPositionInfo[]) : (ConvexHullArea | null) => {
  if (prevHull === null) {
    return getConvexHull(allCells)
  } 
  const point = turf.point(item.position.coordinates as number[])

  const onPolygon = prevHull.hull.coordinates[0].find((c : turf.Position) => turf.booleanEqual(turf.point(c), point))
  if (!onPolygon) {
  return prevHull
  }
  return getConvexHull(allCells)
}

export const getConvexHull = (cells: TaxonPositionInfo[]): (ConvexHullArea | null) => {
  const items = cells.filter(( cell : TaxonPositionInfo) => !cell.deleted)
  if (items.length < 3) {
    return null;
  }
  const points = items.map((i: TaxonPositionInfo): turf.Feature => {
      return turf.point(i.position.coordinates as number[]);
    })

    return calcHull(points)
};

const calcHull = (points: turf.Feature[]): (ConvexHullArea | null) => {
  const hull = turf.convex( turf.featureCollection(points));
  if (!hull) {
    return null
  }
  const a = turf.area(hull) / 1000000;
  return { hull: hull.geometry, area: a };
}
