import React from 'react'
import { TextHelpElement } from './help'


export const KvantitativAnalyseHelpElement = props => {
    return (
        <TextHelpElement title='Kvantitativ analyse'>
            En kvantitativ analyse viser, at sandsynligheden for at uddø i vild tilstand er mindst (tidsperioden må maks. være 100 år) (4 valgmuligheder): 
            Her er der mulighed for direkte at angive en sandsynlighed for, at en art vil uddø. Ved en kvantitativ analyse af en arts risiko for at uddø 
            anvendes ofte en risiko- eller sårbarhedsanalyse (Population Viability Analysis, PVA). For at vælge en af de fire valgmuligheder behøver 
            man ikke foretage en formel PVA. Det centrale er, at der foreligger en kvantitativ bedømmelse af risikoen for artens forsvinden, dvs. at 
            risikoen kan kvantificeres. En formel PVA bygger på en matematisk model, der beskriver, hvordan en population vokser eller svinder under 
            forskellige forudsætninger, herunder forskellige tilvæksthastigheder, dødelighed, miljøvariable m.m. Nogle modeller indbygger desuden tilfældig 
            variation, der kan være forårsaget af de faktorer, der kan tænkes at påvirke en bestand f.eks. vejr, miljøkvalitet, kønsfordeling og 
            genetiske forandringer. Når modellen f.eks. viser, at der er 25% risiko for en arts uddøen i løbet af 20 år, så er dette en beregnet 
            sandsynlighed og ikke et eksakt tal. I virkeligheden indeholder disse tal store usikkerheder. De afhænger af flere faktorer, bl.a. hvor 
            godt modellen beskriver virkeligheden, hvor nøjagtige de værdier er, der anvendes i modellen, og til en vis grad af, hvor stor usikkerhed 
            der indbygges i modellen. (Brook m.fl. 2000) påviste, at hvis en PVA foretages nøje, kan den fremtidige overlevelsesmulighed forudsiges 
            relativt sikkert, men der er også eksempler på det modsatte (Herrick & Fox 2013). Mange modeller tager kun lidt hensyn til forskellige 
            typer af fremtidige miljøforandringer. Der bør således ikke stoles blindt på sårbarhedsanalyser. For mere information om PVA’er og 
            eksempler på brugen af dem henvises til (Kendall 2009; Herrick & Fox 2013).
        </TextHelpElement>
    )
}

export const KvantitativAnalyseDocumentationHelpElement = props => (
    <TextHelpElement title='Dokumentation til kvantitativ analyse'>
        Hvis man har angivet en sandsynlighed beregnet vha. en kvantitativ analyse, skal dette felt altid udfyldes. 
        Angiv hvilken analyse der er anvendt samt parametre og andre relevante informationer, for at andre kan udføre den samme analyse med de samme resultater.
    </TextHelpElement>
)