//import { Base64 } from 'js-base64';
import { FixMeLater } from '../assessments/components/GlobalErrorMessage';
import { del, get, post } from './fetchutil'

class RedlistPublishApi {
    baseUrl : string;
    token?: string;

    constructor() {
        this.baseUrl = ''
        this.token = undefined
    }

    setBaseUrl(baseUrl : string) {
        this.baseUrl = baseUrl
    }

    setToken(token? : string) {
        this.token = token
    }

    async fetchPublicationById(id : number) {
        return get(this.baseUrl, '/publish/' + encodeURIComponent(id), null, () => this.token)
    }  


    async listPublications() {
        return get(this.baseUrl, '/publish/list', null, () => this.token)
    }  

    async releasePublication(id : FixMeLater) {
        return post(this.baseUrl, '/publish/release', { id }, () => this.token)
    }
    
    async deletePublication(id : FixMeLater) {
        return del(this.baseUrl, `/publish/${encodeURIComponent(id)}`, () => this.token)
    }

    async startPublish(vurderingsrundeId : FixMeLater) {
        return get(this.baseUrl, '/publish/start', { vurderingsrundeId }, () => this.token)
    }

    async getProgress() {
        return get(this.baseUrl, '/publish/progress', null, () => this.token)
    }
}
const instance = new RedlistPublishApi()
export default instance