import React from 'react'
import { Section, LabelledValue, booleanConverterHideNull, enumConverter } from '../../../mui/Section'

import { reductionQuestions } from '../questions'
import { reductionTimespanEnum, reductionDerivedFromEnum } from '../enums/enums'
import { FixMeLater } from '../../components/GlobalErrorMessage'

type A_TilbagegangIBestandViewProps = FixMeLater

const A_TilbagegangIBestandView = (props : A_TilbagegangIBestandViewProps) => {
  const reduction = props.reduction || {}
  const { showNull, showtitle } = props
  return (
    <Section title={showtitle && 'Tilbagegang i bestand'}>
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.reductionInPercent.label}
        value={reduction.reductionInPercent}
      />
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.causesOfReductionAreReversible.label}
        value={reduction.causesOfReductionAreReversible}
        converter={booleanConverterHideNull}
      />
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.causesOfReductionAreKnown.label}
        value={reduction.causesOfReductionAreKnown}
        converter={booleanConverterHideNull}
      />
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.causesOfReductionHaveStopped.label}
        value={reduction.causesOfReductionHaveStopped}
        converter={booleanConverterHideNull}
      />
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.reductionHasBeenObserved.label}
        value={reduction.reductionHasBeenObserved}
        converter={booleanConverterHideNull}
      />
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.reductionTimespan.label}
        value={reduction.reductionTimespan}
        converter={enumConverter(reductionTimespanEnum)}
      />
      <LabelledValue
        showNull={showNull}
        label={reductionQuestions.reductionIsDerivedFrom.label}
        value={reduction.reductionIsDerivedFrom}
        converter={enumConverter(reductionDerivedFromEnum)}
      />
      <LabelledValue
        showNull={showNull}
        fullWidth
        label={reductionQuestions.reductionRemark.label}
        value={reduction.reductionRemark}
      />
    </Section>
  )
}

export default A_TilbagegangIBestandView
