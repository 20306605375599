import React from 'react'
import { useState } from 'react'
import AsyncSelect from 'react-select/async'

import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'

import RedlistApi from '../../service/redlistApi'

export const loadGroups = (inputValue, callback) => {
    RedlistApi.loadSpeciesGroups(inputValue)
        .then(data => {
            console.log('fetched', data)
            callback(data.items.map(sf => ({ value: sf.id, label: sf.name })))
        })
};


const SetSpeciesGroupDialog = props => {
    const { onClose, onSave } = props
    const [speciesGroupId, setSpeciesGroupId] = useState()
    const [speciesGroupName, setSpeciesGroupName] = useState('')

    

    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Rediger gruppe for valgte arter</Typography>
            <Grid container spacing={2}>
                 <Grid item xs={12}>
                    <AsyncSelect
                        value={{ value: speciesGroupId, label: speciesGroupName }}
                        defaultOptions
                        loadOptions={loadGroups}
                        onChange={e => { setSpeciesGroupId(e.value); setSpeciesGroupName(e.label)}}
                         />                         
                </Grid>
                <Grid item xs={1}></Grid>                
                <Grid item xs={2}></Grid>
                <Grid item xs={3}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                    <Button onClick={() => onSave({ speciesGroupId })} fullWidth variant='contained' color='primary'>Gem</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default SetSpeciesGroupDialog