import React from 'react'
import { categoryChangeReasonsEnum } from '../sections/enums/enums'
import CheckboxGroup from '../../mui/CheckboxGroup'
import TextAreaReference from '../components/TextAreaReference'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { 
    setCategoryChangeReasons, 
    setCategoryChangeReasonComment, 
    setCategoryChangeReasonValue,
    deleteCategoryChange
} from '../../redux/editAssessment/editAssessmentActions'
import { isCategoryChangeReasonRequired } from '../../service/categoryChangeService'
import GlobalErrorMessage from '../components/GlobalErrorMessage'



const ReelSfvInput = props => {
    const { value, onChange } = props
    const _value = value || {}
    const periodStart = _value.periodStart || ''
    const periodEnd = _value.periodEnd || ''
    return (
        <div>
            <Typography variant='subtitle2'>Angiv hvilken periode ændringen er sket</Typography>
            <TextField style={{ marginRight: 24 }} type='number' label='Fra år' value={periodStart} onChange={evt => onChange({ ..._value, periodStart: evt.target.value })} />
            <TextField type='number' label='Til år' value={periodEnd} onChange={evt => onChange({ ..._value, periodEnd: evt.target.value })} />
        </div>
    )
}

const X_CategoryChangeReason = props => {
    const dispatch = useDispatch()
    const { vurderingsrundeId } = props
    const { previousAssessments, category, categoryChangeReasons } = useSelector(state => state.editAssessment)    
    const catvalidationResult = useSelector(state => state.editAssessment.categoryChangeValidationResult)
    const validationResult = catvalidationResult['categoryChange' + vurderingsrundeId]
    console.log(catvalidationResult, validationResult)
  //  console.log(vurderingsrundeId, previousAssessments)
    const assessmentToCompare = previousAssessments.find(a => a.vurderingsrundeId === vurderingsrundeId)
    const prevCategory = assessmentToCompare ? assessmentToCompare.category : 'Ikke vurderet'
//    console.log('comparing with', assessmentToCompare)

    const requireCategoryChangeReason = isCategoryChangeReasonRequired(assessmentToCompare, category.modifiedCategory)
    const required = requireCategoryChangeReason.ask
    const categoryChange = categoryChangeReasons[vurderingsrundeId] || {}

    if (!required) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5'>Kategoriskift siden {vurderingsrundeId}</Typography>
                    <GlobalErrorMessage validationResult={validationResult} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Det er ikke relevant at angive årsag til kategoriskift</Typography>
                    { assessmentToCompare ? 
                        <Typography variant='body1'>Arten er gået fra {prevCategory} til {category.modifiedCategory.name}</Typography>
                     : <Typography variant='body1'>Arten blev ikke vurderet i {vurderingsrundeId}</Typography>}
                </Grid>
                {
                    categoryChange.reasons && categoryChange.reasons.length > 0 &&
                    <Grid item xs={12}>
                        <Button variant='contained' color='primary' onClick={() => dispatch(deleteCategoryChange(vurderingsrundeId))}>Slet indtastet årsag til kategoriskift</Button>
                    </Grid>
                }
            </Grid>
        )
    }
    console.log('catchange', categoryChange)
    let theseCategoryChangeReasons = categoryChange.reasons || []
    theseCategoryChangeReasons = theseCategoryChangeReasons.filter(e => e.value)
    const change = requireCategoryChangeReason.change
    const title = change ? `Vælg årsag til kategoriskift siden ${vurderingsrundeId} (${prevCategory} -> ${category.modifiedCategory.name})` : 'Angiv hvorvidt det er reelt at arten har fået samme kategori som sidst den blev vurderet (nogle gange kan arter blive henført til samme kategori som sidst, men er reelt faktisk i fremgang eller tilbagegang).'
    const descriptionProperty = change ? 'change' : 'same'
    let selectableReasons = categoryChangeReasonsEnum.asList().filter(a => change ? a.object.change : a.object.same)
    console.log(selectableReasons, theseCategoryChangeReasons)
    const additionalReasons = theseCategoryChangeReasons.filter(ccr => !Boolean(selectableReasons.find(r => r.value === ccr.value)))
    console.log('additionalReasons', additionalReasons)
    selectableReasons = additionalReasons.map(e => categoryChangeReasonsEnum.fromValue(e.value)).concat(selectableReasons)
    
    console.log("selectableReasons", selectableReasons)
    const ccrValues = theseCategoryChangeReasons.map(e => categoryChangeReasonsEnum.fromValue(e.value))
    const sfvReason = theseCategoryChangeReasons.find(e => e.value === categoryChangeReasonsEnum.values().reel_sfv.value)
 //   console.log(sfvReason)
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h5'>{title}</Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>
            <Grid item xs={9} md={8}>
                <CheckboxGroup
                    options={selectableReasons.map(r => ({ value: r.value, label: r.label }))}
                    selectedOptions={theseCategoryChangeReasons}
                    onChange={(value) => dispatch(setCategoryChangeReasons(vurderingsrundeId, value))}
                />
                <div>
                    {sfvReason ?
                        <ReelSfvInput
                            key={sfvReason.value}
                            value={sfvReason.data}
                            onChange={(value) => { dispatch(setCategoryChangeReasonValue(vurderingsrundeId, sfvReason, value))}} />
                        :
                        null
                    }
                    <TextAreaReference
                        title='Kommentar'
                        value={categoryChange.comment || ''}
                        onChange={value => dispatch(setCategoryChangeReasonComment(vurderingsrundeId, value))}
                    />
                </div>
            </Grid>
            <Grid item xs={3} md={4}>
                <div style={{ marginTop: 24, padding: 16, backgroundColor: '#eee' }}>
                    <Typography variant='subtitle2'>Hjælp til den årsag du har valgt</Typography>
                    {ccrValues.map(ccrValue => (
                        <React.Fragment key={ccrValue.value}>
                            <Typography variant='caption'>{ccrValue.label}</Typography>
                            <Typography variant='body2'>
                                {ccrValue ? ccrValue.object[descriptionProperty] : ''}
                            </Typography>
                        </React.Fragment>))}
                </div>
            </Grid>
            
        </Grid>
    )
}

export default X_CategoryChangeReason