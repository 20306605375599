import React from 'react'
import {
    Typography
} from '@material-ui/core'
import Select from 'react-select'

const DialogSelect = (props) => {    
    return (
        <div>
            <Typography variant='subtitle1'>{props.title}</Typography>
            <Select value={props.defaultValue} onChange={(value) => props.onChange(value)} isClearable options={props.items} />
        </div>
    )
}

export default DialogSelect