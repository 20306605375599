import { FixMeLater } from "../../components/GlobalErrorMessage";

export interface EnumValue {
  value: (string | null);
  label: string;
  sortOrder: number;
  object?: FixMeLater;
  isNull?: boolean;
  id?: FixMeLater;
}

export const createEnumValue = (
  value: (string | null),
  label: string,
  sortOrder: number,
  object?: FixMeLater,
  isNull?: boolean,
  id?: FixMeLater
) : EnumValue => ({ value, label, sortOrder, object, isNull, id });

export interface EnumObject {
    values: () => Record<string, EnumValue>
    getNullValue: () => EnumValue | null | undefined
    fromValue: (value: (string | null)) => EnumValue | null | undefined
    asList: () => EnumValue[]
}

export const createEnum = (object : Record<string, EnumValue>) : EnumObject => {
  const list : EnumValue[] = Object.keys(object).map((k) => object[k]);
  list.sort((a, b) => a.sortOrder - b.sortOrder);
  const nullValue = list.find((e) => e.isNull === true);
  const self : EnumObject = {
    values: () => object,
    getNullValue: () => nullValue,
    fromValue: (value : (string | null)) => {
      if (value === null) {
        return nullValue;
      }
      return self.asList().find((e) => e.value === value);
    },
    asList: () => list,
  };
  return self;
};

const reductionTimespanObject = {
  ikkeAngivet: createEnumValue("0", "Ikke angivet", 0, null, true),
  past: createEnumValue(
    "1",
    "Fortidig: I de seneste 10 år eller 3 generationer",
    1
  ),
  present: createEnumValue(
    "2",
    "Nutidig: I en periode på 10 år eller 3 generationer i nær fortid OG fremtid",
    2
  ),
  future: createEnumValue(
    "3",
    "Fremtidig: Forventes/formodes at ske i de næste 10 år eller 3 generationer",
    3
  ),
};

const reductionDerivedFromObject = {
  ikkeAngivet: createEnumValue("0", "Ikke angivet", 0, null, true),
  bestandsIndex: createEnumValue("1", "Et for arten passende bestandsindex", 1),
  reduceretForekomstAreal: createEnumValue(
    "2",
    "Et reduceret forekomstsareal, udbredelsesområder og/eller forringet habitatkvalitet",
    2
  ),
  menneskeligUdnyttelse: createEnumValue(
    "3",
    "En aktuel eller potentiel menneskelig udnyttelse af arten",
    3
  ),
  indforteArter: createEnumValue(
    "4",
    "Påvirkning fra indførte arter, hybridisering, patogener, forurening, konkurrende arter og/eller parasitter",
    4
  ),
};

const reelSammeDecription = "Det er reelt arten har samme kategori nu som før";
const reelNyDescription = `Ændringen i kategori er et resultat af en reel ændring i artens status, 
der er sket siden sidste vurdering. For eksempel kan ændringen skyldes en stigning i populationens 
reduktionshastighed, et fald i populationens udbredelse eller arealet med passende levesteder siden 
sidste vurdering, eller en nytilkommen reduktion i ovenstående (på grund af nye trusler fx).`;

const reelSfvDescription = `Dette valg må kun bruges hvis taxonet har været vurderet mindst tre gange
og bruges i forbindelse med beregning af det såkaldte Red List Index. Ændringen i kategori er resultatet
af en reel ændring der skete før seneste vurdering, men siden første vurdering og som først lige er 
blevet opdaget pga. nye informationer eller ny dokumentation. Hvis denne nye information havde været 
tilgængelig tidligere ville den nye kategori og have været anvendt tidligere.`;

const revisionChange = `Ændringen i kategori er et resultat af ændrede IUCN rødlistekriterier (de har ikke været ændret siden 2001).`;
const revisionSame = `Pga. ændrede IUCN rødlistekriterier (de har ikke været ændret siden 2001), er kategorien i denne vurdering ikke 
sammenlignelig med den fra sidst, selvom kategorierne er ens.`;

const nyInfoChange = `Ændringen i kategori er resultatet af bedre viden om taxonet, fx pga. nye data eller 
nye analyser af eksisterende data (bedre estimater for populationsstørrelse, udbredelsesområde eller reduktions-rater)`;
const nyInfoSame = `Man har i dag bedre viden om arten. Havde man haft samme viden sidst den blev vurderet, ville den dengang 
have fået en anden kategori og derfor er kategorien i denne vurdering ikke sammenlignelig med den fra sidste vurdering, selvom de er ens.`;

const taxonomiChange = `Den nye kategori er forskellig fra den seneste pga. en taxonomisk ændring i perioden
siden seneste vurdering. Sådanne ændringer inkluderer: Opsplitning (et taxon er opslittet i flere arter 
eller ophøjet til artsniveau), nyligt beskrevet (taxonet er for nyligt blevet beskrevet som art), 
sammensmeltning (taxonet er resultatet af en nylig sammensmeltning af to eller flere tidligere taxa), ikke længere 
gyldigt/anerkendt (enten er taxonet ikke længere gyldigt, fx fordi det nu betragtes som en hybrid, varietet, 
form eller underart af en anden art, eller det tidligere gyldige taxon er forskelligt fra det nu gyldige som 
resultat af en opsplitning eller sammensmeltning)`;
const taxonomiSame = `Der er sket taxonomisk ændringer siden seneste vurdering, derfor er det ikke 
længere helt samme taxon der vurderes. Selvom de to vurderinger har ens kategorier er de derfor ikke 
sammenlignelige. Sådanne ændringer inkluderer: Opsplitning (et taxon er opslittet i flere arter eller 
ophøjet til artsniveau), nyligt beskrevet (taxonet er for nyligt blevet beskrevet som art), 
sammensmeltning (taxonet er resultatet af en nylig sammensmeltning af to eller flere tidligere taxa), 
ikke længere gyldigt/anerkendt (enten er taxonet ikke længere gyldigt, fx fordi det nu betragtes som en 
hybrid, varietet, form eller underart af en anden art, eller det tidligere gyldige taxon er forskelligt 
fra det nu gyldige som resultat af en opsplitning eller sammensmeltning).`;

const fejlChange = `Den tidligere kategori blev tildelt arten ved en fejl fordi eksperten misforstod IUCN's rødlistekriterier`;
const fejlSame = `Den tidligere kategori blev tildelt arten ved en fejl fordi eksperten misforstod IUCN's rødlistekriterier. 
Derfor er den tidligere kategori ikke sammenlignelig med den nye selvom de er ens.`;

const andetChange = `Ændringen i kategori er resultatet af andre årsager, som ikke dækkes af ovenstående og som kræver 
yderligere forklaringer. Fx den tidligere eksperts behandling og indstilling overfor risici og usikkerheder og ændringer 
i IUCN's guidelines (altså ikke kriterierne!). Uddyb i nedenstående tekstfelt.`;

const andetSame = `Den nuværende og den seneste kategori er ikke sammenlignelige selvom de er ens. Det skyldes årsager, 
som ikke dækkes af ovenstående og som kræver yderligere forklaringer. Fx den tidligere eksperts behandling og 
indstilling overfor risici og usikkerheder og ændringer i IUCN's guidelines (altså ikke kriterierne!). 
Uddyb i nedenstående tekstfelt.`;

export const categoryChangeReasonsObject = {
  reel_samme: createEnumValue(
    "reel_samme",
    "Det er reelt arten har samme kategori nu som før",
    0,
    { same: reelSammeDecription }
  ),
  reel_ny: createEnumValue("reel_ny", "Reel ændring (for nylig)", 1, {
    change: reelNyDescription,
  }),
  reel_sfv: createEnumValue(
    "reel_sfv",
    "Reel ændring (siden første vurdering)",
    2,
    { change: reelSfvDescription }
  ),
  revision: createEnumValue("revision", "Revision af kriterier", 3, {
    change: revisionChange,
    same: revisionSame,
  }),
  ny_info: createEnumValue("ny_info", "Ny information", 4, {
    change: nyInfoChange,
    same: nyInfoSame,
  }),
  taxonomi: createEnumValue("taxonomi", "Taxonomi", 5, {
    change: taxonomiChange,
    same: taxonomiSame,
  }),
  fejl: createEnumValue("fejl", "Fejl", 6, {
    change: fejlChange,
    same: fejlSame,
  }),
  andet: createEnumValue("andet", "Andet", 7, {
    change: andetChange,
    same: andetSame,
  }),
};

export const categoryChangeReasonsEnum = createEnum(
  categoryChangeReasonsObject
);

export const reductionTimespanEnum = createEnum(reductionTimespanObject);
export const reductionDerivedFromEnum = createEnum(reductionDerivedFromObject);

const possiblyREObject = {
  ikkeAngivet: createEnumValue(null, "Ikke angivet", 0, null, true),
  ja: createEnumValue("true", "Arten er muligvis RE", 1),
  nej: createEnumValue("false", "Arten er formentlig ikke RE", 2),
};

export const possiblyREEnum = createEnum(possiblyREObject);

export type UseArterDkEnumType = null | 'null' | 'yes' | 'no'

export const useArterDkDataObject = {
  ikkeAngivet: createEnumValue('null', "Ikke angivet", 0, null, true),
  ja: createEnumValue("yes", "Ja", 1),
  nej: createEnumValue("no", "Nej", 2)
};

export const useArterDkDataEnum = createEnum(useArterDkDataObject);
