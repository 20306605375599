import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { 
    SET_TITLE, 
    HIDE_SPINNER, SHOW_SPINNER, 
    HIDE_MENU, SHOW_MENU, 
    SHOW_SAVING, HIDE_SAVING, 
    DISABLE_GOBACK, ENABLE_GOBACK, 
    CONFIRM_ACTION, CONFIRM_ACTION_REJECTED, CONFIRM_ACTION_ACCEPTED,
    SHOW_MESSAGE, HIDE_MESSAGE
 } from '../actions/uiActions'
import { API_ERROR } from '../api/apiActions'

export interface UIStore {
    loading: number
    loadingTasks: FixMeLater[]
    saving: number
    goBack: boolean
    showMenu: boolean
    title: string
    confirmAction: null | FixMeLater
    apiError: null | boolean
    message: null | {
        action: {
            type: string,
            payload: (null | Record<string, any>)
        }
        text: string
        title: string
    }
}

const initialState : UIStore = {
    loading: 0,
    loadingTasks: [],
    saving: 0,
    goBack: false,
    showMenu: true,
    title: 'Rødlisten',
    confirmAction: null,
    apiError: null,
    message: null
}

const hideSpinner = (state : FixMeLater, action : FixMeLater) => {
    const taskId = action.payload.id || 'unknown'            
    const loadingTasks = state.loadingTasks.filter((l : FixMeLater) => l !== taskId)
    return { ...state, loadingTasks: loadingTasks, loading: loadingTasks.length } 
}

const showSpinner  = (state : FixMeLater, action : FixMeLater) => {
    const taskId = action.payload.id || 'unknown'
    const loadingTasks = state.loadingTasks.filter((l : FixMeLater) => l !== taskId).concat([taskId])
    return { ...state, loadingTasks, loading: loadingTasks.length }          
}


const ui = (state = initialState, action : FixMeLater) : UIStore => {
    switch (action.type) {
        case API_ERROR:
            return { ...state, apiError: true }
        case SET_TITLE:
            return { ...state, title: action.payload.title }
        case SHOW_SAVING:
            return { ...state, saving: 1}
        case HIDE_SAVING:
            return { ...state, saving: 0}
        case HIDE_SPINNER:
            return hideSpinner(state, action)   
        case SHOW_SPINNER:
            return showSpinner(state, action)   
        case HIDE_MENU:
            return { ...state, showMenu: false }
        case SHOW_MENU:
            return { ...state, showMenu: true }
        case ENABLE_GOBACK:
            return { ...state, goBack: true }
        case DISABLE_GOBACK:
            return { ...state, goBack: false }
        case CONFIRM_ACTION_REJECTED:
            return { ...state, confirmAction: null }
        case CONFIRM_ACTION_ACCEPTED:
            return { ...state, confirmAction: null }
        case CONFIRM_ACTION:
            return { ...state, confirmAction: action.payload }
        case HIDE_MESSAGE: 
            return { ...state, message: null }
        case SHOW_MESSAGE: 
            return { ...state, message: action.payload }
        default:
            return state
    }
}

export default ui;