export const oddRowStyle = {  
    backgroundColor: '#ececec'
}

export const referenceHightlightColor = '';

export const submenuStyle = {  backgroundColor: 'rgba(0, 0, 0, 0)', marginLeft: 16, width: 240 - 16,  borderBottom: '1px solid', borderBottomColor: '#e8e8e8'}
export const submenuActiveStyle = { marginLeft: 12, width: 240 - 12, backgroundColor: 'rgb(171, 86, 0, 0.12)', borderBottom: '1px solid', borderBottomColor: '#e8e8e8', borderLeft: '4px solid', borderLeftColor: '#F57C00' }
//export const menuActiveStyle = { backgroundColor: 'rgb(171, 86, 0, 0.12)', borderLeft: '2px solid', borderColor: '#F57C00' }
//#3f51b5 background-color:rgb(63, 81, 181);
export const menuActiveStyle = { backgroundColor: 'rgba(63, 81, 181, 0.12)', borderLeft: '2px solid', borderColor: '#3f51b5' }