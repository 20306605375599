import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeLatest
} from 'redux-saga/effects'

import {
    FETCH_ASSESSMENTS_BY_SPECIES_ID,
    INIT_EDIT_ASSESSMENT_PAGE,
    fetchAssessmentsBySpeciesId,
    ASSESSMENT_FETCHED,
    SET_PREVIOUS_ASSESSMENTS,
    SET_ASSESSMENT_CRITERIA,
    setCategoryChange
} from '../editAssessment/editAssessmentActions'
import {
    showSpinner,
    hideSpinner,
    showMessage,
    goto,
    GOTO,
    showGoBack,
    hideGoBack,
    showError
} from '../actions/uiActions'
import { apiRequest2 } from '../api/apiRequest'
import { categoryChangeReasonsEnum } from '../../assessments/sections/enums/enums'
import { resetGeography } from './geographyMapActions'
function* processInitEditAssessmentsPage(action) {
    const {
        assessmentId
    } = action.payload
    try {
        yield put(showSpinner())
        //yield put(fetchLevestedsDefinition())
        yield put(resetGeography())        
        const data = yield apiRequest2(RedlistApi, RedlistApi.loadAssessmentById, [assessmentId], {
            "403": showMessage('', 'Du har ikke rettigheder til at se denne vurdering', goto('#/vurderinger')),
            "404": showMessage('Vurdering ikke fundet', `Vurdering med id ${assessmentId} kunne ikke findes`, goto('#/vurderinger'))
        })
        if (!data) {
            yield put({ type: SET_ASSESSMENT_CRITERIA, payload: null})
            yield put({
                type: SET_PREVIOUS_ASSESSMENTS,
                payload: {
                    assessments: []
                }
            })
            return
        }   
        const assessment = yield data.item
        if (!assessment) {
            yield put({ type: SET_ASSESSMENT_CRITERIA, payload: null})
            yield put({
                type: SET_PREVIOUS_ASSESSMENTS,
                payload: {
                    assessments: []
                }
            })
            yield put(showMessage('Vurdering ikke fundet', `Vurdering med id ${assessmentId} kunne ikke findes`, goto('#/vurderinger', false)))
            return
        }
        yield put(fetchAssessmentsBySpeciesId(assessment.speciesInformation.id))
        yield put({
            type: ASSESSMENT_FETCHED,
            payload: data
        })        
        yield put({ type: SET_ASSESSMENT_CRITERIA, payload: { ...assessment.assessmentCriteria, assessmentId: assessment.id }})
        const categoryChanges = {}
        assessment.categoryChangeReasons.forEach(ccr => {
            categoryChanges[ccr.vurderingsrundeId] = {
                comment: ccr.comment,
                reasons: ccr.reasons.map(r => {
                    const enumValue = categoryChangeReasonsEnum.fromValue(r.reason)
                    return { ...enumValue, data: r.data }
                }),
                vurderingsrundeId: ccr.vurderingsrundeId
            }
        })
        yield put(setCategoryChange(categoryChanges))
        yield put(hideSpinner())
    } catch (error) {
        const errorStr = error ? error.toString() : ''
        yield put(hideSpinner())
        yield put(showMessage("Fejl", "Kunne ikke hente vurdering: " + errorStr))
    }
}

function* processfetchAssessmentBySpeciesId(action) {
    const speciesId = action.payload.speciesId
    try {
        yield put(showSpinner())
        const data = yield apply(RedlistApi, RedlistApi.loadAssessmentBySpeciesId, [speciesId])
        const assessments = yield data.items
        yield assessments.sort((a, b) => b.vurderingsrundeId - a.vurderingsrundeId)
        yield assessments.shift()
        yield put({
            type: SET_PREVIOUS_ASSESSMENTS,
            payload: {
                assessments: assessments
            }
        })
        yield put(hideSpinner())
    } catch (error) {
        yield put(hideSpinner())
        yield put(showError(error))
    }
}

function* processGoto(action) {
    try {
        const { urlHash, enableGoback } = action.payload
        yield window.location.hash = urlHash
        if (enableGoback) {
            yield put(showGoBack())
        } else {
            yield put(hideGoBack())
        }
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLatest(FETCH_ASSESSMENTS_BY_SPECIES_ID, processfetchAssessmentBySpeciesId)
    yield takeLatest(INIT_EDIT_ASSESSMENT_PAGE, processInitEditAssessmentsPage)
    yield takeLatest(GOTO, processGoto)
}