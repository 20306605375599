import React, { ReactElement } from 'react'
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import SectionTitle from '../../mui/SectionTitle'
import { makeStyles } from '@material-ui/core/styles'
import { FixMeLater } from './GlobalErrorMessage';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 0,
        width: '100%'
    }
}));

export type BooleanNameType = 'ukendt' | 'yes' | 'no' 

export const ukendt : BooleanNameType = 'ukendt'
export const yes : BooleanNameType = 'yes'
export const no : BooleanNameType = 'no'

const valueConvert = (value : (boolean | null)) : BooleanNameType => {
  if (value === null) {
    return ukendt
  } else if (value === false) {
    return no
  } else if (value === true) {
    return yes
  }
  return ukendt
}

const valueToBoolean = (value : BooleanNameType) : (boolean | null) => {
  if (value === ukendt) {
    return null
  } else if (value === yes) {
    return true
  } else if (value === no) {
    return false
  }
  return null
}

export interface BooleanRadioGroupProps {
  label : string;
  help : () => (null | ReactElement);  
  ghost? : boolean;
  value : (null | boolean);
  onChange : (value : (boolean | null)) => void;
  disabled?: boolean
  yesLabel?: string
  noLabel?: string 
  validation? : FixMeLater
}

const BooleanRadioGroup = (props : BooleanRadioGroupProps) => {
  const { label, help, ghost, value, onChange, disabled, yesLabel, noLabel, validation} = props
  const _value = valueConvert(value)
  const disable = Boolean(disabled)
  const handleChange = (evt : FixMeLater) => {
    onChange(valueToBoolean(evt.target.value))
  }
  const ghostStyle = { backgroundColor: 'rgba(1,0,200,0.1)' }
  const classes = useStyles()
  return (
    <FormControl disabled={disable} className={classes.root}  component="fieldset">       
      <SectionTitle label={label} help={help} />
      { validation && validation.ok === false ? 
                <Typography color='error'>{validation.message}</Typography>
            : null}
      <RadioGroup className={classes.root}  aria-label={label} name={label} value={_value} onChange={handleChange}>
        <FormControlLabel value={ukendt} control={<Radio color='primary' />} label="Ikke angivet" />
        <FormControlLabel style={ghost === true ? ghostStyle : {}} label={yesLabel || 'Ja'} value={yes} control={<Radio color='primary' />} />
        <FormControlLabel style={ghost === false ? ghostStyle : {}} label={noLabel || 'Nej'} value={no} control={<Radio color='primary' />} />
      </RadioGroup>
    </FormControl>
  )
}

export default BooleanRadioGroup