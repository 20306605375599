import { statusEnum } from './assessmentStatusEnum'

const phases = {
    'teknisk': { slug: 'teknisk', canApprove: true, commentPhases: [ 'teknisk']},
    'kommentering': { slug: 'kommentering', canApprove: false, commentPhases: [ 'kommentering' ] },
    'faglig': { slug: 'faglig', canApprove: true, commentPhases: [ 'kommentering', 'faglig' ] },
    'endelig': { slug: 'endelig', canApprove: true, commentPhases: [ 'kommentering', 'faglig', 'endelig' ] },
    'godkendt': { slug: 'godkendt', canApprove: false, commentPhases: [ 'kommentering', 'faglig', 'endelig', 'teknisk' ] }
}

export const phaseFromAssessment = assessment => {
    const statusObject = statusEnum.values()
    const status = statusEnum.fromValue(assessment.status)
    if (statusObject.teknisk_kvalitetssikring === status || statusObject.teknisk_genvurdering === status) {
        return phases.teknisk
    }
    if (statusObject.kommentering === status) {
        return phases.kommentering
    }    
    if (statusObject.faglig_kvalitetssikring === status || statusObject.faglig_genvurdering === status) {
        return phases.faglig
    }   
    if (statusObject.endelig_kvalitetssikring === status || statusObject.endelig_genvurdering === status) {
        return phases.endelig
    } 
    if (statusObject.godkendt === status) {
        return phases.godkendt
    } 
    return null
}