import React, { useState } from 'react'
import { Grid, IconButton } from "@material-ui/core"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Drawer, Typography } from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RefreshIcon from '@material-ui/icons/Refresh';

import Select from 'react-select'

import XTable from '../../mui/XTable'

import { formatUnixtimestamp } from '../../utils/dateUtils'
import UpdatePubliceringerDialog from './UpdatePubliceringerDialog'

import { 
    managePubliceringerInit, 
    selectPublication, 
    setSelectedPublication,
    releasePublication, 
    deletePublication,
    startPublish,
    fetchPubliceringer
} from '../../redux/admin/managePubliceringerPageActions'
import CreatePubliceringerDialog from './CreatePubliceringerDialog'

const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Warnings', property: 'warnings' },
        { header: 'Dato', property: 'date', func: publication => formatUnixtimestamp(publication.date) },
        { header: 'Vurderingsrunde', property: 'vurderingsrunde' },
        { header: 'Version', property: 'version'  },
        { header: 'Publikationsid', property: 'publicationId' },
        { header: 'Aktiv', property: 'release', func: publication => publication.release ? 'Aktiv' : ''},
        { header: 'Beta', property: 'beta', func: publication => publication.beta ? 'Beta' : ''},
        { header: 'Udgivet', property: 'keepForever', func: publication => publication.keepForever ? 'Ja' : ''}
    ],
    selectable: false
}

const vurderingsrundeOptions = [
    { value: '2010', label: '2010' },
    { value: '2019', label: '2019' },
    { value: '2030', label: '2030' }
]

const statusOptions = [
    { value: 'aktiv', label: 'Aktiv'},
    { value: 'beta', label: 'Beta'},
    { value: 'publiceret', label: 'Publiceret' },
    { value: 'udgivet', label: 'Udgivet' },
    { value: 'ikke_udgivet', label: 'Ikke udgivet' }
]

const applyFilter = (publications, filter) => {
    const vurderingsrunde = filter.vurderingsrunde && filter.vurderingsrunde.value ? parseInt(filter.vurderingsrunde.value) : null
    const status = filter.status && filter.status.value
    console.log(status)
    return publications.filter(p => {
        let accept = true
        if (vurderingsrunde) {            
            accept = accept && parseInt(p.vurderingsrunde) === vurderingsrunde
        }
        if (status) {
            switch (status) {
                case 'publiceret':
                    accept = accept && p.keepForever && p.publicationId
                    break
                case 'udgivet':
                    accept = accept && p.keepForever
                    break
                case 'ikke_udgivet':                    
                    accept = !p.keepForever && accept
                    break
                case 'aktiv':                    
                    accept = p.release && accept
                    break
                case 'beta':                    
                    accept = p.beta && accept
                    break
                default:                    
                    break
            }
        }
        return accept
    })
}

const ManagePubliceringerPage = props => {
    const dispatch = useDispatch()

    const [ showCreateDrawer, setShowCreateDrawer ] = useState(false)
    const [ filter, setFilter ] = useState({ vurderingsrunde: ''})

    const { publications, selectedPublication } = useSelector(state => state.managePubliceringerPage)
    
    useEffect(() => {
        console.log("Hello?")
        dispatch(managePubliceringerInit())
    }, [dispatch])

    const updateFilter = (value, property) => {
        const f = { ...filter }
        f[property] = value
        setFilter(f)
    }

    console.log(filter)


    const filteredPublications = applyFilter(publications, filter)

    return (
        <Grid container spacing={2}>
            <Drawer open={showCreateDrawer} anchor='right' onClose={() => { setShowCreateDrawer(false) }} >
                <CreatePubliceringerDialog
                    open={showCreateDrawer}
                    onClose={() => setShowCreateDrawer(false) }
                    onStart={vurderingsrundeId => dispatch(startPublish(vurderingsrundeId))} />
            </Drawer>
            <Drawer open={selectedPublication != null} anchor='right' onClose={() => { dispatch(setSelectedPublication(null)) }} >
                <UpdatePubliceringerDialog
                    publication={selectedPublication}
                    onRelease={() => { dispatch(releasePublication(selectedPublication.id)) }}
                    onDelete={() => { dispatch(deletePublication(selectedPublication.id))}}
                    onClose={() => dispatch(setSelectedPublication(null))}
                />
            </Drawer>
            <Typography variant='h5'>Publiceringer</Typography>
            <Grid style={{ padding: 16, paddingBottom: 0 }} item alignItems='baseline' container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                    <Select isClearable placeholder='Vurderingsrunde' value={filter.vurderingsrunde} onChange={value => updateFilter(value, 'vurderingsrunde')} options={vurderingsrundeOptions} />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Select isClearable placeholder='Status' value={filter.status} onChange={value => updateFilter(value, 'status')} options={statusOptions} />
                </Grid>
            </Grid>
            <IconButton onClick={() => {
                setShowCreateDrawer(true);
            }} size='medium' variant='contained' color='primary'><AddCircleRoundedIcon fontSize="large" /></IconButton>
            <IconButton onClick={() => dispatch(fetchPubliceringer())} size='medium' variant='contained' color='primary'><RefreshIcon fontSize="large" /></IconButton>            
            <XTable
                key={`_${filteredPublications.map(p => p.id).join('_')}`} 
                data={filteredPublications}
                tableConfig={tableConfig}
                onRowClick={row => { dispatch(selectPublication(row)) }}
            />
        </Grid>
    )
}

export default ManagePubliceringerPage