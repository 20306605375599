import React from 'react'
import { useState } from 'react'
import {
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Typography,
    TextField,
    InputAdornment
} from '@material-ui/core'
import Draggable from 'react-draggable'
import { formatUnixtimestamp } from '../../utils/dateUtils'
import SendIcon from '@material-ui/icons/Send';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Clear';

const CommentView = props => {
    const { comment } = props
    return (
        <div style={{ paddingLeft: 8 }}>
            <Typography style={{ display: 'block', marginRight: 0, color: '#777' }} variant='body1'>{formatUnixtimestamp(comment.created)} - {comment.user}</Typography>
            <Typography style={{ display: 'inline-block' }} variant='body1'>{comment.comment}</Typography>
        </div>
    )
}

const CommentInputField = props => {
    const { section, onSave, onClose } = props
    const [comment, setComment] = useState('')
    return (
        <div style={{ backgroundColor: 'white', minWidth: '100%' }}>
            <TextField
                label={`Svar`}
                multiline
                fullWidth
                variant='outlined'
                value={comment}
                onChange={evt => setComment(evt.target.value)}
                onKeyPress={evt => {
                    if (evt.key === 'Enter' && !evt.shiftKey) {
                        setComment('')
                        onSave({ section, comment })
                    }
                }}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="start">
                            <IconButton onClick={() => { onClose() }} color='inherit' variant='outlined'><CancelIcon /></IconButton>
                            <IconButton onClick={() => { setComment(''); onSave({ section, comment }) }} color='inherit' variant='outlined'><SendIcon /></IconButton>
                        </InputAdornment>
                }}
            />
        </div>)
}
//rgba(232,234,246, 0.9)
//rgba(220,220,220,0.9)
//rgba(245,124,0, 0.9)
const CommentViewDialog = props => {
    const { section, comments, onCreateComment } = props;
    const [expanded, setExpanded] = useState(true)
    const [showInput, setShowInput] = useState(false)
    if (!comments || comments.length === 0) {
        return null
    }
    console.log('comments', comments)
    return (
        <Draggable
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}>
            <div style={{ position: 'fixed', bottom: 24, right: 24, minWidth: 600, maxWidth: 600, zIndex: 99999 }} onClick={evt => evt.preventDefault()}>
                <Accordion style={{ color: 'black', backgroundColor: 'rgba(232,234,246, 0.9)' }} expanded={expanded} elevation={4}>
                    <AccordionSummary>
                        <Typography className='handle' style={{   cursor: 'move', width: '90%' }} variant='h6'>Kommentarer</Typography>
                        <IconButton style={{ marginTop: -8 }} onClick={() => setExpanded(!expanded)}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                    </AccordionSummary>
                    <div style={{ overflow: 'auto',  maxHeight: 400, marginLeft: 8, marginRight: 8, paddingTop: 8, paddingBottom: 8, backgroundColor: 'white'}}>
                        {comments.map(c => <CommentView key={c.id} comment={c} />)}
                    </div>
                    <AccordionDetails>
                        {showInput ?
                            <CommentInputField onClose={() => setShowInput(false)} onSave={comment => { setShowInput(false); onCreateComment(comment) }} section={section} />
                            :
                            <Button variant='contained' style={{ float: 'right' }} onClick={() => setShowInput(true)}>Svar</Button>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
        </Draggable>
    )
}

export default CommentViewDialog