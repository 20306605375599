import RedlistApi from '../../service/redlistApi'

import {
    put,
    takeLatest,
    select,
    takeLeading,
    delay
} from 'redux-saga/effects'
import {
    showSpinner,
    hideSpinner,
    showError
} from '../actions/uiActions'
import { 
    VIEW_FAGLIG_KOMMENTERING_PAGE_INIT,
    VIEW_FAGLIG_KOMMENTERING_PAGE_FETCH,
    VIEW_FAGLIG_KOMMENTERING_PAGE_SET_ASSESSMENTS,
    VIEW_FAGLIG_KOMMENTERING_PAGE_SET_FILTER,   
    VIEW_FAGLIG_KOMMENTERING_PAGE_SET_PAGE,
    VIEW_FAGLIG_KOMMENTERING_PAGE_PREV_PAGE,
    VIEW_FAGLIG_KOMMENTERING_PAGE_NEXT_PAGE,
    setFilter,
    setPage,
    fetchAssessments    
} from './viewFagligKommenteringPageActions'
import { initEkspertEntities } from './ekspertEntitiesActions'
import { apiRequest2 } from '../api/apiRequest'

function* processInit(action) {
    try {
        const { status } = action.payload
        const { filter } = yield select(state => state.viewFagligKommenteringPage)
        if (filter.usergroupId) {
            yield put(setFilter({ ...filter, statusList: [status.value] }))
            return
        }
        let {loaded, usergroups} = yield select(state => state.ekspertEntities)
        if (!loaded) {
            yield put(initEkspertEntities())
        }
        let count = 0
        while (!loaded && count < 1000) {
            yield delay(20)
            count += 1
            loaded = yield select(state => state.ekspertEntities.loaded)            
        }
        usergroups = yield select(state => state.ekspertEntities.usergroups)
        if (!usergroups || usergroups.length === 0) {
            return
        }
        yield put(setFilter({ ...filter, statusList: [ status.value ], usergroupId: usergroups[0].id }))
    } catch (error) {
        console.log(error)
        yield put(showError(error))
    }
}

function* processSetFilter(action) {
    try {
        console.log({action})
        yield put(fetchAssessments(action.payload))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processFetchAssessments(action) {
    try {
        yield put(showSpinner())
        const data = yield apiRequest2(RedlistApi, RedlistApi.loadAssessments, [ action.payload.filter ])
        yield put({ type: VIEW_FAGLIG_KOMMENTERING_PAGE_SET_ASSESSMENTS, payload: { assessments: data.items, pages: data.pages, count: data.count }})
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}

function* processSetNextPage(action) {
    try {
        const filter = yield select(state => state.viewFagligKommenteringPage.filter)
        yield put(setPage(filter.page + 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPrevPage(action) {
    try {
        const filter = yield select(state => state.viewFagligKommenteringPage.filter)
        yield put(setPage(filter.page - 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPage(action) {
    try {
        const { filter, pages } = yield select(state => state.viewFagligKommenteringPage)
        let page = action.payload.page
        if (page > pages) {
            page = pages
        } else if (page < 0) {
            page = 0
        }
        yield put(setFilter({ ...filter, page }))
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLeading(VIEW_FAGLIG_KOMMENTERING_PAGE_INIT, processInit)
    yield takeLatest(VIEW_FAGLIG_KOMMENTERING_PAGE_FETCH, processFetchAssessments)
    yield takeLatest(VIEW_FAGLIG_KOMMENTERING_PAGE_SET_FILTER, processSetFilter)
    yield takeLatest(VIEW_FAGLIG_KOMMENTERING_PAGE_SET_PAGE, processSetPage)
    yield takeLatest(VIEW_FAGLIG_KOMMENTERING_PAGE_PREV_PAGE, processSetPrevPage)
    yield takeLatest(VIEW_FAGLIG_KOMMENTERING_PAGE_NEXT_PAGE, processSetNextPage) 
}