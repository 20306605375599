import { ImportStatus, TaxonPositionInfo, TaxonRecordDetails } from "../../service/arterDkModels";
import { ConvexHullArea } from "../../utils/hullHelper";
import { ReduxAction } from "../reduxModel"

export const GEOGRAPHY_RESET = '[geography] reset'
export const GEOGRAPHY_SET_YEAR = '[geography] set year'
export const GEOGRAPHY_CLOSE_SHOW_RECORD_DETAILS = '[geography] close record details'
export const GEOGRAPHY_SELECT_CELL = '[geography] select cell'
export const GEOGRAPHY_SELECT_POSITION = '[geography] select position'
export const GEOGRAPHY_SET_RECORD_DETAILS = '[geography] set record details'

export const GEOGRAPHY_FETCH_BY_POSITION = '[geography] fetch by position'
export const GEOGRAPHY_FETCH_BY_2x2GRID = '[geography] fetch by 2x2 grid'
export const GEOGRAPHY_SET_POINTS = '[geography] set points'
export const GEOGRAPHY_SET_CELLS = '[geography] set cells'
export const GEOGRAPHY_SET_CONVEX_HULL = '[geography] set convex hull'

export const GEOGRAPHY_ADD_POINT = '[geography] add point'
export const GEOGRAPHY_ADD_RECORD = '[geography] add record'
export const GEOGRAPHY_REMOVE_POINT = '[geography] remove point'
export const GEOGRAPHY_REMOVE_RECORD = '[geography] remove record'
export const GEOGRAPHY_REMOVE_CELL = '[geography] remove cell'

export const GEOGRAPHY_SET_LOADING = '[geography] set loading'

export const GEOGRAPHY_START_IMPORT_JOB = '[geography] start import job'
export const GEOGRAPHY_FETCH_TAXON_STATUS = '[geography] fetch taxon status'
export const GEOGRAPHY_SET_TAXON_STATUS = '[geography] set taxon status'


export interface CloseShowRecordDetailsAction extends ReduxAction {

}

export interface SelectPositionAction extends ReduxAction {
    payload: { 
        taxonId: string;
        positionHash: string;
        year: number;
    }
}


export interface SelectCellAction extends ReduxAction {
    payload: { 
        taxonId: string;
        cellId: number;
        year: number;
    }
}

export interface SetRecordDetailsAction extends ReduxAction {
    payload: {
        recordDetails: TaxonRecordDetails[]
    }
}

export interface FetchRecordsByPositionAction extends ReduxAction {
    payload: {
        taxonId: string;
        year: number;
    }
}

export interface FetchRecordsBy2x2GridAction extends ReduxAction {
    payload: {
        taxonId: string;
        year: number;
    }
}

export interface SetPointsAction extends ReduxAction {
    payload: {
        points: TaxonPositionInfo[]
    }
}

export interface AddPointAction extends ReduxAction {
    payload: {
        point: TaxonPositionInfo
    }
}

export interface SetCellsAction extends ReduxAction {
    payload: {
        cells: TaxonPositionInfo[]
    }
}

export interface SetConvexHullAction extends ReduxAction {
    payload: {
        convexHull: (ConvexHullArea | null)
    }
}
export interface GeographySetLoadingAction extends ReduxAction {
    payload: {
        loading: boolean
    }
}

export interface GeographyAddRecordAction extends ReduxAction {
    payload: {
        taxonId: string;
        position: {
            latitude: number,
            longitude: number
        }
    }
}
export interface GeographyRemoveRecordAction extends ReduxAction {
    payload: {
        taxonId: string;
        positionHash: string;
    }
}
export interface GeographyRemoveCellAction extends ReduxAction {
    payload: {
        taxonId: string;
        cellId: number;
    }
}

export const startLoading = () : GeographySetLoadingAction => ({
    type: GEOGRAPHY_SET_LOADING,
    payload: { loading: true }
})
export const stopLoading = () : GeographySetLoadingAction => ({
    type: GEOGRAPHY_SET_LOADING,
    payload: { loading: false }
})

export const fetchRecordsByPosition = (taxonId: string, year: number) : FetchRecordsByPositionAction => ({
    type: GEOGRAPHY_FETCH_BY_POSITION,
    payload: {
        taxonId,
        year
    }
})


export const fetchRecordsBy2x2Grid = (taxonId: string, year: number) : FetchRecordsBy2x2GridAction => ({
    type: GEOGRAPHY_FETCH_BY_2x2GRID,
    payload: {
        taxonId,
        year
    }
})

export const setPoints = (points: TaxonPositionInfo[]) : SetPointsAction => ({
    type: GEOGRAPHY_SET_POINTS,
    payload: {
        points
    }
})

export const addPoint = (point: TaxonPositionInfo) : AddPointAction => ({
    type: GEOGRAPHY_ADD_POINT,
    payload: { point }
})

export const setCells = (cells: TaxonPositionInfo[]) : SetCellsAction => ({
    type: GEOGRAPHY_SET_CELLS,
    payload: {
        cells
    }
})

export const setConvexHull = (convexHull: (ConvexHullArea | null)) : SetConvexHullAction => ({
    type: GEOGRAPHY_SET_CONVEX_HULL,
    payload: {
        convexHull
    }
})



export const closeShowRecordDetails = () : CloseShowRecordDetailsAction => ({
    type: GEOGRAPHY_CLOSE_SHOW_RECORD_DETAILS
})

export const selectPosition = (taxonId: string, positionHash : string, year: number) : SelectPositionAction => ({
    type: GEOGRAPHY_SELECT_POSITION,
    payload: { taxonId, positionHash, year }
})
export const selectCell = (taxonId: string, cellId : string, year: number) : SelectCellAction => ({
    type: GEOGRAPHY_SELECT_CELL,
    payload: { taxonId, cellId: parseInt(cellId), year }
})

export const setRecordDetails = (recordDetails: TaxonRecordDetails[]) : SetRecordDetailsAction => ({
    type: GEOGRAPHY_SET_RECORD_DETAILS,
    payload: {
        recordDetails
    }
})

export const geographyAddRecord = (taxonId: string, longitude: number, latitude: number) : GeographyAddRecordAction => ({
    type: GEOGRAPHY_ADD_RECORD,
    payload: {
        taxonId,
        position: {
            longitude,
            latitude
        }
    }
})
export const geographyRemoveRecord = (taxonId: string, positionHash: string) : GeographyRemoveRecordAction => ({
    type: GEOGRAPHY_REMOVE_RECORD,
    payload: {
        taxonId,
        positionHash
    }
})
export const geographyRemoveCell = (taxonId: string, cellId: string) : GeographyRemoveCellAction => ({
    type: GEOGRAPHY_REMOVE_CELL,
    payload: {
        taxonId,
        cellId: parseInt(cellId)
    }
})

export interface GeographyStartImportJobAction extends ReduxAction {
    payload: {
        taxonId: string
    }
}

export const geographyStartImportJob = (taxonId: string) : GeographyStartImportJobAction => ({
    type: GEOGRAPHY_START_IMPORT_JOB,
    payload: {
        taxonId
    }
})

export const resetGeography = () : ReduxAction => ({
    type: GEOGRAPHY_RESET
})

export interface FetchTaxonStatusAction extends ReduxAction {
    payload: { taxonId : string }
}

export interface SetTaxonStatusAction extends ReduxAction {
    payload: { status : (ImportStatus | null) }
}

export const fetchTaxonStatus = (taxonId: string ) : FetchTaxonStatusAction => ({
    type: GEOGRAPHY_FETCH_TAXON_STATUS,
    payload: { taxonId }
})

export const setTaxonStatus = (status : (ImportStatus | null)) :  SetTaxonStatusAction => ({
    type: GEOGRAPHY_SET_TAXON_STATUS,
    payload: {status}
}) 


export interface GeographySetYearAction extends ReduxAction {
    payload: {
        year: number
    }
}

export const geographySetYear = (year: number) : GeographySetYearAction => ({
    type: GEOGRAPHY_SET_YEAR,
    payload: {
        year
    }
})