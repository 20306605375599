import {
    put,
    call,
    takeLatest,
    select
} from 'redux-saga/effects'

import {
    SET_ASSESSMENT_CRITERIA,
    SET_CATEGORY_CHANGE,
    SET_CRITERIA_VALIDATION,
    SET_CATEGORY_CHANGE_VALIDATION,
    SET_PREVIOUS_ASSESSMENTS,
    validateAssessment,
    VALIDATE_ASSESSMENT
} from '../editAssessment/editAssessmentActions'
import { globalValidator } from '../../assessments/sections/validators/globalValidator'
import { preliminaryValidator } from '../../assessments/sections/validators/preliminaryValidator'
import { reductionValidator } from '../../assessments/sections/validators/reductionValidator'
import { geographyValidator } from '../../assessments/sections/validators/geographyValidator'
import { populationValidator } from '../../assessments/sections/validators/populationValidator'
import { quantitativeAnalysisValidator } from '../../assessments/sections/validators/quantitativeAnalysisValidator'
import { SET_CATEGORY } from '../actions/categoryActions'
import { isCategoryChangeReasonRequired, validateCategoryChangeReason } from '../../service/categoryChangeService'
import { supplerendeOplysningerValidator } from '../../assessments/sections/validators/supplerendeOplysningerValidator'
import { SET_SUPPLERENDE_OPLYSNINGER } from '../actions/editSupplerendeOplysningerActions'
import { adjustmentValidator } from '../../assessments/sections/validators/adjustmentValidator'
import { showError } from '../actions/uiActions'
import { levestedValidator } from '../../assessments/sections/validators/levestedValidator'
import { threatValidator } from '../../assessments/sections/validators/threatValidator'

function* processSetAssessmentCriteria(action) {
    const criteria = action.payload
    const threat = yield select(state => state.editAssessment.threat)
    const supplerendeOplysninger = yield  select(state => state.editSupplerendeOplysninger)
    const levested = yield select(state => state.editLevested)    
    yield put(validateAssessment(criteria, supplerendeOplysninger, levested, threat ))
}

function* processSetSupplerendeOplysninger(action) {
    const supplerendeOplysninger = action.payload.value
    const criteria  = yield  select(state => state.editAssessment.assessmentCriteria)
    const threat = yield  select(state => state.editAssessment.threat)
    const levested = yield select(state => state.editLevested)    
    yield put(validateAssessment(criteria, supplerendeOplysninger, levested, threat ))
}

function* processValidateCriteria(action) {
    const {criteria, supplerendeOplysninger, levested, threat } = action.payload
    try {
        const criteriaValidationResult = {
        }
        if (!criteria) {
            return
        }
        const category = yield select(state => state.editAssessment.category)
        criteriaValidationResult.preliminary = yield call(preliminaryValidator, criteria)
        criteriaValidationResult.reduction = yield reductionValidator(criteria)
        criteriaValidationResult.geography = yield geographyValidator(criteria)
        criteriaValidationResult.population = yield populationValidator(criteria)
        criteriaValidationResult.quantitativeAnalysis = yield quantitativeAnalysisValidator(criteria)        
        criteriaValidationResult.adjustment = yield adjustmentValidator(criteria)
        criteriaValidationResult.supplerendeOplysninger = yield supplerendeOplysningerValidator(supplerendeOplysninger, criteria)
        criteriaValidationResult.levesteder = yield levestedValidator(levested, category?.category)
        criteriaValidationResult.threat = yield threatValidator(threat, category?.category)
        criteriaValidationResult.globalSection = yield globalValidator(criteria, category?.category )
        let ok = true
        Object.keys(criteriaValidationResult).forEach(k => {
            const v = criteriaValidationResult[k]
            if (v.ok === false) {
                ok = false
            }
        })
        criteriaValidationResult.ok = ok
        criteriaValidationResult.kvittering = { ok, message: ok ? null : 'En eller flere sektioner er ikke udfyldt korrekt' }
        yield put({ type: SET_CRITERIA_VALIDATION, payload: criteriaValidationResult })
    } catch (error) {
        yield put(showError(error))
    }
}

const validateSingleCategoryChange = (vurderingsrundeId, category, previousAssessments, categoryChangeReasons) => {
    let catchange = categoryChangeReasons[vurderingsrundeId]
    //console.log(catchange)
    const assessmentToCompare = previousAssessments.find(a => a.vurderingsrundeId === vurderingsrundeId)    
    const categoryChangeAction = isCategoryChangeReasonRequired(assessmentToCompare, category.modifiedCategory)
    const from = assessmentToCompare ? assessmentToCompare.category : 'Ingen';
    const to = category && category.category && category.category.name ? category.category.name : 'Ingen';
    return validateCategoryChangeReason(catchange?.reasons, catchange?.comment, categoryChangeAction, from, to)    
}

const categoryChangeValidator = (category, previousAssessments, categoryChangeReasons) => {
    //console.log(category)
    //console.log(previousAssessments)
    //console.log(categoryChangeReasons)
    if (!previousAssessments || previousAssessments.length === 0) {
        return {
            categoryChange2010: { ok: true },
            categoryChange2019: { ok: true }
        }
    }
    const result = {}
    result['categoryChange2010'] = validateSingleCategoryChange(2010, category, previousAssessments, categoryChangeReasons)
    result['categoryChange2019'] = validateSingleCategoryChange(2019, category, previousAssessments, categoryChangeReasons)
    return result
}

function* validateCategoryChange(action) {
    try {
        const { categoryChangeReasons } = action.payload
        const { category, previousAssessments } = yield select(state => state.editAssessment)        
        const validationResult = yield categoryChangeValidator(category, previousAssessments, categoryChangeReasons)
        yield put({ type: SET_CATEGORY_CHANGE_VALIDATION, payload: validationResult})
    } catch (error) {
        yield put(showError(error))
    }
}

function* validateCategoryChangeFromCategory(action) {
    try {
        const { category } = action.payload
        const { categoryChangeReasons, previousAssessments } = yield select(state => state.editAssessment)        
        const validationResult = yield categoryChangeValidator(category, previousAssessments, categoryChangeReasons)
        yield put({ type: SET_CATEGORY_CHANGE_VALIDATION, payload: validationResult})
    } catch (error) {
        yield put(showError(error))
    }
}

function* validateCategoryChangeFromPrevious(action) {
    try {
        const { assessments } = action.payload
        const { categoryChangeReasons, category } = yield select(state => state.editAssessment)        
        const validationResult = yield categoryChangeValidator(category, assessments, categoryChangeReasons)
        yield put({ type: SET_CATEGORY_CHANGE_VALIDATION, payload: validationResult})
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLatest(SET_PREVIOUS_ASSESSMENTS, validateCategoryChangeFromPrevious)
    yield takeLatest(SET_CATEGORY, validateCategoryChangeFromCategory)
    yield takeLatest(SET_CATEGORY_CHANGE, validateCategoryChange)
    yield takeLatest(SET_ASSESSMENT_CRITERIA, processSetAssessmentCriteria)
    yield takeLatest(SET_SUPPLERENDE_OPLYSNINGER, processSetSupplerendeOplysninger)
    yield takeLatest(VALIDATE_ASSESSMENT, processValidateCriteria)
}