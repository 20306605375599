import {
  Categories
} from '../../../service/categoryEngine'

import {
  oneOf
} from './sectionValidators'

const categoryValuesThatRequiresLevested = [
  Categories.CR.value,
  Categories.EN.value,
  Categories.VU.value,
  Categories.NT.value
]

export const levestedValidator = (levested, category) => {  
  if (category && oneOf(category.value, categoryValuesThatRequiresLevested) ) {
    if (!levested) {
      return {
        ok: true,
        warning: true,
        global: true,
        message: 'Levesteder bør angives'
      }
    }
    if (!levested.hovedmiljoer || levested.hovedmiljoer.length === 0) {
      return {
        ok: true,
        warning: true,
        global: true,
        message: 'Levesteder bør angives'
      }
    }
  }
  return {
    empty: !levested.hovedmiljoer || levested.hovedmiljoer.length === 0,
    ok: true
  }
}