import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const MANAGE_PUBLICERINGER_PAGE_INIT = '[manage publiceringer] init'

export const MANAGE_PUBLICERINGER_PAGE_SET_PUBLICERINGER = '[manage publiceringer] set publiceringer'
export const MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICERINGER = '[manage publiceringer] fetch publiceringer'

export const MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION = '[manage publiceringer] delete publication'
export const MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION_OK = '[manage publiceringer] delete publication complete'

export const MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION = '[manage publiceringer] release publication'
export const MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION_OK = '[manage publiceringer] release publication complete'

export const MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICATION_BY_ID = '[manage publiceringer] fetch publication by id'
export const MANAGE_PUBLICERINGER_PAGE_SET_SELECTED_PUBLICATION = '[manage publiceringer] set selected publication'

export const MANAGE_PUBLICERINGER_PAGE_START_PUBLISH = '[manage publiceringer] start publish'
export const MANAGE_PUBLICERINGER_PAGE_GET_PUBLISH_PROGRESS = '[manage publiceringer] get progress'
export const MANAGE_PUBLICERINGER_PAGE_SET_PUBLISH_PROGRESS = '[manage publiceringer] set progress'
export const MANAGE_PUBLICERINGER_PAGE_PUBLISH_COMPLETE = '[manage publiceringer] publish complete'

export const managePubliceringerInit = () => ({
    type: MANAGE_PUBLICERINGER_PAGE_INIT    
})

export const fetchPubliceringer = () => ({
    type: MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICERINGER
})

export const releasePublication = (id : FixMeLater) => ({
    type: MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION,
    payload: { id }
})

export const deletePublication = (id : FixMeLater) => ({
    type: MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION,
    payload: { id } 
})

export const releasePublicationComplete = () => ({
    type: MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION_OK
})

export const setSelectedPublication = (publication : FixMeLater) => ({
    type: MANAGE_PUBLICERINGER_PAGE_SET_SELECTED_PUBLICATION,
    payload: { publication }
})

export const selectPublication = (publication : FixMeLater) => ({
    type: MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICATION_BY_ID,
    payload: { id: publication.id }
})

export const startPublish = (vurderingsrundeId : FixMeLater) => ({
    type: MANAGE_PUBLICERINGER_PAGE_START_PUBLISH,
    payload: { vurderingsrundeId }
})
