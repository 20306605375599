import React from "react";
import { Typography } from "@material-ui/core";
import { helpStyles } from "./help";

export const ThreatsUnknownHelp = () => {
  const classes = helpStyles();
  return (
    <>
      <Typography className={classes.typography} variant="subtitle1">
      Truslerne for denne art er dårligt kendte
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Her kan du markere hvis du ikke kender artens trusler eller de er så dårligt kendte 
        at man ikke meningsfyldt kan sige noget om dem. Der er altid usikkerhed om de præcise 
        trusler for en art, men de bør alligevel angives selvom man ikke er helt sikker. 
        Denne mulighed skal kun bruges ved meget stor usikkerhed om truslerne.
      </Typography>
    </>
  );
};
