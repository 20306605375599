import React from 'react'
import {
    Button,
    Typography,
    Grid
} from '@material-ui/core'
import { ThemeProvider } from 'styled-components'
import CheckboxButton from '../../mui/CheckboxButton'

const renderHovedmiljo = (hovedmiljo, checked, onSelectedChanged) => {
    let chk = checked ? true : false;
    return (
        <Grid key={hovedmiljo.slug} item xs={12} sm={6} md={4}>
            <CheckboxButton
                fullwidth
                label={hovedmiljo.name}
                checked={chk}
                onChange={(value) => { onSelectedChanged(value) }} />
        </Grid>)
}

const buttonTheme = {
    background: '#039be5',
    color: '#fff',
    borderColor: '#03a9f4',
    checked: {
        background: '#0277bd',
        color: '#fff',
        borderColor: '#01579b'
    }
}

const Hovedmiljoer= (props) => {
    const { value, hovedmiljoer, onSelectedChanged, onProceed } = props;

    const onHovedmiljoSelectedChanged = (hovedmiljo, checked) => {
        const selection = value.filter(h => hovedmiljo.slug !== h.slug)
        if (checked) {
            selection.push(hovedmiljo)
        }
        //setHovedmiljoSelectState(_t);
        //let selection = hovedmiljoer.filter(h => _t[h.id]);
        onSelectedChanged(selection);
    }

    const hovedmiljoerSelected = value.length > 0

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} lg={11} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Angiv hovedmiljøer</Typography>
                </Grid>
                <ThemeProvider theme={buttonTheme}>
                    {hovedmiljoer.map(h =>
                        renderHovedmiljo(h, value.filter(v => v.slug === h.slug).length > 0, (checked) => onHovedmiljoSelectedChanged(h, checked))
                    )}
                </ThemeProvider>
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }}
                        variant='contained'
                        color='primary'
                        disabled={!hovedmiljoerSelected}
                        onClick={() => onProceed()}>Videre</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Hovedmiljoer