import React from 'react'
import { useState, useEffect } from 'react';
import {
    IconButton,
    Drawer,
    Chip,
    LinearProgress
} from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { useDispatch, useSelector } from 'react-redux';
import XTable from '../../mui/XTable';
import CreateAssessmentsHeader from './CreateAssessmentsHeader';
import CreateAssessmentsDialog from './CreateAssessmentsDialog';

import { setTitle } from '../../redux/actions/uiActions'
import { fetchAdminEntities } from '../../redux/admin/adminEntitiesActions';
import { initCreateAssessmentsPage, setPage, prevPage, nextPage, createAssessments } from '../../redux/admin/createAssessmentsPageActions'
import Paging from '../../mui/Paging';

import { isEmptyArray } from '../../utils/collectionUtils'


const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Id', property: 'id' },
        { header: 'Videnskabeligt navn', property: 'scientificName' },
        { header: 'Almindeligt navn', property: 'vernacularName' },
        { header: 'Population', property: 'population' },
        { header: 'Familie', property: 'speciesFamily' },
        { header: 'Vurderinger', property: 'assessments', render: (value) => { return  value.map(v => (
           <Chip key={v.vurderingsrundeId} label={v.vurderingsrundeId}></Chip> 
        )) }}
    ],
    selectable: true
}

const assessorFromId = (id, assessors) => assessors.find(a => a.id === id)

const CreateAssessmentsPage = (props) => {
    const dispatch = useDispatch();

    const { vurderingsrunder, assessors, usergroups } = useSelector(state => state.adminEntities);
    const { queryId, pages, count, filter, assessments } = useSelector(state => state.createAssessmentsPage)
    const { page, limit } = filter
    const loading = useSelector(state => state.ui.loading);
    const defaultAssessor = assessorFromId(filter.assessorId, assessors)

    let [selectedRows, setSelectedRows] = useState([]);
    let [showCreateDrawer, setShowCreateDrawer] = useState(false);
    
    useEffect(() => {
        dispatch(setTitle('Opret vurderinger'))
    }, [ dispatch ])

    useEffect(() => {
        dispatch(fetchAdminEntities());
        dispatch(initCreateAssessmentsPage())
        //dispatch(getSpeciesWithAssessments({ noAssessmentsOnly: false, limit: 200}));        
    }, [ dispatch ])
    if (isEmptyArray(vurderingsrunder) || isEmptyArray(assessors) || isEmptyArray(usergroups)) {
        return (<div><LinearProgress variant='indeterminate' /></div>)
    }
    return (
        <div style={{ minHeight: '80vh' }}>
            <Drawer open={showCreateDrawer} anchor='right' onClose={() => { setShowCreateDrawer(false) }} >
                <CreateAssessmentsDialog 
                    onSave={(value) => { console.log('creating using', value); setSelectedRows([]); dispatch(createAssessments({ ...value, speciesIds: selectedRows })); setShowCreateDrawer(false) }}
                    onClose={() => setShowCreateDrawer(false)}
                    defaultAssessor={defaultAssessor} 
                    assessors={assessors} 
                    usergroups={usergroups}
                    vurderingsrunder={vurderingsrunder} />
            </Drawer>        
            <CreateAssessmentsHeader defaultAssessor={assessors[0]} />
            <IconButton onClick={() => {
                setShowCreateDrawer(true);
            }} disabled={selectedRows.length === 0} size='medium' variant='contained' color='primary'><AddCircleRoundedIcon fontSize="large" /></IconButton>
            <Paging pageSize={limit} page={page} count={count} onNext={() => dispatch(nextPage())} onPrev={() => dispatch(prevPage())} onGoto={(page) => dispatch(setPage(page))} pages={pages} />
            <XTable loading={loading} tableConfig={tableConfig} key={'_' + queryId} data={assessments} onSelectionChanged={value => { setSelectedRows(value) }} />
        </div>
    )
}

export default CreateAssessmentsPage

// TODO - fix this Component! 
// the assessment paging, fetching and filtering must be improved
// possibly make XTable handle data and paging using a callback function
// 