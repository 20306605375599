import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { AssessmentCriteria, CategoryChangeType, CommentType, SpeciesInformation } from '../reducers/editAssessmentRed';
import { 
  ASSESSMENT_QA_SET_PHASE,
  ASSESSMENT_QA_SET_ASSESSMENT,  
  ASSESSMENT_QA_SET_COMMENTS   
} from './assessmentQAPageActions'

export interface AssessmentQAType {
  id: number;
  vurderingsDato: null | number;
  assessorId: number;
  technicalApproverId: number;
  approverId: number;
  status: string;
  statusComment: string | null;
  speciesInformation : SpeciesInformation;
  assessmentCriteria: AssessmentCriteria;
  categoryChangeReasons: CategoryChangeType[];
  supplerendeOplysninger: FixMeLater;
  levested: FixMeLater;
  threat: {
    threats: FixMeLater[];
    overallThreats: FixMeLater[];
  };
  categoryRoute: FixMeLater;
  category: string;
  categoryRaw: string;
  categoryModifier: number;  
}

export interface PhaseType {
  slug: string;
  canApprove: boolean;
  commentPhases: string[];
}

export interface AssessmentQAPageStore {
  assessment: AssessmentQAType | null;
  comments: CommentType[];
  phase: PhaseType | null;
}

const initialState : AssessmentQAPageStore = {
    assessment: null,
    comments: [],
    phase: null
}

const assessmentQAPageReducer = (state = initialState, action : FixMeLater) => {
    switch (action.type) {
      case ASSESSMENT_QA_SET_PHASE:
        return { ...state, phase: action.payload.phase }  
      case ASSESSMENT_QA_SET_ASSESSMENT:
        return { ...state, assessment: action.payload.assessment }
      case ASSESSMENT_QA_SET_COMMENTS:
        return { ...state, comments: action.payload.comments }
    default:
        return state
    }
  }
  
export default assessmentQAPageReducer