import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Grid,
    Typography,
    IconButton,
    Drawer
} from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import {
    crudPageInit,
    createItem,
    updateItem,
    deleteItem,
    setFilter,
    showCreateDialog,
    hideCreateDialog,
    showEditDialog,
    hideEditDialog,
    nextPage,
    prevPage,
    setPage
} from './sagas/crudActions'
import XTable from '../mui/XTable'

import CreateDialog from './CreateDialog'
import EditDialog from './EditDialog'
import DelayedTextField from '../mui/DelayedTextField';
import Paging from '../mui/Paging';


const ListPage = props => {
    const { pageTitle, tableConfig, crudConfig } = props
    const dispatch = useDispatch()
    const { count, pages, filter, selectedItem, items, showEdit, showCreate, queryId } = useSelector(state => state.crudPage)

    useEffect(() => {
        dispatch(crudPageInit(crudConfig))
    }, [dispatch, crudConfig])
    
    //console.log(showEdit, selectedItem)
    return (
        <Grid container spacing={2}>
            <Drawer open={showCreate} anchor='right' onClose={() => { dispatch(hideCreateDialog()) }} >
                <CreateDialog
                    title={crudConfig.createTitle}
                    properties={crudConfig.properties}
                    onClose={() => dispatch(hideCreateDialog())}
                    onSave={(value) => { dispatch(createItem(value)) }}
                />
            </Drawer>
            <Drawer open={Boolean(showEdit && selectedItem)} anchor='right' onClose={() => { dispatch(hideEditDialog()) }} >
                <EditDialog
                    item={selectedItem || {}}
                    properties={crudConfig.properties}
                    onClose={() => dispatch(hideEditDialog())}
                    onSave={(value) => { dispatch(updateItem(selectedItem.id, value)) }}
                    onDelete={id => dispatch(deleteItem(id))}
                />
            </Drawer>
            <Grid item xs={12}>
                <Typography variant='h5'>{pageTitle}</Typography>
            </Grid>
            <Grid item xs={12}>
                <IconButton onClick={() => {
                    dispatch(showCreateDialog())
                }} size='medium' variant='contained' color='primary'><AddCircleRoundedIcon fontSize="large" /></IconButton>
            </Grid>
            <Grid item xs={12}>
                {
                    crudConfig.filter.map(f => (
                            <DelayedTextField
                                key={f.property}
                                label={f.label}
                                value={filter[f.property] || ''}
                                onChange={value => {
                                    let newFilter = { ...filter }
                                    newFilter[f.property] = value                        
                                    dispatch(setFilter(newFilter))
                            }} />))                    
                }                
            </Grid>
            <Grid xs={12} item container>
                <Paging
                    count={count}
                    pageSize={filter.limit}
                    pages={pages}
                    page={filter.page}
                    onNext={() => dispatch(nextPage())} onPrev={() => dispatch(prevPage())}
                    onGoto={(page) => dispatch(setPage(page))} />
            </Grid>
            <XTable
                key={queryId}
                data={items}
                tableConfig={tableConfig}
                onRowClick={value => dispatch(showEditDialog(value))}
            />
        </Grid>
    )
}

export default ListPage