import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeEvery,   
    select
} from 'redux-saga/effects'
import {
    showSaving,
    hideSaving,
    showError
} from '../actions/uiActions'
import { 
    USER_PROFILE_UPDATE,
} from './userProfilePageActions'
import { loginOk } from '../actions/loginActions'
//import { fetchAdminEntities } from '../admin/adminEntitiesActions'



function* processUpdateUser(action) {
    try {
        const { user, rememberMe } = yield select(state => state.app)
        console.log(user)
        let { name, email, password } = action.payload
        if (!name) {name = null}
        if (!email) {email = null}
        if (!password) {password = null}
        if (name) {user.name = name}
        if (email) {user.email = email}

        yield put(showSaving())
        yield apply(RedlistApi, RedlistApi.updateUser, [ name, email, password ])
        yield put(loginOk(user, rememberMe))
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeEvery(USER_PROFILE_UPDATE, processUpdateUser)
}