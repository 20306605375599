import RedlistPublishApi from '../../service/redlistPublishApi'

import {
    put,
    takeEvery,
    takeLatest,
    takeLeading,
    delay
} from 'redux-saga/effects'
/*import {
    showSpinner,
    hideSpinner,
    showSaving,
    hideSaving
} from '../actions/uiActions'*/
import { 
    MANAGE_PUBLICERINGER_PAGE_INIT,
    MANAGE_PUBLICERINGER_PAGE_SET_SELECTED_PUBLICATION,
    MANAGE_PUBLICERINGER_PAGE_SET_PUBLICERINGER,
    MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICATION_BY_ID,
    MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICERINGER,
    MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION,
    MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION_OK,
    fetchPubliceringer,
    setSelectedPublication,
    releasePublicationComplete,
    MANAGE_PUBLICERINGER_PAGE_START_PUBLISH,
    MANAGE_PUBLICERINGER_PAGE_GET_PUBLISH_PROGRESS,
    MANAGE_PUBLICERINGER_PAGE_SET_PUBLISH_PROGRESS,
    MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION,
    MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION_OK
} from './managePubliceringerPageActions'
import { apiRequest2 } from '../api/apiRequest'
import { hideSaving, showMessage, showSaving } from '../actions/uiActions'

function* processInit(action) {
    try {
        yield put(fetchPubliceringer())
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}


function* processFetchPubliceringById(action) {
    try {
        yield apiRequest2(RedlistPublishApi, RedlistPublishApi.fetchPublicationById, [ action.payload.id ], {
            "ok": data => ({
                type: MANAGE_PUBLICERINGER_PAGE_SET_SELECTED_PUBLICATION,
                payload: { publication: data }
            }),
        })
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}


function* processFetchPubliceringer(action) {
    try {
        yield apiRequest2(RedlistPublishApi, RedlistPublishApi.listPublications, [], {
            "ok": data => ({
                type: MANAGE_PUBLICERINGER_PAGE_SET_PUBLICERINGER,
                payload: { publications: data.items }
            }),
        })
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}

function* processDeletePublication(action) {
    try {
        const { id } = action.payload        
        yield put(showSaving())
        yield apiRequest2(RedlistPublishApi, RedlistPublishApi.deletePublication, [ id ], {
            "ok": data => ({
                type: MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION_OK
            }),
            "400": showMessage("Publicering kan ikke slettes"),
            "error": showMessage('Fejl under sletning')
        })
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}

function* processDeletePublicationOk(action) {
    try {
        yield put(hideSaving())
        yield put(setSelectedPublication(null))
        yield put(showMessage("Publicering slettet"))
        yield put(fetchPubliceringer())        
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}

function* processReleasePublication(action) {
    try {
        const { id } = action.payload
        yield put(showSaving())
        yield apiRequest2(RedlistPublishApi, RedlistPublishApi.releasePublication, [ id ], {
            "ok": data => releasePublicationComplete(),
            "error": showMessage('Fejl under release')
        })
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}

function* processReleasePublicationComplete(action) {
    try {
        yield put(hideSaving())
        yield put(fetchPubliceringer())
        yield put(setSelectedPublication(null))
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}

function* processStartPublish(action) {
    try {
        const { vurderingsrundeId } = action.payload
        yield put({
            type: MANAGE_PUBLICERINGER_PAGE_SET_PUBLISH_PROGRESS,
            payload: { stage: 'requested', completed: 0, total: 1 }
        })
        yield apiRequest2(RedlistPublishApi, RedlistPublishApi.startPublish, [ vurderingsrundeId ], {
            "ok": data => ({
                type: MANAGE_PUBLICERINGER_PAGE_GET_PUBLISH_PROGRESS
            }),
            "409": () => showMessage("Conflict!")
        })
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}


function* processPublishProgress(action) {
    try {
        const data = yield apiRequest2(RedlistPublishApi, RedlistPublishApi.getProgress, [])
        yield put({
            type: MANAGE_PUBLICERINGER_PAGE_SET_PUBLISH_PROGRESS,
            payload: data
        })
        if (data.stage !== 'ready') {
            yield delay(1000)
            yield put({
                type: MANAGE_PUBLICERINGER_PAGE_GET_PUBLISH_PROGRESS
            })
        } else {
            yield put(fetchPubliceringer())
        }
    } catch (error) {
        yield put(showMessage('Uventet fejl'))
    }
}

export default function* watcher() {
    yield takeLeading(MANAGE_PUBLICERINGER_PAGE_INIT, processInit)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICATION_BY_ID, processFetchPubliceringById)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_FETCH_PUBLICERINGER, processFetchPubliceringer)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION, processReleasePublication)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_RELEASE_PUBLICATION_OK, processReleasePublicationComplete)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_START_PUBLISH, processStartPublish)
    yield takeEvery(MANAGE_PUBLICERINGER_PAGE_GET_PUBLISH_PROGRESS, processPublishProgress)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION, processDeletePublication)
    yield takeLatest(MANAGE_PUBLICERINGER_PAGE_DELETE_PUBLICATION_OK, processDeletePublicationOk)

}