import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import Select from 'react-select'
import AsyncSelect from "react-select/async";

import XTable from '../mui/XTable'
import Paging from '../mui/Paging';


import { setTitle } from '../redux/actions/uiActions'
import {
    init,
    setPage,
    nextPage,
    prevPage,
    setFilter
} from '../redux/ekspert/viewQAAssessmentsPageActions'

import { statusEnum } from '../service/assessmentStatusEnum';
import RedlistApi from '../service/redlistApi';

const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Videnskabeligt navn', property: 'speciesInformation.scientificName' },
        { header: 'Almindeligt navn', property: 'speciesInformation.vernacularName' },
        { header: 'Population', property: 'speciesInformation.population' },
        { header: 'Familie', property: 'speciesInformation.speciesFamily' },
        { header: 'Status', property: 'status', func: assessment => statusEnum.fromValue(assessment.status).label }
    ],
    selectable: false
}


const toOption = e => {
    if (!e) {
        return null
    }
    return { id: e.id, value: e.id, label: e.name }
}

const toObject = (id, list) => list.find(l => l.id === id)

const loadFamilyOptions = async (inputValue, callback) => {
    const data = await RedlistApi.loadSpeciesFamilies(inputValue)
    return Promise.resolve(
        data.map((item) => { return { id: item.id, label: item.name, name: item.name, value: item.id } })
    )
}

const loadAssessedByOptions = async (inputValue, callback) => {
    const data = await RedlistApi.loadAssessors()
    console.log('fetched assessors', data)
    const assessors = inputValue ? data.items.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase())) : data.items
    return Promise.resolve(
        assessors.map((item) => { return { id: item.id, label: item.name, name: item.name, value: item.id } })
    )
}


const ViewQAAssessmentsPage = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { assessors } = useSelector(state => state.ekspertEntities)
    const { pages, count, assessments, filter } = useSelector(state => state.viewQaAssessmentsPage)
    const { user } = useSelector(state => state.app)
    const { loading } = useSelector(state => state.ui)
    const isAdmin = user.role === 'ADMIN'
    const updateFilter = (value, property) => {
        const _f = { ...filter, page: 1 }
        _f[property] = value
        console.log('setting filter', _f)
        dispatch(setFilter(_f))
    }

    useEffect(() => {
        dispatch(setTitle('Kvalitetssikring'))
        dispatch(init(user))

    }, [dispatch, user])

    if (!assessors) {
        return (<div>Loading</div>)
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Select
                        isClearable={false}
                        options={assessors.map(toOption)}
                        onChange={value => { updateFilter(value?.id, 'approverId') }}
                        value={toOption(toObject(filter.approverId, assessors))}
                    />
                </Grid>
                { isAdmin &&
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary" gutterBottom>
                            Vurderet af
                        </Typography>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={(inputValue, callback) => loadAssessedByOptions(inputValue, callback)}
                            defaultOptions
                            isClearable={true}
                            onChange={(value) => updateFilter(value?.id, 'assessedById')}
                            placeholder={"Vurderet af..."} />
                    </Grid>
                }   
                <Grid item xs={12} md={isAdmin ? 6 : 12}>
                    <Typography color="textSecondary" gutterBottom>
                        Familie
                    </Typography>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={(inputValue, callback) => loadFamilyOptions(inputValue, callback)}
                        defaultOptions
                        isClearable={true}
                        onChange={(value) => updateFilter(value?.id, 'speciesFamilyId')}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 24 }} />
            <Paging
                count={count}
                pages={pages}
                page={filter.page}
                pageSize={filter.limit}
                onPrev={() => dispatch(prevPage())}
                onNext={() => dispatch(nextPage())}
                onGoto={page => dispatch(setPage(page))}
            />
            <XTable loading={loading} key={JSON.stringify(filter)} size={'medium'} onRowClick={value => history.push(`/qa/${value.id}`)} data={assessments} tableConfig={tableConfig} />
        </div>
    )
}

export default ViewQAAssessmentsPage