import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
  SET_ASSESSORS,
  SET_USERGROUPS,
  SET_THREAT_DEFINITION,
  EKSPERT_ENTITIES_LOADED,
  SET_LEVESTEDS_DEFINITION,
} from './ekspertEntitiesActions'

export interface EkspertEntitiesStore {
  loaded: boolean
  threatDefinition : FixMeLater[]
  levestedDefinition: FixMeLater[]
  assessors: FixMeLater[]
  usergroups: FixMeLater[]
}

const initialState = {
  loaded: false,
  threatDefinition: {},
  levestedsDefinition: {},
  assessors: [],
  usergroups: [],
}

const ekspertEntitiesReducer = (state = initialState, action : FixMeLater) => {
  switch (action.type) {
    case EKSPERT_ENTITIES_LOADED:
      return { ...state, loaded: true }
    case SET_LEVESTEDS_DEFINITION:
      return { ...state, levestedsDefinition: action.payload.levestedsDefinition }
    case SET_THREAT_DEFINITION:
      return { ...state, threatDefinition: action.payload.threatDefinition }
    case SET_ASSESSORS:
      return { ...state, assessors: action.payload.assessors }
    case SET_USERGROUPS:
      return { ...state, usergroups: action.payload.usergroups }
    default:
      return state
  }
}

export default ekspertEntitiesReducer
