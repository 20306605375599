import React from 'react'

import { LabelledValue, optionsConverter, Section } from '../../../mui/Section'

import { preliminaryQuestions } from '../questions'
import { preliminaryValues } from '../1_IndledendeVurdering'
import { FixMeLater } from '../../components/GlobalErrorMessage'

interface IndledendeVurderingViewProps {
    preliminary: FixMeLater;
    showNull?: boolean;
    showtitle?: boolean
}

const IndledendeVurderingView = (props : IndledendeVurderingViewProps) => {
    const  preliminary = props.preliminary || {}
    const { showNull, showtitle } = props
    return (
        <Section title={showtitle ? 'Indledende vurdering' : undefined}>
            <LabelledValue showNull={showNull} label={preliminaryQuestions.preliminaryAssessment.label} value={preliminary.preliminaryAssessment} converter={optionsConverter(preliminaryValues, true)} />
            <LabelledValue showNull={showNull} fullWidth label={preliminaryQuestions.preliminaryAssessmentRemark.title} value={preliminary.preliminaryAssessmentRemark} />
        </Section>
    )
}

export default IndledendeVurderingView