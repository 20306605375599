import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const VIEW_QA_ASSESSMENTS_PAGE_INIT = '[viewQaAssesmentsPage] init'
export const VIEW_QA_ASSESSMENTS_PAGE_FETCH = '[viewQaAssesmentsPage] fetch'
export const VIEW_QA_ASSESSMENTS_PAGE_SET_ASSESSMENTS = '[viewQaAssesmentsPage] set assessments'
export const VIEW_QA_ASSESSMENTS_PAGE_SET_FILTER = '[viewQaAssesmentsPage] set filter'
export const VIEW_QA_ASSESSMENTS_SET_PAGE = '[viewQaAssesmentsPage] set page'
export const VIEW_QA_ASSESSMENTS_NEXT_PAGE = '[viewQaAssesmentsPage] set next page'
export const VIEW_QA_ASSESSMENTS_PREV_PAGE = '[viewQaAssesmentsPage] set prev page'



export const init = (user : FixMeLater) => (
    {
         type: VIEW_QA_ASSESSMENTS_PAGE_INIT,
         payload: { user }
    }
)

export const fetchAssessments = (filter : FixMeLater) => ({ type: VIEW_QA_ASSESSMENTS_PAGE_FETCH, payload: { filter }})

export const setFilter = (filter : FixMeLater) => ({
    type: VIEW_QA_ASSESSMENTS_PAGE_SET_FILTER,
    payload: filter
})

export const nextPage = () => ({
    type: VIEW_QA_ASSESSMENTS_NEXT_PAGE
})

export const prevPage = () => ({
    type: VIEW_QA_ASSESSMENTS_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: VIEW_QA_ASSESSMENTS_SET_PAGE,
    payload: { page }
})
