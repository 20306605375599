import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import { showError } from "../actions/uiActions"

export const AUTH_ERROR = '[app] AUTH Error'
export const API_ERROR = '[app] API Error'

export const apiError = (error? : FixMeLater) => showError(error, 'API')

export const authError = () => ({
  type: AUTH_ERROR
})