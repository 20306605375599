import { categoryChangeReasonsEnum } from "../assessments/sections/enums/enums"

export const isCategoryChangeReasonRequired = (previousAssessment, category) => {
    if (!previousAssessment) {
        return false
    }
    if (!category) {
        return false
    }
    const { category: prevCategory } = previousAssessment
    const currentCategory = category.name

    const dontAsk = ['NA', 'NE', 'DD']
    const askIfChanged = ['LC', 'NT', 'VU', 'EN', 'CR', 'RE']
    const askIfSame = ['NT', 'VU', 'EN', 'CR', 'RE']

    if (dontAsk.find(e => e === prevCategory || e === currentCategory)) {
        return { ask: false }
    }

    if (prevCategory === currentCategory) {
        if (askIfSame.find(e => e === currentCategory)) {
            return { ask: true, change: false }
        } else {
            return { ask: false, change: false }
        }
    } else {
        if (askIfChanged.filter(e => e === prevCategory || e === currentCategory).length > 1) {
            return { ask: true, change: true, newCategory: currentCategory, oldCategory: prevCategory }
        } else {
            return { ask: false }
        }
    }
}

export const validateCategoryChangeReason = (reasons, comment, categoryChangeAction, from, to) => {
    if (!categoryChangeAction.ask) {
        if (reasons && reasons.length > 0) {
            return { global: true, ok: false, empty: true, message: 'Der skal ikke angives årsag til kategoriskift når der skiftes fra ' + from + ' til ' + to }
        } else {
            return { ok: true, empty: true }
        }  
    }
    if (!reasons || reasons.length === 0) {
        return { global: true, ok: false, message: 'Der skal angives en årsag' }
    }
    const reelle = reasons.filter(r => {
        return r.value === categoryChangeReasonsEnum.values().reel_samme.value
            || r.value === categoryChangeReasonsEnum.values().reel_ny.value
            || r.value === categoryChangeReasonsEnum.values().reel_sfv.value
    })

    if (reelle.length > 1) {
        return { global: true, ok: false, message: 'Der må højst angives én "reel" årsag' }
    }

    if (reelle.length > 0) {
        if (categoryChangeAction.change === true) {
            if (reelle[0].value === categoryChangeReasonsEnum.values().reel_samme.value) {
                return { global: true, ok: false, message: 'Der kan ikke angives "' + categoryChangeReasonsEnum.fromValue(reelle[0].value).label + '" når vurdering har skiftet kategori' }
            }
        } else {
            if (reelle[0].value !== categoryChangeReasonsEnum.values().reel_samme.value) {
                return { global: true, ok: false, message: 'Der kan ikke angives "' + categoryChangeReasonsEnum.fromValue(reelle[0].value).label + '" når vurdering IKKE har skiftet kategori' }
            }
        }
    
        const sfv = reasons.find(r => r.value === categoryChangeReasonsEnum.values().reel_sfv.value)
        if (sfv) {
            const data = sfv.data
            if (!data || !data.periodStart || !data.periodEnd) {
                return { global: true, ok: false, message: 'Der skal angives for hvilken periode ændringen er foregået' }
            }
            const periodStart = parseInt(data.periodStart)
            const periodEnd = parseInt(data.periodEnd)
            if (!(Number.isInteger(periodStart) && Number.isInteger(periodEnd))) {
                return { global: true, ok: false, message: 'Der skal angives for hvilken periode ændringen er foregået' }
            }
            if (periodStart < 2000) {
                return { global: true, ok: false, message: 'Perioden hvor ændringen er foregået kan ikke starte før år 2000' }
            }
            if (periodStart >= periodEnd) {
                return { global: true, ok: false, message: 'Perioden skal slutte efter den starter' }
            }
        }
    }
    if (!comment) {
        return { global: true, ok: false, message: 'Kommentar skal udfyldes' }
    }
    return { ok: true }
}