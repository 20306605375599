export const USER_PROFILE_SET_USER = '[userProfile] set user'
export const USER_PROFILE_INIT = '[userProfile] init'
export const USER_PROFILE_UPDATE = '[userProfile] update'

export const userProfileInit = () => ({
    type: USER_PROFILE_INIT
})

export const updateUserProfile = (email : string, password: string, name: string) => ({
    type: USER_PROFILE_UPDATE,
    payload: {
        email,
        password,
        name
    }
})
