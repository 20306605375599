import { Chip } from '@material-ui/core'
import React from 'react'

import ListPage from '../../genericCrud/ListPage'

const tableConfig = {
  id: 'id',
  properties: [
    {
      header: 'Navn',
      property: 'name',
    },
    {
        header: 'Brugere',
        property: 'users',
        render: (p) => {
            return (
              <React.Fragment>
                {p && p.length > 0 && p.slice(0,4).map((u) => {
                  return <Chip  key={u.username} label={u.name}></Chip>
                })}
                { p.length > 4 && <Chip label='...' />}
              </React.Fragment>
            )
          },        
    }
  ],
  selectable: false,
}

const crudConfig = {
  key: 'usergroups',
  createTitle: 'Opret brugergruppe',
  filter: [{ label: 'Navn', property: 'name' }],
  properties: [
    { label: 'Navn', property: 'name', type: 'string', required: true },
    {
      label: 'Brugere',
      property: 'users',
      type: 'multilist',
      required: false,
      reference: {
        parent: 'users',
        idProperty: 'username',
        valueProperty: 'name',
        key: 'users',
      },
    },
  ],
}

const ManageUserGroup = (props) => {
  return <ListPage pageTitle="Administrer brugergrupper" crudConfig={crudConfig} tableConfig={tableConfig} />
}

export default ManageUserGroup
