import React from 'react'
import { Section, LabelledValue, listConverter, booleanConverterWithDefault } from '../../../mui/Section'
import { FixMeLater } from '../../components/GlobalErrorMessage'

const overallThreatConverter = (overallThreatDefinitions : FixMeLater) => (v : FixMeLater) => {
    if (!overallThreatDefinitions) {
        return null
    }
    const overallThreat = overallThreatDefinitions.find((o: FixMeLater) => o.id === v.overallThreatDefinitionId)
    if (!overallThreat) {
        return 'Fejl: Ukendt hovedtrussel. ' + v.overallThreatDefinitionId
    }
    return overallThreat.name
}

const threatConverter = (threatDefinitions : FixMeLater) => (v : FixMeLater) => {
    if (!threatDefinitions) {
        return null
    }
    const threat = threatDefinitions.find((o : FixMeLater) => o.id === v.threatDefinitionId)
    if (!threat) {
        return 'Fejl: Ukendt trussel. ' + v.threatDefinitionId
    }
    return threat.name
}

const G_TruslerView = (props : FixMeLater) => {
    const {showtitle, showNull } = props    
    if (!props.threatDefinition) {
        return (<Section title={showtitle && 'Trusler'} />)
    }
    if (!props.threat  && !showNull) {
        return (<Section title='Trusler' />)
    }
    const {
        overallThreats,
        threats,
        meta
    } = props.threat || {}

    const threatsUnknown = meta && meta.threatsUnknown
    const {
        overallThreats: overallThreatDefinitions,
        threats: threatDefinitions
    } = props.threatDefinition
    return (
        <Section title={showtitle && 'Trusler'}>
            <LabelledValue showNull={showNull} label={'Hovedtrusler'} value={overallThreats} converter={listConverter(overallThreatConverter(overallThreatDefinitions))} />
            <LabelledValue showNull={showNull} label={'Trusler'} value={threats} converter={listConverter(threatConverter(threatDefinitions))} />
            <LabelledValue showNull={showNull} label={'Trusler dårligt kendte'} value={threatsUnknown} converter={booleanConverterWithDefault('Nej')} />
        </Section>
        
    )
}

export default G_TruslerView