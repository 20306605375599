import {
  TaxonPositionInfo,
  TaxonPositionHashInfoResponse,
  TaxonPositionResponse,
  TaxonRecordDetailsResponse,
  CreateRecordBody,
  TaxonRecordDetails,
  ImportCreateResponse,
  ImportStatusResponse,
  ImportStatusListResponse,
  SnapshotMetadataResponse,
  SnapshotRowsResponse,
  SnapshotRow,
} from './arterDkModels'

import { del, get, getWithRetry, post, put } from './fetchutil'

export interface ApiProgress {
  count: number
  total?: number
}

export interface RecordInfo {
  recordId: string | number
  count: number
  year: number
  validationStatus: string
}

class ArterDkService {
  baseUrl: string | null
  authKey: string | null
  redlistToken: string | undefined;

  constructor() {
    this.authKey = null
    this.baseUrl = null
  }

  setRedlistToken(jwt : string | undefined) {
    this.redlistToken = jwt
  }

  setAuthKey(authKey: string): void {
    this.authKey = authKey
  }

  setBaseUrl(url: string): void {
    console.log('Setting arterDk baseUrl: ', url)
    this.baseUrl = url
  }

  async get2x2Cells(taxonId: string, year: number): Promise<TaxonPositionResponse> {
    const path = '/records/' + encodeURIComponent(taxonId) + '/cell'
    const json = await get(this.baseUrl as string, path, { year }, () => this.redlistToken)
    //const json = await response.json()
    return json as TaxonPositionResponse
  }

  async getRecordsGroupedByPosition(taxonId: string, year: number): Promise<TaxonPositionResponse> {
    let page = 1
    const json: TaxonPositionHashInfoResponse = await get(
      this.baseUrl as string,
      '/records/' + encodeURIComponent(taxonId),
      { year, page }, () => this.redlistToken
    )
    let items: TaxonPositionInfo[] = json.items
    while (page < json.pages) {
      page += 1
      console.log('page', page)
      const json2 = await get(this.baseUrl as string, '/records/' + encodeURIComponent(taxonId), { year, page }, () => this.redlistToken)
      items = items.concat(json2.items)
    }
    return { items } as TaxonPositionResponse
  }

  async getRecordsByCell(taxonId: string, cellId: number, year: number): Promise<TaxonRecordDetailsResponse> {
    const path =
      '/records/' +
      encodeURIComponent(taxonId) +
      '/cell/' +
      encodeURIComponent(cellId)
    const queryParams = {
      year
    }
    const json = await get(this.baseUrl as string, path, queryParams, () => this.redlistToken)
    return json
  }
  async getRecordsByPosition(taxonId: string, positionHash: string, year: number): Promise<TaxonRecordDetailsResponse> {
    const path =
      '/records/' +
      encodeURIComponent(taxonId) +
      '/hash/' +
      encodeURIComponent(positionHash) +
      '/year/' +
      encodeURIComponent(year)
    const json = await get(this.baseUrl as string, path, {}, () => this.redlistToken)
    return json
  }

  async deleteRecordsByPosition(taxonId: string, positionHash: string): Promise<void> {
    const path =
      '/records/' +
      encodeURIComponent(taxonId) +
      '/hash/' +
      encodeURIComponent(positionHash)
    await del(this.baseUrl as string, path, () => this.redlistToken)
  }

  async deleteRecordsByCell(taxonId: string, cellId: number): Promise<void> {
    const path =
      '/records/' +
      encodeURIComponent(taxonId) +
      '/cell/' +
      encodeURIComponent(cellId)
    await del(this.baseUrl as string, path, () => this.redlistToken)
  }

  async createRecord(taxonId: string, record: CreateRecordBody): Promise<TaxonRecordDetails> {
    const path = '/records/' + encodeURIComponent(taxonId)
    return (await put(this.baseUrl as string, path, record, () => this.redlistToken)) as TaxonRecordDetails
  }

  async importTaxon(taxonId: string): Promise<ImportCreateResponse> {
    const json = await get(this.baseUrl as string, '/import/' + encodeURIComponent(taxonId), {}, () => this.redlistToken)
    return json as ImportCreateResponse
  }

  async getImportStatus(importId: string): Promise<ImportStatusResponse> {
    return (await get(
      this.baseUrl as string,
      '/import/' + encodeURIComponent(importId) + '/status',
      {}, () => this.redlistToken
    )) as ImportStatusResponse
  }

  async getImportStatusByTaxonId(taxonId: string): Promise<ImportStatusListResponse> {
    return (await get(
      this.baseUrl as string,
      '/import/taxon/' + encodeURIComponent(taxonId),
      {}, () => this.redlistToken
    )) as ImportStatusListResponse
  }

  async takeSnapshot(taxonId: string, year: number) : Promise<SnapshotMetadataResponse> {
    return (await post(
      this.baseUrl as string,
      '/snapshot',
      { taxonId, year }, () => this.redlistToken
    )) as SnapshotMetadataResponse
  }

  async getSnapshotRows(snapshotId: string) : Promise<SnapshotRowsResponse> {
    let page = 1
    const dataUrl = '/snapshot/' + encodeURIComponent(snapshotId) + '/data/'
    const json: SnapshotRowsResponse = await getWithRetry(
      this.baseUrl as string,
      dataUrl,
      { page }, () => this.redlistToken,
      1, 5
    )
    let items: SnapshotRow[] = json.items
    while (page < json.pages) {
      page += 1
      console.log('page', page)
      const json2 : SnapshotRowsResponse = await getWithRetry(this.baseUrl as string, dataUrl, { page }, () => this.redlistToken, 1, 5)
      items = items.concat(json2.items)
    }
    return { items, pages: json.pages } as SnapshotRowsResponse
  }

  async getSnapshotMetadata(snapshotId: string): Promise<SnapshotMetadataResponse> {
    return (await getWithRetry(
      this.baseUrl as string,
      '/snapshot/' + encodeURIComponent(snapshotId),
      {}, () => this.redlistToken,1 , 5
    )) as SnapshotMetadataResponse
  }

}

const instance = new ArterDkService()
export default instance
