import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
  SET_HOVEDMILJO,
  SET_LEVESTEDSTYPE,
  SET_ECOSYSTEM,
  SET_HABITAT,
  SET_OVERORDNET_SUBSTRAT,
  SET_SUBSTRAT,
  SET_SUBSTRAT_FUNKTION,
  SET_LEVESTED,
  SAVE_DIET_SPECIES,
  SAVE_DIET_GENUS,
  SET_GRADIENT,
  SetLevestedCommentAction,
  SET_COMMENT
} from '../actions/editLevestedActions'

export interface EditLevestedState {
  dietSpecies: FixMeLater[]
  dietGenus: FixMeLater[]
  hovedmiljoer: FixMeLater[]
  levestedstyper: FixMeLater[]
  ecosystems: FixMeLater[]
  habitater: FixMeLater[]
  overordnedeSubstrater: FixMeLater[]
  substrater: FixMeLater[]
  comment: string
  gradienter: FixMeLater
}

const initialState: EditLevestedState = {
  dietSpecies: [],
  dietGenus: [],
  hovedmiljoer: [],
  levestedstyper: [],
  ecosystems: [],
  habitater: [],
  overordnedeSubstrater: [],
  substrater: [],
  gradienter: undefined,
  comment: ''
}

const editLevested = (state = initialState, action: { type: string, payload: Record<string, any> }): EditLevestedState => {
  //console.log('action', action);
  switch (action.type) {
    case SET_LEVESTED:
      return { ...action.payload.levested }
    case SET_COMMENT:
      const setDocAction = action as SetLevestedCommentAction
      return { ...state, comment: setDocAction.payload.comment }
    case SET_HOVEDMILJO:
      return { ...state, hovedmiljoer: action.payload.value }
    case SET_LEVESTEDSTYPE:
      return { ...state, levestedstyper: action.payload.value }
    case SET_ECOSYSTEM:
      return { ...state, ecosystems: action.payload.value }
    case SET_HABITAT:
      return { ...state, habitater: action.payload.value }
    case SET_GRADIENT:
      return { ...state, gradienter: action.payload.gradienter }
    case SET_OVERORDNET_SUBSTRAT:
      return { ...state, overordnedeSubstrater: action.payload.value }
    case SET_SUBSTRAT:
      return { ...state, substrater: action.payload.value }
    case SET_SUBSTRAT_FUNKTION:
      const substrat: FixMeLater = state.substrater.find((s: FixMeLater) => s.id === action.payload.substrat.id)
      const rem = state.substrater.filter((s: FixMeLater) => s.id !== action.payload.substrat.id)
      const result = rem.concat({ ...substrat, funktion: action.payload.funktion })
      result.sort((a: FixMeLater, b: FixMeLater) => a.id - b.id)
      return { ...state, substrater: result }
    case SAVE_DIET_SPECIES:
      return { ...state, dietSpecies: action.payload.dietSpecies }
    case SAVE_DIET_GENUS:
      return { ...state, dietGenus: action.payload.dietGenus }
    default:
      return state
  }
}

export default editLevested