import {
    showSaving,
    hideSaving,
    showError
} from '../actions/uiActions'
import {
    SET_SUPPLERENDE_OPLYSNING_PROPERTY,
    SAVE_SUPPLERENDE_OPLYSNINGER,
    setSupplerendeOplysninger,
    saveSupplerendeOplysninger
} from '../actions/editSupplerendeOplysningerActions';
import {
    ASSESSMENT_FETCHED
} from '../editAssessment/editAssessmentActions'
import {
    takeEvery,
    put,
    throttle,
    select
} from 'redux-saga/effects';
import RedlistApi from '../../service/redlistApi';

import {
    apiRequest
} from '../api/apiRequest';

const defaultSupplerendeOplysninger = {
    firstRecord: '',
    latestRecord: '',
    antalLokaliteter: '',
    trend: 'ikke_angivet',
    historiskTrend: '',
    geographicExtentAcrossDenmark: null,
    isNative: null,
    nationalForekomst: '',
    totaludbredelse: '',
    nomenklatur: '',
    litteratur: '',
    remarkSupplerendeOplysninger: '',
    additionalComments: ''
}

function* processFetchAssessmentOk(action) {
    try {
        let { supplerendeOplysninger } = action.payload.item
        if (!supplerendeOplysninger || supplerendeOplysninger === null) {
            supplerendeOplysninger = { ...defaultSupplerendeOplysninger }
        }
        yield put(setSupplerendeOplysninger(supplerendeOplysninger))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSaveSupplerendeOplysninger(action) {
    const {
        assessmentId,
        value,
        previousValue
    } = action.payload
    try {
        yield put(showSaving())
        yield apiRequest(RedlistApi, RedlistApi.updateSupplerendeOplysninger, [assessmentId, value])
        yield put(hideSaving())
    } catch (error) {
        yield put(setSupplerendeOplysninger(previousValue))
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processSetValue(action) {
    try {
        const {
            property,
            value
        } = action.payload
        const { assessmentId } = yield select(state => state.editAssessment)
        const prev = yield select(state => state.editSupplerendeOplysninger)
        const supplerendeOplysninger = { ...prev }
        supplerendeOplysninger[property] = value
        yield put(setSupplerendeOplysninger(supplerendeOplysninger))
        yield put(saveSupplerendeOplysninger(assessmentId, supplerendeOplysninger, { ...prev }))
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeEvery(ASSESSMENT_FETCHED, processFetchAssessmentOk)
    yield takeEvery(SET_SUPPLERENDE_OPLYSNING_PROPERTY, processSetValue)
    yield throttle(1500, SAVE_SUPPLERENDE_OPLYSNINGER, processSaveSupplerendeOplysninger)
}