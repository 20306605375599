import { Feature } from './OlFeatureCollection'
import { FeatureCollection } from './OlFeatureCollection'
import * as turf from '@turf/helpers'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import { TaxonPositionInfo } from '../service/arterDkModels'

export const polygonStyle = () =>
  new Style({
    fill: new Fill({
      color: 'rgba(128,128,128,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(80,80,80)',
    }),
  })

  
export const cellStyle = () =>
new Style({
  fill: new Fill({
    color: 'rgba(128,128,196,0.6)',
  }),
  stroke: new Stroke({
    color: 'rgb(80,80,80)',
  }),
})

export const defaultWebGlStyle = {
  symbol: {
    symbolType: 'circle',
    size: ['interpolate', ['linear'], ['zoom'], 5, 6, 10, 10, 17, 18],
    color: 'rgb(255,96,96)',
    opacity: 1,
  },
}
export const emptyFeatureCollection = (): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [],
})

export const createFeatureCollection = (features: Feature[]): FeatureCollection => ({
  type: 'FeatureCollection',
  features,
})

export const createFeatureCollectionFromPoints = (points: number[][]): FeatureCollection => ({
  type: 'FeatureCollection',
  features: featuresFromPoints(points),
})

export const createFeatureCollectionFromOverviewCells = (cells: TaxonPositionInfo[]): FeatureCollection => {
  const features = cells.map(( cell : TaxonPositionInfo) : Feature => {
    return {
      type: 'Feature',
      geometry: cell.position,
      properties: {
        positionHash: cell.positionHash,
        deleted: cell.deleted,
        taxonId: cell.taxonId,
        typeId: 1,
        cellId: cell.cellId,
        
      }
    }
  })
  return {
  type: 'FeatureCollection',
  features
}
}


export const createFeatureCollectionFromPolygon = (polygon: turf.Polygon): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: polygon,
      properties: {},
    },
  ],
})

export const featureFromPoint = (point: number[]): Feature => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: point,
  },
  properties: {},
})

export const featuresFromPoint = (point: number[] | null): Feature[] => {
  if (!point) {
    return []
  }
  return [featureFromPoint(point)]
}

export const featuresFromPoints = (points: number[][] | null): Feature[] => {
  if (!points) {
    return []
  }
  return points.map((point: number[]) => featureFromPoint(point))
}
