import { FixMeLater } from "../../assessments/components/GlobalErrorMessage";

export const RESET_CURRENT_SECTION = '[editAsseessmentMenu] reset current section';
export const SET_CURRENT_SECTION = '[editAsseessmentMenu] set current section';
export const SET_CURRENT_SUBMENU = '[editAsseessmentMenu] set current submenu';
export const SET_NEXT_SUBMENU = '[editAssessmentMenu] set next submenu'
export const NEXT_SECTION = '[editAssessmentMenu] next section'

export const resetCurrentSection = () => ({
  type: RESET_CURRENT_SECTION
})

export const setCurrentSection = (id : FixMeLater) => ({
  type: SET_CURRENT_SECTION,
  payload: { id }
})

export const nextSection = () => ({
  type: NEXT_SECTION  
})

export const nextSubmenu = () => ({
  type: SET_NEXT_SUBMENU
})
export const setCurrentSubmenu = (id : FixMeLater) => ({
  type: SET_CURRENT_SUBMENU,
  payload: { id }
})

