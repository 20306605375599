import React from 'react';
import { useState } from 'react';
import {
    Button
} from '@material-ui/core';
import DialogSelect from '../../mui/DialogSelect'

const assessorToOption = assessor => {
    if (!assessor) {
        return ''
    }
    return { value: assessor.id, label: assessor.name }
}

const usergroupToOption = usergroup => {
    if (!usergroup) {
        return ''
    }
    return { value: usergroup.id, label: usergroup.name }
}

const vurderingsrundeToOption = vurderingsrunde => {
    if (!vurderingsrunde) {
        return ''
    }
    return { value: vurderingsrunde.id, label: vurderingsrunde.name }
}

const ManageAssessmentsDialog = (props) => {
    const { assessors, usergroups, runder, statuses } = props;
    const [ assessor, setAssessor ] = useState('');
    const [ technicalApprover, setTechnicalApprover ] = useState()
    const [ usergroup, setUsergroup ] = useState()
    const [ approver, setApprover ] = useState()
    const [ vurderingsrunde, setVurderingsrunde ] = useState()
    const [ status, setStatus ] = useState('') 
    
    return (
        <div style={{ minWidth: 320, margin: 48 }}>
            <DialogSelect onChange={value => setStatus(value)} items={statuses} title={'Status'} />
            <DialogSelect onChange={value => setAssessor(value)} items={assessors.map(assessorToOption)} title={'Assessor'} />
            <DialogSelect onChange={value => setTechnicalApprover(value)} items={assessors.map(assessorToOption)} title={'Technical Approver'} />
            <DialogSelect onChange={value => setApprover(value)} items={assessors.map(assessorToOption)} title={'Approver'} />
            <DialogSelect onChange={value => setUsergroup(value)} items={usergroups.map(usergroupToOption)} title={'Gruppe'} />
            <DialogSelect onChange={value => setVurderingsrunde(value)} items={runder.map(vurderingsrundeToOption)} title={'Vurderingsrunde'} />
            <div style={{ marginTop: 32 }}>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button fullWidth color='secondary' variant='contained' onClick={() => props.onClose()}>Annuller</Button>
                </div>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button fullWidth color='primary' variant='contained' onClick={() => props.onSave({ status, assessor, approver, technicalApprover, usergroup, vurderingsrunde })}>Gem</Button>
                </div>
            </div>
        </div>
    )
}

export default ManageAssessmentsDialog