import React from 'react'

import {
    Button,
    Typography
} from '@material-ui/core'

const Paging = props => {
    const { pages, page, pageSize, count, onNext, onPrev, onGoto } = props
    if (count === 0) {
        return null
    }
    const gotoPagesBack = []
    const gotoPagesForward = []
    const first = page < 5 ? page - 3 : page - 2
    const min = 1 + (page - 1) * pageSize
    let max = page * pageSize
    if (max > count) {
        max = count
    }
    for (let i = first; i < page; i++) {
        if (i > 0) {
            gotoPagesBack.push(i)
        }
    }
    const end = page > pages - 4 ? pages : page + 2
    for (let i = page + 1; i <= end; i++) {
        if (i <= pages) {
            gotoPagesForward.push(i)
        }
    }
    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-block' }}>
                {min} - {max} of {count}
            </div>
            <div style={{ display: 'inline-block', float: 'right' }} >
                {
                    page > 1 ? <Button color='primary' onClick={() => onPrev()}>Prev</Button> : null
                }
                {
                    page > 4 ? (
                        <React.Fragment>
                            <Button color='primary' onClick={() => onGoto(1)}>1</Button>
                            <Typography component='span' variant='subtitle1'>...</Typography>
                        </React.Fragment>
                    ) : null
                }
                {
                    gotoPagesBack.map(p => (<Button key={p} color='primary' onClick={() => onGoto(p)}>{p}</Button>))
                }
                <Button disabled>{page}</Button>
                {
                    gotoPagesForward.map(p => (<Button key={p} color='primary' onClick={() => onGoto(p)}>{p}</Button>))
                }
                {
                    page < (pages - 3) ? (
                        <React.Fragment>
                            <Typography component='span' variant='subtitle1'>...</Typography>
                            <Button color='primary' onClick={() => onGoto(pages)}>{pages}</Button>
                        </React.Fragment>
                    ) : null
                }
                {
                    page < pages ? <Button color='primary' onClick={() => onNext()}>Next</Button> : null
                }
            </div>
        </div>
    )
}

export default Paging