import { createEnum, createEnumValue } from '../assessments/sections/enums/enums'

export const statusObject = {
    'ny': createEnumValue('ny', 'Ny', 0, true),
    'kladde': createEnumValue('kladde', 'Kladde', 1),
    'teknisk_kvalitetssikring': createEnumValue('teknisk_kvalitetssikring', 'Teknisk kvalitetssikring', 2),
    'kommentering': createEnumValue('kommentering', 'Kommentering', 3),
    'faglig_kvalitetssikring': createEnumValue('faglig_kvalitetssikring', 'Faglig kvalitetssikring', 4),
    'endelig_kvalitetssikring': createEnumValue('endelig_kvalitetssikring', 'Endelig kvalitetssikring', 5),
    'teknisk_genvurdering': createEnumValue('teknisk_genvurdering', 'Teknisk Genvurdering', 6),
    'faglig_genvurdering': createEnumValue('faglig_genvurdering', 'Faglig genvurdering', 7),
    'endelig_genvurdering': createEnumValue('endelig_genvurdering', 'Endelig genvurdering', 8),
    'godkendt': createEnumValue('godkendt', 'Godkendt', 9),
    'slettet': createEnumValue('slettet', 'Slettet', 10)
  }
  
export const statusEnum = createEnum(statusObject)

