import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateSupplerendeOplysninger } from '../../redux/actions/editSupplerendeOplysningerActions'
import {
    Typography,
    Grid
} from '@material-ui/core'

import RadioGroupReference from '../components/RadioGroupReference'
import BooleanRadioGroup from '../components/BooleanRadioGroup'
import TextfieldWithHelp from '../components/TextfieldWithHelp'
import { integerInterval } from '../../utils/validators'
import { oddRowStyle } from '../components/styles'
import Footer from '../components/Footer'
import TextAreaReference from '../components/TextAreaReference'
import { additionalQuestions } from './questions'
import {
    FirstRecordHelpElement, 
    LatestRecordHelpElement, 
    AntalLokaliteterHelpElement, 
    TrendHelpElement,
    HistoriskTrendHelpElement,
    GeographicExtentAcrossDenmarkHelpElement,
    IsNativeHelpElement,
    NationalForekomstHelpElement,
    TotaludbredelseHelpElement,
    NomenklaturHelpElement,
    LitteraturHelpElement,
    RemarkSupplerendeOplysningerHelpElement,
    AdditionalCommentsHelpElement
} from './help/supplerendeOplysningerHelp'
import { trends, isNativeEnum } from './enums/supplerendeOplysningerEnums'
import GlobalErrorMessage from '../components/GlobalErrorMessage'

const H_SupplerendeOplysninger = (props) => {
    const { referenceArt, onClose, onNext } = props
    const dispatch = useDispatch()
    const {
        firstRecord,
        latestRecord,
        antalLokaliteter,
        trend,
        historiskTrend,
        geographicExtentAcrossDenmark,        
        nationalForekomst,
        totaludbredelse,
        nomenklatur,
        litteratur,
        remarkSupplerendeOplysninger,
        additionalComments
    } = useSelector(state => state.editSupplerendeOplysninger)
    const validationResult = useSelector(state => state.editAssessment.criteriaValidationResult?.supplerendeOplysninger)
    const isNative = useSelector(state => state.editSupplerendeOplysninger?.isNative) || isNativeEnum.getNullValue()
    const referenceSupp = referenceArt?.supplerendeOplysninger
    console.log({ validationResult})
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    Supplerende informationer
                </Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>
            <Grid item xs={12}>
                <TextfieldWithHelp
                    type='number'
                    validator={integerInterval(1000, 2100, true)}
                    title={additionalQuestions.firstRecord.title}
                    label={additionalQuestions.firstRecord.label}
                    value={firstRecord || ''}
                    referenceValue={referenceSupp?.firstRecord}
                    onChange={value => dispatch(updateSupplerendeOplysninger('firstRecord', value))}
                    help={() => <FirstRecordHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <TextfieldWithHelp
                    type='number'
                    validator={integerInterval(1000, 2100, true)}
                    title={additionalQuestions.latestRecord.title}
                    label={additionalQuestions.latestRecord.label}
                    value={latestRecord || ''}
                    referenceValue={referenceSupp?.latestRecord}
                    onChange={value => dispatch(updateSupplerendeOplysninger('latestRecord', value))}
                    help={() => <LatestRecordHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextfieldWithHelp
                    type='number'
                    validator={integerInterval(1, false, true)}
                    title={additionalQuestions.antalLokaliteter.title}
                    label={additionalQuestions.antalLokaliteter.label}
                    value={antalLokaliteter || ''}
                    referenceValue={referenceSupp?.antalLokaliteter}
                    onChange={value => dispatch(updateSupplerendeOplysninger('antalLokaliteter', value))}
                    help={() => <AntalLokaliteterHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <RadioGroupReference
                    label={additionalQuestions.trend.label}
                    values={trends}
                    value={trend}
                    referenceValue={referenceSupp?.trend}
                    onChange={value => dispatch(updateSupplerendeOplysninger('trend', value))}
                    help={() => <TrendHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.historiskTrend.title}
                    label={additionalQuestions.historiskTrend.label}
                    value={historiskTrend || ''}
                    referenceValue={referenceSupp?.historiskTrend}
                    onChange={value => dispatch(updateSupplerendeOplysninger('historiskTrend', value))}
                    help={() => <HistoriskTrendHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <BooleanRadioGroup
                    label={additionalQuestions.geographicExtentAcrossDenmark.label}
                    value={geographicExtentAcrossDenmark}
                    ghost={referenceSupp?.geographicExtentAcrossDenmark}
                    onChange={value => dispatch(updateSupplerendeOplysninger('geographicExtentAcrossDenmark', value))}
                    help={() => <GeographicExtentAcrossDenmarkHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <RadioGroupReference
                    label={additionalQuestions.isNative.label}
                    value={isNative}
                    referenceValue={referenceSupp?.isNative}
                    onChange={value => dispatch(updateSupplerendeOplysninger('isNative', value))}
                    values={isNativeEnum.asList()}
                    help={() => <IsNativeHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.nationalForekomst.title}
                    label={additionalQuestions.nationalForekomst.label}
                    value={nationalForekomst || ''}
                    referenceValue={referenceSupp?.nationalForekomst}
                    onChange={value => dispatch(updateSupplerendeOplysninger('nationalForekomst', value))}
                    help={() => <NationalForekomstHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.totaludbredelse.title}
                    label=''
                    value={totaludbredelse || ''}
                    referenceValue={referenceSupp?.totaludbredelse}
                    onChange={value => dispatch(updateSupplerendeOplysninger('totaludbredelse', value))}
                    help={() => <TotaludbredelseHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.nomenklatur.title}
                    label={additionalQuestions.nomenklatur.label}
                    value={nomenklatur || ''}
                    referenceValue={referenceSupp?.nomenklatur}
                    onChange={value => dispatch(updateSupplerendeOplysninger('nomenklatur', value))}
                    help={() => <NomenklaturHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.litteratur.title}
                    label={additionalQuestions.litteratur.label}
                    value={litteratur || ''}
                    referenceValue={referenceSupp?.litteratur}
                    onChange={value => dispatch(updateSupplerendeOplysninger('litteratur', value))}
                    help={() => <LitteraturHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.remarkSupplerendeOplysninger.title}
                    label={additionalQuestions.remarkSupplerendeOplysninger.label}
                    value={remarkSupplerendeOplysninger || ''}
                    referenceValue={referenceSupp?.remarkSupplerendeOplysninger}
                    onChange={value => dispatch(updateSupplerendeOplysninger('remarkSupplerendeOplysninger', value))}
                    help={() => <RemarkSupplerendeOplysningerHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextAreaReference
                    multiline
                    rows={5}
                    title={additionalQuestions.additionalComments.title}
                    label={additionalQuestions.additionalComments.label}
                    value={additionalComments || ''}
                    referenceValue={referenceSupp?.additionalComments}
                    onChange={value => dispatch(updateSupplerendeOplysninger('additionalComments', value))}
                    help={() => <AdditionalCommentsHelpElement />}
                />
            </Grid>
            <Footer onNext={() => onNext()} onClose={() => onClose()} />
        </Grid>
    )
}

export default H_SupplerendeOplysninger