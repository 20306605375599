import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const MANAGE_ASSESSMENTS_PAGE_INIT = '[manageAssessmentsPage] init'

export const MANAGE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS = '[manageAssessmentPage] fetch assessments'
export const MANAGE_ASSESSMENTS_PAGE_SET_ASSESSMENTS = '[manageAssessmentPage] set assessments'

export const MANAGE_ASSESSMENTS_SET_PAGE = '[manageAssesmentsPage] set page'
export const MANAGE_ASSESSMENTS_NEXT_PAGE = '[manageAssesmentsPage] set next page'
export const MANAGE_ASSESSMENTS_PREV_PAGE = '[manageAssesmentsPage] set prev page'

export const MANAGE_ASSESSMENTS_PAGE_SET_FILTER = '[manageAssessmentPage] set filter'

export const REQUEST_SAVE_ASSESSMENT_METADATA = '[manageAssessmentPage] save metadata'
export const REQUEST_SAVE_ASSESSMENT_METADATA_OK = '[manageAssessmentPage] save metadata ok'

export const REQUEST_DELETE_ASSESSMENT = '[manageAssessmentPage] delete assessment'
export const REQUEST_DELETE_ASSESSMENT_OK = '[manageAssessmentPage] delete assessment ok'

export const initManageAssessmentsPage = () => ({
    type: MANAGE_ASSESSMENTS_PAGE_INIT
})

export const saveAssessmentMetadata = (assessmentIds : number[], metadata : FixMeLater) => ({
    type: REQUEST_SAVE_ASSESSMENT_METADATA,
    payload: {
        assessmentIds,
        metadata
    }
})

export const deleteAssessments = (assessmentIds : number[], comment : FixMeLater) => ({
    type: REQUEST_DELETE_ASSESSMENT,
    payload: {
        assessmentIds,
        comment
    }
})

export const fetchAssessments = (filter : FixMeLater) => ({
    type: MANAGE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS,
    payload: { filter }
})

export const setFilter = (filter : FixMeLater) => ({
    type: MANAGE_ASSESSMENTS_PAGE_SET_FILTER,
    payload: filter
})

export const nextPage = () => ({
    type: MANAGE_ASSESSMENTS_NEXT_PAGE
})

export const prevPage = () => ({
    type: MANAGE_ASSESSMENTS_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: MANAGE_ASSESSMENTS_SET_PAGE,
    payload: { page }
})

