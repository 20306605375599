import {CONFIRM_ACTION_ACCEPTED, SHOW_NOT_FOUND, HIDE_MESSAGE} from "../actions/uiActions";


// this middleware care only for API calls
export const processConfirmActionAccepted = ({  dispatch, getState }) => next => async action => {
  next(action)

  if(action.type === CONFIRM_ACTION_ACCEPTED) {    
    action.payload.actions.forEach(
      action => dispatch(action)
    )    
  }  
};

export const processShowNotFound = ({  dispatch, getState }) => next => async action => {
  next(action)

  if(action.type === SHOW_NOT_FOUND) {
    window.location.hash = '#/notfound'
  }  

};

export const processHideMessage = ({dispatch, getState }) => next => action => {
  next(action)
  if(action.type === HIDE_MESSAGE) {    
    if (action.payload.action) {
      dispatch(action.payload.action)
    }
  }
}

export const uiMdl = [ processConfirmActionAccepted, processShowNotFound, processHideMessage ]