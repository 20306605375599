import { ReactElement, useState } from 'react'
import {
    Tooltip,
    IconButton
} from '@material-ui/core'
//import PanToolIcon from '@material-ui/icons/PanTool'; 
import QueryIcon from './icons/QueryIcon'
import ToggleFeatureIcon from './icons/ToggleFeatureIcon'
import TrashIcon from './icons/TrashIcon'
//import '../controls/DrawControl.css'

type Position = {
    top?: number
    right?: number
}

export interface ControlButtonProps {    
    id: RedlistMapMode
    selectedButton: string
    disabled?: boolean
    tooltip?: string
    onClick: (id: RedlistMapMode) => void
}

const ControlButton = (props : ControlButtonProps) => {
    const { id, selectedButton, disabled, tooltip, onClick } = props
    const selected = id === selectedButton
    const color : string = disabled ? 'disabled' : selected ? 'primary' : 'dark-gray'
    
    const iconSize = 24    
    const mapIcon = (color : string , id : string) => {
        if (id === 'info') {
            return (<QueryIcon color={color} size={iconSize} />)        
        } else if (id === 'add') {
            return (<ToggleFeatureIcon color={color} size={iconSize} />)
        } else if (id === 'remove') {
            return (<TrashIcon color={color} size={iconSize} />)
        }
        return null
    }

    //console.log(id, selected, selectedButton, color)
    return (
        <div>
            {disabled ?
                <IconButton onClick={() => onClick(id)} disabled={disabled}>
                    {mapIcon(color, id)}
                </IconButton> :
                <Tooltip title={tooltip || ''}>
                    <IconButton onClick={() => onClick(id)} disabled={disabled}>
                        {mapIcon(color, id)}
                    </IconButton>
                </Tooltip>}
        </div>
    )
}

export type RedlistMapMode = 'info' | 'add' | 'remove'


export interface RedlistModeControlProps {
    position: Position
    onModeChanged: (mode: RedlistMapMode) => void
}

const OlRedlistModeControl = (props: RedlistModeControlProps) : ReactElement => {
    const { position, onModeChanged } = props
    const [ selectedButton, setSelectedButton ] = useState<RedlistMapMode>("info")

    const selectedButtonChanged = (id : RedlistMapMode) => {
        onModeChanged(id)
        setSelectedButton(id)
    }

    return (
        <div style={{
            padding: 2,
            borderRadius: 3,
            borderColor: '#ccc',
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor: 'rgba(240, 240, 240, 0.8)',
            position: 'absolute',
            cursor: 'pointer',
            top: position.top as number,
            right: position.right as number    
        }}>            
            <ControlButton
                id='info'
                tooltip='Info om punkt'
                selectedButton={selectedButton}
                onClick={id => selectedButtonChanged(id)}
            />           
            <ControlButton
                id='add'
                tooltip='Tilføj punkter'
                selectedButton={selectedButton}                
                onClick={id => selectedButtonChanged(id) }
            />
            <ControlButton
                id='remove'
                tooltip='Fjern punkter'
                selectedButton={selectedButton}
                onClick={id => selectedButtonChanged(id) }
            />
        </div>
    )
}

export default OlRedlistModeControl