import { adjustmentPreliminaryObject } from '../enums/adjustmentEnums'
import { nullOrNullValue, allEmpty, objectIsEmpty, isNullOrEmpty, validationResultFromProperties } from './sectionValidators'

const ok = { ok: true, empty: false }


export const adjustmentValidator = (criteria) => {
  const adjustment = criteria.adjustment
  if (isNullOrEmpty(adjustment) || objectIsEmpty(adjustment)) {
    return {
      empty: true,
      ok: true,
    }
  }
  const {
    affectedByNeighbours,
    preliminaryAdjustment,
    immigrantPropagulesReproducing,
    decreaseInPropagulesExpected,
    regionalSink,
    deterioratingConditionsOutsideRegion,
    deterioratingConditionsInsideRegion,
    sourceSinkDynamicsAcrossBorders,
  } = adjustment

  if (nullOrNullValue(affectedByNeighbours, false)) {
    if (
      nullOrNullValue(preliminaryAdjustment, adjustmentPreliminaryObject.ikkeAngivet.value) &&
      allEmpty(
        immigrantPropagulesReproducing,
        decreaseInPropagulesExpected,
        regionalSink,
        deterioratingConditionsOutsideRegion,
        deterioratingConditionsInsideRegion,
        sourceSinkDynamicsAcrossBorders
      )
    ) {
      return { empty: isNullOrEmpty(adjustment.affectedByNeighbours), ok: true }
    } else {
      return {
        ok: false,
        global: true,
        message:
          'Der kan ikke angives andre værdier når "Kan den danske bestand være påvirket af bestande i vore nabolande" er Nej eller Ikke angivet',
      }
    }
  }

  // If affectedByNeighbours === true
  const properties = {}
  // prelimanaryAdjust must be set
  if (nullOrNullValue(adjustment.preliminaryAdjustment, adjustmentPreliminaryObject.ikkeAngivet.value)) {
    properties['preliminaryAdjustment'] = {
      message: 'Der skal angives en værdi',
    }
    return validationResultFromProperties(properties, false)
  }

  if (preliminaryAdjustment === adjustmentPreliminaryObject.kolonisering.value
    || preliminaryAdjustment === adjustmentPreliminaryObject.demografiskUstabil.value
    ) {
    // No other values must be set
    if (
      allEmpty(
        immigrantPropagulesReproducing,
        decreaseInPropagulesExpected,
        regionalSink,
        deterioratingConditionsOutsideRegion,
        deterioratingConditionsInsideRegion,
        sourceSinkDynamicsAcrossBorders
      )
    ) {
      return ok
    } else {
      return {
        ok: false,
        global: true,
        message: 'Der kan ikke angives andre værdier når der er tale om en koloniseringsfase eller den nationale bestand er demografisk ustabil',
      }
    }
  }

  // Visiting population
  if (preliminaryAdjustment === adjustmentPreliminaryObject.visiting.value) {
    // 
    if (isNullOrEmpty(deterioratingConditionsOutsideRegion)) {
      properties['deterioratingConditionsOutsideRegion'] = { message: 'Der skal angives en værdi' }
      return validationResultFromProperties(properties, false)
    }
    if (deterioratingConditionsOutsideRegion === true) {
      // Other values must not be set
      return ok
    }
    if (isNullOrEmpty(deterioratingConditionsInsideRegion)) {
      properties['deterioratingConditionsInsideRegion'] = { message: 'Der skal angives en værdi' }
      return validationResultFromProperties(properties, false)
    }
    if (deterioratingConditionsInsideRegion === true) {
      return ok;
    }
    if (isNullOrEmpty(sourceSinkDynamicsAcrossBorders)) {
      properties['sourceSinkDynamicsAcrossBorders'] = { message: 'Der skal angives en værdi' }
      return validationResultFromProperties(properties)
    }
  }

  if (preliminaryAdjustment === adjustmentPreliminaryObject.breeding.value) {
    // 
    if (isNullOrEmpty(immigrantPropagulesReproducing)) {
      properties['immigrantPropagulesReproducing'] = { message: 'Der skal angives en værdi' }
      return validationResultFromProperties(properties, false)
    }
    if (immigrantPropagulesReproducing === false) {
      // Other values must not be set
      return ok
    }
    if (isNullOrEmpty(decreaseInPropagulesExpected)) {
      properties['decreaseInPropagulesExpected'] = { message: 'Der skal angives en værdi' }
      return validationResultFromProperties(properties, false)
    }
    if (decreaseInPropagulesExpected === false) {
      return ok;
    }
    if (isNullOrEmpty(regionalSink)) {
      properties['regionalSink'] = { message: 'Der skal angives en værdi' }
      return validationResultFromProperties(properties)
    }
  }
  return { empty: false, ok: true }
}
