import React, { ReactElement } from 'react'
import {
    Button  
} from '@material-ui/core'
import SectionTitle from '../../mui/SectionTitle'
import { FixMeLater } from './GlobalErrorMessage'
import DelayedTextField from '../../mui/DelayedTextField'
import { PropertyValidationResult } from '../sections/validators/sectionValidators'

export interface TextfieldWithHelpProps {
    type : FixMeLater
    validator?: (value: string) => PropertyValidationResult
    value : string
    title: string
    label: string
    help: () => ReactElement | null
    onChange: (value: string) => void;
    referenceValue : string;
    multiline? : boolean;
    rows? : number;
    validation: PropertyValidationResult;
}

const TextfieldWithHelp = (props : TextfieldWithHelpProps) => {
    const {
        type,
        validator,
        value,
        title,
        label,
        help,
        onChange,
        referenceValue,
        multiline,
        rows,
        validation: _validation
    } = props

    let validation : PropertyValidationResult = { ok: true }


    if (validator) {
        validation = validator(value)
    } else {
        validation = _validation || { ok: true }
    }
    //console.log({label, referenceValue})
    const endAdornment = referenceValue ?
        (
            <Button
                size='small'
                onClick={() => onChange(referenceValue)}
                style={{ backgroundColor: 'rgba(1,0,200,0.1)', marginTop: 0, marginRight: 4 }}
                color='inherit'
                variant={'contained'}
            >{referenceValue}</Button>
        )
        : <></>
    return (
        <React.Fragment>
            <SectionTitle label={title} help={help} />
            <DelayedTextField
                type={type}
                multiline={Boolean(multiline)}
                rows={rows}
                variant='outlined'
                fullWidth
                margin='normal'
                delay={250}
                value={value}
                error={!validation.ok}
                helperText={validation.message}
                endAdornment={endAdornment}
                onChange={(v : string) => onChange(v)}
                label={label} />
        </React.Fragment >
    )
}

export default TextfieldWithHelp