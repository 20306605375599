export const EKSPERT_ENTITIES_INIT = '[ekspert entities] init'
export const REQUEST_FETCH_ASSESSORS = '[ekspert entities] fetch assessors'
export const SET_ASSESSORS = '[ekspert entities] set assessors'
export const SET_USERGROUPS = '[ekspert entities] set usergroups'
export const REQUEST_FETCH_THREAT_DEFINITION = '[ekspert entities] fetch threat definition'
export const SET_THREAT_DEFINITION = '[ekspert entities] set threat definition'
export const EKSPERT_ENTITIES_LOADED = '[ekspert entity] loaded done'
export const REQUEST_FETCH_LEVESTEDS_DEFINTION = '[ekspert entity] fetch levestedsdefinition'
export const SET_LEVESTEDS_DEFINITION = '[ekspert entity] set levestedsdefinition'

export const initEkspertEntities = () => ({
    type: EKSPERT_ENTITIES_INIT
})

export const fetchAssessorsForUser = () => ({
    type: REQUEST_FETCH_ASSESSORS
})

export const fetchThreatDefinition = () => ({
    type: REQUEST_FETCH_THREAT_DEFINITION
})

export const fetchLevestedsDefinition = () => ({
    type: REQUEST_FETCH_LEVESTEDS_DEFINTION,
    payload: {}
})
