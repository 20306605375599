import { FixMeLater } from "../../assessments/components/GlobalErrorMessage";

export const SET_SUPPLERENDE_OPLYSNING_PROPERTY = '[edit supplerende oplysninger] set property'
export const SET_SUPPLERENDE_OPLYSNINGER       = '[edit supplerende oplysninger] set';
export const SAVE_SUPPLERENDE_OPLYSNINGER = '[edit supplerende oplysninger] save'
export const SAVE_SUPPLERENDE_OPLYSNINGER_OK = '[edit supplerende oplysninger] save ok'
export const SAVE_SUPPLERENDE_OPLYSNINGER_FAILED  = '[edit supplerende oplysninger] save failed'


export const setSupplerendeOplysninger = (value : FixMeLater) => ({
  type: SET_SUPPLERENDE_OPLYSNINGER,
  payload: { value }
});

export const updateSupplerendeOplysninger = (property : string, value : FixMeLater) => ({
  type: SET_SUPPLERENDE_OPLYSNING_PROPERTY,
  payload: {
    property,
    value
  }
})

export const saveSupplerendeOplysninger = (assessmentId : number, value : FixMeLater, previousValue : FixMeLater) => ({
  type: SAVE_SUPPLERENDE_OPLYSNINGER,
  payload: {
    assessmentId,
    value,
    previousValue
  }
})