import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const MANAGE_USER_PAGE_INIT = '[manage user] init'

export const REQUEST_CREATE_USER = '[manage user] create'
export const MANAGE_USER_PAGE_CREATE_OK = '[manage user] create ok'
export const MANAGE_USER_PAGE_CREATE_ERROR = '[manage user] create error'

export const REQUEST_UPDATE_USER = '[manage user] update'
export const MANAGE_USER_PAGE_UPDATE_OK = '[manage user] update ok'
export const MANAGE_USER_PAGE_UPDATE_ERROR = '[manage user] update error'

export const MANAGE_USER_PAGE_SET_USERS = '[manage user] set users'
export const MANAGE_USER_PAGE_SET_SELECTED_USER = '[manage user] set selected user'

export const MANAGE_USER_PAGE_SHOW_CREATE_DIALOG = '[manage user] show create' 
export const MANAGE_USER_PAGE_HIDE_CREATE_DIALOG = '[manage user] hide create' 

export const MANAGE_USER_PAGE_SHOW_EDIT_DIALOG = '[manage user] show edit' 
export const MANAGE_USER_PAGE_HIDE_EDIT_DIALOG = '[manage user] hide edit' 

export const manageUserPageInit = () => ({
    type: MANAGE_USER_PAGE_INIT
})

export const createUser = (user : FixMeLater) => (
    {
        type: REQUEST_CREATE_USER,
        payload: {
            user
        }
    }
)

export const updateUser = (username : string, user : FixMeLater) => (
    {
        type: REQUEST_UPDATE_USER,
        payload: {
            username,
            user
        }
    }
)

export const showCreateDialog = () => ({
    type: MANAGE_USER_PAGE_SHOW_CREATE_DIALOG
})

export const hideCreateDialog = () => ({
    type: MANAGE_USER_PAGE_HIDE_CREATE_DIALOG
})

export const showEditDialog = (user : FixMeLater) => ({
    type: MANAGE_USER_PAGE_SHOW_EDIT_DIALOG,
    payload: { user }
})

export const hideEditDialog = () => ({
    type: MANAGE_USER_PAGE_HIDE_EDIT_DIALOG
})