import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import { useState } from 'react'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import Select from 'react-select'
import {
    setSubstratFunktion
} from '../../redux/actions/editLevestedActions'

export const substratFunktioner = [
    { label: 'Lever i', value: 'lever_i' },
    { label: 'Lever af', value: 'lever_af' },
    { label: 'Lever på', value: 'lever_paa' }
]

const valueToFunktion = (value) => {
    return substratFunktioner.find(f => f.value === value)
}

const SubstratFunktioner = (props) => {
    const { onProceed } = props;
    const dispatch = useDispatch()
    const selectedSubstrater = useSelector((state) => state.editLevested.substrater)

    //let [substratFunktioner, setSubstratFunktioner] = useState([])

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} lg={11} spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>
                        Angiv funktion for valgte substrater
                    </Typography>
                </Grid>
                {
                    selectedSubstrater.map((s) => {
                        if (!s.funktion) {
                            s.funktion = []
                        }
                        return (
                            <Grid key={s.id} item xs={12} sm={6} lg={4}>
                                <Typography variant='h6'>{s.name}</Typography>
                                <Select
                                    isMulti
                                    value={s.funktion.map((f) => valueToFunktion(f))}

                                    onChange={(evt) => dispatch(setSubstratFunktion(s, evt ? evt.map((e) => e.value) : []))} 
                                    options={substratFunktioner} />
                            </Grid>
                        )
                    })
                }
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={() => onProceed()} color='primary' variant='contained'>Videre</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SubstratFunktioner
