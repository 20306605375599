import React from 'react'
import { Typography } from '@material-ui/core'
import { TextHelpElement, helpStyles } from './help'
import { FixMeLater } from '../../components/GlobalErrorMessage'

export const VedvarendeTilbagegangHelpElement = (props : FixMeLater) => <TextHelpElement title='Vedvarende tilbagegang i bestandsstørrelsen'>
    Det er observeret, forudset eller beregnet, at der er en vedvarende tilbagegang i bestandsstørrelsen på mindst (4 valgmuligheder): Hvis en af valgmulighederne passer, vælg denne. Tilbagegangen skal være vedvarende, hvilket betyder, at den kan måles enten tilbage eller varsles frem i tiden. Vides det derimod, at tilbagegangen er tilfældig, eller at bestandsstørrelsen er udsat for store eller ekstreme udsving, er tilbagegangen ikke vedvarende.
</TextHelpElement>

export const ModneIndividerHelpElement = (props : FixMeLater) => {
    const classes = helpStyles();
    return (
        <React.Fragment>
            <Typography className={classes.typography} variant='subtitle1'>
                Angiv antal modne individer
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                For at kunne udfylde dette felt forudsætter det, at antallet af modne
                individer er kendt eller kan vurderes. Det mest kritiske er definitionen af modenhed og individ.
                Den globale manual (IUCN 2013) giver en række fortolkninger, der dog langt fra er udtømmende.
                Det er et spørgsmål om at skønne antallet af reproducerende individer, uanfægtet deres reproduktionsmåde.
                Mange organismer f.eks. de såkaldte r-strateger producerer afkom i store mængder, hvoraf flertallet hurtigt bukker
                under. Disse individantal bør ikke forveksles med det betydeligt lavere antal individer, som både opnår
                moden alder og forplanter sig. Antallet af modne individer af disse arter, som naturligt svinger i
                antal med måske en faktor 10, opgøres i den periode, hvor de er mest fåtallige.
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Generelt optælles kun de individer, der indgår i forplantningen. Hos sociale arter tæller man således ikke
                unger og andre individer, som ikke indgår i reproduktionen (f.eks. individer der er for gamle til at få unger).
                Hos to- eller flerårige planter optælles kun de individer, som er formeringsdygtige.
                Individer, som af miljømæssige grunde ikke forplanter sig optælles heller ikke, f.eks. muslinger
                i forurenet vand, eller planter som ikke sætter frø pga. mangel på bestøvere eller som vokser på en
                tidligere eng, der nu er sprunget i skov. For klon- og kolonidannende arter opgøres enhver delenhed,
                som kan formere sig, som et selvstændigt individ. Der bør dog ikke gås til yderligheder, da
                sammenligningsgrundlaget med selvstændige individer bortfalder. For svampe kan et rimeligt kompromis
                være at opgøre grupper (f.eks. hekseringe) eller substratenheder (f.eks. forrådnende, dødt ved) 
                som selvstændige individer. (Hallingbäck m.fl. 1998) har foreslået, at enhver pude opgøres som et 
                individ hos pudedannende mosser. For træboende laver kan antallet af træer med kloner af den pågældende 
                art betragtes som lig med antallet af individer, mens der for jordboende laver gælder, at én kvadratmeter 
                sættes lig et individ. Den globale manual (IUCN 2013) anbefaler, at for arter, som i visse eller alle dele 
                af deres livscyklus er obligat afhængige af andre, bør en passende bi20 ologisk vurdering anvendes for 
                værtsarten. Dette indebærer i det mindste for monofage insekter, der lever på planter, at antallet af 
                planteindivider, som insekterne udvikles på, bør opgøres i stedet for antallet af forekommende, modne 
                insekter. Såvel for karplantearter med frøbank som for dyr med dvale eller udviklingsstadier med varierende 
                udviklingstid kan det fremføres, at de forplantningsdygtige individer udgør en mindre del af den totale bestand 
                end hos de arter, hvor sådanne stadier ikke forekommer.
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Nogle marine taxa har evnen til at skifte køn som en del af deres livscyklus. Hos
                disse taxa ses ofte flest individer af det mindste køn. Her må man bruge et lavere estimat
                for antal modne individer end blot det samlede antal, idet ikke alle vil kunne bidrage til at
                føre arten videre. I mange tilfælde vil et estimat svarende til det dobbelte af det store køn være passende.
                Træer der blomstrer, men som ikke producerer levedygtige frø tæller ikke som modne individer.
            </Typography>
        </React.Fragment>
    )
}

export const SubPopulationHelpElement = (props : FixMeLater) => <TextHelpElement title=''>
    Det er observeret, forudset, beregnet eller udledt, at der er en vedvarende tilbagegang i antallet af modne individer og ingen delbestande beregnes at bestå af mere end (4 valgmuligheder) modne individer: Hvis én af valgmulighederne passer, vælg denne. En delbestand er en gruppe af organismer, der geografisk eller på anden måde er adskilt fra hovedbestanden, og mellem hvilke der kun foregår beskeden genetisk udveksling (typisk maksimalt en succesfuld udveksling af ét individ eller én kønscelle om året eller endnu sjældnere). En delbestand kan være begrænset til landet eller strække sig uden for dette. For forklaring af begreberne forudset og beregnet, se Tilbagegangen er skønnet/ beregnet/forventet/udledt/formodet og baseret på i afsnittet om tilbagegang i bestand.
</TextHelpElement>

export const PercentMatureIndividualsHelpElement = (props : FixMeLater) => <TextHelpElement title=''>
    Det er observeret, forudset, beregnet eller udledt, at der er en vedvarende tilbagegang i antallet af modne individer og mindst (3 valgmuligheder) procent af alle modne individer findes i én eneste delbestand: Hvis én af valgmulighederne passer, vælg denne. Begrebet delbestand er forklaret ovenfor. For forklaring af begreberne forudset og beregnet, se Tilbagegangen er skønnet/beregnet/for-ventet/udledt/formodet og baseret på i afsnittet om tilbagegang i bestand.
</TextHelpElement>

export const EkstremeUdsvingHelpElement = (props : FixMeLater) => <TextHelpElement title=''>
    Det er observeret, forudset, beregnet eller udledt, at der er en vedvarende tilbagegang i antallet af modne individer og antallet af modne individer svinger ekstremt: Svar ja, hvis antallet af modne individer svinger ekstremt. Som udgangspunkt betyder ekstreme svingninger, at antallet af modne individer varierer med mere end en faktor 10 fra år til år (IUCN 2013).
</TextHelpElement>

export const Max3DelbestandeHelpElement = (props : FixMeLater) => <TextHelpElement title=''>
    Svar ja, hvis der er vedvarende tilbagegang i antallet af modne individer og antallet af delbestande er højst 3. Begrebet delbestand er forklaret ovenfor.
</TextHelpElement>