import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Copyright from './Copyright';

//https://source.unsplash.com/random

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/1600x900/?nature,water)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface LoginProps {
  errorMessage: (string | null)
  version: string
  onSubmit: (username: string, password: string, rememberMe: boolean) => void;
}

export default function Login(props : LoginProps) {
  const classes = useStyles();
  const { errorMessage, version, onSubmit } = props
  const [ username, setUsername] = useState('')
  const [ password, setPassword] = useState('')
  const [ rememberMe, setRememberMe ] = useState(false)
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={(evt) => { evt.preventDefault(); onSubmit(username, password, rememberMe) }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Brugernavn"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={evt => setUsername(evt.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={evt => setPassword(evt.target.value)}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox checked={rememberMe} onChange={evt => setRememberMe(evt.target.checked)} value="remember" color="primary" />}
              label="Remember me"              
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>       
            { errorMessage ? <Typography variant='caption'>{errorMessage}</Typography> : null}
            <Box mt={5}>
              <Copyright version={version} />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}