export const FETCH_ALL_ADMIN_ENTITIES = '[adm entity] fetch all'
export const FETCH_ADM_ENTITY = '[adm entity] fetch'
export const SET_ADM_ENTITY = '[adm entity] set'

export const fetchAdminEntities = () => ({
    type: FETCH_ALL_ADMIN_ENTITIES
})

export const fetchUsergroups = () => ({
    type: FETCH_ADM_ENTITY,
    meta: {
        entity: 'usergroups'
    }
})

export const fetchUsers = () => ({
    type: FETCH_ADM_ENTITY,
    meta: {
        entity: 'users'
    }
})

export const fetchAssessors = () => ({
    type: FETCH_ADM_ENTITY,
    meta: {
        entity: 'assessors'
    }
})

export const fetchVurderingsrunder = () => ({
    type: FETCH_ADM_ENTITY,
    meta: {
        entity: 'vurderingsrunder'
    }
})