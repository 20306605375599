import React from 'react'
import { useEffect, useState } from 'react'

import {
    Grid,
    Button,
    Typography, TextField
} from '@material-ui/core'
import Select from 'react-select'

const assessorFromId = (id, assessors) => {
    if (!id) {
        return null
    }
    if (!assessors) {
        return null
    }
    return assessors.find(a => a.id === id)
}

const toOption = assessor => {
    if (!assessor) {
        return null
    }
    return { id: assessor.id, value: assessor.id + '', label: assessor.name }
}

const EditUserDialog = props => {
    const { user, assessors, usergroups, onClose, onSave } = props
   
    const [selectedAssessors, setSelectedAssessors] = useState([])
    const [selectedUsergroups, setSelectedUsergroups] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')

    useEffect(() => {
        if (user) {
            setSelectedAssessors(user.assessorIds.map(a => toOption(assessorFromId(a, assessors))))
        }
    }, [ setSelectedAssessors, user, assessors])

    useEffect(() => {
        if (user && user.usergroupIds) {
            setSelectedUsergroups(user.usergroupIds.map(a => toOption(assessorFromId(a, usergroups))))
        }
    }, [ setSelectedUsergroups, user, usergroups])

    console.log(selectedAssessors)

    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Rediger {user && user.name}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Assessors</Typography>
                    <Select                    
                        isMulti    
                        placeholder='Select assessors...'
                        options={assessors.map(toOption)}
                        value={selectedAssessors}
                        onChange={value => setSelectedAssessors(value)} />
                </Grid>
                <Grid item xs={12}>
                <Typography variant='subtitle1'>Usergroups</Typography>
                    <Select                    
                        isMulti    
                        placeholder='Select usergroups...'
                        options={usergroups.map(toOption)}
                        value={selectedUsergroups}
                        onChange={value => setSelectedUsergroups(value)} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Rolle</Typography>
                    <Select
                        placeholder='Select rolle...'
                        options={[{ value: 'admin', label: 'Admin' }, { value: 'godkender', label: 'Godkender' },{ value: 'ekspert', label: 'Ekspert' }]}
                        onChange={value => setRole(value.value)} />
                </Grid>               
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Navn'
                        value={name}                       
                        onChange={evt => setName(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Email'                       
                        value={email}
                        onChange={evt => setEmail(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Password'                        
                        value={password}
                        onChange={evt => setPassword(evt.target.value)} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onSave({ usergroupIds: selectedUsergroups, assessorIds: selectedAssessors, name, email, password, role })}  fullWidth variant='contained' color='primary'>Gem</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default EditUserDialog