import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Skeleton
} from '@material-ui/lab'
import { Grid, Typography, TextField, Button } from '@material-ui/core'
import { updateUserProfile } from '../redux/ekspert/userProfilePageActions'

const UserProfilePage = props => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.app)    
    const [ name, setName ] = useState(user.name)
    const [ email, setEmail ] = useState(user.email)
    const [ password, setPassword ] = useState('')
    if (!user) {
        return (<Skeleton />)
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>Bruger profil</Typography>                
            </Grid>
            <Grid item xs={12}>
            <TextField
                    label='Navn'
                    variant='outlined'                    
                    value={name}
                    onChange={evt => setName(evt.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Email'
                    variant='outlined'                    
                    value={email}
                    onChange={evt => setEmail(evt.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Nyt password'
                    variant='outlined'
                    type='password'
                    value={password}
                    onChange={evt => setPassword(evt.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => dispatch(updateUserProfile(email, password, name))}>Gem</Button>
            </Grid>
        </Grid>
    )
}

export default UserProfilePage