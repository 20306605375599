import RedlistApi from '../../service/redlistApi'

import {
    put,
    takeLatest,
    takeEvery,
    select,
    takeLeading,
    delay
} from 'redux-saga/effects'
import {
    showSpinner,
    hideSpinner,
    showMessage,
    showError
} from '../actions/uiActions'
import { 
    ASSESSMENT_QA_PAGE_INIT,
    ASSESSMENT_QA_SET_PHASE,
    ASSESSMENT_QA_FETCH_ASSESSMENT,
    ASSESSMENT_QA_SET_ASSESSMENT,
    ASSESSMENT_QA_FETCH_COMMENTS,
    ASSESSMENT_QA_CREATE_COMMENT,
    ASSESSMENT_QA_DELETE_COMMENT,
    ASSESSMENT_QA_UPDATE_COMMENT,
    fetchComments,
    ASSESSMENT_QA_SET_COMMENTS,
    ASSESSMENT_QA_APPROVE,
    ASSESSMENT_QA_REJECT,
    ASSESSMENT_QA_APPROVE_OK,
    ASSESSMENT_QA_REJECT_OK,
    fetchCurrentAssessment
} from './assessmentQAPageActions'
import { apiRequest2 } from '../api/apiRequest'
import { initEkspertEntities } from './ekspertEntitiesActions'
import { phaseFromAssessment } from '../../service/assessmentPhase'


function* processInit(action) {
    try {
        const { assessmentId } = action.payload
        yield put(initEkspertEntities())
        yield put(fetchCurrentAssessment(assessmentId))
    } catch (error) {
        console.log(error)
        yield put(showError(error))
    }
}

function* processFetchAssessment(action) {
    try {
        const { assessmentId } = action.payload
        yield put(showSpinner())
        const data = yield apiRequest2(RedlistApi, RedlistApi.loadAssessmentById, [ assessmentId ])
        const assessment = data.item
        
        yield put({ type: ASSESSMENT_QA_SET_ASSESSMENT, payload: { assessment }})
        const phase = phaseFromAssessment(assessment)
        yield put({ type: ASSESSMENT_QA_SET_PHASE, payload: { phase }})
        yield put(fetchComments(assessmentId, phase))
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}

function* processFetchComments(action) {
    try {
        const { assessmentId } = action.payload
        let phase = yield select(state => state.assessmentQaPage.phase)
        let count = 0
        while (!phase) {
            console.log(phase)
            count += 1
            if (count > 5) {
                throw new Error("Phase not set!")
            }
            yield delay(50)
        }

        yield put(showSpinner())
        const data = yield apiRequest2(RedlistApi, RedlistApi.fetchCommentsForAssessment, [ assessmentId ], {
            "error": showMessage("Fejl", "Kunne ikke hente kommentarer")
        })
        if (data) {
            console.log(phase, data)
            const comments = data.items.filter(c => phase.commentPhases.find(p => p === c.phase))
            yield put({ type: ASSESSMENT_QA_SET_COMMENTS, payload: { comments: comments }})
        }
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}


function* processCreateComment(action) {
    try {
        const { assessmentId, phase, comment } = action.payload
        yield apiRequest2(RedlistApi, RedlistApi.createAssessmentComment, [ assessmentId, phase.slug, comment ], {
            "error": showMessage("Fejl", "Kunne ikke gemme kommentar")
        })
        yield put(fetchComments(assessmentId))        
    } catch(error) {
        yield put(showError(error))
    }
}

function* processUpdateComment(action) {
    try {
        const { assessmentId, commentId, text } = action.payload
        yield apiRequest2(RedlistApi, RedlistApi.updateAssessmentComment, [ assessmentId, commentId, text ], {
            "error": showMessage("Fejl", "Kunne ikke gemme kommentar")
        })
        yield put(fetchComments(assessmentId))
    } catch(error) {
        console.log(error)
        yield put(showError(error))
    }
}

function* processDeleteComment(action) {
    try {
        const { assessmentId, commentId } = action.payload
        yield apiRequest2(RedlistApi, RedlistApi.deleteAssessmentComment, [ assessmentId, commentId ], {
            "error": showMessage("Fejl", "Kunne ikke slette kommentar")
        })
        yield put(fetchComments(assessmentId))        
    } catch(error) {
        yield put(showError(error))
    }
}

function* processApprove(action) {
    try {
        const { assessmentId } = action.payload
        yield apiRequest2(RedlistApi, RedlistApi.updateStatusNext, [ assessmentId ], { "200": { type: ASSESSMENT_QA_APPROVE_OK }})        
    } catch (error) {
        yield put(showError(error))
    }
}

function* processReject(action) {
    try {
        const { assessmentId } = action.payload
        yield apiRequest2(RedlistApi, RedlistApi.updateStatusReject, [ assessmentId ], { "200": { type: ASSESSMENT_QA_REJECT_OK}})
        // TODO - if approve ok
    } catch (error) {
        yield put(showError(error))
    }
}

function* processApproveRejectOk(action) {
    try {
        yield window.location.hash = '#/godkendelse'
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLeading(ASSESSMENT_QA_PAGE_INIT, processInit)
    yield takeLatest(ASSESSMENT_QA_FETCH_ASSESSMENT, processFetchAssessment)
    yield takeLatest(ASSESSMENT_QA_FETCH_COMMENTS, processFetchComments)
    yield takeEvery(ASSESSMENT_QA_CREATE_COMMENT, processCreateComment)
    yield takeEvery(ASSESSMENT_QA_UPDATE_COMMENT, processUpdateComment)
    yield takeEvery(ASSESSMENT_QA_DELETE_COMMENT, processDeleteComment)
    yield takeEvery(ASSESSMENT_QA_APPROVE, processApprove)
    yield takeEvery(ASSESSMENT_QA_REJECT, processReject)  
    yield takeEvery(ASSESSMENT_QA_APPROVE_OK, processApproveRejectOk)
    yield takeEvery(ASSESSMENT_QA_REJECT_OK, processApproveRejectOk)
}