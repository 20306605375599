import React from 'react'
import { Typography } from '@material-ui/core'
import { TextHelpElement, helpStyles } from './help'

export const KlarReversibelHelpElement = (props) => {
    const classes = helpStyles();
    return (
        <React.Fragment>
            <Typography className={classes.typography} variant='subtitle1'>
                Årsager til tilbagegangen er klart reversible
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                I en række tilfælde er en biologisk proces reversibel,
                dvs. at retningen af den udvikling, som dens bestanddele udviser, drejes tilbage til udgangspunktet
                som et led i de naturlige processer. Dette indebærer, at en bestand 'af sig selv' kan opnå en størrelse,
                den havde førhen, som følge af samspillet mellem de biologiske processer, der styrer levevilkårene.
                Svar ja hvis årsagerne er klart reversible.
            </Typography>
        </React.Fragment>
    )
}

export const KlarlagteHelpElement = props =>
    <TextHelpElement title='Årsagerne til tilbagegangen er klarlagte'>
        Svar ja, hvis man kender og forstår de processer, der er skyld i tilbagegangen.
    </TextHelpElement>

export const OphorteHelpElement = props =>
    <TextHelpElement title='Årsagerne til tilbagegangen er ophørte'>
        Svar ja, hvis årsagerne til tilbagegangen ikke længere påvirker bestanden.
    </TextHelpElement>


export const ObserveretHelpElement = props =>
    <TextHelpElement title='Tilbagegangen er direkte observeret'>
        Svar ja, hvis den kendte tilbagegang kan dokumenteres, enten ved egne eller andres observationer.
    </TextHelpElement>

export const TilbagegangBaseretPaaHelpElement = props =>
    <TextHelpElement title='Tilbagegangen er skønnet/beregnet/forventet/udledt/formodet og baseret på:'>
        Skønnet/ beregnet betyder i denne sammenhæng, at informationen er baseret på beregninger,
        der kan inkludere antagelser om sampling eller biologiske antagelser om forholdet mellem en observeret variabel
        (f.eks. et index) og den information, man er interesseret i. Skøn/beregninger kan også involvere interpolering
        i tid for at udregne den variabel, man er interesseret i til et givet tidspunkt (f.eks. en 10-års tilbagegang
        baseret på estimeringer af populationens størrelse for hhv. 5 og 15 år siden). Forventet er det samme som skønnet/ beregnet,
        men den variabel, man er interesseret i er ekstrapoleret ud i fremtiden. Udledt betyder, at informationen er baseret på
        indirekte evidens, på variable som er indirekte relaterede til den information, man er interesseret i, men målt i samme
        generelle type af enheder (f.eks. antal individer, areal eller antal delpopulationer). Udledte værdier er baseret på flere
        antagelser 16 end skønnede/beregnede værdier. Formodet betyder, at informationen er baseret på ydre omstændigheder,
        altså en formodning baseret på information i en anden type enheder. F.eks. en procentvis tilbagegang, der er baseret
        på, at habitatkvaliteten er forringet. Se i øvrigt IUCN’s definitioner i deres guidelines (IUCN 2013) side 17 for
        yderligere uddybning af disse begreber. Et for arten passende udbredelsesindeks: Vælg dette udsagn, hvis den angivne
        tilbagegang er baseret på et for arten passende udbredelsesindeks. For marine fisk, er reproduktionspotentialet f.eks.
        tæt relateret til kropsstørrelsen, så her kunne et for arten passende udbredelsesindeks være biomassen af forplantningsdygtige
        fisk i stedet for antallet af sådanne.
        
        Et reduceret forekomstareal, udbredelsesområde og/eller forringet habitatkvalitet: Vælg dette udsagn,
        hvis den angivne tilbagegang er baseret på, at forekomstareal eller udbredelsesområde er reduceret,
        og/eller habitatkvaliteten er forringet. Forringet habitatkvalitet kan dække over en række forskellige processer og afhænger af arten.
        Det er vigtigt ikke at være for kreativ her.
        Eksempler på forringelse af habitatkvaliteten:
        (1) For trærugende fugle ville en forringelse være, hvis en så stor del af deres redetræer fældes,
        at det påvirker deres mulighed for at opretholde den pågældende population.
        (2) For bundlevende vandplanter planter ville et øget klorofylindhold være en forringelse af habitatkvaliteten,
        hvis det påvirker populationen negativt.
        (3) For svampe på dødt ved er en reduktion af mængden af dødt ved i den pågældende svamps nuværende eller
        potentielle udbredelsesområde en forringelse af habitatkvaliteten. (4) For de fleste vandlevende organismer
        kan en vandstandssænkning ofte føre til forringet habitatkvalitet, fordi den ofte medfører et mindre
        udbredelsesareal og/eller ændret vandkemi/strømningsforhold. I det hele taget dækker begrebet forringet
        habitatkvalitet over, at vigtige elementer for den pågældende arts livscyklus såsom fødeudvalg, jord/vandkemi,
        vandtilgængelighed, struktur/skyggeforhold osv. ændres i en sådan grad, at det påvirker artens population(er) negativt.
        Det er meget vigtigt grundigt at dokumentere en evt. forringet habitatkvalitet.
        Se øvrige definitioner under ’Angiv det skønnede/ kendte areal (km2) for artens udbredelse og/eller for artens forekomst’
        i nedenstående afsnit.
        
        En aktuel eller potentiel menneskelig udnyttelse af arten:
        Vælg dette udsagn, hvis den angivne tilbagegang er baseret på en aktuel eller potentiel menneskelig udnyttelse af arten.
        
        Påvirkning fra indførte arter, hybridisering, patogener, forurening, konkurrerende arter og/eller parasitter:
        Vælg dette udsagn, hvis den angivne tilbagegang er baseret på påvirkning fra indførte arter, hybridisering, patogener, forurening,
        konkurrerende arter og/eller parasitter.
</TextHelpElement>

export const TilbagegangPeriodeHelpElement = (props) => {
    const classes = helpStyles();
    return (
        <React.Fragment>
            <Typography className={classes.typography} variant='subtitle1'>
                Tilbagegangen er pågået
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Tilbagegangen er pågået i løbet af de seneste 10 år eller 3 generationer: Svar ja, hvis tilbagegangen er sket over
                de SENESTE 10 år/3 generationer (den længste af de to, se indledningen til dette afsnit), men ikke ud i fremtiden.
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Tilbagegangen er pågået/pågår over en tidsramme på 10 år eller 3 generationer, som strækker sig over
                både umiddelbar fortid og nær fremtid: Svar ja, hvis tilbagegangen er sket frem til vurderingstidspunktet
                og fortsat forventes at pågå over en sammenhængende periode på i alt 10 år eller 3 generationer (den længste af de to, se indledningen til dette afsnit).
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Tilbagegangen er fremtidig og forventes/formodes at pågå i løbet af de næste 10 år eller 3 generationer
                (maks. 100 år frem): Svar ja, hvis den angivne tilbagegang forventes fremover i en periode på 10 år eller 3
                generationer (den længste af de to, se indledningen til dette afsnit) fra vurderingstidspunktet.
                Se i øvrigt uddybning af betydningen af forventes/formodes under ’Tilbagegangen er
                skønnet/beregnet/forventet/udledt/formodet og baseret på’ nedenfor.
            </Typography>
        </React.Fragment>
    )
}


export const ProcentvisTilbagegangHelpElement = (props) => {
    const classes = helpStyles();
    return (
        <React.Fragment>
            <Typography className={classes.typography} variant='subtitle1'>
                Angiv procentvis tilbagegang i en periode på 10 år eller 3 generationer (vælg længste periode)
        </Typography>
            <Typography className={classes.typography} variant='body2'>
                Hvis artens bestand er gået/går tilbage over en periode på 10 år/3 generationer –
                som dækker perioden op til bedømmelsestidspunktet, en periode som dækker over både
                fortid og fremtid (altså en pågående tilbagegang) eller en periode som strækker sig
                fra vurderingstidspunktet og frem i tiden (altså forventet/formodet) – angives den
                procentvise tilbagegang i dette felt. Angiv kun tilbagegangen over 3 generationer,
                hvis det dækker over en periode på mere end 10 år. Bemærk, at den angivne tilbagegang
                gerne må være skønnet, beregnet, forventet, udledt og/eller formodet såvel som observeret.
                Grunden til, at generationsalternativet findes, er, at en negativ påvirkning af en længelevende
                art ofte ikke viser sig i form af reducerede bestande indenfor en 10 års periode.
                Eksempelvis kan antallet af voksne sæler, muslinger eller træer forblive næsten konstant i en
                periode selvom forplantningen er ophørt. Generationslængden er defineret ved gennemsnitsalderen
                af populationens forældreindivider, og ikke alderen ved første reproduktion, dog undtaget de tilfælde,
                hvor arten kun formerer sig lejlighedsvis. For urter med kort levetid og længelevende frøbank er det
                bedre at anvende gennemsnitslevetiden af frøbanken (den formodede gennemsnitlige omsætningstid).
                I den globale manual (IUCN 2013) sættes ingen øvre grænse for, hvor lang generationstiden i systemet skal være.
                Dette kan føre til, at der hos meget længelevende organismer måles en tilbagegang over et tidsrum,
                der langt overstiger de tidsskalaer, som andre processer i omgivelserne (negative påvirkninger,
                bevaringsforanstaltninger osv.) opererer inden for. Det anbefales i overensstemmelse med Baillie
                &amp; Groombridge (1996) og Hallingbäck m.fl. (1998), at en maksimal generationslængde sættes til 25 år, dvs.
                tilbagegangen bedømmes over maksimalt 75 år; træer er en undtagelse, hvor generationstidsloftet sættes til 75 år (225 år).
                I den globale manual (IUCN 2013) er indført, at ingen forudsigelse må overstige 100 år, mens der ikke er
                begrænsninger tilbage i tiden.
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Det er formodentlig sjældent, at sikre og regelmæssige data om bestandsstørrelser,
                der gør det muligt at beregne en eventuel tilbagegang, forekommer. Det er til gengæld
                muligt og tilladt at vurdere ændringerne i bestandsstørrelser på grundlag af delpopulationer
                ved at ekstrapolere fra målinger over andre tidsrum eller ved vurderinger baseret på observerede
                eller formodede formindskelser af f.eks. udbredelsesareal, biotop, levestedskvalitet, biomasse
                (f.eks. hos fisk), fangst i fælder, træktælling eller ændringer i menneskelig udnyttelse, forurening
                eller af andre negative påvirkninger. Desuden kan disse vurderinger gøres såvel tilbage som frem i
                tiden, dvs. at der er mulighed for at vurdere, om bestandene formindskes i fremtiden. For at opretholde
                troværdigheden til systemet er det derfor vigtigt at undgå sjusk og rutinemæssig indtastning i dette felt!
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                Det er vigtigt at granske de foreliggende oplysninger om en arts tilbagegang.
                Eksempelvis kan en grundig, gentagen undersøgelse af en sjælden art vise, at den er
                forsvundet på 30 % af sine levesteder. Hvis en sådan undersøgelse udelukkende har været
                foretaget på artens kendte levesteder, har man ikke taget højde for, at den kan være spredt
                til nye levesteder, hvor den måske trives. En realistisk angivelse af en arts tilbagegang
                afhænger derfor af, at man inddrager sådanne detaljer. I de tilfælde, hvor pålidelige data om en
                bestandstilbagegang foreligger, strækker de sig sjældent over nøjagtigt 10 år eller det antal år,
                som svarer til 3 generationer. Det indebærer, at der skal ekstrapoleres på grundlag af de forhåndenværende
                oplysninger for det tidsrum, målingen foretages i, hvilket kan være meget besværligt. Hvor stor
                tilbagegangen inden for en given tidsperiode er, beror på den måde, tilbagegangen (antagelig) har
                fundet sted på, dvs. hvilken form reduktionskurven har. Trækkes der en ret linje mellem to målepunkter,
                antages det samtidig, at reduktionshastigheden er øget med tiden (Figur 1). Det er således let at begå
                fejl på tankeplanet, når den observerede formindskelse ekstrapoleres fra én tidsperiode til en anden.
                Der er ofte grund til at antage, at kurven, der beskriver en bestandstilbagegang, ikke er en ret linie.
                Den kan f.eks. være konveks, dvs. når tilbagegangen sker meget hurtigt, i andre tilfælde konkav, hvilket
                den bliver, når den årlige tilbagegangsrate er konstant. I de efterfølgende felter angives detaljer om tilbagegangen.
            </Typography>
            <Typography className={classes.typography} variant='body2'>
                En ekstrapolering af en bestandstilbagegang på baggrund af andre typer af data end direkte målinger af
                en arts bestand, er heller ikke nødvendigvis lineær. Det, at arten er forsvundet fra 25% af de tyndest
                besatte dele af udbredelsesområdet, indebærer ikke, at bestanden er formindsket med 25%; det kan
                sammenlagt måske dreje sig om en formindskelse på 5%. Tilsvarende er det, at en art er forsvundet
                fra 3 af 10 tidligere levesteder, ikke det samme som en 30 % bestandstilbagegang. En realistisk
                angivelse af en arts tilbagegang afhænger derfor af, at man overvejer, hvordan sammenhængen er mellem
                de tilgængelige data for artens bestandsstørrelse.
            </Typography>
        </React.Fragment>
    )
}