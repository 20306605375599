import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const REQUEST_SET_COMMENT = '[editLevested] request set_comment'
export const REQUEST_SET_HOVEDMILJO = '[editLevested] request SET_HOVEDMILJO'
export const REQUEST_SET_LEVESTEDSTYPE = '[editLevested] request SET_LEVESTEDSTYPE'
export const REQUEST_SET_ECOSYSTEM = '[editLevested] request SET_ECOSYSTEM'
export const REQUEST_SET_HABITAT = '[editLevested] request SET_HABITAT'
export const REQUEST_SET_OVERORDNET_SUBSTRAT = '[editLevested] request SET_OVERORDNET_SUBSTRAT'
export const SET_COMMENT = '[editLevested] set comment'
export const SET_HOVEDMILJO = '[editLevested] SET_HOVEDMILJO'
export const SET_LEVESTEDSTYPE = '[editLevested] SET_LEVESTEDSTYPE'
export const SET_ECOSYSTEM = '[editLevested] SET_ECOSYTEM'
export const SET_LINKED_ECOSYSTEM = '[editLevested] SET_LINKED_ECOSYSTEM'
export const SET_HABITAT = '[editLevested] SET_HABITAT'
export const SET_OVERORDNET_SUBSTRAT = '[editLevested] SET_OVERORDNET_SUBSTRAT'
export const REQUEST_SET_SUBSTRAT = '[editLevested] request set substrat'
export const SET_LEVESTED = '[editLevested] SET_LEVESTED'
export const SET_SUBSTRAT = '[editLevested] SET_SUBSTRAT'
export const SET_SUBSTRAT_FUNKTION = '[editLevested] SET_SUBSTRAT_FUNKTION'
export const SAVE_LEVESTED = '[editLevested] SAVE'
export const SAVE_LEVESTED_OK = '[editLevested] SAVE OK'
export const SAVE_LEVESTED_FAILED = '[editLevested] SAVE FAILED'

export const SAVE_DIET_SPECIES = '[editLevested] save diet species'
export const SAVE_DIET_SPECIES_OK = '[editLevested] save diet species ok'
export const SAVE_DIET_GENUS = '[editLevested] save diet genus'
export const SAVE_DIET_GENUS_OK = '[editLevested] save diet genus ok'

export const LEVESTED_USE_REFERENCE = '[editLevested] anvend reference art'

export const REQUEST_SET_GRADIENT = '[editLevested] request set gradient'
export const SAVE_GRADIENT = '[editLevested] save gradient'
export const SET_GRADIENT = '[editLevested] set gradient'

export interface SetLevestedCommentAction {
  type: string,
  payload: {
    comment: string
  }
}

export interface RequestSetLevestedCommentAction {
  type: string,
  payload: {
    assessmentId : number
    comment: string
  }
}

export const setLevestedComment = (assessmentId : number, comment :string) : RequestSetLevestedCommentAction => ({
  type: REQUEST_SET_COMMENT,
  payload: {
    assessmentId,
    comment
  }
})

export const setHovedmiljo = (value : FixMeLater, confirmed = false) => ({
  type: REQUEST_SET_HOVEDMILJO,
  payload: {
    value,
    confirmed
  }
})

export const setLevestedstype = (value : FixMeLater, confirmed = false) => ({
  type: REQUEST_SET_LEVESTEDSTYPE,
  payload: {
    value,
    confirmed
  }
})

export const setEcosystem = (value : FixMeLater, confirmed = false) => ({
  type: REQUEST_SET_ECOSYSTEM,
  payload: {
    value,
    confirmed
  }
})

export const setHabitat = (value : FixMeLater, confirmed = false) => ({
  type: REQUEST_SET_HABITAT,
  payload: {
    value,
    confirmed
  }
})

export const setOverordnetSubstrat = (value : FixMeLater, confirmed = false) => ({
  type: REQUEST_SET_OVERORDNET_SUBSTRAT,
  payload: {
    value,
    confirmed
  }
})

export const setSubstrat = (value : FixMeLater, confirmed = false) => ({
  type: REQUEST_SET_SUBSTRAT,
  payload: {
    value,
    confirmed
  }
})

export const setSubstratFunktion = (substrat : string, funktion : string, confirmed = false) => ({
  type: SET_SUBSTRAT_FUNKTION,
  payload: {
    substrat,
    funktion,
    confirmed
  }
})

export const saveLevested = (assessmentId : number, levested : FixMeLater, values : FixMeLater[]) => ({
  type: SAVE_LEVESTED,
  payload: {
    assessmentId,
    levested, 
    values
  }
})

export const saveDietGenus = (assessmentId : number, dietGenus : FixMeLater) => ({
  type: SAVE_DIET_GENUS,
  payload: {
    assessmentId,
    dietGenus
  }
})

export const saveDietSpecies = (assessmentId : number, dietSpecies : FixMeLater) => ({
  type: SAVE_DIET_SPECIES,
  payload: {
    assessmentId,
    dietSpecies
  }
})

export const anvendLevestedReference = (assessmentId : number, levestedReference : FixMeLater) => ({
  type: LEVESTED_USE_REFERENCE,
  payload: {
    assessmentId,
    levestedReference
  }
})

export const updateGradient = (assessmentId : number, gradientName : string, value : FixMeLater) => ({
  type: REQUEST_SET_GRADIENT,
  payload: {
    assessmentId,
    gradientName,
    value
  }
})