import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { ReduxAction } from  '../reduxModel'
export const INIT_EDIT_ASSESSMENT_PAGE = '[editAssessment] init'

export const ASSESSMENT_FETCHED = '[editAssessment] fetched'

export const SET_PREVIOUS_ASSESSMENTS = '[editAssessment] set previous'
export const SET_ASSESSMENT_CRITERIA = '[editAssessment] set assessment criteria'
export const SET_CRITERIA_VALIDATION = '[editAssessment] set criteria validation'

export const VALIDATE_ASSESSMENT = '[editAssessment] validate assessment'

export const UPDATE_PROPERTY = '[editAssessment] update property'
export const UPDATE_ADJUSTMENT = '[editAssessment] update adjustment'
export const UPDATE_ADJUSTMENT_BREEDING = '[editAssessment] update adjustment breeding'
export const UPDATE_ADJUSTMENT_VISITING = '[editAssessment] update adjustment visiting'

export const SAVE_SECTION = '[editAssessment] save section'
export const SAVE_SECTION_GLOBAL = '[editAssessment] save section global'
export const SAVE_SECTION_PRELIMINARY = '[editAssessment] save section preliminary'
export const SAVE_SECTION_REDUCTION = '[editAssessment] save section reduction'
export const SAVE_SECTION_GEOGRAPHY = '[editAssessment] save section geography'
export const SAVE_SECTION_POPULATION = '[editAssessment] save section population'
export const SAVE_SECTION_ANALYSIS = '[editAssessment] save section analysis'
export const SAVE_SECTION_ADJUSTMENT = '[editAssessment] save section adjustment'

export const SAVE_SECTION_OK = '[editAssessment] save section ok'
export const SAVE_SECTION_FAILED = '[editAssessment] save section failed'

export const REQUEST_SEND_TO_QA = '[editAssessment] request send to qa'
export const SEND_TO_QA_OK = '[editAssessment] send to qa ok'
export const SEND_TO_QA_FAILED = '[editAssessment] send to qa failed'

export const FETCH_ASSESSMENTS_BY_SPECIES_ID = '[assessments] by species id fetch'
export const FETCH_ASSESSMENTS_BY_SPECIES_ID_OK = '[assessments] by species id fetch ok'
export const FETCH_ASSESSMENTS_BY_SPECIES_ID_FAILED = '[assessments] by species id fetch failed'

export const SET_CATEGORY_CHANGE = '[editAssessment] set categorychange'
export const SET_SINGLE_CATEGORY_CHANGE = '[editAssessment] set single categorychange'
export const SET_CATEGORY_CHANGE_REASONS = '[editAssessment] set categorychangereasons'
export const SET_CATEGORY_CHANGE_REASON_VALUE = '[editAssessment] set categorychangereasonvalue'
export const SET_CATEGORY_CHANGE_REASON_COMMENT = '[editAssessment] set categorychangereasonComment'
export const DELETE_CATEGORY_CHANGE = '[editAssessment] delete categorychange'
export const SET_CATEGORY_CHANGE_VALIDATION = '[editAssessment] set categorychange validation'

export const ADD_OVERALL_THREAT = '[editAssessmentPage] add overall threat'
export const REMOVE_OVERALL_THREAT = '[editAssessmentPage] remove overall threat'
export const ADD_THREAT = '[editAssessmentPage] add threat'
export const REMOVE_THREAT = '[editAssessmentPage] remove threat'

export const SAVE_OVERALL_THREATS = '[editAssessmentPage] update overall threats'
export const SET_OVERALL_THREATS = '[editAssessmentPage] set overall threats'
export const SAVE_THREATS = '[editAssessmentsPage] update threats'
export const SET_THREATS = '[editAssessmentsPage] set threats'

export const SAVE_THREATMETA = '[editAssessmentsPage] update threatmeta'
export const SET_THREATMETA = '[editAssessmentsPage] set threatmeta'

export const SET_PHASE               = '[editAssessmentsPage] set phase'
export const SET_ASSESSMENT_COMMENTS = '[editAssessmentsPage] set comments'
export const FETCH_ASSESSMENT_COMMENTS = '[editAssessmentsPage] fetch comments'
export const EDIT_ASSESSMENT_CREATE_COMMENT = '[editAssessmentsPage] create comment'

export type SectionTypeEnum = 'global' | 'preliminary' | 'reduction' | 'geography' | 'population' | 'quantitativeAnalysis' | 'adjustment'

const sectionTypes = {
  'global': SAVE_SECTION_GLOBAL,
  'preliminary': SAVE_SECTION_PRELIMINARY,
  'reduction': SAVE_SECTION_REDUCTION,
  'geography': SAVE_SECTION_GEOGRAPHY,
  'population': SAVE_SECTION_POPULATION,
  'quantitativeAnalysis': SAVE_SECTION_ANALYSIS,
  'adjustment': SAVE_SECTION_ADJUSTMENT
}

export interface InitEditAssessmentPageAction extends ReduxAction {
  payload: { assessmentId : number }
}

export const initEditAssessmentPage = (assessmentId : number) : InitEditAssessmentPageAction => ({
  type: INIT_EDIT_ASSESSMENT_PAGE,
  payload: {
    assessmentId
  }
})

export const fetchAssessmentsBySpeciesId = (speciesId : number) => ({
  type: FETCH_ASSESSMENTS_BY_SPECIES_ID,
  payload: {
    speciesId
  }
})

export const assessmentFetched = (item : FixMeLater) => ({
  type: ASSESSMENT_FETCHED,
  payload: item
})

export const setAssessmentCriteria = (criteria : FixMeLater) => ({
  type: SET_ASSESSMENT_CRITERIA,
  payload: criteria
})

export const validateAssessment = (criteria : FixMeLater, supplerendeOplysninger : FixMeLater, levested : FixMeLater, threat : FixMeLater) => ({
  type: VALIDATE_ASSESSMENT,
  payload: { criteria, supplerendeOplysninger, levested, threat }
})

export const updatePrelimary = (property : string, value : FixMeLater) => ({
  type: UPDATE_PROPERTY,
  payload: {
    section: 'preliminary',
    property,
    value
  }
})

export const updateReduction = (property : string, value : FixMeLater) => ({
  type: UPDATE_PROPERTY,
  payload: {
    section: 'reduction',
    property,
    value
  }
})


export const updateGlobal = (property : string, value : FixMeLater) => ({
  type: UPDATE_PROPERTY,
  payload: {
    section: 'global',
    property,
    value
  }
})

export const updateGeography = (property : string, value : FixMeLater) => ({
  type: UPDATE_PROPERTY,
  payload: {
    section: 'geography',
    property,
    value
  }
})

export const updatePopulation = (property : string, value : FixMeLater) => ({
  type: UPDATE_PROPERTY,
  payload: {
    section: 'population',
    property,
    value
  }
})

export const updateQuantitativeAnalysis = (property : string, value : FixMeLater) => ({
  type: UPDATE_PROPERTY,
  payload: {
    section: 'quantitativeAnalysis',
    property,
    value
  }
})

export const updateUpDownAdjustment = (property : string, value : FixMeLater) => ({
  type: UPDATE_ADJUSTMENT,
  payload: {
    section: 'adjustment',
    property,
    value
  }
})

export const updateUpDownAdjustmentBreeding = (property : string, value : FixMeLater) => ({
  type: UPDATE_ADJUSTMENT_BREEDING,
  payload: {
    section: 'adjustment',
    property,
    value
  }
})

export const updateUpDownAdjustmentVisiting = (property : string, value : FixMeLater) => ({
  type: UPDATE_ADJUSTMENT_VISITING,
  payload: {
    section: 'adjustment',
    property,
    value
  }
})

export const saveSection = (section : SectionTypeEnum, assessmentId : number, body : any) => {
  if (!sectionTypes[section]) {
    console.log("ERROR: Invalid section", section)
  }
  return {
    type: sectionTypes[section],
    payload: {
      section,
      assessmentId,
      body
    }
  }
}

export const sendToQA = (assessmentId : number, category : FixMeLater, categoryChangeReason : FixMeLater) => ({
  type: REQUEST_SEND_TO_QA,
  payload: {
    assessmentId,
    category,
    categoryChangeReason
  }
})

export const setCategoryChange = (categoryChangeReasons : FixMeLater[]) => ({
  type: SET_CATEGORY_CHANGE,
  payload: {
    categoryChangeReasons
  }
})

export const setSingleCategoryChange = (vurderingsrundeId : number, categoryChange : FixMeLater) => ({
  type: SET_SINGLE_CATEGORY_CHANGE,
  payload: {
    vurderingsrundeId,
    categoryChange
  }
})

export const deleteCategoryChange = (vurderingsrundeId : number) => ({
  type: DELETE_CATEGORY_CHANGE,
  payload: {
    vurderingsrundeId
  }
})

export const setCategoryChangeReasons = (vurderingsrundeId : number, reasons : FixMeLater[]) => ({
  type: SET_CATEGORY_CHANGE_REASONS,
  payload: {
    vurderingsrundeId,
    reasons
  }
})

export const setCategoryChangeReasonValue = (vurderingsrundeId : number, reason : FixMeLater, data : FixMeLater) => ({
  type: SET_CATEGORY_CHANGE_REASON_VALUE,
  payload: {
    vurderingsrundeId,
    reason,
    data
  }
})

export const setCategoryChangeReasonComment = (vurderingsrundeId : number, comment : string) => ({
  type: SET_CATEGORY_CHANGE_REASON_COMMENT,
  payload: {
    vurderingsrundeId,
    comment
  }
})

export const saveThreatMeta = (assessmentId : number, threatMeta : (null | { threatsUnknown: (boolean | null) })) => ({
  type: SAVE_THREATMETA,
  payload: {
    assessmentId,
    threatMeta
  }
})

export const saveOverallThreats = (assessmentId : number, overallThreatIds : any[]) => ({
  type: SAVE_OVERALL_THREATS,
  payload: {
    assessmentId,
    overallThreatIds
  }
})

export const saveThreats = (assessmentId : number, threatIds : any[]) => ({
  type: SAVE_THREATS,
  payload: {
    assessmentId,
    threatIds
  }
})

export const addOverallThreat = (assessmentId : number, overallThreatId : FixMeLater) => ({
  type: ADD_OVERALL_THREAT,
  payload: {
    assessmentId,
    overallThreatId
  }
})

export const removeOverallThreat = (assessmentId : number, overallThreatId : FixMeLater) => ({
  type: REMOVE_OVERALL_THREAT,
  payload: {
    assessmentId,
    overallThreatId
  }
})

export const addThreat = (assessmentId : number, threat : FixMeLater) => ({
  type: ADD_THREAT,
  payload: {
    assessmentId,
    threat
  }
})

export const removeThreat = (assessmentId : string, threat : FixMeLater) => ({
  type: REMOVE_THREAT,
  payload: {
    assessmentId,
    threat
  }
})

export const createCommentResponse = (assessmentId : number, phase : string, comment : string) => ({
  type: EDIT_ASSESSMENT_CREATE_COMMENT,
  payload: {
    assessmentId,
    phase,
    comment
  }
})

export const fetchAssessmentComments = (assessmentId : number, phase: string) => ({
  type: FETCH_ASSESSMENT_COMMENTS,
  payload: {
    assessmentId,
    phase
  }
})
