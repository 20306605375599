const question = (property : string, label : string, title? : string) => {
    if (!title) {
        title = label
    }
    return { property, label, title }
}

export const preliminaryQuestions = {
    preliminaryAssessment: question('preliminaryAssessment', 'Indledende vurdering'),
    preliminaryAssessmentRemark: question('preliminaryAssessmentRemark', 'Dokumentatíon til ovenstående fem spørgsmål. SKAL udfyldes med mindre der svares "Ingen af ovenstående (arten skal vurderes nærmere)"')
}

export const reductionQuestions = {
    reductionInPercent: question('reductionInPercent', 'Procentvis tilbagegang', 'Angiv procentvis tilbagegang i en periode på 10 år eller 3 generationer (vælg længste periode)'),
    causesOfReductionAreReversible: question('causesOfReductionAreReversible', 'Årsagerne til tilbagegangen er: - klart reversible'),
    causesOfReductionAreKnown: question('causesOfReductionAreKnown', 'Årsagerne til tilbagegangen er: - klarlagte'),
    causesOfReductionHaveStopped: question('causesOfReductionHaveStopped', 'Årsagerne til tilbagegangen er: - ophørte'),
    reductionHasBeenObserved: question('reductionHasBeenObserved', 'Tilbagegangen er direkte observeret'),
    reductionTimespan: question('reductionTimespan', 'Hvornår er tilbagegangen sket?'),
    reductionIsDerivedFrom: question('reductionIsDerivedFrom', 'Tilbagegangen er skønnet/beregnet/forventet/udledt/formodet/baseret på'),
    reductionRemark: question('reductionRemark', 'Dokumentation til tilbagegangen'),
}

export const geographyQuestions = {
    extentOfOccurrence: question('extentOfOccurrence', 'Udbredelsesareal (km2)', 'Angiv artens skønnede/kendte udbredelsesareal i km2'),
    areaOfOccupancy: question('areaOfOccupancy', 'Forekomstsareal (km2)', 'Angiv artens skønnede/kendte forekomstareal i km2'),
    extentOfTheSpeciesIsFragmented: question('extentOfTheSpeciesIsFragmented', 'Artens udbredelse er stærkt fragmenteret'),
    extentLimitedToNoOfLocations: question('extentLimitedToNoOfLocations', 'Antal lokaliteter', 'Artens udbredelse er begrænset til x lokaliteter (angiv x)'),
    continuedReduction: question('continuedReduction', 'Der er en observeret, formodet eller forudset vedvarende tilbagegang i (1) udbredelsesområde, (2) forekomstareal, (3) antallet af modne individer, (4) areal og/eller kvalitet af habitat eller (5) antallet af lokaliteter/delbestande'),
    extremeFluctuations: question('extremeFluctuations', 'Der er ekstreme udsving i (1) udbredelsesområde, (2) forekomstareal, (3) antallet af lokaliteter/delbestande eller (4) antallet af modne individer'),
    geographicExtentRemark: question('geographicExtentRemark', 'Dokumentation til geografisk udbredelse (SKAL udfyldes, hvis info om geografisk udbredelse er udfyldt)')
}

export const populationQuestions = {
    noOfSexuallyMatureIndividuals: question('noOfSexuallyMatureIndividuals', 'Antal modne individer', 'Angiv antal modne individer'),
    estimatedContinuingReduction: question('estimatedContinuingReduction', 'Der er observeret, forudset eller beregnet en vedvarende tilbagegang i antal modne individer på mindst'),
    subpopulationSizeMatureIndividuals: question('subpopulationSizeMatureIndividuals', 'Der er observeret, forudset eller beregnet en vedvarende tilbagegang i antallet af modne individer OG ingen delbestande beregnes at bestå af mere end (4 valgmuligheder) modne individer'),
    percentMatureIndividuals: question('percentMatureIndividuals', 'Der er observeret, forudset, beregnet eller udledt en vedvarende tilbagegang i antallet af modne individer OG mindst (3 valgmuligheder) procent af alle modne individer findes i én eneste delbestand'),
    extremeFluctuationsInNumberOfMatureIndivduals: question('extremeFluctuationsInNumberOfMatureIndivduals', 'Der er observeret, forudset, beregnet eller udledt en vedvarende tilbagegang i antallet af modne individer OG dette antal svinger ekstremt'),
    noOfSubpopulationsIsMax3: question('noOfSubpopulationsIsMax3', 'Der er observeret, forudset, beregnet eller udledt en vedvarende tilbagegang i antallet af modne individer og antallet af delbestande er højst 3'),
    populationSizeRemark: question('populationSizeRemark', 'Dokumentation', 'Dokumentation til bestandsstørrelse (SKAL udfyldes, hvis info om bestandsstørrelse er udfyldt)')
}


export const quantitativeQuestions = {
    probabilityOfExtinctionByQuantitativeAnalysis: question('probabilityOfExtinctionByQuantitativeAnalysis', 'En kvantitativ analyse viser, at sandsynligheden for at arten uddør i vild tilstand er mindst'),
    quantitativeAnalysisRemark: question('quantitativeAnalysisRemark', 'Dokumentation', 'Dokumentation til kvantitativ analyse (SKAL udfyldes, hvis info om kvantitativ analyse er udfyldt. Angiv altid, hvilken analyse der er anvendt, parametre etc.)')
}

export const adjustmentQuestions = {
    affectedByNeighbours: question('affectedByNeighbours', 'Kan den danske bestand være påvirket af bestande i vore nabolande?'),
    preliminaryAdjustment: question('preliminaryAdjustment', 'På hvilken måde kan arten påvirkes af nabolande?'),
    immigrantPropagulesReproducing: question('immigrantPropagulesReproducing', '3a) Udsættes den danske bestand for en væsentlig tilførsel af spredningsenheder, der er i stand til at formere sig?'),
    decreaseInPropagulesExpected: question('decreaseInPropagulesExpected', '3b) Forventes tilførsel af spredningsenheder at aftage?' ),
    regionalSink: question('regionalSink', '3c) Er den danske bestand afhængig af tilførsel af spredningsenheder fra nabolande?'),
    deterioratingConditionsOutsideRegion: question('deterioratingConditionsOutsideRegion', '3d) Er levevilkårene i nabolandene forringede?'),
    deterioratingConditionsInsideRegion: question('deterioratingConditionsInsideRegion', '3e) Er levevilkårene i Danmark forringede?'),
    sourceSinkDynamicsAcrossBorders: question('sourceSinkDynamicsAcrossBorders', '3f) Kan ynglebestanden redde trækbestanden i Danmark, hvis denne reduceres?'),
    adjustmentRemark: question('adjustmentRemark', 'Kommentar', 'Bemærkninger til op/ned-kategorisering')    
}

export const additionalQuestions = {
    firstRecord: question('firstRecord', 'Årstal for første fund i Danmark'),
    latestRecord: question('latestRecord', 'Årstal for nyeste fund med reproduktion i Danmark'),
    antalLokaliteter: question('antalLokaliteter', 'Hvor mange lokaliteter har arten historisk været kendt fra i Danmark?'),
    trend: question('trend', 'Min ekspertvurdering af artens samlede udviklingstendens (her og nu) er at arten er…'),
    historiskTrend: question('historiskTrend', 'Beskriv historisk trend'),
    geographicExtentAcrossDenmark: question('geographicExtentAcrossDenmark', 'Går artens geografiske udbredelsesgrænse gennem Danmark?'),
    isNative: question('isNative', 'Anses arten for hjemmehørende i Danmark?'),
    nationalForekomst: question('nationalForekomst', 'National forekomst'),
    totaludbredelse: question('totaludbredelse', 'Totaludbredelse'),
    nomenklatur: question('nomenklatur', 'Nomenklatur', 'Bemærkninger til nomenklatur'),
    litteratur: question('litteratur', 'Litteratur'),
    remarkSupplerendeOplysninger: question('remarkSupplerendeOplysninger', 'Bemærkninger til supplerende oplysninger'),
    additionalComments: question('additionalComments', 'Yderligere bemærkninger', 'Yderligere bemærkninger/kommentarer')
}

export const kvitteringQuestions = {
    possiblyRE: question('possiblyRE', 'Er det overvejende sandsynligt at arten er uddød i Danmark (I så fald viser vi kategorien i rødlisten som CR (muligvis RE)?'),
    useArterDkData: question('useArterDkData', 'Har du anvendt data fra arter.dk til at vurdere udbredelses- eller forekomsts-areal')
}

const mark = (value : number, label : string) => ({value, label })

const evenMarks = (labels : string[]) => {
    const min = 0
    const max = 100
    const step = (max - min) / (labels.length - 1)
    return labels.map((label : string, idx : number) => mark((idx) * step, label))
}

export const levestedQuestions = {
    gradienter: {
        header: 'Gradienter',
        fugtighed: {
            property: 'fugtighed',
            label: 'Fugtighed',
            marks: evenMarks(["Meget tørt", "Tørt", "Fugtigt", "Vådt", "Vanddækket"])
        },
        ph: {
            property: 'ph',
            label: 'pH',
            marks: evenMarks(['Meget surt', 'Surt', 'Neutralt', 'Basisk/kalkrigt', 'Meget basisk/ren kalk'])
        },
        nutrientRichness: {
            property: 'nutrientRichness',
            label: 'Næringsstatus',
            marks: evenMarks(['Meget næringsfattigt', 'Næringsfattigt', 'Hverken-eller', 'Næringsrigt', 'Meget næringsrigt'])
        },
        lys: {
            property: 'lys',
            label: 'Lys',
            marks: evenMarks(['Fuld skygge', 'Skygget', 'Halvskygget', 'Lysåbent', 'Fuld sol']) 
        },
        forstyrrelsesFrekvens: {
            property: 'forstyrrelsesFrekvens',
            label: 'Forstyrrelsesfrekvens',
            marks: evenMarks(['Aldrig', 'Næsten aldrig', 'Jævnligt', 'Ofte', 'Næsten hele tiden'])
        },
        forstyrrelsesIntensitet: {
            property: 'forstyrrelsesIntensitet',
            label: 'Forstyrrelsesintensitet',
            marks: evenMarks(['Meget lav', 'Lav', 'Midtimellem', 'Høj', 'Meget høj'])
        },
        positionIVandsoejlen: {
            property: 'positionIVandsoejlen',
            label: 'Position i vandsøjlen',
            marks: evenMarks(['I sedimentet', 'Bundlevende', 'Dermersalt', 'Pelagisk', 'Lige under eller på overfladen'])
        },
        salinitet: {
            property: 'salinitet',
            label: 'Salinitet',
            marks: evenMarks(['0 promille', '', '', '', '34 promille'])
        },
        vanddybde: {
            property: 'vanddybde',
            label: 'Vanddybde',
            marks: evenMarks(['0 m', '65 m', '130 m', '195 m', '260 m'])
        }
    }
}

/*
Ny gradient: “Position i vandsøjlen”. Labels: “I sedimentet”, “Bundlevende”, “Dermersalt”, “Pelagisk”, “Lige under eller på overfladen”

Ny Gradient: “Salinitet”. Labels: “0 promille”, ““, ““, ““, “34 promille”

Ny Gradient: ”Vanddybde”. Labels: “0 m”, “65 m”, “130 m”, “195 m”, “260 m”
*/