import { validationResultFromProperties } from './sectionValidators'
import { trendsObject } from '../enums/supplerendeOplysningerEnums'

export const supplerendeOplysningerValidator = (supplerendeOplysninger, criteria) => {

  const supplerendeTrend = supplerendeOplysninger.trend
  const geography = criteria.geography
  const continuedReduction = geography && geography.continuedReduction

  /* 
  Hvis man har svaret ja til vedvarende tilbagegang under "Geografisk udbredelse" 
  og så svarer "stabil" eller "i fremgang" eller "ukendt" under "Min ekspertvurdering 
  af artens samlede udviklingstendens (her og nu) er at arten er…" under Supplerende oplysninger, 
  skal der gives en advarsel: der er ikke overensstemmelse mellem dine svar på de to spørgsmål, 
  er du sikker på du har svaret rigtigt? Konsultér evt. manualen eller hjælpeknapperne"
  */
  if (continuedReduction) {
    if (supplerendeTrend === trendsObject.stabil.value || supplerendeTrend === trendsObject.fremgang.value) {
      return {
        ok: true,
        global: true,
        warning: true,
        empty: false,
        message: `Der er ikke overensstemmelse mellem dine svar om vedvarende tilbagegang (under punktet "Geografisk Udbredelse") og artens udviklingstendens. Er du sikker på du har svaret rigtigt? Konsultér evt. manualen eller hjælpeknapperne`,
      }
    }
  }

  return validationResultFromProperties({}, true)
}
