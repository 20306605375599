import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import {
      MANAGE_PUBLICERINGER_PAGE_SET_PUBLICERINGER, MANAGE_PUBLICERINGER_PAGE_SET_PUBLISH_PROGRESS, MANAGE_PUBLICERINGER_PAGE_SET_SELECTED_PUBLICATION,
} from "./managePubliceringerPageActions"

const initialState = {
      publications: [],
      selectedPublication: null,
      progress: {}
}

const managePubliceringerPageReducer = (state = initialState, action : FixMeLater) => {
      switch (action.type) {
            case MANAGE_PUBLICERINGER_PAGE_SET_PUBLICERINGER:
                  return {
                        ...state, publications: action.payload.publications
                  }
            case MANAGE_PUBLICERINGER_PAGE_SET_SELECTED_PUBLICATION:
                  return { 
                        ...state, selectedPublication: action.payload.publication
                  }
            case MANAGE_PUBLICERINGER_PAGE_SET_PUBLISH_PROGRESS:
                  return {
                        ...state, progress: action.payload
                  }
            default:
                  return state
      }
}

export default managePubliceringerPageReducer