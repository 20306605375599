import React from 'react'
import {
    Typography
} from '@material-ui/core'

export type FixMeLater = any;

export interface GlobalErrorMessageProps {
    validationResult : FixMeLater;
}

const GlobalErrorMessage = (props : GlobalErrorMessageProps) => {
    const { validationResult } = props
    if (!validationResult) {
        return null
    }    
    if (validationResult.warning === true && validationResult.global === true) {
        return <Typography color='secondary'>{validationResult.message}</Typography>
    }
    if (validationResult.ok === true || validationResult.global !== true) {
        return null
    }
    return (
        <Typography color='error'>{validationResult.message}</Typography>
    )
}

export default GlobalErrorMessage