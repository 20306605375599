import React from 'react'
import { useEffect, useState } from 'react'

import {
    Grid,
    Button,
    Typography, TextField
} from '@material-ui/core'
import Select from 'react-select'


const toOption = user => {
    if (!user) {
        return null
    }
    return { id: user.id, value: user.id + '', label: user.name }
}

const EditAssessorDialog = props => {
    const { assessor, users, onClose, onSave } = props
    //console.log(users)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [name, setName] = useState('')

    useEffect(() => {
        if (assessor) {
            setSelectedUsers(assessor.users.map(u => toOption(u)))
        }
    }, [ setSelectedUsers, assessor, users])

    //console.log(selectedUsers)
    //console.log({users})
    if (!users) {
        return null
    }

    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Rediger {assessor && assessor.name}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Brugere</Typography>
                    <Select                    
                        isMulti    
                        placeholder='Select assessors...'
                        options={users.map(toOption)}
                        value={selectedUsers}
                        onChange={value => setSelectedUsers(value)} />
                </Grid>              
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Navn'
                        value={name}                       
                        onChange={evt => setName(evt.target.value)} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onSave({ users: selectedUsers, name })}  fullWidth variant='contained' color='primary'>Gem</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default EditAssessorDialog