import {
  SET_ASSESSMENT_CRITERIA
} from '../editAssessment/editAssessmentActions'

import {
  SET_CATEGORY
} from "../actions/categoryActions";
import categoryEngine from '../../service/categoryEngine'

// this middleware care only for category
export const processRequestCategory = ({  dispatch, getState }) => next => action => {
  next(action)

  if(action.type === SET_ASSESSMENT_CRITERIA) {    
    const assessmentCriteria = action.payload
    const fullresult = categoryEngine.calculate(assessmentCriteria)
    const result = fullresult.getHighestResult();
    const modifiedCategory = categoryEngine.Categories.adjust(result.category, result.modification)
    dispatch({
      type: SET_CATEGORY,
      payload: { category: { ...result, modifiedCategory }}
    })
  }
};

export const categoryMdl = [  
  processRequestCategory
];