import React from 'react'
import { LabelledValue, booleanConverterHideNull, Section } from '../../../mui/Section'

import { geographyQuestions } from '../questions'
import GeographyMapViewComponent from '../../geography/GeographyMapViewComponent'
import { FixMeLater } from '../../components/GlobalErrorMessage'

const B_GeografiskUdbredelseView = (props : FixMeLater) => {
    const geography = props.geography || {}    
    const {showNull, showtitle, showMap } = props
    return (
        <Section title={showtitle && 'Geografisk udbredelse'}>
            { showMap && <GeographyMapViewComponent /> }
            <LabelledValue showNull={showNull} label={geographyQuestions.extentOfOccurrence.label} value={geography.extentOfOccurrence} />
            <LabelledValue showNull={showNull} label={geographyQuestions.areaOfOccupancy.label} value={geography.areaOfOccupancy} />
            <LabelledValue showNull={showNull} label={geographyQuestions.extentOfTheSpeciesIsFragmented.label} value={geography.extentOfTheSpeciesIsFragmented} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={geographyQuestions.extentLimitedToNoOfLocations.label} value={geography.extentLimitedToNoOfLocations} />
            <LabelledValue showNull={showNull} label={geographyQuestions.continuedReduction.label} value={geography.continuedReduction} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} label={geographyQuestions.extremeFluctuations.label} value={geography.extremeFluctuations} converter={booleanConverterHideNull} />
            <LabelledValue showNull={showNull} fullWidth label={geographyQuestions.geographicExtentRemark.label} value={geography.geographicExtentRemark} />
        </Section>
    )
}

export default B_GeografiskUdbredelseView