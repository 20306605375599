import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
  VIEW_ASSESSMENTS_PAGE_SET_FILTER,
  VIEW_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
  VIEW_ASSESSMENTS_PAGE_SET_ASSESSORS,
  VIEW_ASSESSMENTS_PAGE_SET_SORT_ORDER,
} from './viewAssessmentsPageActions'

const initialState = {
  filter: {
    page: 1,
    limit: 25,
    vurderingsrundeId: 2030,
    statusList: ['ny', 'kladde', 'teknisk_genvurdering', 'faglig_genvurdering', 'endelig_genvurdering'],
    speciesName: '',
    speciesFamily: '',
    listAll: false
  },
  sortOrder: 'scientificName',
  assessors: [],
  assessments: [],
  pages: 1,
  count: 0,
}

const viewAssessmentsPageReducer = (state = initialState, action : FixMeLater) => {
  switch (action.type) {
    case VIEW_ASSESSMENTS_PAGE_SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload.assessments,
        count: action.payload.count,
        pages: action.payload.pages,
      }
    case VIEW_ASSESSMENTS_PAGE_SET_SORT_ORDER:
      return { ...state, sortOrder: action.payload }
    case VIEW_ASSESSMENTS_PAGE_SET_FILTER:
      return { ...state, filter: action.payload }
    case VIEW_ASSESSMENTS_PAGE_SET_ASSESSORS:
      return { ...state, assessors: action.payload.assessors }
    default:
      return state
  }
}

export default viewAssessmentsPageReducer
