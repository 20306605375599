import React from 'react'

import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'

const LabelledCheckbox = props => (
    <FormControlLabel
        control={<Checkbox checked={props.checked} onChange={props.onChange} name={props.name} />}
        label={props.label}
    />)

const CheckboxGroup = props => {
    const { options, selectedOptions, onChange } = props

    const handleChange = (option, checked) => {
        const newOptions = selectedOptions.filter(o => o.value !== option.value)
        if (checked) {
            newOptions.push(option)
        }
        onChange(newOptions, option, checked)
    }

    return (
        <FormControl>
            <FormGroup>
                {options.map(o => <LabelledCheckbox key={o.value} checked={Boolean(selectedOptions.find(e => e.value === o.value))} name={o.label} label={o.label} onChange={(evt, checked) => handleChange(o, checked)} />)}
            </FormGroup>
        </FormControl>
    )
}

export default CheckboxGroup