import React from 'react'

import ListPage from '../../genericCrud/ListPage'

const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Navn', property: 'name' },
        { header: 'Orden', property: 'order.name' }
    ],
    selectable: false
}

const crudConfig = {
    key: 'speciesFamily',
    createTitle: 'Opret familie',
    filter: [
        { label: 'Navn', property: 'name' }
    ],
    properties: [
        { label: 'Navn', property: 'name', type: 'string', required: true },      
        { 
            label: 'Orden', 
            property: 'speciesOrderId', 
            required: true,
            type: 'list', 
            reference: {                
                parent: 'order',
                idProperty: 'id',
                valueProperty : 'name',
                key: 'speciesOrder'
            }
        }
    ]
}

const ManageSpeciesFamily = () => {
    return (
        <ListPage pageTitle='Administrer Familier' crudConfig={crudConfig} tableConfig={tableConfig} />
    )
}

export default ManageSpeciesFamily