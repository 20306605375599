import React from 'react'
//import { useSelector } from 'react-redux'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import CheckboxButton from '../../mui/CheckboxButton'
import { ThemeProvider } from 'styled-components'

const buttonTheme = {
    background: '#9575cd',
    color: '#fff',
    borderColor: '#7e57c2',
    checked: {
        background: '#673ab7',
        color: '#fff',
        borderColor: '#7e57c2'
    }
}

const renderSubstrat = (substrat, selectedSubstrates, onChange) => {
    let selected = selectedSubstrates.filter(e => {
        return e.id === substrat.id;
    }).length > 0;
    return (
        <Grid key={substrat.id} item xs={12} sm={6} md={4}>
            <CheckboxButton
                fullwidth
                onChange={checked => onChange(substrat, checked)}
                label={substrat.name}
                checked={selected} />
        </Grid>
    )
}

const OverordnedeSubstrater = (props) => {
    const { selectedOverordnedeSubstrater, hovedmiljoer, overordnedeSubstrater, onSelectedChanged, onProceed } = props;
    
    console.log(overordnedeSubstrater, hovedmiljoer)
    const availableOverordnedeSubstrater = overordnedeSubstrater.filter(s => {
        if (hovedmiljoer.length === 0) {
            return true
        }
        return hovedmiljoer.find(h => s.hovedmiljoer.find(sh => {
            return sh === h.slug
        }))
    })
    let selectionChanged = (overordnetSubstrat, checked) => {
        let _t = selectedOverordnedeSubstrater.filter(s => s.id !== overordnetSubstrat.id);
        if (checked) {
            _t = _t.concat([overordnetSubstrat]);
        }
        onSelectedChanged(_t);
    }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} lg={11} spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>
                        Angiv overordnede substrater
                </Typography>
                </Grid>
                <ThemeProvider theme={buttonTheme}>
                    {
                        availableOverordnedeSubstrater.map(e => renderSubstrat(e, selectedOverordnedeSubstrater, selectionChanged))
                    }
                </ThemeProvider>
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={() => onProceed()} color='primary' variant='contained'>Videre</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OverordnedeSubstrater