import React from 'react'
import {
    Grid,
    Button,
    Typography, TextField, LinearProgress
} from '@material-ui/core'
import { useState } from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'

const nonEmptyValidator = value => {
    if (!value) {
        return { ok: false, message:'Der skal indtastes en værdi' }
    }
    return { ok: true}
} 

const noSpacesValidator = value => {
    if (value.includes(' ')) {
        return {ok: false, message:  'Mellemrum ikke tilladt' }
    }
    return {ok: true}
}

const combineValidators = validators => {
    return value => {
        let result = { ok: true }
        validators.forEach(v => {
            if (result.ok) {
                result = v(value)
            }
        })
        return result;
    }
}


const CreateUserDialog = props => {
    const { onClose, onSave } = props
    const { createUserError } = useSelector(state => state.manageUserPage)
    const { saving } = useSelector(state => state.ui)
    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')
    
    const usernameValid = combineValidators([ nonEmptyValidator, noSpacesValidator ])(username)
    const nameValid = nonEmptyValidator(name)
    const emailValid = nonEmptyValidator(email)
    const roleValid = nonEmptyValidator(role)
    const passwordValid = combineValidators([ nonEmptyValidator, noSpacesValidator ])(password)

    const valid = usernameValid.ok && nameValid.ok && emailValid.ok && roleValid.ok && passwordValid.ok
    console.log('rv', roleValid)
    
    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Opret bruger</Typography>
            <LinearProgress variant={ saving ? 'indeterminate' : 'determinate'} value={0} />
            <Grid container spacing={2}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <Select                        
                        options={[{ value: 'admin', label: 'Admin' }, { value: 'godkender', label: 'Godkender' },{ value: 'ekspert', label: 'Ekspert' }]}
                        onChange={value => setRole(value.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Username'
                        value={username}
                        error={Boolean(username && !usernameValid.ok)}
                        helperText={usernameValid.message}
                        onChange={evt => setUsername(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Navn'
                        value={name}
                        error={Boolean(name && !nameValid.ok)}
                        helperText={nameValid.message}
                        onChange={evt => setName(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Email'
                        error={Boolean(email && !emailValid.ok)}
                        helperText={emailValid.message}
                        value={email}
                        onChange={evt => setEmail(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Password'
                        error={Boolean(password && !passwordValid.ok)}
                        helperText={passwordValid.message}
                        value={password}
                        onChange={evt => setPassword(evt.target.value)} />
                </Grid>
                { createUserError ? <Grid item xs={12}>
                    <Typography variant='body1' color='error'>An error occurred while trying to create the user</Typography>
                </Grid> : null }
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onSave({username, name, email, password, role})} disabled={!valid} fullWidth variant='contained' color='primary'>Opret</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateUserDialog