import { FixMeLater } from "../assessments/components/GlobalErrorMessage";
import { AssessmentCriteria } from "../redux/reducers/editAssessmentRed";

export type CategoryEnumType = 'NE' | 'NA' | 'DD' | 'RE' | 'CR' | 'EN' | 'VU' | 'NT' | 'LC'

export interface CategoryType {
    name: CategoryEnumType;
    value: number;
    threatened: boolean;
}

export const Categories : FixMeLater = {
    NE: {
        name: 'NE',
        value: 0,
        threatened: false
    },
    NA: {
        name: 'NA',
        value: 1,
        threatened: false
    },
    RE: {
        name: 'RE',
        value: 2,
        threatened: true
    },
    DD: {
        name: 'DD',
        value: 3,
        threatened: true
    },
    LC: {
        name: 'LC',
        value: 4,
        threatened: false
    },
    NT: {
        name: 'NT',
        value: 5,
        threatened: true
    },
    VU: {
        name: 'VU',
        value: 6,
        threatened: true
    },
    EN: {
        name: 'EN',
        value: 7,
        threatened: true
    },
    CR: {
        name: 'CR',
        value: 8,
        threatened: true
    },
    adjust: function (category : CategoryType, amount: number) {
        if (!category || category.value < 4) {
            return category;
        }
        let newValue = category.value + amount;
        if (newValue < 4) {
            newValue = 4;
        } else if (newValue > 8) {
            newValue = 8;
        }
        for (var key in Categories) {
            let e = Categories[key] as CategoryType;
            if (e.value === newValue) {
                return e;
            }
        }
        return;
    },
    min: function (c1 : CategoryType, c2 : CategoryType) {
        if (c1.value < c2.value) {
            return c1;
        } else {
            return c2;
        }
    },
    max: function (c1 : CategoryType, c2 : CategoryType) {
        if (c1.value > c2.value) {
            return c1;
        } else {
            return c2;
        }
    },
    parse: function (str : string) {
        for (var key in Categories) {
            if (key === str) {
                return Categories[key];
            }
        }
        return null;
    }
}

let redlistCategoryEngine = function () {
    const PreliminaryAdjustmentEnum = {
        IKKE_ANGIVET: "ikke_angivet", 
        KOLONISERING: "kolonisering", 
        DEMOGRAFISK_USTABIL: "demografisk_ustabil", 
        BREEDING: "breeding",
        VISITING: "visiting"
    }

    const PreliminaryAssessmentEnum = {
            Null: "Null",
            IrrelevantToAssess: "IrrelevantToAssess",
            RegionallyExtinct: "RegionallyExtinct",
            InsufficientData: "InsufficientData",
            AbsolutelyNotThreatened: "AbsolutelyNotThreatened",
            NotEvaluated: "NotEvaluated",
            Assessed: "Assessed"               
    }

    let ProbabilityOfExtinctionByQuantitativeAnalysisEnum = {
        None: "0",        
        VeryHighProbability: "1",
        HighProbability: "2",
        MediumProbability: "3",
        LowProbability: "4"
    };

    let ReductionIsDerivedFromEnum = {
        None: "0",
        SpeciesPopulationIndex: "1",
        HabitatChanges: "2",
        HumanExploitationOfSpecies: "3",
        BioticInteractions: "4",
    };

    let ReductionTimespanEnum = {
        None: "0",
        OccuredWithin10YearsOr3Gens: "1",
        IsOngoingWithin10YearsOr3Gens: "2",
        WillOccurWithin10YearsOr3Gens: "3"
    };

    let EstimatedContinuingReductionEnum = {
        None: "0",
        TwentyfiveIn3YearOr1Gen: "1",
        TwentyIn5YearOr2Gen: "2",
        TenIn10YearOr3Gen: "4",
        FiveIn10YearOr3Gen: "8"
    };

    

    let a = {
        _reasons: [
      function (input : AssessmentCriteria) {
                if (input.reduction.reductionHasBeenObserved === true) {
                    return {
                        fulfilled: true,
                        name: 'a',
                        applies: function (name : string) {
                            if (name === 'a3') return false;
                            else return true
                        }
                    }
                }
                return false;
      },
      function (input : AssessmentCriteria) {
                if (input.reduction.reductionIsDerivedFrom === ReductionIsDerivedFromEnum.SpeciesPopulationIndex) {
                    return {
                        fulfilled: true,
                        name: 'b',
                        applies: function () {
                            return true;
                        }
                    }
                }
                return false;
      },
      function (input : AssessmentCriteria) {
                if (input.reduction.reductionIsDerivedFrom === ReductionIsDerivedFromEnum.HabitatChanges) {
                    return {
                        fulfilled: true,
                        name: 'c',
                        applies: function () {
                            return true;
                        }
                    }
                }
                return false;
      },
      function (input : AssessmentCriteria) {
                if (input.reduction.reductionIsDerivedFrom === ReductionIsDerivedFromEnum.HumanExploitationOfSpecies) {
                    return {
                        fulfilled: true,
                        name: 'd',
                        applies: function () {
                            return true;
                        }
                    }
                }
                return false;
      },
      function (input : AssessmentCriteria) {
                if (input.reduction.reductionIsDerivedFrom === ReductionIsDerivedFromEnum.BioticInteractions) {
                    return {
                        fulfilled: true,
                        name: 'e',
                        applies: function () {
                            return true;
                        }
                    }
                }
                return false;
      }
    ],
        a1: {
            evaluate: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                if (reduction.reductionTimespan === ReductionTimespanEnum.OccuredWithin10YearsOr3Gens) {
                    return (reduction.causesOfReductionAreReversible 
                        && reduction.causesOfReductionAreKnown 
                        && reduction.causesOfReductionHaveStopped 
                        && reduction.reductionInPercent >= 30);
                } else {
                    return false;
                }
            },
            category: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                let route = callReasons(input, a._reasons, "a1");
                let result = {
                    category: Categories.LC,
                    route: route
                }
                if (reduction.reductionInPercent >= 90) {
                    result.category = Categories.CR;
                } else if (reduction.reductionInPercent >= 70) {
                    result.category = Categories.EN;
                } else if (reduction.reductionInPercent >= 50) {
                    result.category = Categories.VU;
                } else if (reduction.reductionInPercent >= 30) {
                    result.category = Categories.NT;
                } else {
                    result.category = Categories.LC;
                }
                return result;
            }
        },
        a2: {
            evaluate: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                if (reduction.reductionTimespan === ReductionTimespanEnum.OccuredWithin10YearsOr3Gens) {
                    return (reduction.causesOfReductionAreReversible === false 
                        || reduction.causesOfReductionAreKnown === false 
                        || reduction.causesOfReductionHaveStopped === false)
                } else {
                    return false;
                }
            },
            category: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                let route = callReasons(input, a._reasons, "a2");
                let result = {
                    category: Categories.LC,
                    route: route
                }
                if (reduction.reductionInPercent >= 80) {
                    result.category = Categories.CR;
                } else if (reduction.reductionInPercent >= 50) {
                    result.category = Categories.EN;
                } else if (reduction.reductionInPercent >= 30) {
                    result.category = Categories.VU;
                } else if (reduction.reductionInPercent >= 10) {
                    result.category = Categories.NT;
                } else {
                    result.category = Categories.LC;
                }
                return result;
            }
        },
        a3: {
            evaluate: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                return (reduction.reductionTimespan === ReductionTimespanEnum.WillOccurWithin10YearsOr3Gens);
            },
            category: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                let route = callReasons(input, a._reasons, "a3");
                let result = {
                    category: Categories.LC,
                    route: route
                }
                if (reduction.reductionInPercent >= 80) {
                    result.category = Categories.CR;
                } else if (reduction.reductionInPercent >= 50) {
                    result.category = Categories.EN;
                } else if (reduction.reductionInPercent >= 30) {
                    result.category = Categories.VU;
                } else if (reduction.reductionInPercent >= 10) {
                    result.category = Categories.NT;
                } else {
                    result.category = Categories.LC;
                }
                return result;
            }
        },
        a4: {
            evaluate: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                if (reduction.reductionTimespan === ReductionTimespanEnum.IsOngoingWithin10YearsOr3Gens) {
                    return (reduction.causesOfReductionAreReversible === false 
                        || reduction.causesOfReductionAreKnown ===false
                        || reduction.causesOfReductionHaveStopped === false)
                }
                return false;
            },
            category: function (input : AssessmentCriteria) {
                const reduction = input.reduction || {}
                let route = callReasons(input, a._reasons, "a4");
                let result = {
                    category: Categories.LC,
                    route: route
                }
                if (reduction.reductionInPercent >= 80) {
                    result.category = Categories.CR;
                } else if (reduction.reductionInPercent >= 50) {
                    result.category = Categories.EN;
                } else if (reduction.reductionInPercent >= 30) {
                    result.category = Categories.VU;
                } else if (reduction.reductionInPercent >= 10) {
                    result.category = Categories.NT;
                } else {
                    result.category = Categories.LC;
                }
                return result;
            }
        }
    }

    let b = {
        _conditions: {
            a: function (input : AssessmentCriteria) {
                const geography = input.geography || {}
                if (geography.extentOfTheSpeciesIsFragmented) {
                    return {
                        fulfilled: true,
                        maxCategory: Categories.CR,
                        minCategory: Categories.LC
                    };
                }
                if (!geography.extentLimitedToNoOfLocations) {
                    return false;
                }
                // eslint-disable-next-line
                if (geography.extentLimitedToNoOfLocations == 1) {
                    return {
                        fulfilled: true,
                        maxCategory: Categories.CR,
                        minCategory: Categories.LC
                    };
                } else if (geography.extentLimitedToNoOfLocations <= 5) {
                    return {
                        fulfilled: true,
                        maxCategory: Categories.EN,
                        minCategory: Categories.LC
                    };
                } else if (geography.extentLimitedToNoOfLocations <= 10) {
                    return {
                        fulfilled: true,
                        maxCategory: Categories.VU,
                        minCategory: Categories.LC
                    };
                } else if (geography.extentLimitedToNoOfLocations < 25) {
                    return {
                        fulfilled: true,
                        maxCategory: Categories.NT,
                        minCategory: Categories.LC
                    };
                }
                return {
                    fulfilled: false
                };
            },
            b: function (input : AssessmentCriteria) {
                const geography = input.geography || {}
                if (geography.continuedReduction) {
                    return {
                        fulfilled: true
                    }
                } else {
                    return {
                        fulfilled: false
                    };
                }
            },
            c: function (input : AssessmentCriteria) {
                const geography = input.geography || {}
                if (geography.extremeFluctuations) {
                    return {
                        fulfilled: true
                    }
                } else {
                    return {
                        fulfilled: false
                    }
                }
            },
            testConditions: function (input : AssessmentCriteria) {
                let result : FixMeLater = {
                    fulfilled: [],
                    maxCategory: Categories.CR,
                    minCategory: Categories.LC
                }
                //let fulfilledResults = [];
                for (var key in b._conditions) {
                    if (key.startsWith("test")) {
                        continue;
                    }
                    let current = (b as FixMeLater)._conditions[key];
                    let tmp = current(input);
                    if (tmp.fulfilled) {
                        result.fulfilled.push({ name: key, result: tmp });
                        if (tmp.maxCategory && tmp.maxCategory.value < result.maxCategory.value) {
                            result.maxCategory = tmp.maxCategory;
                        }
                        if (tmp.minCategory && tmp.minCategory.value > result.minCategory.value) {
                            result.minCategory = tmp.minCategory;
                        }
                    }
                }
                if (result.fulfilled.length === 3) {
                    result.maxCategory = Categories.CR;
                }
                return result;
            }
        },
        b1: {
            evaluate: function (input : FixMeLater) {
                const geography = input.geography || {}
                return (
                    (geography.extentOfOccurrence > 0 && geography.extentOfOccurrence < 30000)
                     && 
                     b._conditions.testConditions(input).fulfilled.length >= 1);
            },
            category: function (input : FixMeLater) {
                const geography = input.geography || {}
                let condition = b._conditions.testConditions(input);
                let result = {
                    category: Categories.LC,
                    route: condition.fulfilled.map(function(f : FixMeLater) { return f.name; })
                }

                if (condition.fulfilled.length === 1) {
                    if (geography.extentOfOccurrence < 20000 && condition.maxCategory.value > Categories.NT.value) {
                        result.category = Categories.NT;
                        result.route = condition.fulfilled.map(function(f : FixMeLater) { return f.name; });;
                        return result;
                    } else {
                        result.category = Categories.LC;
                        result.route = [];
                        return result;
                    }
                }

                if (geography.extentOfOccurrence < 100) {
                    result.category = Categories.min(Categories.CR, condition.maxCategory);
                } else if (geography.extentOfOccurrence < 5000) {
                    result.category = Categories.min(Categories.EN, condition.maxCategory);
                } else if (geography.extentOfOccurrence < 20000) {
                    result.category = Categories.min(Categories.VU, condition.maxCategory);
                } else if (geography.extentOfOccurrence < 30000) {
                    result.category = Categories.min(Categories.NT, condition.maxCategory);
                }
                let fulfilled = condition.fulfilled.filter( function(f : FixMeLater) {
                  return (!f.result.maxCategory || f.result.maxCategory.value >= result.category.value);
                });
                result.route = fulfilled.map(function(f : FixMeLater) { return f.name; });
                return result;
            }
        },
        b2: {
            evaluate: function (input : FixMeLater) {
                const geography = input.geography || {}
                return (
                    (geography.areaOfOccupancy > 0 && geography.areaOfOccupancy < 3000) 
                    && b._conditions.testConditions(input).fulfilled.length >= 1);
            },
            category: function (input : FixMeLater) {
                const geography = input.geography || {}

                let condition = b._conditions.testConditions(input);
                let result = {
                    category: Categories.LC,
                    route: condition.fulfilled.map(function(f : FixMeLater) { return f.name; })
                }

                if (condition.fulfilled.length === 1) {
                    if (geography.areaOfOccupancy < 2000 && condition.maxCategory.value > Categories.NT.value) {
                        result.category = Categories.NT;
                        result.route = condition.fulfilled.map(function(f : FixMeLater) { return f.name; });
                        return result;
                    } else {
                        return result;
                    }
                }

                if (geography.areaOfOccupancy < 10) {
                    result.category = Categories.min(Categories.CR, condition.maxCategory);
                } else if (geography.areaOfOccupancy < 500) {
                    result.category = Categories.min(Categories.EN, condition.maxCategory);
                } else if (geography.areaOfOccupancy < 2000) {
                    result.category = Categories.min(Categories.VU, condition.maxCategory);
                } else if (geography.areaOfOccupancy < 3000) {
                    result.category = Categories.min(Categories.NT, condition.maxCategory);
                }
                let fulfilled = condition.fulfilled.filter( function(f : FixMeLater) {
                  return (!f.result.maxCategory || f.result.maxCategory.value >= result.category.value);
                });
                result.route = fulfilled.map(function(f : FixMeLater) { return f.name; });
                return result;
            }
        }
    }

    let c = {
        _c2Routes: [
              // a(i)
              function (input : FixMeLater) {
                const population = input.population || {}
                let result : FixMeLater = {
                    name: 'a(i)',
                    fulfilled: false
                };
                const subpopulationSizeMatureIndividuals = population.subpopulationSizeMatureIndividuals ? parseInt(population.subpopulationSizeMatureIndividuals) : 0                              
                if (subpopulationSizeMatureIndividuals > 0 && subpopulationSizeMatureIndividuals <= 50) {
                    result.maxCategory = Categories.CR;
                    result.fulfilled = true;
                } else if (subpopulationSizeMatureIndividuals > 0 && subpopulationSizeMatureIndividuals <= 250) {
                    result.maxCategory = Categories.EN;
                    result.fulfilled = true;
                } else if (subpopulationSizeMatureIndividuals && subpopulationSizeMatureIndividuals <= 1000) {
                    result.maxCategory = Categories.VU;
                    result.fulfilled = true;
                } else if (subpopulationSizeMatureIndividuals && subpopulationSizeMatureIndividuals <= 2000) {
                    result.maxCategory = Categories.NT;
                    result.fulfilled = true;
                }
                return result;
              },
              function (input : FixMeLater) {
                const population = input.population || {}
                let result : FixMeLater = {
                    fulfilled: false,
                    name: 'a(ii)'
                }
                const percentMatureIndividualsInSingleSubpopulation = population.percentMatureIndividualsInSingleSubpopulation ? parseInt(population.percentMatureIndividualsInSingleSubpopulation) : null
                if (percentMatureIndividualsInSingleSubpopulation === null) {
                    // Hack to please typescript
                } else if (percentMatureIndividualsInSingleSubpopulation === 100) {
                    result.maxCategory = Categories.VU;
                    result.fulfilled = true;
                } else if (percentMatureIndividualsInSingleSubpopulation >= 95) {
                    result.maxCategory = Categories.EN;
                    result.fulfilled = true;
                } else if (percentMatureIndividualsInSingleSubpopulation >= 90) {
                    result.maxCategory = Categories.CR;
                    result.fulfilled = true;
                }
                return result;
              },
              function (input : FixMeLater) {
                // b
                const population = input.population || {}
                if (population.extremeFluctuationsInNumberOfMatureIndivduals) {
                    return {
                        fulfilled: true,
                        name: 'b',
                        maxCategory: Categories.CR
                    };
                }
                return false;
              }
    ],
        c1: {
            evaluate: function (input : FixMeLater) {
                const population = input.population || {}
                if (population.noOfSexuallyMatureIndividuals >= 15000) {
                    return false;
                }
                return population.estimatedContinuingReduction !== EstimatedContinuingReductionEnum.None;
            },
            category: function (input : FixMeLater) {
                const population = input.population || {}
                let result = {
                    category: Categories.LC,
                    route: []
                }
                let continuingReductionCategory = Categories.LC;
                if (population.estimatedContinuingReduction === EstimatedContinuingReductionEnum.TwentyfiveIn3YearOr1Gen) {
                    continuingReductionCategory = Categories.CR;
                } else if (population.estimatedContinuingReduction === EstimatedContinuingReductionEnum.TwentyIn5YearOr2Gen) {
                    continuingReductionCategory = Categories.EN;
                } else if (population.estimatedContinuingReduction === EstimatedContinuingReductionEnum.TenIn10YearOr3Gen) {
                    continuingReductionCategory = Categories.VU;
                } else if (population.estimatedContinuingReduction === EstimatedContinuingReductionEnum.FiveIn10YearOr3Gen) {
                    continuingReductionCategory = Categories.NT;
                }
                let matureIndividualsCategory = Categories.LC;
                if (population.noOfSexuallyMatureIndividuals < 250) {
                    matureIndividualsCategory = Categories.CR;
                } else if (population.noOfSexuallyMatureIndividuals < 2500) {
                    matureIndividualsCategory = Categories.EN;
                } else if (population.noOfSexuallyMatureIndividuals < 10000) {
                    matureIndividualsCategory = Categories.VU;
                } else if (population.noOfSexuallyMatureIndividuals < 15000) {
                    matureIndividualsCategory = Categories.NT;
                }
                if (continuingReductionCategory.value <= Categories.NT.value && matureIndividualsCategory.value <= Categories.NT.value) {
                  // To return NT we need at least one of the conditions to be more severe than NT
                  result.category = Categories.LC;
                } else {
                  result.category = Categories.min(continuingReductionCategory, matureIndividualsCategory);
                }
                return result;
            }
        },
        c2: {
            evaluate: function (input : FixMeLater) {
                const population = input.population || {}
                if (population.noOfSexuallyMatureIndividuals >= 15000) {
                    return false;
                }
                let routes = callRoutes(input, c._c2Routes);
                return (routes.length > 0);
            },
            category: function (input : FixMeLater) {
                const population = input.population || {}
                let routes = callRoutes(input, c._c2Routes);
                let c2RoutesCategory = Categories.LC;
                let route = [];
                for (var i = 0; i < routes.length; i += 1) {
                    let e = routes[i];
                    if (e.maxCategory && e.maxCategory.value > c2RoutesCategory.value) {
                        c2RoutesCategory = e.maxCategory;
                        route = [e.name];
                    } else if (e.maxCategory && e.maxCategory.value === c2RoutesCategory.value) {
                        route.push(e.name);
                    }
                }
                let matureIndividualsCategory = Categories.LC;
                if (population.noOfSexuallyMatureIndividuals < 250) {
                    matureIndividualsCategory = Categories.CR;
                } else if (population.noOfSexuallyMatureIndividuals < 2500) {
                    matureIndividualsCategory = Categories.EN;
                } else if (population.noOfSexuallyMatureIndividuals < 10000) {
                    matureIndividualsCategory = Categories.VU;
                } else if (population.noOfSexuallyMatureIndividuals < 15000 && c2RoutesCategory.value > Categories.NT.value) {
                  // We only pick NT here if the a(i), a(ii) or b route return worse than NT
                   matureIndividualsCategory = Categories.NT;
                }
                let cat = Categories.min(matureIndividualsCategory, c2RoutesCategory);
                let result = {
                    category: cat,
                    route: route
                }
                return result;
            }
        }
    }

    let d = {
        d: {
            evaluate: function (input : FixMeLater) {
                const population = input.population || {}
                if (population.noOfSexuallyMatureIndividuals && population.noOfSexuallyMatureIndividuals < 1500) {
                    return true;
                }
                return false;
            },
            category: function (input : FixMeLater) {
                const population = input.population || {}
                let result = {
                    category: Categories.LC,
                    route: []
                }
                if (population.noOfSexuallyMatureIndividuals < 50) {
                    result.category = Categories.CR;
                } else if (population.noOfSexuallyMatureIndividuals < 250) {
                    result.category = Categories.EN;
                } else if (population.noOfSexuallyMatureIndividuals < 1000) {
                    result.category = Categories.VU;
                } else if (population.noOfSexuallyMatureIndividuals < 1500) {
                    result.category = Categories.NT;
                }
                return result;
            }
        },
        d2: {
            evaluate: function (input : FixMeLater) {
                const geography = input.geography || {}
              
                if ((geography.areaOfOccupancy && geography.areaOfOccupancy < 20) ||
                    (geography.extentLimitedToNoOfLocations && geography.extentLimitedToNoOfLocations <= 5)) {
                    return true;
                }
                return false;
            },
            category: function (input : FixMeLater) {
                const geography = input.geography || {}
                let result : FixMeLater = {
                    route: []
                }
                if (geography.areaOfOccupancy < 20 || geography.extentLimitedToNoOfLocations <= 5) {
                    result.category = Categories.VU;
                }
                return result;
            }
        }
    }

    let e = {
        e: {
            evaluate: function (input : FixMeLater) {
                const quantitativeAnalysis = input.quantitativeAnalysis || {}
                return (quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis !== ProbabilityOfExtinctionByQuantitativeAnalysisEnum.None);
            },
            category: function (input : FixMeLater) {
                const quantitativeAnalysis = input.quantitativeAnalysis || {}
                let category = null;
                if (quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis === ProbabilityOfExtinctionByQuantitativeAnalysisEnum.VeryHighProbability) {
                    category = Categories.CR;
                } else if (quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis === ProbabilityOfExtinctionByQuantitativeAnalysisEnum.HighProbability) {
                    category = Categories.EN;
                } else if (quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis === ProbabilityOfExtinctionByQuantitativeAnalysisEnum.MediumProbability) {
                    category = Categories.VU;
                } else if (quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis === ProbabilityOfExtinctionByQuantitativeAnalysisEnum.LowProbability) {
                    category = Categories.NT;
                }
                return {
                    category: category,
                    route: []
                }
            }
        }
    }

    let nt = {
        nt_1: {
            evaluate: function (input : FixMeLater) {
                const geography = input.geography || {}
                return (geography.areaOfOccupancy >= 20 && geography.areaOfOccupancy < 45)
            },
            category: function (input : FixMeLater) {
                let category = Categories.NT;
                return  {
                    category: category,
                    route: []
                }
            }
        },
        nt_2: {
            evaluate: function (input : FixMeLater) {
              return false;
              // This NT is currently disabled as we couldn't see it was implemented before and therefore did not want to introduce it.
              //  return (geography.extentLimitedToNoOfLocations > 5 && geography.extentLimitedToNoOfLocations <= 25);
            },
            category: function (input : FixMeLater) {
                let category = Categories.NT;
                return  {
                    category: category,
                    route: []
                }
            }
        }
    }

    let rules = [a, b, c, d, e, nt];

    let callRoutes = function (input : FixMeLater, routes : FixMeLater) {
        let result = [];
        for (var i = 0; i < routes.length; i += 1) {
            let current = routes[i];
            let tmp = current(input);
            if (tmp && tmp.fulfilled) {
                result.push(tmp);
            }
        }
        return result;
    }

    let callReasons = function (input : FixMeLater, reasons : FixMeLater, name : FixMeLater) {
        let result = [];
        for (var i = 0; i < reasons.length; i += 1) {
            let current = reasons[i];
            let tmp = current(input);
            if (tmp && tmp.fulfilled && tmp.applies(name)) {
                result.push(tmp.name);
            }
        }
        return result;
    }



    let CategoryResults = function () {
        let ztatic = {
            create: function () {
                let results : FixMeLater = {};
                let categoryModification = 0;
                let self = {
                    setCategoryModification: function (modification : FixMeLater) {
                        categoryModification = modification;
                    },
                    addResult: function (name : string, result : FixMeLater) {
                        results[name] = result;
                    },
                    getHighestResult: function () {
                        var output : { routes: any[]; category?: CategoryType; modification: FixMeLater} = {
                            routes: [],
                            modification: categoryModification
                        }
                        for (var name in results) {
                            var current = results[name];
                            if (!output.category) {
                                output.category = current.category;
                                output.routes.push({
                                    name: name,
                                    route: current.route
                                });
                            } else if (current.category.value === output.category.value) {
                                output.routes.push({
                                    name: name,
                                    route: current.route
                                });
                            } else if (current.category.value > output.category.value) {
                                output.category = current.category;
                                output.routes = [];
                                output.routes.push({
                                    name: name,
                                    route: current.route
                                });
                            }
                        }
                        if (!output.category) {
                            output.category = Categories.LC;
                        }
                        return output;
                    },
                    getResults: function () {
                        return results;
                    }
                }
                return self;
            }
        }
        return ztatic;
    }();


    let self = {
        preliminaryAssessment: function (criteria : FixMeLater) {
            const preliminary = criteria.preliminary || {}
            if (!preliminary.preliminaryAssessment 
                    || preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.Null 
                    || preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.Assessed) {
                return false;
            }
            var result = {
                category: Categories.LC,
                route: []
            }

            if (preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.IrrelevantToAssess) {
                result.category = Categories.NA;
            } else if (preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.RegionallyExtinct) {
                result.category = Categories.RE;
            } else if (preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.InsufficientData) {
                result.category = Categories.DD;
            } else if (preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.AbsolutelyNotThreatened) {
                result.category = Categories.LC;
            } else if (preliminary.preliminaryAssessment === PreliminaryAssessmentEnum.NotEvaluated) {
                result.category = Categories.NE;
            } else {
                console.log('Error', preliminary.preliminaryAssessment)
                return false
            }
            return result;
        },

        calculateCategoryModification: function (criteria : FixMeLater) {
            const adjustment = criteria.adjustment || {}
            if (!adjustment.affectedByNeighbours) {
                return 0;
            }

            var upDownCat = 0;
            if (adjustment.preliminaryAdjustment === PreliminaryAdjustmentEnum.KOLONISERING) {
                upDownCat = -2;
            }
            if (adjustment.preliminaryAdjustment === PreliminaryAdjustmentEnum.DEMOGRAFISK_USTABIL) {
                upDownCat = 1;
            }
            if (adjustment.immigrantPropagulesReproducing && adjustment.decreaseInPropagulesExpected === false) {
                upDownCat = -1;
            }
            if (adjustment.regionalSink === true) {
                upDownCat = 1;
            }
            if (adjustment.sourceSinkDynamicsAcrossBorders === true) {
                upDownCat = -1;
            }
            return upDownCat;
        },

        calculate: function (input : FixMeLater) {
            let results = CategoryResults.create();

            let preliminaryResult = self.preliminaryAssessment(input);
            if (preliminaryResult) {
                //console.log("Pre-res: ", preliminaryResult);
                results.addResult("PRE", preliminaryResult);
                return results;
            }

            results.setCategoryModification(self.calculateCategoryModification(input));
            for (var i = 0; i < rules.length; i += 1) {
                let t : FixMeLater = rules[i];
                for (var key in t) {
                    if (key.startsWith("_")) {
                        continue;
                    }
                    let current = t[key];
                    if (current.evaluate(input)) {
                        let tmp = current.category(input);
                        if (tmp && tmp.category && tmp.category.value > Categories.LC.value) {
                            results.addResult(key, tmp);
                        }
                    }
                }
            }
           // console.log(input, results.getResults())
            return results;
        },
        Categories: Categories,
        PreliminaryAdjustmentEnum: PreliminaryAdjustmentEnum,
        PreliminaryAssessmentEnum: PreliminaryAssessmentEnum,
        ProbabilityOfExtinctionByQuantitativeAnalysisEnum: ProbabilityOfExtinctionByQuantitativeAnalysisEnum,
        ReductionIsDerivedFromEnum: ReductionIsDerivedFromEnum,
        ReductionTimespanEnum: ReductionTimespanEnum,
        EstimatedContinuingReductionEnum: EstimatedContinuingReductionEnum
    };
    return self;
}();

export default redlistCategoryEngine