import React from 'react'
import { Section, LabelledValue, optionsConverter, booleanConverterHideNull } from '../../../mui/Section'
import { FixMeLater } from '../../components/GlobalErrorMessage'
import { populationReductionValues, subpopulationSizes, percentMatureIndividualsValues } from '../C_Bestandsstorrelse'

import { populationQuestions } from '../questions'

const C_BestandsstorrelseView = (props : FixMeLater) => {
  const population = props.population || {}
  const { showNull, showtitle } = props
  return (
    <Section title={showtitle && 'Bestandsstørrelse'}>
      <LabelledValue
        showNull={showNull}
        label={populationQuestions.noOfSexuallyMatureIndividuals.label}
        value={population.noOfSexuallyMatureIndividuals}
      />
      <LabelledValue
        showNull={showNull}
        label={populationQuestions.estimatedContinuingReduction.label}
        value={population.estimatedContinuingReduction}
        converter={optionsConverter(populationReductionValues)}
      />
      <LabelledValue
        showNull={showNull}
        label={populationQuestions.subpopulationSizeMatureIndividuals.label}
        value={population.subpopulationSizeMatureIndividuals}
        converter={optionsConverter(subpopulationSizes)}
      />
      <LabelledValue
        showNull={showNull}
        label={populationQuestions.percentMatureIndividuals.label}
        value={population.percentMatureIndividuals}
        converter={optionsConverter(percentMatureIndividualsValues)}
      />
      <LabelledValue
        showNull={showNull}
        label={populationQuestions.extremeFluctuationsInNumberOfMatureIndivduals.label}
        value={population.extremeFluctuationsInNumberOfMatureIndivduals}
        converter={booleanConverterHideNull}
      />
      <LabelledValue
        showNull={showNull}
        label={populationQuestions.noOfSubpopulationsIsMax3.label}
        value={population.noOfSubpopulationsIsMax3}
        converter={booleanConverterHideNull}
      />
      <LabelledValue
        showNull={showNull}
        fullWidth
        label={populationQuestions.populationSizeRemark.label}
        value={population.populationSizeRemark}
      />
    </Section>
  )
}

export default C_BestandsstorrelseView
