import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { statusEnum } from '../../service/assessmentStatusEnum'
import {
  VIEW_FAGLIG_KOMMENTERING_PAGE_SET_ASSESSMENTS,
  VIEW_FAGLIG_KOMMENTERING_PAGE_SET_FILTER,
} from './viewFagligKommenteringPageActions'

const initialState = {
  filter: { page: 1, limit: 25, vurderingsrundeId: 2030, statusList: [statusEnum.values().kommentering.value] },
  assessments: [],
  pages: 1,
  count: 0,
}

const viewAssessmentsPageReducer = (state = initialState, action : FixMeLater) => {
  switch (action.type) {
    case VIEW_FAGLIG_KOMMENTERING_PAGE_SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload.assessments,
        count: action.payload.count,
        pages: action.payload.pages,
      }
    case VIEW_FAGLIG_KOMMENTERING_PAGE_SET_FILTER:
      return { ...state, filter: action.payload }
    default:
      return state
  }
}

export default viewAssessmentsPageReducer;