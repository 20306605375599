import { createEnum, createEnumValue } from './enums'

export const adjustmentPreliminaryObject = {
    ikkeAngivet: createEnumValue('ikke_angivet', "Ikke angivet", 0, null, true),
    kolonisering: createEnumValue('kolonisering', "Bestanden er i fremgang i nabolandene, men endnu meget lille i DK (Der er tale om en koloniseringsfase, nedkategorisér to trin)", 1),
    demografiskUstabil: createEnumValue('demografisk_ustabil', "Den nationale bestand er demografisk ustabil og forventes samtidig en tilbagegang i nabolandenes bestande (opkategorisér ét trin)", 2),
    visiting: createEnumValue('visiting', 'Arten er en ikke-reproducerende gæst', 3),
    breeding: createEnumValue('breeding', 'Arten yngler i Danmark', 4)
}

export const adjustmentPreliminaryEnum = createEnum(adjustmentPreliminaryObject)