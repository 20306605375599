import {
    put,
    select,
    apply,
    takeLeading,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects'
import {
    CRUD_INIT,
    CRUD_CREATE_OK,
    CRUD_SET_ITEMS,
    CRUD_NEXT_PAGE,
    CRUD_PREV_PAGE,
    CRUD_SET_PAGE,
    CRUD_DELETE_ITEM,
    setFilter,
    setPage,
    CRUD_LIST_SET_FILTER,
    REQUEST_CRUD_UPDATE,
    CRUD_UPDATE_OK,
    CRUD_UPDATE_ERROR,
    hideCreateDialog,
    hideEditDialog,
    REQUEST_CRUD_CREATE,
    CRUD_CREATE_ERROR
} from './crudActions'
import { hideSaving, showError, showMessage, showSaving } from '../../redux/actions/uiActions'
import crudProvider from '../provider/crudProvider'


const newFilter = (filter) => {
    let result = {
          page: 1,
          limit: 50,
    }
    filter.forEach(f => {
          result[f.property] = ''
    })
    return result
}

function* processInit(action) {
    try {

        //yield put(fetchAdminEntities())
        const filter = newFilter(action.payload.config.filter)
        yield put(setFilter(filter))
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processNextPage(action) {
    try {
        const filter = yield select(state => state.crudPage.filter)
        let page = filter.page + 1
        yield put(setPage(page))
    } catch (error) {
        yield put(showError(error))
    }
}


function* processPrevPage(action) {
    try {
        const filter = yield select(state => state.crudPage.filter)
        let page = filter.page - 1
        if (page >= 1) {
            yield put(setPage(page))
        }
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPage(action) {
    try {
        const {
            page
        } = action.payload
        const filter = yield select(state => state.crudPage.filter)
        yield put(setFilter({
            ...filter,
            page
        }))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetFilter(action) {
    try {
        const { filter } = action.payload
        const key = yield select(state => state.crudPage.key)
        console.log(filter)
        const data = yield apply(crudProvider, crudProvider.loadByFilter, [key, filter])
        console.log(data)
        const {
            items,
            count,
            pages
        } = data
        yield put({
            type: CRUD_SET_ITEMS,
            payload: {
                items: items,
                pages,
                count,
                queryId: new Date().getTime() + ''
            }
        })
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}


function* processUpdateItem(action) {
    try {
        const {key, filter} = yield select(state => state.crudPage)
        yield put(showSaving())
        const { id, item } = action.payload
        const patch = {}
        for (let key in item) {
            let val = item[key]
            if (!val && val !== 0) {
                val = null
            }
            patch[key] = val
        }
        const data = yield apply(crudProvider, crudProvider.update, [key, id, patch])
        console.log(data)
        yield put({
            type: CRUD_UPDATE_OK
        })
        yield put(setFilter(filter))
        yield put(hideEditDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({
            type: CRUD_UPDATE_ERROR,
            payload: 'Fejl'
        })
        yield put(showError(error))
    }
}

function* processCreateItem(action) {
    try {
        const {key, filter} = yield select(state => state.crudPage)
        const { item } = action.payload
        yield put(showSaving())
        const data = yield apply(crudProvider, crudProvider.create, [key, item])
        console.log(data)
        yield put({
            type: CRUD_CREATE_OK
        })
        yield put(setFilter(filter))
        yield put(hideCreateDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({
            type: CRUD_CREATE_ERROR,
            payload: 'Fejl'
        })
        yield put(showError(error))
    }
}

function* processDeleteItem(action) {
    try {
        const {key, filter} = yield select(state => state.crudPage)
        const { id } = action.payload
        yield put(showSaving())
        yield apply(crudProvider, crudProvider.delete, [key, id])
        yield put(setFilter(filter))
        yield put(hideEditDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showMessage('Fejl', 'Kunne ikke slette'))        
    }
}

export default function* watcher() {
    yield takeLatest(CRUD_NEXT_PAGE, processNextPage)
    yield takeLatest(CRUD_PREV_PAGE, processPrevPage)
    yield takeLatest(CRUD_SET_PAGE, processSetPage)
    yield takeLatest(CRUD_DELETE_ITEM, processDeleteItem)
    yield takeLatest(REQUEST_CRUD_UPDATE, processUpdateItem)
    yield takeEvery(REQUEST_CRUD_CREATE, processCreateItem)
    yield takeLatest(CRUD_LIST_SET_FILTER, processSetFilter)
    yield takeLeading(CRUD_INIT, processInit)
}