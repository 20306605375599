import {
    apply,
    call,
    put,
    delay
} from 'redux-saga/effects'
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage';
import {
    apiError,
    authError    
} from "./apiActions";

export interface ResponseGenerator extends Record<string, any> {   
}

function* apiRequestSaga(obj : FixMeLater, fn : FixMeLater, args : FixMeLater, onSuccess? : FixMeLater, onError? : FixMeLater, onNotFound? : FixMeLater) {
    for (let i = 0; i < 7; i++) {
        try {
            const data : ResponseGenerator = yield apply(obj, fn, args)
            if (onSuccess) {
                yield put({ type: onSuccess, payload: { data }})
            }
            return data
        } catch (error : FixMeLater) {
            if (error.status && error.status === 401) {
                yield put(authError())
                return
            }        
            if (error.status && error.status === 403) {
                if (onError) {
                    if (onError.type) {
                        yield put(onError)
                    } else {
                        yield put({ type: onError })
                    }
                }
                yield put(apiError(error))
                return
            }
            if (error.status && error.status === 404) {
                if (onNotFound) {
                    yield put(onNotFound)
                } else if (onError) {
                    if (onError.type) {
                        yield put(onError)
                    } else {
                        yield put({ type: onError })
                    }
                }
                return
            }
            yield delay(1000)
        }
    }
    if (onError) {
        if (onError.type) {
            yield put(onError)
        } else {
            yield put({ type: onError })
        }
    }
    yield put(apiError())
}

export const apiRequest = (obj : FixMeLater, fn : FixMeLater, args : FixMeLater, onSuccess? : FixMeLater, onError? : FixMeLater, onNotFound? : FixMeLater) => {
    return call(apiRequestSaga, obj, fn, args, onSuccess, onError, onNotFound)
}

function* apiRequest2Saga(obj : any, fn : any, args : unknown[], statusMapper? : FixMeLater) {
    let _error = null;
    for (let i = 0; i < 7; i++) {
        try {
            const data : ResponseGenerator = yield apply(obj, fn, args)
            if (statusMapper["200"]) {
                yield put(statusMapper["200"])
            }
            if (statusMapper["ok"]) {
                yield put(statusMapper["ok"](data))
            }
            return data
        } catch (error : FixMeLater) {
            _error = error
            if (error.status && error.status === 401) {
                yield put(authError())
                return
            }        
            if (error.status) {
                const status = "" + error.status
                if (statusMapper[status]) {
                    yield put(statusMapper[status])
                    return
                }
            }
            yield delay(1000)
        }
    }
    if (statusMapper.error) {
        yield put(statusMapper.error)
    } else {
        yield put(apiError())
    }
    if (_error) {
        throw _error
    }
}

export const apiRequest2 = (obj : FixMeLater, fn : FixMeLater, args : FixMeLater, statusMapper? : FixMeLater) => {
    return call(apiRequest2Saga, obj, fn, args, statusMapper || {})
}