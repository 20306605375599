import {
    validationResultFromProperties
} from './sectionValidators'
import {
    preliminaryValuesAssessed
} from "../1_IndledendeVurdering"
import {
    kvantitativValues
} from '../D_KvantitativAnalyse'

const isEmpty = quantitativeAnalysis => {
    const {
        probabilityOfExtinctionByQuantitativeAnalysis,
        quantitativeAnalysisRemark
    } = quantitativeAnalysis
    return (
        (probabilityOfExtinctionByQuantitativeAnalysis === null || probabilityOfExtinctionByQuantitativeAnalysis === kvantitativValues[0].value) &&
        !quantitativeAnalysisRemark
    )
}

export const quantitativeAnalysisValidator = criteria => {
    const {
        quantitativeAnalysis,
        preliminary
    } = criteria
    const empty = isEmpty(quantitativeAnalysis)
    if (preliminary.preliminaryAssessment !== preliminaryValuesAssessed) {
        if (!empty) {
            return {
                ok: false,
                global: true,
                message: 'Kvantitativ analyse må ikke udfyldes når der er angivet en indledende vurdering, der gør at arten ikke skal vurderes'
            }
        }
    }

    const {
        quantitativeAnalysisRemark
    } = quantitativeAnalysis

    const properties = {}
    if (!empty && !quantitativeAnalysisRemark) {
        properties.quantitativeAnalysisRemark = {
            message: 'Hvis der er udfyldt noget i denne sektion skal der være skrevet noget i dokumentationsfeltet'
        }
    }
    return validationResultFromProperties(properties, empty)
}