import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const VIEW_FAGLIG_KOMMENTERING_PAGE_INIT = '[viewFagligKommenteringPage] init'
export const VIEW_FAGLIG_KOMMENTERING_PAGE_FETCH = '[viewFagligKommenteringPage] fetch'
export const VIEW_FAGLIG_KOMMENTERING_PAGE_SET_ASSESSMENTS = '[viewFagligKommenteringPage] set assessments'
export const VIEW_FAGLIG_KOMMENTERING_PAGE_SET_FILTER = '[viewFagligKommenteringPage] set filter'
export const VIEW_FAGLIG_KOMMENTERING_PAGE_SET_PAGE = '[viewFagligKommenteringPage] set page'
export const VIEW_FAGLIG_KOMMENTERING_PAGE_NEXT_PAGE = '[viewFagligKommenteringPage] set next page'
export const VIEW_FAGLIG_KOMMENTERING_PAGE_PREV_PAGE = '[viewFagligKommenteringPage] set prev page'


export const init = (user : FixMeLater, status : FixMeLater) => (
    {
         type: VIEW_FAGLIG_KOMMENTERING_PAGE_INIT,
         payload: { user, status }
    }
)

export const fetchAssessments = (filter : FixMeLater) => ({ type: VIEW_FAGLIG_KOMMENTERING_PAGE_FETCH, payload: { filter }})

export const setFilter = (filter : FixMeLater) => ({
    type: VIEW_FAGLIG_KOMMENTERING_PAGE_SET_FILTER,
    payload: filter
})

export const nextPage = () => ({
    type: VIEW_FAGLIG_KOMMENTERING_PAGE_NEXT_PAGE
})

export const prevPage = () => ({
    type: VIEW_FAGLIG_KOMMENTERING_PAGE_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: VIEW_FAGLIG_KOMMENTERING_PAGE_SET_PAGE,
    payload: { page }
})
