import React from 'react'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import CheckboxButton from '../../mui/CheckboxButton'
import { ThemeProvider } from 'styled-components'


const buttonTheme = {
    background: '#ba68c8',
    color: '#fff',
    borderColor: '#e1bee7',
    checked: {
        background: '#9c27b0',
        color: '#fff',
        borderColor: '#7b1fa2'
    }
}

const renderSubstrat = (substrat, selectedSubstrates, onChange) => {
    let selected = selectedSubstrates.filter(e => {
        return e.id === substrat.id;
    }).length > 0;
    return (
        <Grid key={substrat.id} item xs={12} sm={6} md={4}>
            <CheckboxButton
                fullwidth
                onChange={checked => onChange(substrat, checked)}
                label={substrat.name}
                tooltip={substrat.name + ': ' + substrat.description}
                checked={selected} />
        </Grid>
    )
}

const Substrater = (props) => {
    const { substrater, selectedSubstrater, hovedmiljoer, selectedOverordnedeSubstrater, onSelectedChanged, onProceed } = props;

    //console.log(substrater, selectedOverordnedeSubstrater)

    let availableSubstrater = substrater.filter(s => {
        return (
            selectedOverordnedeSubstrater.find(os => os.id === s.overordnetSubstratId)
                &&
            (hovedmiljoer.length === 0 || hovedmiljoer.find(h => s.hovedmiljoer.find(sh => sh === h.slug)))
        )            
    })

    let selectionChanged = (substrat, value) => {
        //  console.log(ecosystem, value);
        let _t = selectedSubstrater.filter(e => e.id !== substrat.id);
        if (value) {
            _t = _t.concat([substrat]);
        }
        onSelectedChanged(_t);
    }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} lg={11} spacing={2}>               
                <ThemeProvider theme={buttonTheme}>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>
                        Angiv substrater
                </Typography>
                    {
                        availableSubstrater.map(e => renderSubstrat(e, selectedSubstrater, selectionChanged))
                    }
                </ThemeProvider>
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={() => onProceed()} color='primary' variant='contained'>Videre</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Substrater