import React from "react";
import { Typography } from "@material-ui/core";
import { TextHelpElement, helpStyles } from "./help";

export const ArterDkHelp = () => {
  const classes = helpStyles();
  return (
    <>
      <Typography className={classes.typography} variant="subtitle1">
        Observations-data fra arter.dk
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Her finder du alle tilgængelige data fra arter.dk også data som ikke er
        validerede. Derfor skal du tage stilling til hvilke observationer der er
        valide inden du bruger dem. Du kan selv tilføje nye punkter hvis arten
        findes på steder som ikke er vist og du kan slette punkter hvis en vist
        observation ikke er valid eller repræsenterer en lokalitet for arten. På
        slidebaren kan du indstille hvilket tidsinterval du vil se data fra.
      </Typography>
      <Typography className={classes.typography} variant="body2">
        På knappen "Hent alt" kan du hente al data også data som ligger i samme
        2x2 km kvadrat. Hvis der er mange observationer kan det godt tage nogle
        minutter, så brug den kun hvis det er nødvendigt for dig at se alt.
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Med knappen "Hent 2x2 km" kan du hente en tilfældig observation for hver
        2x2 km celle hvor der findes observationer og dermed på beregnet et
        foreløbigt forekomst- og udbredelsesareal.
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Husk at du udover de data der vises på kortet skal basere det indtastede
        forekomst- og udbredelsesareal på det mest realistiske scenarie og altså
        ikke kun på de kendte lokaliteter der vises på kortet. Husk at
        dokumentere hvordan du kommer fra kortets data til de tal du indtaster.
      </Typography>
    </>
  );
};

export const AoeHelpElement = () => {
  const classes = helpStyles();
  return (
    <React.Fragment>
      <Typography className={classes.typography} variant="subtitle1">
        Angiv det skønnede/kendte areal (km2) for artens udbredelse og/eller for
        artens forekomst
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Udbredelsesarealet måles ved at trække en linje rundt om samtlige
        forekomster og måle det omkransede areal (Figur 2). Dette indebærer, at
        land og havområder, hvor arten ikke forekommer, medtages. Findes der
        derimod inden for det omkransede areal meget store områder, hvor det
        pga. umiddelbart ugunstige livsbetingelser er utænkeligt, at arten kan
        leve, er det tilladt at udelade disse områder fra arealberegningen
        (f.eks. havområdet mellem Møn/Sjælland og Bornholm og landarealet i
        Skåne). Derimod foretages der normalt ingen undtagelser for f.eks.
        ferskvandsarter, fugtigbundsarter eller andre arter med en udbredelse,
        som naturligt eller ved menneskelig påvirkning er fragmenteret. For
        disse arter måles udbredelsesarealet som hele det omkransede areal.
        Forekomstarealet skal afspejle den detaljerede, faktiske udbredelse
        (Figur 2). Man angiver det mindste område, en bestand af en forekommende
        art på et givet livsstadium har til rådighed. Hvis alle larver af en
        guldsmedeart udvikles i to små vandhuller, og de voksne individer senere
        på sommeren flyver uden for vandhullet for at søge føde, eller hvis en
        fiskeart anvender et begrænset kystområde til parringsleg og derefter er
        spredt i hele havet, så er det vandhullernes henholdsvis kystområdernes
        areal, der skal beregnes. På samme måde gælder det, hvis hele Danmarks
        bestand af en trækfugleart i en vis periode under trækket samles i én
        eneste vig i Holland, så er det arealet af dette område, som skal
        beregnes, også selvom det ikke ligger i Danmark, og arten yngler i et
        stort område her i landet. Forekomstarealet måles ved at lægge et
        kvadratnet over artens udbredelse og dernæst udregne arealet af de
        kvadrater, hvor arten rent faktisk forekommer. Den globale manual (IUCN
        2013) definerer ikke kvadratnettets skala, men foreskriver, at det bør
        tilpasses artens biologiske forhold og bør angives i km2. Det er i
        Danmark bestemt, at man kan anvende kvadratstørrelser på 1x1, 2x2, 5x5
        eller 10x10 km alt efter art (Wind 2003), men man bør som udgangspunkt
        anvende 2x2 km så vidt muligt, og undgå at anvende kvadratstørrelser
        over dette, idet en art i så fald ikke kan henføres til kategorien CR,
        hvor grænsen går ved 10km2 (IUCN 2013). Hvis ikke man bruger 2x2 km bør
        der desuden foretages en standardisering, så data bliver
        sammenlignelige, se IUCN’s manual (IUCN 2013).
      </Typography>
    </React.Fragment>
  );
};

export const ProcentvisTilbagegangHelpElement = () => {
  const classes = helpStyles();
  return (
    <React.Fragment>
      <Typography className={classes.typography} variant="subtitle1">
        Angiv procentvis tilbagegang i en periode på 10 år eller 3 generationer
        (vælg længste periode)
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Hvis artens bestand er gået/går tilbage over en periode på 10 år/3
        generationer – som dækker perioden op til bedømmelsestidspunktet, en
        periode som dækker over både fortid og fremtid (altså en pågående
        tilbagegang) eller en periode som strækker sig fra vurderingstidspunktet
        og frem i tiden (altså forventet/formodet) – angives den procentvise
        tilbagegang i dette felt. Angiv kun tilbagegangen over 3 generationer,
        hvis det dækker over en periode på mere end 10 år. Bemærk, at den
        angivne tilbagegang gerne må være skønnet, beregnet, forventet, udledt
        og/eller formodet såvel som observeret. Grunden til, at
        generationsalternativet findes, er, at en negativ påvirkning af en
        længelevende art ofte ikke viser sig i form af reducerede bestande
        indenfor en 10 års periode. Eksempelvis kan antallet af voksne sæler,
        muslinger eller træer forblive næsten konstant i en periode selvom
        forplantningen er ophørt. Generationslængden er defineret ved
        gennemsnitsalderen af populationens forældreindivider, og ikke alderen
        ved første reproduktion, dog undtaget de tilfælde, hvor arten kun
        formerer sig lejlighedsvis. For urter med kort levetid og længelevende
        frøbank er det bedre at anvende gennemsnitslevetiden af frøbanken (den
        formodede gennemsnitlige omsætningstid). I den globale manual (IUCN
        2013) sættes ingen øvre grænse for, hvor lang generationstiden i
        systemet skal være. Dette kan føre til, at der hos meget længelevende
        organismer måles en tilbagegang over et tidsrum, der langt overstiger de
        tidsskalaer, som andre processer i omgivelserne (negative påvirkninger,
        bevaringsforanstaltninger osv.) opererer inden for. Det anbefales i
        overensstemmelse med Baillie &amp; Groombridge (1996) og Hallingbäck
        m.fl. (1998), at en maksimal generationslængde sættes til 25 år, dvs.
        tilbagegangen bedømmes over maksimalt 75 år; træer er en undtagelse,
        hvor generationstidsloftet sættes til 75 år (225 år). I den globale
        manual (IUCN 2013) er indført, at ingen forudsigelse må overstige 100
        år, mens der ikke er begrænsninger tilbage i tiden.
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Det er formodentlig sjældent, at sikre og regelmæssige data om
        bestandsstørrelser, der gør det muligt at beregne en eventuel
        tilbagegang, forekommer. Det er til gengæld muligt og tilladt at vurdere
        ændringerne i bestandsstørrelser på grundlag af delpopulationer ved at
        ekstrapolere fra målinger over andre tidsrum eller ved vurderinger
        baseret på observerede eller formodede formindskelser af f.eks.
        udbredelsesareal, biotop, levestedskvalitet, biomasse (f.eks. hos fisk),
        fangst i fælder, træktælling eller ændringer i menneskelig udnyttelse,
        forurening eller af andre negative påvirkninger. Desuden kan disse
        vurderinger gøres såvel tilbage som frem i tiden, dvs. at der er
        mulighed for at vurdere, om bestandene formindskes i fremtiden. For at
        opretholde troværdigheden til systemet er det derfor vigtigt at undgå
        sjusk og rutinemæssig indtastning i dette felt!
      </Typography>
      <Typography className={classes.typography} variant="body2">
        Det er vigtigt at granske de foreliggende oplysninger om en arts
        tilbagegang. Eksempelvis kan en grundig, gentagen undersøgelse af en
        sjælden art vise, at den er forsvundet på 30 % af sine levesteder. Hvis
        en sådan undersøgelse udelukkende har været foretaget på artens kendte
        levesteder, har man ikke taget højde for, at den kan være spredt til nye
        levesteder, hvor den måske trives. En realistisk angivelse af en arts
        tilbagegang afhænger derfor af, at man inddrager sådanne detaljer. I de
        tilfælde, hvor pålidelige data om en bestandstilbagegang foreligger,
        strækker de sig sjældent over nøjagtigt 10 år eller det antal år, som
        svarer til 3 generationer. Det indebærer, at der skal ekstrapoleres på
        grundlag af de forhåndenværende oplysninger for det tidsrum, målingen
        foretages i, hvilket kan være meget besværligt. Hvor stor tilbagegangen
        inden for en given tidsperiode er, beror på den måde, tilbagegangen
        (antagelig) har fundet sted på, dvs. hvilken form reduktionskurven har.
        Trækkes der en ret linje mellem to målepunkter, antages det samtidig, at
        reduktionshastigheden er øget med tiden (Figur 1). Det er således let at
        begå fejl på tankeplanet, når den observerede formindskelse
        ekstrapoleres fra én tidsperiode til en anden. Der er ofte grund til at
        antage, at kurven, der beskriver en bestandstilbagegang, ikke er en ret
        linie. Den kan f.eks. være konveks, dvs. når tilbagegangen sker meget
        hurtigt, i andre tilfælde konkav, hvilket den bliver, når den årlige
        tilbagegangsrate er konstant. I de efterfølgende felter angives detaljer
        om tilbagegangen.
      </Typography>
      <Typography className={classes.typography} variant="body2">
        En ekstrapolering af en bestandstilbagegang på baggrund af andre typer
        af data end direkte målinger af en arts bestand, er heller ikke
        nødvendigvis lineær. Det, at arten er forsvundet fra 25% af de tyndest
        besatte dele af udbredelsesområdet, indebærer ikke, at bestanden er
        formindsket med 25%; det kan sammenlagt måske dreje sig om en
        formindskelse på 5%. Tilsvarende er det, at en art er forsvundet fra 3
        af 10 tidligere levesteder, ikke det samme som en 30 %
        bestandstilbagegang. En realistisk angivelse af en arts tilbagegang
        afhænger derfor af, at man overvejer, hvordan sammenhængen er mellem de
        tilgængelige data for artens bestandsstørrelse.
      </Typography>
    </React.Fragment>
  );
};

export const FragmenteretHelpElement = () => {
  return (
    <TextHelpElement title="Udbredelsen er stærkt fragmenteret">
      Svar ja, hvis udbredelsen er stærkt fragmenteret. Begrebet stærk
      fragmentering dækker over, at sandsynligheden er meget lav for spredning
      af individer eller spredningsenheder imellem lokaliteterne.
      Sandsynligheden afhænger ikke kun af afstanden mellem lokaliteterne, men
      også af artens spredningsstrategi og spredningsområde (kan den flyve,
      transporteres passivt med dyr, vand eller vind osv.). Det er imidlertid
      let at undervurdere mange organismers spredningsmuligheder. Hallingbäck
      m.fl. (1998) anbefaler eksempelvis for mosser uden sporespredning, at
      afstanden mellem populationerne skal være mindst 50 km og for mosser med
      sporespredning 100-1000 km (afhængigt af sporeproduktionen), før der er
      tale om stærk fragmentering. I praksis er det ikke usædvanligt, at en art
      har en tættere forekomst i én egn af landet og spredte forekomster i andre
      dele. I dette tilfælde, er det fristende at benævne en arts samlede
      udbredelse for kraftigt fragmenteret. Når de isolerede forekomster uddør,
      fremstår den tilbageværende bestand ufragmenteret. Artens risiko for at
      uddø er dog næppe formindsket. Det er altid vigtigt at betragte begrebet
      stærk fragmentering i et helhedsperspektiv. I ovenstående tilfælde er det
      ikke rigtigt at betegne en bestand som stærkt fragmenteret, da hovedparten
      af den findes samlet.
    </TextHelpElement>
  );
};

export const LokaliteterHelpElement = () => {
  return (
    <TextHelpElement title="Udbredelsen er begrænset til X lokaliteter (udfyld kun hvis under 25)">
      Angiv hvor mange lokaliteter arten p.t. findes på i Danmark. De flestes
      opfattelse af begrebet lokalitet er ofte intuitiv. Det centrale er, at en
      enkelt hændelse – udslip i et vandløbssystem, afdrift af et skovområde,
      ophør af hidtidig driftsform, f.eks. ekstensiv græsning, nye grundejere
      med ændret syn på forvaltning osv. – kan udrydde en hel bestand. For
      arter, der optræder i metapopulationer, kan lokalitetsbegrebet være
      komplekst. Når det drejer sig om virkelige metapopulationer med mere eller
      mindre regelmæssig uddøen og rekolonisering, bør beregningerne indskrænkes
      til antallet af levesteder, som er beboede. Metapopulationsbegrebet er i
      første række opstillet på baggrund af dyrebestandes livsmønstre, men
      karplanter med frøbank eller planter, der ikke sætter overjordiske skud
      hvert år (f.eks. flere arter af orkidéer), kan også opføre sig som
      metapopulationer, også selv om det i virkeligheden drejer sig om, at de i
      visse (og ind imellem ganske lange) perioder kun findes underjordisk på
      lokaliteterne. I disse tilfælde kan det være berettiget at kompensere og
      anvende et højere antal lokaliteter end det antal, hvor arten viser sig i
      et givet år.
    </TextHelpElement>
  );
};

export const VedvarendeTilbagegangHelpElement = () => (
  <TextHelpElement title="Der er en observeret, formodet eller forudset vedvarende tilbagegang">
    Der er en observeret, formodet eller forudset vedvarende tilbagegang i (1)
    udbredelsesområde, (2) forekomstareal, (3) antallet af kønsmodne individer,
    (4) areal og/eller kvalitet af habitat eller (5) antallet af
    lokaliteter/delpopulationer: Svar ja, hvis ovenstående gælder. Der skal være
    tale om en vedvarende tilbagegang, hvor formindskelsesraten ikke er
    defineret. Se forklaring af begreberne formodet og forudset under
    Tilbagegangen er skønnet/beregnet/forventet/udledt/formodet og baseret på i
    ovenstående afsnit. Se definition samt eksempler på forringet
    habitatkvalitet under Tilbagegangen er
    skønnet/beregnet/forventet/udledt/formodet og baseret på et reduceret
    forekomstareal, udbredelsesområde og/eller forringet habitatkvalitet.
  </TextHelpElement>
);

export const EkstremeUdsvingHelpElement = () => (
  <TextHelpElement title="Der er ekstreme udsving">
    Der er ekstreme udsving i (1) udbredelsesområde, (2) forekomstareal, (3)
    antallet af lokaliteter/delpopulationer eller (4) antallet af kønsmodne
    individer: Svar ja, hvis ovenstående gælder. Både naturlige og
    menneskeforårsagede udsving gælder. Med ekstreme svingninger menes 10 gange
    eller mere i udsving, f.eks. det, at en art i visse år har en population på
    850 kønsmodne individer og 8500 i andre.
  </TextHelpElement>
);
