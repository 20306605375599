import { useEffect } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";

const OlView = (props: {
  lon: number;
  lat: number;
  zoom: number;
  map?: Map;
}) => {
  const { map, lon, lat, zoom } = props;
  useEffect(() => {
    if (map) {
      map?.setView(new View({
        center: fromLonLat([lon, lat]),
        zoom: zoom,
      }))
    }    
  }, [map, lon, lat, zoom]);

  return null;
};

export default OlView;
