import React from 'react'
import {
    Button,
    Popover
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FixMeLater } from '../assessments/components/GlobalErrorMessage'

const helpButton = (open : boolean, handleClick : (evt: FixMeLater) => void) => {
    return <Button
        disabled={open}
        style={{ float: 'right', height: 32, marginRight: 16, marginTop: 0 }}
        onClick={(evt : FixMeLater) => handleClick(evt)}
        variant='contained'
        color='inherit'>
        <FontAwesomeIcon icon={faQuestion} />
    </Button>
}

export interface SectionTitleProps {
    help?: () => FixMeLater;
    label: string;
    disabled?: boolean; 
}

const SectionTitle = (props : SectionTitleProps) => {
    const { help, label, disabled } = props
    const [anchorEl, setAnchorEl] = React.useState<FixMeLater>(null);

    const handleClick = (event : FixMeLater) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div style={ {  marginTop: 4, marginBottom: 8 }}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                elevation={16}
            >
                {help ? help() : null}
            </Popover>
            <span style={{ color: disabled ? '#777' : 'black', display: 'inline-block', maxWidth: 'calc(100% - 100px)', fontFamily: '"Roboto"', fontSize: '1rem', fontWeight: 500 }}>{label}</span>
            {help ? helpButton(open, handleClick) : null}
        </div>
    )
}

export default SectionTitle