import { SET_CURRENT_SECTION, SET_CURRENT_SUBMENU } from '../editAssessment/editAssessmentMenuActions'
import { sectionMenuList, sections, SectionType } from '../../service/assessmentSections'
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'

export interface EditAssessmentMenuStore {
  currentSection: SectionType;
  currentSubmenu: FixMeLater;
  sections: SectionType[];
}

const initialState : EditAssessmentMenuStore = {
  currentSection: sections.preliminary,
  currentSubmenu: null,
  sections: sectionMenuList
}

const assessmentMenu = (state = initialState, action : FixMeLater) : EditAssessmentMenuStore => {
  //console.log('action', action);
    switch (action.type) {  
      case SET_CURRENT_SECTION:
        const _currentSection = state.sections.find((s : FixMeLater) => s.id === action.payload.id)
        if (!_currentSection) {
          console.error("Error: Section not found. action", action)
          return state
        }
        return { ...state, currentSection: _currentSection, currentSubmenu: 0 }
      case SET_CURRENT_SUBMENU:
        return { ...state, currentSubmenu: action.payload.id}
      default:
        return state
    }
  }
  
  export default assessmentMenu