import React from 'react'
import {
    Grid,
    Button,
    Typography, TextField, LinearProgress
} from '@material-ui/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const nonEmptyValidator = value => {
    if (!value) {
        return { ok: false, message:'Der skal indtastes en værdi' }
    }
    return { ok: true}
} 

const noSpacesValidator = value => {
    if (value.includes(' ')) {
        return {ok: false, message:  'Mellemrum ikke tilladt' }
    }
    return {ok: true}
}

const combineValidators = validators => {
    return value => {
        let result = { ok: true }
        validators.forEach(v => {
            if (result.ok) {
                result = v(value)
            }
        })
        return result;
    }
}


const CreateAssessorDialog = props => {
    const { onClose, onSave } = props
    const { createAssessorError } = useSelector(state => state.manageAssessorsPage)
    const { saving } = useSelector(state => state.ui)
    const [alias, setAlias] = useState('')
    const [name, setName] = useState('')
    
    const aliasValid = combineValidators([ nonEmptyValidator, noSpacesValidator ])(alias)
    const nameValid = nonEmptyValidator(name)

    const valid = aliasValid.ok && nameValid.ok    
    
    return (
        <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
            <Typography variant='h6'>Opret assessor</Typography>
            <LinearProgress variant={ saving ? 'indeterminate' : 'determinate'} value={0} />
            <Grid container spacing={2}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Alias'
                        value={alias}
                        error={Boolean(alias && !aliasValid.ok)}
                        helperText={aliasValid.message}
                        onChange={evt => setAlias(evt.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Navn'
                        value={name}
                        error={Boolean(name && !nameValid.ok)}
                        helperText={nameValid.message}
                        onChange={evt => setName(evt.target.value)} />
                </Grid>
               
                { createAssessorError ? <Grid item xs={12}>
                    <Typography variant='body1' color='error'>An error occurred while trying to create the assessor</Typography>
                </Grid> : null }
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onClose()} fullWidth variant='contained' color='secondary'>Luk</Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Button onClick={() => onSave({alias, name})} disabled={!valid} fullWidth variant='contained' color='primary'>Opret</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateAssessorDialog