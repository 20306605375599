import React, { ReactElement } from "react";

import { Feature, Map, MapBrowserEvent, View  } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import Geometry from "ol/geom/Geometry";
import { defaults as InteractionDefaults, MouseWheelZoom } from "ol/interaction";
import RenderFeature from "ol/render/Feature";
import "./ol.css";
import { Extent, getBottomLeft, getTopRight } from "ol/extent";
import { BBox } from "./OlFeatureCollection";

export type MouseWheelZoomSettingEnum = 'default' | 'ctrl' | 'disabled'

type MapOptions = {
  target?: HTMLElement | string;
  mouseWheelZoomSettings: MouseWheelZoomSettingEnum
  doubleClickZoom: boolean
}

const i = new MouseWheelZoom();

const oldFn = i.handleEvent;

i.handleEvent = function(e) {
  var type = e.type;
  if (type !== "wheel" && type !== "wheel" ) {
    return true;
  }
  
  if (!e.originalEvent.ctrlKey) {
    return true
  }

  return oldFn.call(this,e);
}

const createMap = (mapOptions: MapOptions): Map => {
  const { target, mouseWheelZoomSettings, doubleClickZoom } = mapOptions;
  let interactions = null;
  if (mouseWheelZoomSettings === 'ctrl') {
    interactions = InteractionDefaults({ doubleClickZoom, mouseWheelZoom: false  }).extend([i])
  } else if (mouseWheelZoomSettings === 'disabled') {
    interactions = InteractionDefaults({ doubleClickZoom, mouseWheelZoom: false  })
  } else{
    interactions = InteractionDefaults()
  }
  const map = new Map({
    target: target,
    interactions,
    layers: [],
    view: new View({
      center: fromLonLat([0, 0]),
      zoom: 3,
    }),
  });

  return map;
};

export type OlFeature = (Feature<Geometry> | RenderFeature) & { getProperties: () => Record<string, any>};

export interface OpenLayersMapProps {
  onFeatureClicked?: (features: OlFeature[]) => void;
  onMoveEnd?: (extent: BBox, zoom? : number) => void;
  id: string;
  left?: number;
  right?: number;
  top?: number;
  height?: number;
  heightVh?: number;
  marginBottom?: number;
  mouseWheelZoomSettings? : MouseWheelZoomSettingEnum;
  doubleClickZoom? : boolean
  children: ReactElement<any>[]
}

class OpenLayersMap extends React.Component<OpenLayersMapProps, { isMounted: boolean; map: Map | null}> {
   constructor(props: any) {
    super(props);
    this.state = {
      isMounted: false,
      map: null,
    };   
  }

  componentDidMount = () => {
    const map: Map = this.initMap(this.props);
    const { onFeatureClicked, onMoveEnd } = this.props;
    if (onFeatureClicked) {
      map.on("click", function (e: MapBrowserEvent<any>) {        
        const features : OlFeature[] = map.getFeaturesAtPixel(e.pixel);
        if (features.length > 0) {
          onFeatureClicked(features)
        }        
      });
    }
    if (onMoveEnd) {
      map.on('moveend', (evt: any) => {
        const map = evt.map;
        const view = map.getView() as View
        const extent : Extent = view.calculateExtent(map.getSize());
        const bottomLeft = toLonLat(getBottomLeft(extent));
        const topRight = toLonLat(getTopRight(extent));
        const zoom = view.getZoom()
        onMoveEnd({ topRight: { lon: topRight[0], lat: topRight[1] }, bottomLeft: { lon: bottomLeft[0], lat: bottomLeft[1] }}, zoom)
      });
    }
    this.setState({ ...this.state, map, isMounted: true });
  };

  componentWillUnmount = () => {
    this.state.map?.dispose();
  }

  componentDidUpdate(prevProps: any, nextProps: any) {
    if (prevProps.refreshSizeToken !== nextProps.refreshSizeToken) {
      this.state.map?.updateSize()
    }
  }

  initMap(props: OpenLayersMapProps) {
    const { id, mouseWheelZoomSettings, doubleClickZoom } = props;
    const mapOptions = {
      target: id,
      mouseWheelZoomSettings: mouseWheelZoomSettings || 'default',
      doubleClickZoom: typeof (doubleClickZoom) === 'undefined' ? true : doubleClickZoom
    };
    return createMap(mapOptions);
  }

  renderChildren = (props: any, map: Map) => {
    const _children: ReactElement[] = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children];
    const renderedChildren: ReactElement[] = _children.map(
      (c: ReactElement, idx: number) =>
        React.createElement(c.type, { ...c.props, map: map, key: idx })
    );
    return renderedChildren;
  };

  render = () => {
    const { id, left, right, top, height, heightVh, marginBottom } = this.props; 
    
    const _height = height || `${heightVh}vh`
    const width = "calc(100% - " + left + "px - " + right + "px)";

    return (
      <div
        key={id}
        id={id}
        style={{ position: "relative", top, left, width, height: _height, marginBottom: marginBottom || 0 }}
      >
        {this.state.map && this.renderChildren(this.props, this.state.map)}
      </div>
    );
  };
}
export default OpenLayersMap;
