import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { MANAGE_ASSESSMENTS_PAGE_SET_FILTER, MANAGE_ASSESSMENTS_PAGE_SET_ASSESSMENTS } from './manageAssessmentsPageActions'

const initialState = {
    filter: { page: 1, limit: 50, vurderingsrundeId: 2030, speciesName: '', speciesFamily: '' },
    assessments: [],
    pages: 1,
    count: 0
}

const adminEntities = (state = initialState, action : FixMeLater) => {
    switch (action.type) {  
      case MANAGE_ASSESSMENTS_PAGE_SET_ASSESSMENTS:
        return { ...state, assessments: action.payload.assessments, count: action.payload.count, pages: action.payload.pages }
      case MANAGE_ASSESSMENTS_PAGE_SET_FILTER: 
        return { ...state, filter: action.payload }
    default:
        return state
    }
  }
  
  export default adminEntities