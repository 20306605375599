import RedlistApi from '../../service/redlistApi'

import {
    put,
    takeLatest,
    takeEvery
} from 'redux-saga/effects'

import {
    showSpinner,
    hideSpinner,
    showMessage,
    showError
} from '../actions/uiActions'
import { FETCH_ADM_ENTITY, SET_ADM_ENTITY, fetchUsergroups, fetchAssessors, fetchVurderingsrunder, FETCH_ALL_ADMIN_ENTITIES, fetchUsers } from './adminEntitiesActions'
import { apiRequest2 } from '../api/apiRequest'

function* processFetchAllEntities(action) {
    try {
        yield put(fetchUsergroups())
        yield put(fetchAssessors())
        yield put(fetchUsers())
        yield put(fetchVurderingsrunder())
    } catch (error) {
        yield put(showError(error))
    }
}

function* processFetchEntities(action) {
    const procId = 'processFetchEntities'
    try {
        yield put(showSpinner(procId))
        yield apiRequest2(RedlistApi, RedlistApi.loadAdminEntities, [action.meta.entity], {
            "ok": data => ({
                type: SET_ADM_ENTITY,
                payload: data.items,
                meta: action.meta
            }),
            "error": showMessage('Fejl', action.meta.entity + ' kunne ikke hentes')
        })        
        yield put(hideSpinner(procId))
    } catch (error) {
        console.log('error', error)
        yield put(hideSpinner(procId))
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeEvery(FETCH_ADM_ENTITY, processFetchEntities)
    yield takeLatest(FETCH_ALL_ADMIN_ENTITIES, processFetchAllEntities)
}