import { all } from 'redux-saga/effects'

import loginWatcher from './loginSagas'

// Edit assessment
import editAssessmentWatcher from '../editAssessment/editAssessmentSagas'
import geographyMapWatcher from '../editAssessment/geographyMapSagas'
import categoryChangeWatcher from '../editAssessment/categoryChangeSagas'
import updatePropertyWatcher from '../editAssessment/updatePropertySagas'
import editAssessmentThreatWatcher from '../editAssessment/editAssessmentThreatSagas'
import editLevestedWatcher from '../editAssessment/editLevestedSagas'
import editSupplerendeOplysningerWatcher from '../editAssessment/editSupplerendeOplysningerSagas'
import initEditAssessmentWatcher from '../editAssessment/initEditAssessmentSagas'
import updateAssessmentCriteriaWatcher from '../editAssessment/editAssessmentValidationSagas'
import editAssessmentCommentsWatcher from '../editAssessment/editAssessmentCommentsSagas'

// Admin
import adminEntitiesWatcher from '../admin/adminEntitiesSagas'
import manageAssessmentsPageWatcher from '../admin/manageAssessmentsPageSagas'
import createAssessmentsPageWatcher from '../admin/createAssessmentsPageSagas'
import manageUserPageWatcher from '../admin/manageUserPageSagas'
import manageAssessorsPageWatcher from '../admin/manageAssessorsPageSagas'
import manageSpeciesPageWatcher from '../admin/manageSpeciesPageSagas'
import managePubliceringerPageWatcher from '../admin/managePubliceringerPageSagas'

// Ekspert
import ekspertEntitiesWatcher from '../ekspert/ekspertEntitiesSagas'
import viewAssessmentsPageWatcher from '../ekspert/viewAssessmentsPageSagas'
import viewQaAssessmentsPageWatcher from '../ekspert/viewQAAssessmentsPageSagas'
import assessmentQaPageWatcher from '../ekspert/assessmentQAPageSagas'
import viewFagligKommenteringPageWatcher from '../ekspert/viewFagligKommenteringPageSagas'
import userProfilePageWatcher from '../ekspert/userProfilePageSagas'

import apiWatcher from '../api/apiSagas'
import crudPageWatcher from '../../genericCrud/sagas/crudPageSagas'


export default function* rootSaga() {
    yield all([
        loginWatcher(),
        initEditAssessmentWatcher(),
        editAssessmentWatcher(),
        geographyMapWatcher(),
        categoryChangeWatcher(),
        editAssessmentCommentsWatcher(),
        updatePropertyWatcher(),
        updateAssessmentCriteriaWatcher(),
        editLevestedWatcher(),
        editAssessmentThreatWatcher(),
        editSupplerendeOplysningerWatcher(),
        adminEntitiesWatcher(),
        manageAssessmentsPageWatcher(),
        createAssessmentsPageWatcher(),
        manageUserPageWatcher(),
        manageAssessorsPageWatcher(),
        manageSpeciesPageWatcher(),
        managePubliceringerPageWatcher(),
        ekspertEntitiesWatcher(),
        viewAssessmentsPageWatcher(),
        viewQaAssessmentsPageWatcher(),
        viewFagligKommenteringPageWatcher(),
        assessmentQaPageWatcher(),
        userProfilePageWatcher(),
        apiWatcher(),
        crudPageWatcher()
    ])
}