export interface SectionType {
    id: number;
    title: string;
    component: string;
    validationProperty: string;
    section?: string;
    submenuComponent?: string;
}

export interface SectionsType {
    preliminary: SectionType;
    reduction: SectionType;
    geography: SectionType;
    population: SectionType;
    quantitativeAnalysis: SectionType;
    adjustment: SectionType;
    levesteder: SectionType;
    trusler: SectionType;
    supplerendeOplysninger: SectionType;
    kategoriskift2010: SectionType;
    kategoriskift2019: SectionType;
}

export const sections : SectionsType = {
    "preliminary": { id: 0, title: 'Indledende vurdering', component: 'IndledendeVurdering', validationProperty: 'preliminary', section: 'preliminary' },
    "reduction": { id: 1, title: 'Tilbagegang i bestand', component: 'TilbagegangIBestand', validationProperty: 'reduction', section: 'reduction' },
    "geography": { id: 2, title: 'Geografisk udbredelse', component: 'GeografiskUdbredelse', validationProperty: 'geography', section: 'geography' },
    "population": { id: 3, title: 'Bestandsstørrelse', component: 'Bestandsstorrelse', validationProperty: 'population', section: 'population' },
    "quantitativeAnalysis": { id: 4, title: 'Kvantitativ analyse', component: 'KvantitativAnalyse', validationProperty: 'quantitativeAnalysis', section: 'quantitativeAnalysis' },
    "adjustment": { id: 5, title: 'Op/ned-kategorisering', component: 'OpNedKategorisering', validationProperty: 'adjustment', section: 'adjustment' },
    "levesteder": { id: 6, title: 'Levestedsangivelse', component: 'Levestedsangivelse', validationProperty: 'levesteder', submenuComponent: 'LevestedsMenu', section: 'levesteder' },
    "trusler": { id: 7, title: 'Trusler', component: 'Trusler', validationProperty: 'threat', section: 'trusler' },
    "supplerendeOplysninger": { id: 8, title: 'Supplerende informationer', component: 'SupplerendeOplysninger', validationProperty: 'supplerendeOplysninger', section: 'supplerendeOplysninger' },
    "kategoriskift2010": { id: 9, title: 'Sammenligning af kategori med 2010', component: 'XCategoryChangeReason2010', validationProperty: 'categoryChange2010', section: 'kategoriskift2010' },
    "kategoriskift2019": { id: 10, title: 'Sammenligning af kategori med 2019', component: 'XCategoryChangeReason2019', validationProperty: 'categoryChange2019', section: 'kategoriskift2019' }
}

export const sectionMenuList : SectionType[] = [
    sections.preliminary,
    sections.reduction,
    sections.geography,
    sections.population,
    sections.quantitativeAnalysis,
    sections.adjustment,
    sections.levesteder,
    sections.trusler,
    sections.supplerendeOplysninger,
    sections.kategoriskift2010,
    sections.kategoriskift2019,
    { id: 11, title: 'Send til kvalitetssikring', component: 'Kvittering', validationProperty: 'kvittering' }
]
