import RedlistApi from '../../service/redlistApi'

import {
    put,
    takeEvery,
    select
} from 'redux-saga/effects'

import {
    SAVE_OVERALL_THREATS,
    SET_OVERALL_THREATS,
    SAVE_THREATS,
    SET_THREATS,
    ADD_THREAT,
    REMOVE_THREAT,
    saveThreats,
    saveOverallThreats,
    ADD_OVERALL_THREAT,
    REMOVE_OVERALL_THREAT,
    validateAssessment,
    SAVE_THREATMETA,
    SET_THREATMETA,
} from '../editAssessment/editAssessmentActions'

import {
    showSaving,
    hideSaving,
    confirmAction,
    showError
} from '../actions/uiActions'
import { apiRequest } from '../api/apiRequest'

function* processAddThreat(action) {
    const { assessmentId, threat } = action.payload
    let selectedThreats = yield select(state => state.editAssessment.threat.threats)
    let selectedOverallThreats = yield select(state => state.editAssessment.threat.overallThreats)
    if (!selectedThreats) {
        selectedThreats = []
    }
    if (!selectedOverallThreats.find(sot => sot.overallThreatDefinitionId === threat.overallThreatDefinitionId)) {
        const newOverallThreatIds = selectedOverallThreats.map(e => e.overallThreatDefinitionId).concat([threat.overallThreatDefinitionId])
        yield put(saveOverallThreats(assessmentId, newOverallThreatIds))
    }
    let selectedThreatIds = selectedThreats.map(t => t.threatDefinitionId)
    if (selectedThreatIds.find(id => id === threat.id)) {
        return
    }
    yield put(saveThreats(assessmentId, selectedThreatIds.concat([threat.id])))
}

function* processRemoveThreat(action) {
    const { assessmentId, threat } = action.payload
    let selectedThreats = yield select(state => state.editAssessment.threat.threats)
    if (!selectedThreats) {
        return
    }
    let selectedThreatIds = selectedThreats.map(t => t.threatDefinitionId)
    const threatIds = selectedThreatIds.filter(id => id !== threat.id)
    yield put(saveThreats(assessmentId, threatIds))
}

function* processAddOverallThreat(action) {
    const { assessmentId, overallThreatId } = action.payload
    let selectedOverallThreats = yield select(state => state.editAssessment.threat.overallThreats)
    if (!selectedOverallThreats) {
        selectedOverallThreats = []
    }
    
    let overallThreatIds = selectedOverallThreats.map(t => t.overallThreatDefinitionId)
    if (overallThreatIds.find(id => id === overallThreatId)) {
        return
    }
    yield put(saveOverallThreats(assessmentId, overallThreatIds.concat([overallThreatId])))
}

function* processRemoveOverallThreat(action) {
    const { assessmentId, overallThreatId } = action.payload
    const { threats } = yield select(state => state.ekspertEntities.threatDefinition)
    let selectedOverallThreats = yield select(state => state.editAssessment.threat.overallThreats)

    if (!selectedOverallThreats) {
        return
    }
    const selectedOverallThreatIds = selectedOverallThreats.map(t => t.overallThreatDefinitionId)
    const overallThreatIds = selectedOverallThreatIds.filter(id => id !== overallThreatId)

    const selectedThreats = yield select(state => state.editAssessment.threat.threats)    
    const selectedThreatDefinitions = selectedThreats.map(st => threats.find(td => td.id === st.threatDefinitionId))
    const threatsThatWillBeRemoved = selectedThreatDefinitions.filter(td => td.overallThreatDefinitionId === overallThreatId)
    
    if (threatsThatWillBeRemoved.length > 0) {
        const remainingThreatIds = selectedThreatDefinitions.filter(td => td.overallThreatDefinitionId !== overallThreatId).map(td => td.id)
        yield put(confirmAction('Dette vil fjerne indtastede værdier', threatsThatWillBeRemoved.map(t => t.name), [
            saveOverallThreats(assessmentId, overallThreatIds),
            saveThreats(assessmentId, remainingThreatIds)
        ]))        
    } else {        
        yield put(saveOverallThreats(assessmentId, overallThreatIds))
    }
}

function* processSaveThreatMeta(action) {
    const {
        assessmentId,
        threatMeta
    } = action.payload
    const prevThreatmeta = yield select(state => state.editAssessment.threat.meta)
    console.log('prev: ', { prevThreatmeta})
    console.log('payload: ', { assessmentId, threatMeta})
    try {
        console.log("showsaving")
        yield put(showSaving())
        console.log("set threat meta", threatMeta)
        yield put({ type: SET_THREATMETA, payload: { threatMeta }})
        console.log("send to api", threatMeta)
        yield apiRequest(RedlistApi, RedlistApi.updateThreatMeta, [assessmentId, threatMeta])
        yield put(hideSaving())
    } catch (error) {
        console.log('error: ', {error, prevThreatmeta})
        yield put({ type: SET_THREATMETA, payload: { threats: prevThreatmeta } })
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processSaveThreats(action) {
    const {
        assessmentId,
        threatIds
    } = action.payload
    const previousThreats = yield select(state => state.editAssessment.threat.threats)
    try {
        yield put(showSaving())
        yield put({ type: SET_THREATS, payload: { threats: threatIds.map(id => ({ threatDefinitionId: id, assessmentId })) } })
        yield apiRequest(RedlistApi, RedlistApi.updateThreats, [assessmentId, threatIds])
        yield put(hideSaving())
    } catch (error) {
        yield put({ type: SET_THREATS, payload: { threats: previousThreats } })
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processSaveOverallThreats(action) {
    const {
        assessmentId,
        overallThreatIds
    } = action.payload
    const previousOverallThreats = yield select(state => state.editAssessment.threat.overallThreats)
    try {
        yield put(showSaving())
        yield put({ type: SET_OVERALL_THREATS, payload: { overallThreats: overallThreatIds.map(id => ({ overallThreatDefinitionId: id, assessmentId })) } })
        yield apiRequest(RedlistApi, RedlistApi.updateOverallThreats, [assessmentId, overallThreatIds])
        yield put(hideSaving())
    } catch (error) {
        yield put({ type: SET_OVERALL_THREATS, payload: { overallThreats: previousOverallThreats } })
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processSetThreats(action) {
    try {
        const {
            threats
        } = action.payload
        const criteria  = yield  select(state => state.editAssessment.assessmentCriteria)
        const supplerendeOplysninger = yield  select(state => state.editSupplerendeOplysninger)
        const levested = yield select(state => state.editLevested)    
        const threat = yield select(state => state.editAssessment.threat)
        yield put(validateAssessment(criteria, supplerendeOplysninger, levested, { ...threat, threats }))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetThreatMeta(action) {
    try {
        const {
            threatMeta
        } = action.payload
        const criteria  = yield  select(state => state.editAssessment.assessmentCriteria)
        const supplerendeOplysninger = yield  select(state => state.editSupplerendeOplysninger)
        const levested = yield select(state => state.editLevested)    
        const threat = yield select(state => state.editAssessment.threat)
        yield put(validateAssessment(criteria, supplerendeOplysninger, levested, { ...threat, meta: threatMeta }))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetOverallThreats(action) {
    try {
        const {
            overallThreats
        } = action.payload
        const criteria  = yield  select(state => state.editAssessment.assessmentCriteria)
        const supplerendeOplysninger = yield  select(state => state.editSupplerendeOplysninger)
        const levested = yield select(state => state.editLevested)    
        const threat = yield select(state => state.editAssessment.threat)
        yield put(validateAssessment(criteria, supplerendeOplysninger, levested, { ...threat, overallThreats }))
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeEvery(ADD_THREAT, processAddThreat)
    yield takeEvery(REMOVE_THREAT, processRemoveThreat)
    yield takeEvery(ADD_OVERALL_THREAT, processAddOverallThreat)
    yield takeEvery(REMOVE_OVERALL_THREAT, processRemoveOverallThreat)
    yield takeEvery(SAVE_THREATS, processSaveThreats)
    yield takeEvery(SAVE_OVERALL_THREATS, processSaveOverallThreats)
    yield takeEvery(SAVE_THREATMETA, processSaveThreatMeta)
    yield takeEvery(SET_THREATS, processSetThreats)
    yield takeEvery(SET_OVERALL_THREATS, processSetOverallThreats)
    yield takeEvery(SET_THREATMETA, processSetThreatMeta)

}