import React from 'react'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'

import CheckboxButton from '../../mui/CheckboxButton'
import { ThemeProvider } from 'styled-components'

const buttonTheme = {
    background: '#ffe082',
    color: '#444',
    borderColor: '#ffecb3',
    checked: {
        background: '#ffc107',
        color: '#000',
        borderColor: '#ffb300'
    }
}

const renderEcosystem = (ecosystem, selectedEcosystems, onChange) => {
    let selected = selectedEcosystems.filter(e => {
        return e.id === ecosystem.id;
    }).length > 0;
    return (
        <Grid key={ecosystem.id} item xs={12} sm={6} md={4}>
            <CheckboxButton 
                fullwidth 
                onChange={checked => onChange(ecosystem, checked)} 
                key={ecosystem.id} 
                label={ecosystem.name}
                tooltip={ecosystem.name} 
                checked={selected} />
        </Grid>)
}

const Ecosystems = (props) => {
    const { value, ecosystems, hovedmiljoer, onSelectedChanged, onProceed } = props;

    const selectionChanged = (ecosystem, checked) => {
        let _t = value.filter(e => e.id !== ecosystem.id);
        if (checked) {
            _t = _t.concat([ecosystem]);
        }
        onSelectedChanged(_t);
    }
    const availableEcosystems = ecosystems.filter(e => {
        if (hovedmiljoer.length === 0) {
            return true
        }
        return hovedmiljoer.find(h => h.slug === e.hovedmiljoSlug)
    })

    return (
        <Grid container spacing={2}>

            <Grid item container xs={12} lg={11} spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>Angiv økosystemer</Typography>
                </Grid>
                <ThemeProvider theme={buttonTheme}>
                    {
                        availableEcosystems.map(e => renderEcosystem(e, value, selectionChanged))
                    }
                </ThemeProvider>
                <Grid xs={12} item>
                    <Button style={{ float: 'right' }} onClick={() => onProceed()} color='primary' variant='contained'>Videre</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Ecosystems