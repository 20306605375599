import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Typography,
    Grid,
    Fade
} from '@material-ui/core'

import TextAreaReference from '../components/TextAreaReference'
import BooleanRadioGroup from '../components/BooleanRadioGroup'
import RadioGroupReference from '../components/RadioGroupReference'
import { oddRowStyle } from '../components/styles'
import {
    updateUpDownAdjustment,
    updateUpDownAdjustmentBreeding,
    updateUpDownAdjustmentVisiting,
    saveSection
} from '../../redux/editAssessment/editAssessmentActions'
import Footer from '../components/Footer'
import {
    adjustmentQuestions
} from './questions'
import { adjustmentPreliminaryEnum } from './enums/adjustmentEnums'
import {
    AffectedByNeighboursHelpElement,
    PreliminaryAdjustmentHelpElement,
    Q3aHelpElement,
    Q3bHelpElement,
    Q3cHelpElement,
    Q3dHelpElement,
    Q3eHelpElement,
    Q3fHelpElement
} from './help/adjustmentHelp'
import GlobalErrorMessage from '../components/GlobalErrorMessage'
import { extractPropertyValidationResult } from './validators/sectionValidators'

const yesY = 'Ja / Ved ikke'
const noY = 'Nej / Usandsynligt'
const yesN = 'Ja / Sandsynligt'
const noN = 'Nej / Ved ikke'

const VisitingPopulation = props => {
    const {
        referenceAdjustment,
        deterioratingConditionsOutsideRegion,
        setDeterioratingConditionsOutsideRegion,
        deterioratingConditionsInsideRegion,
        setDeterioratingConditionsInsideRegion,
        sourceSinkDynamicsAcrossBorders,
        setSourceSinkDynamicsAcrossBorders,
        validationResult
    } = props

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <img src='./visiting_figure.png' alt='Fig. 3 visiting' />
            </Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'deterioratingConditionsOutsideRegion')}
                    help={() => <Q3dHelpElement />}
                    yesLabel={yesY}
                    noLabel={noY}
                    label={adjustmentQuestions.deterioratingConditionsOutsideRegion.label}
                    ghost={referenceAdjustment && referenceAdjustment.deterioratingConditionsOutsideRegion}
                    value={deterioratingConditionsOutsideRegion}
                    onChange={value => setDeterioratingConditionsOutsideRegion(value)}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'deterioratingConditionsInsideRegion')}
                    help={() => <Q3eHelpElement />}
                    yesLabel={yesY}
                    noLabel={noY}
                    ghost={referenceAdjustment && referenceAdjustment.deterioratingConditionsInsideRegion}
                    disabled={deterioratingConditionsOutsideRegion !== false}
                    label={adjustmentQuestions.deterioratingConditionsInsideRegion.label}
                    value={deterioratingConditionsInsideRegion}
                    onChange={value => setDeterioratingConditionsInsideRegion(value)}
                />
            </Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'sourceSinkDynamicsAcrossBorders')}
                    help={() => <Q3fHelpElement />}
                    yesLabel={yesN}
                    noLabel={noN}
                    disabled={deterioratingConditionsInsideRegion !== false}
                    ghost={referenceAdjustment && referenceAdjustment.sourceSinkDynamicsAcrossBorders}
                    label={adjustmentQuestions.sourceSinkDynamicsAcrossBorders.label}
                    value={sourceSinkDynamicsAcrossBorders}
                    onChange={value => setSourceSinkDynamicsAcrossBorders(value)}
                /></Grid>
        </React.Fragment>
    )
}


const BreedingPopulation = props => {
    const {
        referenceAdjustment,
        immigrantPropagulesReproducing,
        setImmigrantPropagulesReproducing,
        decreaseInPropagulesExpected,
        setDecreaseInPropagulesExpected,
        regionalSink,
        setRegionalSink,
        validationResult
    } = props
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <img src='./breeding_figure.png' alt='Fig. 3' />
            </Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'immigrantPropagulesReproducing')}            
                    help={() => <Q3aHelpElement />}
                    yesLabel={yesN}
                    noLabel={noN}
                    ghost={referenceAdjustment && referenceAdjustment.immigrantPropagulesReproducing}
                    label={adjustmentQuestions.immigrantPropagulesReproducing.label}
                    value={immigrantPropagulesReproducing}
                    onChange={value => setImmigrantPropagulesReproducing(value)}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'decreaseInPropagulesExpected')}            
                    help={() => <Q3bHelpElement />}
                    yesLabel={yesY}
                    noLabel={noY}
                    disabled={immigrantPropagulesReproducing !== true}
                    ghost={referenceAdjustment && referenceAdjustment.decreaseInPropagulesExpected}
                    label={adjustmentQuestions.decreaseInPropagulesExpected.label}
                    value={decreaseInPropagulesExpected}
                    onChange={value => setDecreaseInPropagulesExpected(value)}
                /></Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'regionalSink')}            
                    help={() => <Q3cHelpElement />}
                    yesLabel={yesN}
                    noLabel={noN}
                    disabled={decreaseInPropagulesExpected !== true}
                    ghost={referenceAdjustment && referenceAdjustment.regionalSink}
                    label={adjustmentQuestions.regionalSink.label}
                    value={regionalSink}
                    onChange={value => setRegionalSink(value)}
                /></Grid>
        </React.Fragment>
    )
}

/*
const visiting = 'visiting'
const breeding = 'breeding'
export const adjustmentPreliminaryOptions = [
    option(0, 'ikke_angivet', 'Ikke angivet'),
    option(1, 'kolonisering', 'Bestanden er i fremgang i nabolandene, men endnu meget lille i DK (Der er tale om en koloniseringsfase, nedkategorisér to trin)'),
    option(2, 'demografisk_ustabil', 'Den nationale bestand er demografisk ustabil og forventes samtidig en tilbagegang i nabolandenes bestande (opkategorisér ét trin)'),
    option(3, visiting, 'Arten er en ikke-reproducerende gæst'),
    option(4, breeding, 'Arten yngler i Danmark')
]
*/

const E_OpNedKategorisering = (props) => {
    const dispatch = useDispatch()
    const assessmentId = useSelector(state => state.editAssessment.assessmentId)
    const currentCriteria = useSelector(state => state.editAssessment.assessmentCriteria)
    const validationResult = useSelector(state => state.editAssessment.criteriaValidationResult?.adjustment)
    const currentAdjustment = currentCriteria ? currentCriteria.adjustment : {}
    const { referenceArt, onClose, onNext } = props
    const referenceAdjustment = referenceArt ? referenceArt.assessmentCriteria.adjustment : null

    const {
        affectedByNeighbours
    } = currentAdjustment
    const adjustmentRemark = currentAdjustment.adjustmentRemark || ''

    const preliminaryAdjustment = currentAdjustment.preliminaryAdjustment || adjustmentPreliminaryEnum.getNullValue().value
    //const [categoryShouldNotBeAdjusted, setCategoryShouldNotBeAdjusted] = useState(ukendt)
    //const [colonizationPhase, setColonizationPhase] = useState(ukendt)
    //const [unstableNationalPopulationAndReductionInForeignPopulations, setUnstableNationalPopulationAndReductionInForeignPopulations] = useState(ukendt)
    //const [nonReproducingGuest, setNonReproducingGuest] = useState(ukendt)

    // Breeding props
    const {
        immigrantPropagulesReproducing,
        decreaseInPropagulesExpected,
        regionalSink
    } = currentAdjustment

    // Visiting props
    const {
        deterioratingConditionsOutsideRegion,
        deterioratingConditionsInsideRegion,
        sourceSinkDynamicsAcrossBorders
    } = currentAdjustment

    const visiting = adjustmentPreliminaryEnum.values().visiting.value
    const breeding = adjustmentPreliminaryEnum.values().breeding.value


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    Op/ned-kategorisering
                </Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>

            <Grid item xs={12}>
                <BooleanRadioGroup
                    help={() => <AffectedByNeighboursHelpElement />}
                    ghost={referenceAdjustment && referenceAdjustment.affectedByNeighbours}
                    label={adjustmentQuestions.affectedByNeighbours.label}
                    value={affectedByNeighbours}
                    onChange={value => dispatch(updateUpDownAdjustment('affectedByNeighbours', value))}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <RadioGroupReference
                    validation={extractPropertyValidationResult(validationResult, 'preliminaryAdjustment')}
                    help={() => <PreliminaryAdjustmentHelpElement />}
                    referenceValue={referenceAdjustment && referenceAdjustment.preliminaryAdjustment}
                    values={adjustmentPreliminaryEnum.asList()}
                    value={preliminaryAdjustment}
                    label={adjustmentQuestions.preliminaryAdjustment.label}
                    onChange={value => dispatch(updateUpDownAdjustment('preliminaryAdjustment', value))}
                />
            </Grid>
            <Grid item container xs={12}>
                <Fade timeout={{ enter: 1450, exit: 0 }} direction='left' in={preliminaryAdjustment === visiting} mountOnEnter unmountOnExit>
                    <div style={{}}>
                        <VisitingPopulation
                            validationResult={validationResult}
                            referenceAdjustment={referenceAdjustment}
                            deterioratingConditionsOutsideRegion={deterioratingConditionsOutsideRegion}
                            setDeterioratingConditionsOutsideRegion={value => dispatch(updateUpDownAdjustmentVisiting('deterioratingConditionsOutsideRegion', value))}
                            deterioratingConditionsInsideRegion={deterioratingConditionsInsideRegion}
                            setDeterioratingConditionsInsideRegion={value => dispatch(updateUpDownAdjustmentVisiting('deterioratingConditionsInsideRegion', value))}
                            sourceSinkDynamicsAcrossBorders={sourceSinkDynamicsAcrossBorders}
                            setSourceSinkDynamicsAcrossBorders={value => dispatch(updateUpDownAdjustmentVisiting('sourceSinkDynamicsAcrossBorders', value))}
                        />
                    </div>
                </Fade>
                <Fade timeout={{ enter: 1450, exit: 0 }} direction='right' in={preliminaryAdjustment === breeding} mountOnEnter unmountOnExit>
                    <div style={{ backgroundColor: '#fff' }}>
                        <BreedingPopulation
                            validationResult={validationResult}
                            referenceAdjustment={referenceAdjustment}
                            immigrantPropagulesReproducing={immigrantPropagulesReproducing}
                            setImmigrantPropagulesReproducing={value => dispatch(updateUpDownAdjustmentBreeding('immigrantPropagulesReproducing', value))}
                            decreaseInPropagulesExpected={decreaseInPropagulesExpected}
                            setDecreaseInPropagulesExpected={value => dispatch(updateUpDownAdjustmentBreeding('decreaseInPropagulesExpected', value))}
                            regionalSink={regionalSink}
                            setRegionalSink={value => dispatch(updateUpDownAdjustmentBreeding('regionalSink', value))}
                        />
                    </div>
                </Fade>
            </Grid>
            <Grid xs={12} item>
                <TextAreaReference
                    value={adjustmentRemark}
                    onChange={value => dispatch(updateUpDownAdjustment('adjustmentRemark', value))}
                    rowStyle={preliminaryAdjustment === visiting || preliminaryAdjustment === breeding ? oddRowStyle : null}
                    title={adjustmentQuestions.adjustmentRemark.title}
                    referenceTitle='Referenceart'
                    referenceValue={referenceAdjustment && referenceAdjustment.adjustmentRemark}
                    />
            </Grid>
            <Footer
                onNext={() => {
                    dispatch(saveSection('adjustment', assessmentId, currentAdjustment))
                    onNext()
                }}
                onClose={() => onClose()} />        </Grid>
    )
}

export default E_OpNedKategorisering