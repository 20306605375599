import React from 'react'
import { TextHelpElement } from './help'
import { additionalQuestions } from '../questions'

export const FirstRecordHelpElement = props => (
    <TextHelpElement title={additionalQuestions.firstRecord.label}>
        Udfyld hvis oplysningen er kendt
    </TextHelpElement>
)

export const LatestRecordHelpElement = props => (
    <TextHelpElement title={additionalQuestions.latestRecord.label}>
        Udfyld hvis oplysningen er kendt. Årstal for fund af strejfende og/eller endnu ikke etablerede individer angives ikke.
    </TextHelpElement>
)

export const AntalLokaliteterHelpElement = props => (
    <TextHelpElement title={additionalQuestions.antalLokaliteter.label}>
        Det kan være meget svært/umuligt at angive, men hvis man ved det, udfyldes dette felt. Tallet må gerne være et cirkatal.
    </TextHelpElement>
)

export const TrendHelpElement = props => (
    <TextHelpElement title={additionalQuestions.trend.label}>
        Her angives den overordnede samlede trend for arten. Denne angivelse baseres på en ekspertvurdering ud fra den nuværende viden.
        Dette felt behøver man ikke udfylde, før den automatiske rødlistevurdering er foretaget, baseret på de data der indtastes ovenfor.
        Det er meget vigtigt at det udfyldes, så man har mulighed for at analysere på arternes status i Danmark.
    </TextHelpElement>
)

export const HistoriskTrendHelpElement = props => (
    <TextHelpElement title={additionalQuestions.historiskTrend.label}>
        Beskriv hvordan artens status i Danmark har udviklet sig historisk.
    </TextHelpElement>
)

export const GeographicExtentAcrossDenmarkHelpElement = props => (
    <TextHelpElement title={additionalQuestions.geographicExtentAcrossDenmark.label}>
        Svar ja, hvis artens geografiske udbredelsesgrænse går gennem Danmark. Ellers nej.
    </TextHelpElement>
)

export const IsNativeHelpElement = props => (
    <TextHelpElement title={additionalQuestions.isNative.label}>
        Det kan være meget svært at svare på, og i mange tilfælde er det en uafklaret diskussion, 
        men hvis man ved det, besvares spørgsmålet. 
        Man kan evt. svare usikkert, hvis der stadig ikke er klar enighed om det.
    </TextHelpElement>
)

export const NationalForekomstHelpElement = props => (
    <TextHelpElement title={additionalQuestions.nationalForekomst.label}>
        Her kan den nationale forekomst af arten beskrives med ord.
    </TextHelpElement>
)

export const TotaludbredelseHelpElement = props => (
    <TextHelpElement title={additionalQuestions.totaludbredelse.label}>
        Her kan den totale udbredelse af arten beskrives med ord.
    </TextHelpElement>
)

export const NomenklaturHelpElement = props => (
    <TextHelpElement title={additionalQuestions.nomenklatur.label}>
        Angiv eventuelle bemærkninger til nomenklaturen.
    </TextHelpElement>
)

export const LitteraturHelpElement = props => (
    <TextHelpElement title={additionalQuestions.litteratur.label}>
        Angiv litteratur, hvis det ikke er angivet i dokumentationen til de enkelte datafelter.
    </TextHelpElement>
)

export const RemarkSupplerendeOplysningerHelpElement = props => (
    <TextHelpElement title={additionalQuestions.remarkSupplerendeOplysninger.label}>
        Her er der mulighed for at angive yderligere information og dokumentation, 
        som er relevant i forhold til de supplerende oplysninger om den pågældende art.
    </TextHelpElement>
)

export const AdditionalCommentsHelpElement = props => (
    <TextHelpElement title={additionalQuestions.additionalComments.label}>
        Her er der mulighed for at angive yderligere oplysninger, der er relevante 
        i forhold til den samlede proces med rødlistning og/eller de supplerende oplysninger.
    </TextHelpElement>
)
/*
    remarkSupplerendeOplysninger: question('remarkSupplerendeOplysninger', 'Bemærkninger til supplerende oplysninger'),
    additionalComments: question('additionalComments', 'Yderligere bemærkninger', 'Yderligere bemærkninger/kommentarer')
*/