import { createEnum, createEnumValue } from './enums'


export const trends = [
    { value: 'ikke_angivet', id: 0, label: 'Ikke angivet' },
    { value: 'tilbage', label: 'I tilbagegang', id: 3 },
    { value: 'stabil', label: 'Stabil', id: 2 },
    { value: 'fremgang', label: 'I fremgang', id: 1 },
    { value: 'ukendt', id: 4, label: 'Ukendt' }
]

export const trendsObject = {
    ikkeAngivet: createEnumValue('ikke_angivet', "Ikke angivet", 0, null, true, 0),
    tilbage: createEnumValue('tilbage', 'I tilbagegang', 1, null, false, 3),
    stabil: createEnumValue('stabil', 'Stabil', 2, null, false, 2),
    fremgang: createEnumValue('fremgang', 'I fremgang', 3, null, false, 1),
    ukendt: createEnumValue('ukendt', 'Ukendt', 4, null, false, 4),
}

export const trendsEnum = createEnum(trendsObject)

export const isNativeObject = {
    ikkeAngivet: createEnumValue('ikke_angivet', "Ikke angivet", 0, null, true),
    ja: createEnumValue('ja', "Ja", 1),
    nej: createEnumValue('nej', "Nej", 2),
    usikkert: createEnumValue('usikkert', 'Usikkert', 3)
}

export const isNativeEnum = createEnum(isNativeObject)