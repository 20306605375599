import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
  MANAGE_ASSESSORS_PAGE_CREATE_OK,
  MANAGE_ASSESSORS_PAGE_HIDE_CREATE_DIALOG,
  MANAGE_ASSESSORS_PAGE_SET_SELECTED_ASSESSOR,
  MANAGE_ASSESSORS_PAGE_SHOW_CREATE_DIALOG,
} from './manageAssessorsPageActions'

const initialState = {
  queryId: '0',
  showCreateAssessor: false,
  createAssessorError: null,
  editAssessorError: null,
  showEditAssessor: false,
  selectedAssessor: null
}

const manageAssessorsPageReducer = (state = initialState, action : FixMeLater) => {
  switch (action.type) {
    case MANAGE_ASSESSORS_PAGE_SET_SELECTED_ASSESSOR:
      return {
        ...state,
        selectedAssessor: action.payload,
      }
    case MANAGE_ASSESSORS_PAGE_HIDE_CREATE_DIALOG:
      return {
        ...state,
        showCreateAssessor: false,
        createAssessorError: null
      }
    case MANAGE_ASSESSORS_PAGE_SHOW_CREATE_DIALOG:
      return {
        ...state,
        showCreateAssessor: true,
        createAssessorError: null
      }
    case MANAGE_ASSESSORS_PAGE_CREATE_OK:
      return {
        ...state,
        showCreateAssessor: false,
        createAssessorError: null
      }
    default:
      return state
  }
}

export default manageAssessorsPageReducer
