import {
  NEXT_SECTION,
  SET_CURRENT_SECTION,
  setCurrentSection,
  RESET_CURRENT_SECTION,
  SET_NEXT_SUBMENU,
  nextSection,
  setCurrentSubmenu
} from '../editAssessment/editAssessmentMenuActions';
import { DISABLE_GOBACK } from '../actions/uiActions';
import { menus, filterAvailableMenus } from '../../assessments/LevestedSubmenu'

export const processNextSection = ({dispatch, getState}) => next => action => {
  next(action)
  if (action.type === NEXT_SECTION) {
    dispatch(setCurrentSection(getState().editAssessmentMenu.currentSection.id + 1))
  }
}


export const processReset = ({dispatch, getState}) => next => action => {
  next(action)
  if (action.type === RESET_CURRENT_SECTION) {
    dispatch(setCurrentSection(0))
  }
}

export const processSetSection = ({dispatch, getState}) => next => action => {
  next(action)
  if (action.type === SET_CURRENT_SECTION) {
    window.scrollTo(0, 0)
  }
}

export const processNextSubmenu = ({dispatch, getState}) => next => action => {
  next(action)
  if (action.type === SET_NEXT_SUBMENU) {
    const activeSubmenuId = getState().editAssessmentMenu.currentSubmenu
    const {substrater} = getState().ekspertEntities.levestedsDefinition
    const selectedHovedmiljoer = getState().editLevested.hovedmiljoer
    const selectedLevestedstyper = getState().editLevested.levestedstyper
    const selectedEcosystems = getState().editLevested.ecosystems
    const selectedOverordnedeSubstrater = getState().editLevested.overordnedeSubstrater
    const selectedSubstrater = getState().editLevested.substrater
    const availableMenus = menus.filter(m => filterAvailableMenus(m, selectedHovedmiljoer, selectedLevestedstyper, selectedEcosystems, selectedOverordnedeSubstrater, selectedSubstrater, substrater))
    const menusAfterCurrent = availableMenus.filter(m => m.id > activeSubmenuId)
    if (menusAfterCurrent.length === 0) {
      dispatch(nextSection())
    } else {
      dispatch(setCurrentSubmenu(menusAfterCurrent[0].id))
    }
  }
}

export const processGoBack = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === DISABLE_GOBACK) {
    setTimeout(() => dispatch(setCurrentSection(0)), 25)
  }
}

export const editAssessmentMenuMdl = [
  processReset,
  processNextSection,
  processGoBack,
  processSetSection,
  processNextSubmenu
];