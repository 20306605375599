import React from 'react'

import { Collapse, ListItem, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Info'

import CommentIcon from '@material-ui/icons/Chat'
//import useMediaQuery from '@material-ui/core/useMediaQuery';

import { menuActiveStyle } from './styles'

import LevestedSubmenu from '../LevestedSubmenu'

const CommentIndicator = (props) => {
  if (!props.hasComments) {
    return null
  }
  return (
    <ListItemIcon style={{ paddingLeft: 24, paddingRight: 0, marginRight: -16 }}>
      <CommentIcon color="secondary" />
    </ListItemIcon>
  )
}

const ValidationIndicator = (props) => {
  const { validation } = props
  const marginRight = -16
  const paddingLeft = 16
  if (validation.warning) {
    return (
      <ListItemIcon style={{ paddingLeft, paddingRight: 0, marginRight }}>
        <Tooltip title={validation.message}>
          <WarningIcon color="secondary" />
        </Tooltip>
      </ListItemIcon>
    )
  }
  if (validation.ok && validation.empty) {
    return null
  }
  if (validation.ok) {
    return (
      <ListItemIcon style={{ paddingLeft, paddingRight: 0, marginRight }}>
        <CheckIcon color="primary" />
      </ListItemIcon>
    )
  } else {
    return (
      <ListItemIcon style={{ paddingLeft, paddingRight: 0, marginRight }}>
        <Tooltip title={validation.message || 'En eller flere fejl'}>
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      </ListItemIcon>
    )
  }
}

const EditMenuItem = (props) => {
  const { submenuComponent, active, title, validation, hasComments } = props
  if (submenuComponent) {
    return (
      <React.Fragment>
        <ListItem onClick={() => props.onClick()} button style={active ? menuActiveStyle : {}}>
          <ListItemText primary={title} />
          <CommentIndicator hasComments={hasComments} />
          <ValidationIndicator validation={validation} />
        </ListItem>
        <Collapse in={active} timeout="auto" unmountOnExit>
          {submenuToComponent[submenuComponent]()}
        </Collapse>
      </React.Fragment>
    )
  }
  return (
    <ListItem onClick={() => props.onClick()} button style={active ? menuActiveStyle : {}}>
      <ListItemText primary={title} />
      <CommentIndicator hasComments={hasComments} />
      <ValidationIndicator validation={validation} />
    </ListItem>
  )
}

const submenuToComponent = {
  LevestedsMenu: () => <LevestedSubmenu />,
}

export default EditMenuItem
