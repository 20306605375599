import redlistCategoryEngine from './categoryEngine'

const redlistCategoryView = function () {
    const self = {
        formatRoutes: function (routes) {
            if (!routes) {
                return "";
            }
            // The category route string
            var categoryRoute = "";
            for (var i = 0; i < routes.length; i += 1) {
                var cr = routes[i];
                // Ignore category routes for 'nt' and 'PRE' (Custom Near Threatened and Preliminary)
                if (cr.name.startsWith('nt') || cr.name.startsWith('PRE')) {
                    continue;
                }
                categoryRoute += cr.name.toUpperCase();
                for (var j = 0; j < cr.route.length; j += 1) {
                    categoryRoute += cr.route[j];
                }
                categoryRoute += "; ";
            }
            if (categoryRoute.length > 2) {
                categoryRoute = categoryRoute.substring(0, categoryRoute.length - 2);
            }
            return categoryRoute;
        },
        format: function (categoryResult, bolleSymbol) {
            if (!bolleSymbol) {
                bolleSymbol = String.fromCharCode(176) 
            }
            var category = categoryResult.category;
            var categoryModification = (categoryResult.modification && categoryResult.modification !== "") ? categoryResult.modification : 0;
            var modifiedCategory = redlistCategoryEngine.Categories.adjust(category, categoryModification);
            //console.log("Cat", category, "mod", categoryModification, "->", modifiedCategory)
            // Name of the Category (CR, EN, VU etc.) including the modification value (up/down adjustment)
            let categoryName = modifiedCategory ? modifiedCategory.name : "";
            let bolleCount = Math.abs(categoryModification);
            for (let i = 0; i < bolleCount; i += 1) {
                categoryName += bolleSymbol;
            }

            if (modifiedCategory === redlistCategoryEngine.Categories.LC) {
                return categoryName;
            }

            const categoryRoute = self.formatRoutes(categoryResult.routes)
            return categoryName + " " + categoryRoute;
        }
    }
    return self;
}();

export default redlistCategoryView