import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import {
      MANAGE_USER_PAGE_SET_USERS,
      MANAGE_USER_PAGE_SHOW_CREATE_DIALOG,
      MANAGE_USER_PAGE_HIDE_CREATE_DIALOG,
      MANAGE_USER_PAGE_SHOW_EDIT_DIALOG,
      MANAGE_USER_PAGE_HIDE_EDIT_DIALOG,
      MANAGE_USER_PAGE_CREATE_ERROR,
      MANAGE_USER_PAGE_CREATE_OK,
      MANAGE_USER_PAGE_SET_SELECTED_USER
} from "./manageUserPageActions"

const initialState = {
      queryId: '0',
      showCreateUser: false,
      createUserError: null,
      editUserError: null,
      showEditUser: false,
      selectedUser: null,
      users: []
}

const manageUserPageReducer = (state = initialState, action : FixMeLater) => {
      switch (action.type) {
            case MANAGE_USER_PAGE_CREATE_ERROR:
                  return {
                        ...state, createUserError: action.payload
                  }
            case MANAGE_USER_PAGE_CREATE_OK:
                  return {
                        ...state, createUserError: null
                  }
            case MANAGE_USER_PAGE_SHOW_CREATE_DIALOG:
                  return {
                        ...state, showCreateUser: true
                  }
            case MANAGE_USER_PAGE_HIDE_CREATE_DIALOG:
                  return {
                        ...state, showCreateUser: false, createUserError: null
                  }
            case MANAGE_USER_PAGE_SHOW_EDIT_DIALOG:
                  return {
                        ...state, showEditUser: true
                  }
            case MANAGE_USER_PAGE_SET_SELECTED_USER:
                  return { ...state, selectedUser: action.payload.user }
            case MANAGE_USER_PAGE_HIDE_EDIT_DIALOG:
                  return {
                        ...state, showEditUser: false, editUserError: null, selectedUser: null
                  }
            case MANAGE_USER_PAGE_SET_USERS:
                  return {
                        ...state, users: action.payload.users, queryId: new Date().getTime() + ''
                  }
            default:
                  return state
      }
}

export default manageUserPageReducer