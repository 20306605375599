import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormatAlignLeftRoundedIcon from '@material-ui/icons/FormatAlignLeftRounded';
import PeopleIcon from '@material-ui/icons/People';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PetsRoundedIcon from '@material-ui/icons/PetsRounded';
import GrainIcon from '@material-ui/icons/Grain';
import GridOnIcon from '@material-ui/icons/GridOn';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import EditIcon from '@material-ui/icons/Edit';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
//import PersonIcon from '@material-ui/icons/Person';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import { NavLink } from 'react-router-dom';

export const mainListItems = userrole => {
  return (
    <div>
      <ListItem button component={NavLink} activeClassName="Mui-selected" to="/vurderinger">
        <ListItemIcon>
          <FormatAlignLeftRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Vurderinger" />
      </ListItem>
      <ListItem button component={NavLink} activeClassName="Mui-selected" to="/kommentering">
        <ListItemIcon>
          <ChatBubbleOutlineRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Kommentering" />
      </ListItem>
      <ListItem button component={NavLink} activeClassName="Mui-selected" to="/qa">
        <ListItemIcon>
          <AssignmentTurnedInRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Kvalitetssikring" />
      </ListItem>
      {
        (userrole === 'GODKENDER' || userrole === 'ADMIN') && godkenderListItems
      }
      <ListItem button component={NavLink} activeClassName="Mui-selected" to="/profil">
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary='Profil' />
      </ListItem>
    </div>
  )
}

export const godkenderListItems = (
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/godkendelse">
      <ListItemIcon>
        <AssignmentTurnedInRoundedIcon />
      </ListItemIcon>
      <ListItemText primary="Godkendelse" />
    </ListItem>
);
//activeStyle={activeStyle} 

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Admin</ListSubheader>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/vurderinger">
      <ListItemIcon>
        <PlaylistAddRoundedIcon />
      </ListItemIcon>
      <ListItemText primary="Opret vurderinger" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/redigerVurderinger">
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText primary="Rediger vurderinger" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/arter">
      <ListItemIcon>
        <FingerprintIcon />
      </ListItemIcon>
      <ListItemText primary="Arter" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/familier">
      <ListItemIcon>
        <PetsRoundedIcon />
      </ListItemIcon>
      <ListItemText primary="Familier" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/artsGrupper">
      <ListItemIcon>
        <GrainIcon />
      </ListItemIcon>
      <ListItemText primary="Artsgrupper" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/ordener">
      <ListItemIcon>
        <GridOnIcon />
      </ListItemIcon>
      <ListItemText primary="Ordener" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/publiceringer">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Publiceringer" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/runder">
      <ListItemIcon>
        <ScheduleRoundedIcon />
      </ListItemIcon>
      <ListItemText primary="Vurderingsrunder" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/brugere">
      <ListItemIcon>
        <EmojiPeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Brugere" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/assessors">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Assessors" />
    </ListItem>
    <ListItem button component={NavLink} activeClassName="Mui-selected" to="/admin/brugergrupper">
      <ListItemIcon>
        <NaturePeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Brugergrupper" />
    </ListItem>
  </div>
);