import { USER_PROFILE_SET_USER } from './userProfilePageActions'
import { LOGIN_OK } from '../actions/loginActions'
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'

const initialState = {    
    user: null
}

const userProfilePageReducer = (state = initialState, action : FixMeLater) => {
    switch (action.type) {  
      case LOGIN_OK:
        return { ...state, user: action.payload.user }
      case USER_PROFILE_SET_USER:
        return { ...state, user: action.payload.user }
    default:
        return state
    }
  }
  
export default userProfilePageReducer