import React from 'react'
import { Section, LabelledValue, listConverter, enumConverter } from '../../../mui/Section'
import { CategoryChangeReasonRunde, CategoryChangeReasonType, CategoryChangesType } from '../../../redux/reducers/editAssessmentRed';
import { categoryChangeReasonsEnum } from '../enums/enums'

export interface X_CategoryChangeViewProps {
  categoryChanges: CategoryChangesType;
  showtitle?: boolean;
  vurderingsrundeId: number;
}

const X_CategoryChangeView = (props : X_CategoryChangeViewProps) => {
  const { categoryChanges, vurderingsrundeId } = props
  const { showtitle } = props
  const categoryChange = categoryChanges[`${vurderingsrundeId}` as CategoryChangeReasonRunde] //.find((c : FixMeLater) => c.vurderingsrundeId === vurderingsrundeId)
  const categoryChangeReasons = categoryChange ? categoryChange.reasons.map((r : CategoryChangeReasonType) => r.value || r.reason) : []
  const comment = categoryChange ? categoryChange.comment || null : null
  //console.log(categoryChangeReasons)
  return (
    <Section title={showtitle ? `Årsag til kategoriskift siden ${vurderingsrundeId}` : undefined}>
      <LabelledValue
        fullWidth
        label={'Årsager'}
        converter={listConverter(enumConverter(categoryChangeReasonsEnum))}
        value={categoryChangeReasons}
      />
      <LabelledValue fullWidth label={'Bemærkninger'} value={comment} />
    </Section>
  )
}

export default X_CategoryChangeView
