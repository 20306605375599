import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import { ReduxAction } from "../reduxModel"

export const MANAGE_ASSESSORS_PAGE_INIT = '[manage assessors] init'
export const MANAGE_ASSESSORS_PAGE_SET_ASSESSORS = '[manage assessors] set assessors'

export const MANAGE_ASSESSORS_PAGE_SELECT_ASSESSOR = '[manage assessors] select assessor'
export const MANAGE_ASSESSORS_PAGE_SET_SELECTED_ASSESSOR = '[manage assessors] set selected assessor'

export const MANAGE_ASSESSORS_PAGE_REQUEST_UPDATE = '[manage assessors] request update'
export const MANAGE_ASSESSORS_PAGE_UPDATE_OK = '[manage assessors] update assessor ok'

export const MANAGE_ASSESSORS_PAGE_REQUEST_CREATE = '[manage assessors] request create'
export const MANAGE_ASSESSORS_PAGE_CREATE_OK = '[manage assessors] create ok'

export const MANAGE_ASSESSORS_PAGE_HIDE_CREATE_DIALOG = '[manage assessors] hide create dialog'
export const MANAGE_ASSESSORS_PAGE_SHOW_CREATE_DIALOG = '[manage assessors] show create dialog'

export const manageAssessorsPageInit = () => ({
    type: MANAGE_ASSESSORS_PAGE_INIT
})

export const selectAssessor = (assessorId : FixMeLater) => ({
    type: MANAGE_ASSESSORS_PAGE_SELECT_ASSESSOR,
    payload: { assessorId }
})

export const udpateAssessor = (assessorId : FixMeLater, data : FixMeLater) => ({
    type: MANAGE_ASSESSORS_PAGE_REQUEST_UPDATE,
    payload: { assessorId, data }
})

export interface CreateAssessorAction {
    type: string,
    payload: CreateAssessorRequest
}

export interface CreateAssessorRequest {
    alias: string,
    name: string
}

export const createAssessor = (data: CreateAssessorRequest) => ({
    type: MANAGE_ASSESSORS_PAGE_REQUEST_CREATE,
    payload: data
})

export const hideCreateDialog = () : ReduxAction => ({
    type: MANAGE_ASSESSORS_PAGE_HIDE_CREATE_DIALOG
})

export const showCreateDialog = () : ReduxAction => ({
    type: MANAGE_ASSESSORS_PAGE_SHOW_CREATE_DIALOG
})