import RedlistApi from '../../service/redlistApi'

import {  
    hideSaving,
    showError,
    showMessage, showSaving
} from "../actions/uiActions";

import {
    SET_CATEGORY_CHANGE_REASON_VALUE,
    SET_CATEGORY_CHANGE_REASON_COMMENT,
    setCategoryChange,
    SET_CATEGORY_CHANGE_REASONS,
    setSingleCategoryChange,
    SET_SINGLE_CATEGORY_CHANGE,
    DELETE_CATEGORY_CHANGE
} from './editAssessmentActions';

import {
    apiRequest2
} from '../api/apiRequest'
import {
    select,
    put,
    takeEvery    
} from "redux-saga/effects";

function* processSetCategoryChangeReasonComment(action) {
    try {
        const {
            vurderingsrundeId,
            comment
        } = action.payload
        const { categoryChangeReasons } = yield select(state => state.editAssessment)        
        const prevCategoryChange = categoryChangeReasons[vurderingsrundeId] || { reasons: []}
        const prevReasons = prevCategoryChange.reasons || []
        const newCategoryChange = {
            vurderingsrundeId,
            comment: comment,
            reasons: [].concat(prevReasons)
        }
        yield put(setSingleCategoryChange(vurderingsrundeId, newCategoryChange))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetCategoryChangeReasonValue(action) {
    try {  
        const {
            vurderingsrundeId,
            reason,
            data
        } = action.payload
        const { categoryChangeReasons } = yield select(state => state.editAssessment)        
        const prevCategoryChange = categoryChangeReasons[vurderingsrundeId] || { reasons: [] }
        const reasons = prevCategoryChange.reasons.filter(r => r.value !== reason.value)
        reasons.push({ ...reason, data: data })
        const newCategoryChange = {
            vurderingsrundeId,
            comment: prevCategoryChange.comment,
            reasons
        }

        yield put(setSingleCategoryChange(vurderingsrundeId, newCategoryChange))

    } catch (error) {
        yield put(showError(error))
    }
}


function* processSetCategoryChangeReasons(action) {
    try {  
        const {
            vurderingsrundeId,
            reasons
        } = action.payload
        const { categoryChangeReasons } = yield select(state => state.editAssessment)        
        const prevCategoryChange = categoryChangeReasons[vurderingsrundeId] || {}
        const newCategoryChange = {
            vurderingsrundeId,
            comment: prevCategoryChange.comment || '',
            reasons
        }
        yield put(setSingleCategoryChange(vurderingsrundeId, newCategoryChange))

    } catch (error) {
        yield put(showError(error))
    }
}

function* processDeleteCategoryChange(action) {
    try {
        const { vurderingsrundeId } = action.payload
        const { assessmentId, categoryChangeReasons } = yield select(state => state.editAssessment)
        const newCategoryChangeReasons = { ...categoryChangeReasons }
        newCategoryChangeReasons[vurderingsrundeId] = { reasons: [], comment: '' }
        yield put(setCategoryChange(newCategoryChangeReasons))
        yield apiRequest2(RedlistApi, RedlistApi.deleteCategoryChange, [ assessmentId, vurderingsrundeId ], {
            "error": showMessage("Fejl", "Kunne ikke slette årsag til kategoriskift")
        })
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetSingleCategoryChange(action) {
    try {
        const {
            vurderingsrundeId,
            categoryChange
        } = action.payload        
        const { assessmentId, categoryChangeReasons } = yield select(state => state.editAssessment)
        const newCategoryChangeReasons = { ...categoryChangeReasons }
        newCategoryChangeReasons[vurderingsrundeId] = categoryChange
        const patch = {
            reasons: categoryChange.reasons.map(r => ({ reason: r.value, data: r.data })),
            comment: categoryChange.comment,
            vurderingsrundeId: categoryChange.vurderingsrundeId
        }
        yield put(setCategoryChange(newCategoryChangeReasons))
        yield put(showSaving())
        yield apiRequest2(RedlistApi, RedlistApi.updateCategoryChange, [ assessmentId, vurderingsrundeId, patch ], {
            "error": showMessage("Fejl", "Kunne ikke gemme kategoriskift")
        })
        yield put(hideSaving())
    } catch (error) {
        yield put(showError(error))
        yield put(hideSaving())
    }
    // TODO - store updated category change stuff using API
    // maybe only store one category change set at a time (e.g. vurderingsrunde 2010)
}

export default function* watcher() {
    yield takeEvery(SET_CATEGORY_CHANGE_REASONS, processSetCategoryChangeReasons)
    yield takeEvery(SET_CATEGORY_CHANGE_REASON_COMMENT, processSetCategoryChangeReasonComment)
    yield takeEvery(SET_CATEGORY_CHANGE_REASON_VALUE, processSetCategoryChangeReasonValue)
    yield takeEvery(DELETE_CATEGORY_CHANGE, processDeleteCategoryChange)
    yield takeEvery(SET_SINGLE_CATEGORY_CHANGE, processSetSingleCategoryChange)
}