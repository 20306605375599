import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import {
      MANAGE_SPECIES_PAGE_SET_SPECIES,
      MANAGE_SPECIES_PAGE_SET_FILTER,
      MANAGE_SPECIES_PAGE_SHOW_CREATE_DIALOG,
      MANAGE_SPECIES_PAGE_HIDE_CREATE_DIALOG,
      MANAGE_SPECIES_PAGE_SHOW_EDIT_DIALOG,
      MANAGE_SPECIES_PAGE_HIDE_EDIT_DIALOG,
      MANAGE_SPECIES_PAGE_CREATE_ERROR,
      MANAGE_SPECIES_PAGE_CREATE_OK,
      MANAGE_SPECIES_PAGE_SET_SELECTED_SPECIES
} from "./manageSpeciesPageActions"

const initialState = {
      queryId: '0',
      showCreateSpecies: false,
      createSpeciesError: null,
      editSpeciesError: null,
      showEditSpecies: false,
      selectedSpecies: null,
      species: [],
      count: 1000,
      pages: 200,
      filter: {
            page: 1, 
            limit: 50,
            scientificName: '',
            vernacularName: '',
            speciesFamilyId: null
      }
}

const manageSpeciesPageReducer = (state = initialState, action : FixMeLater) => {
      switch (action.type) {
            case MANAGE_SPECIES_PAGE_CREATE_ERROR:
                  return {
                        ...state, createSpeciesError: action.payload
                  }
            case MANAGE_SPECIES_PAGE_CREATE_OK:
                  return {
                        ...state, createSpeciesError: null
                  }
            case MANAGE_SPECIES_PAGE_SHOW_CREATE_DIALOG:
                  return {
                        ...state, showCreateSpecies: true
                  }
            case MANAGE_SPECIES_PAGE_HIDE_CREATE_DIALOG:
                  return {
                        ...state, showCreateSpecies: false, createSpeciesError: null
                  }
            case MANAGE_SPECIES_PAGE_SHOW_EDIT_DIALOG:
                  return {
                        ...state, selectedSpecies: action.payload.species, showEditSpecies: true
                  }
            case MANAGE_SPECIES_PAGE_SET_SELECTED_SPECIES:
                  return { ...state, selectedSpecies: action.payload.species }
            case MANAGE_SPECIES_PAGE_HIDE_EDIT_DIALOG:
                  return {
                        ...state, showEditSpecies: false, editSpeciesError: null, selectedSpecies: null
                  }
            case MANAGE_SPECIES_PAGE_SET_FILTER: 
                  return {
                        ...state, filter: action.payload.filter
                  }
            case MANAGE_SPECIES_PAGE_SET_SPECIES:
                  return {
                        ...state, count: action.payload.count, pages: action.payload.pages, species: action.payload.species, queryId: action.payload.queryId
                  }
            default:
                  return state
      }
}

export default manageSpeciesPageReducer