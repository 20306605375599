import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Typography,
    Grid
} from '@material-ui/core'
import { oddRowStyle } from '../components/styles'
import TextAreaReference from '../components/TextAreaReference'
import RadioGroupReference from '../components/RadioGroupReference'
import { updateQuantitativeAnalysis, saveSection } from '../../redux/editAssessment/editAssessmentActions'

import Footer from '../components/Footer'
import GlobalErrorMessage, { FixMeLater } from '../components/GlobalErrorMessage' 
import { extractPropertyValidationResult } from './validators/sectionValidators'
import { quantitativeQuestions } from './questions'
import { KvantitativAnalyseHelpElement, KvantitativAnalyseDocumentationHelpElement } from './help/quantitativeAnalysisHelp'
import { RedlistStore } from '../../redux/reducers'
export const kvantitativValues = [
    { value: '0', label: "Ikke angivet" },
    { value: '1', label: "50 % over 10 år eller 3 generationer" },
    { value: '2', label: "20 % over 20 år eller 5 generationer" },
    { value: '3', label: "10 % over 100 år" },
    { value: '4', label: "5 % over 100 år" }
]


const D_KvantitativAnalyse = (props : FixMeLater) => {
    const dispatch = useDispatch()
    const assessmentId = useSelector((state : RedlistStore) => state.editAssessment.assessmentId)
    const currentCriteria = useSelector((state : RedlistStore) => state.editAssessment.assessmentCriteria)
    const quantitativeAnalysis = currentCriteria ? currentCriteria.quantitativeAnalysis : {}
    const { referenceArt, onNext, onClose } = props
    const referenceAnalysis = referenceArt && referenceArt.assessmentCriteria && referenceArt.assessmentCriteria.quantitativeAnalysis

    const probabilityOfExtinctionByQuantitativeAnalysis = quantitativeAnalysis.probabilityOfExtinctionByQuantitativeAnalysis || kvantitativValues[0].value
    const quantitativeAnalysisRemark = quantitativeAnalysis.quantitativeAnalysisRemark || ''
    
    const validationResult = useSelector((state : RedlistStore) => state.editAssessment.criteriaValidationResult?.quantitativeAnalysis)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    Kvantitativ analyse
                </Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>

            <Grid item xs={12}>
                <RadioGroupReference
                    help={() => <KvantitativAnalyseHelpElement />}
                    label={quantitativeQuestions.probabilityOfExtinctionByQuantitativeAnalysis.label}
                    referenceValue={referenceAnalysis ? referenceAnalysis.probabilityOfExtinctionByQuantitativeAnalysis : null}
                    name="kvantitativ_analyse"
                    value={probabilityOfExtinctionByQuantitativeAnalysis}
                    onChange={(value : FixMeLater) => dispatch(updateQuantitativeAnalysis('probabilityOfExtinctionByQuantitativeAnalysis', value))}
                    values={kvantitativValues} />
            </Grid>
            <TextAreaReference
                help={() => <KvantitativAnalyseDocumentationHelpElement />}
                rowStyle={oddRowStyle}
                validation={extractPropertyValidationResult(validationResult, 'quantitativeAnalysisRemark')}
                title={quantitativeQuestions.quantitativeAnalysisRemark.title}
                value={quantitativeAnalysisRemark}
                onChange={value => dispatch(updateQuantitativeAnalysis('quantitativeAnalysisRemark', value))}
                referenceValue={referenceAnalysis && referenceAnalysis.quantitativeAnalysisRemark}
                referenceTitle='Referenceart'
            />
            <Footer
                onNext={() => {
                    dispatch(saveSection('quantitativeAnalysis', assessmentId as number, quantitativeAnalysis))
                    onNext()
                }}
                onClose={() => onClose()} />        </Grid>
    )
}

export default D_KvantitativAnalyse