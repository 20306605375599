import React from 'react';
import styled from 'styled-components';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';

import { Tooltip } from '@material-ui/core'

const Outer = styled.div`
    ${ props => props.fullwidth && 'width: 100%;'}
    height: 42px;
    background-color: ${ 
        props => props.checked ? 
            (props.theme && props.theme.checked && props.theme.checked.background) || '#3D94F6' 
            :
            (props.theme.background || '#78c2f0')
    };
    border-radius: 4px;
    border: 1px solid;
    border-color: ${ props => props.checked ?
            (props.theme && props.theme.checked && props.theme.checked.borderColor) || '#00c'
            :
            (props.theme.borderColor || '#D0D0D0')
    };
    display: inline-block;
    cursor: pointer;
    color: ${
        props => props.checked ?
            (props.theme && props.theme.checked && props.theme.checked.color) || '#fff'
            :
            (props.theme.color || '#fff')
    };
    transition: .25s;
    &:hover {
        box-shadow: 0 2px 8px -1px rgba(152, 152, 152, .4);
    }
    ${ props => props.checked && 'border: 0px solid #c00;' }
`

const Span = styled.span`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: block;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CheckboxButton = (props) => {
    const { fullwidth, checked, label, tooltip } = props;
    let checkedIconDisplay = checked ? '' : 'hidden';
    return (
        <Outer fullwidth={fullwidth} checked={checked} onClick={() => props.onChange && props.onChange(!checked)}>
            <CheckBoxOutlineBlankIcon fontSize="medium" style={ { float: 'left', marginRight: 8, marginTop: 8, marginLeft: 8 }} />
            <CheckIcon color={'inherit'} style={ { visibility: checkedIconDisplay, float: 'left', marginLeft: -28, marginTop: 6}} />
            <Tooltip placement='top' title={tooltip || label}>
            <Span checked={checked}>{label}</Span>
            </Tooltip>
        </Outer>
    )
}

export default CheckboxButton