import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { LabelledValue, optionsConverter, listConverter, Section, IntervalValue } from '../../../mui/Section'
import { levestedQuestions } from '../questions'

import { substratFunktioner } from '../../levesteder/SubstratFunktioner'
import { FixMeLater } from '../../components/GlobalErrorMessage'
import { SpeciesInformation } from '../../../redux/reducers/editAssessmentRed'

export interface Gradienter {
    fugtighedMin?: number;
    fugtighedMax?: number;
    phMin?: number;
    phMax?: number;
    lysMin?: number;
    lysMax?: number;
    nutrientRichnessMin?: number;
    nutrientRichnessMax?: number;
    forstyrrelsesFrekvensMin?: number;
    forstyrrelsesFrekvensMax?: number;
    forstyrrelsesIntensitetMin?: number;
    forstyrrelsesIntensitetMax?: number;
    positionIVandsoejlenMin?: number;
    positionIVandsoejlenMax?: number;
    salinitetMin?: number;
    salinitetMax?: number;
    vanddybdeMin?: number;
    vanddybdeMax?: number;
}

interface Interval {
    min?: number;
    max?: number;
}

export interface GradienterNorm {
    fugtighed?: Interval;
    ph?: Interval;
    lys?: Interval;
    nutrientRichness?: Interval;
    forstyrrelsesFrekvens?: Interval;
    forstyrrelsesIntensitet?: Interval;
    positionIVandsoejlen?: Interval;
    salinitet?: Interval;
    vanddybde?: Interval;
}

export interface LabelledGradient {
    label: string;
    value: Interval | null;
    marks: string;

}

const isNullOrUndefined = (value : any) : boolean => {
    return value === null || typeof(value) === 'undefined'
}

const minMaxConverter = (minMax : FixMeLater) : FixMeLater => {
    if (isNullOrUndefined(minMax) || (isNullOrUndefined(minMax.min) && isNullOrUndefined(minMax.max) ) ) {
        return null
    }
    return minMax
}

const gradientConverter = (gradienter : Gradienter, gradientQuestions : FixMeLater) :  LabelledGradient[] => {
    //console.log('gradienter: ', gradienter)
    let _g : GradienterNorm | null = null
    if (!gradienter) {
        return []
    }
    if (gradienter.fugtighedMin || gradienter.fugtighedMax 
        || gradienter.phMin || gradienter.phMax 
        || gradienter.lysMin || gradienter.lysMax 
        || gradienter.nutrientRichnessMin || gradienter.nutrientRichnessMax 
        || gradienter.forstyrrelsesFrekvensMin || gradienter.forstyrrelsesFrekvensMax 
        || gradienter.forstyrrelsesIntensitetMin || gradienter.forstyrrelsesIntensitetMax
        || gradienter.positionIVandsoejlenMin || gradienter.positionIVandsoejlenMax
    || gradienter.salinitetMin || gradienter.salinitetMax
    || gradienter.vanddybdeMin || gradienter.vanddybdeMax) {
        _g = gradientNormaliser(gradienter)        
    }
    if (!_g) {
        return []
    }
    const { 
        fugtighed, ph, lys, nutrientRichness, forstyrrelsesFrekvens, forstyrrelsesIntensitet, positionIVandsoejlen, salinitet, vanddybde
    } = _g
    //console.log('gradienter: ', gradienter)
    const values =  []
    if (fugtighed !== null) values.push({ label: 'Fugtighed', value: minMaxConverter(fugtighed), marks: gradientQuestions.fugtighed.marks })
    if (ph !== null) values.push({ label: 'pH', value: minMaxConverter(ph), marks: gradientQuestions.ph.marks})
    if (lys !== null) values.push({ label: 'Lys', value: minMaxConverter(lys), marks: gradientQuestions.lys.marks })
    if (nutrientRichness !== null) values.push({ label: 'Næringsstatus', value: minMaxConverter(nutrientRichness), marks: gradientQuestions.nutrientRichness.marks })
    if (forstyrrelsesFrekvens !== null) values.push({ label: 'Forstyrrelsesfrekvens', value: minMaxConverter(forstyrrelsesFrekvens), marks: gradientQuestions.forstyrrelsesFrekvens.marks })
    if (forstyrrelsesIntensitet !== null) values.push({ label: 'Forstyrrelsesintensitet', value: minMaxConverter(forstyrrelsesIntensitet), marks: gradientQuestions.forstyrrelsesIntensitet.marks })
    if (positionIVandsoejlen !== null) values.push({ label: 'Position i vandsøjlen', value: minMaxConverter(positionIVandsoejlen), marks: gradientQuestions.positionIVandsoejlen.marks })
    if (salinitet !== null) values.push({ label: 'Salinitet', value: minMaxConverter(salinitet), marks: gradientQuestions.salinitet.marks })
    if (vanddybde !== null) values.push({ label: 'Vanddybde', value: minMaxConverter(vanddybde), marks: gradientQuestions.vanddybde.marks })
    //console.log(values)
    return values
}

const gradientNormaliser = (gradienter : Gradienter) : GradienterNorm | null => {
    if (!gradienter) {
        return null
    }
    const { 
        fugtighedMin, fugtighedMax, 
        phMin, phMax, 
        lysMin, lysMax, 
        nutrientRichnessMin, nutrientRichnessMax, 
        forstyrrelsesFrekvensMin, forstyrrelsesFrekvensMax, 
        forstyrrelsesIntensitetMin, forstyrrelsesIntensitetMax,
        positionIVandsoejlenMin, positionIVandsoejlenMax,
        salinitetMin, salinitetMax,
        vanddybdeMin, vanddybdeMax
    } = gradienter    
    return {
        fugtighed: { min: fugtighedMin, max: fugtighedMax },
        ph: { min: phMin, max: phMax },
        lys: { min: lysMin, max: lysMax },
        nutrientRichness: { min: nutrientRichnessMin, max: nutrientRichnessMax },
        forstyrrelsesFrekvens: { min: forstyrrelsesFrekvensMin, max: forstyrrelsesFrekvensMax },
        forstyrrelsesIntensitet: { min: forstyrrelsesIntensitetMin, max: forstyrrelsesIntensitetMax },
        positionIVandsoejlen: { min: positionIVandsoejlenMin, max: positionIVandsoejlenMax },
        salinitet: { min: salinitetMin, max: salinitetMax },
        vanddybde: { min: vanddybdeMin, max: vanddybdeMax }
    }
}

const formatSpecies = (s: SpeciesInformation) : string => {
    if (s.vernacularName) {
        return `${s.scientificName} (${s.vernacularName})`
    } else {
        return s.scientificName
    }
}

const F_LevestedsAngivelseView = (props : FixMeLater) => {
    const levested = props.levested || {}
    const {
        comment,
        hovedmiljoer,
        levestedstyper,
        ecosystems,
        habitater,
        overordnedeSubstrater,
        substrater,
        dietSpecies,
        dietGenus,
        gradienter
    } = levested
    const { showNull, showtitle } = props

    const gradientQuestions = levestedQuestions.gradienter
    return (
        <Section title={showtitle && 'Levestedsangivelse'}>
            <LabelledValue showNull={showNull} label={'Hovedmiljøer'} value={hovedmiljoer} converter={listConverter((v : FixMeLater) => v.name)} />
            <LabelledValue showNull={showNull} label={'Levestedstyper'} value={levestedstyper} converter={listConverter((v : FixMeLater) => v.name)} />
            <LabelledValue showNull={showNull} label={'Økosystemer'} value={ecosystems} converter={listConverter((v : FixMeLater) => v.name)} />
            <LabelledValue showNull={showNull} label={'Habitater'} value={habitater} converter={listConverter((v : FixMeLater) => v.name)} />
            <LabelledValue showNull={showNull} label={'Overordnede substrater'} value={overordnedeSubstrater} converter={listConverter((v : FixMeLater) => v.name)} />
            <LabelledValue showNull={showNull} label={'Substrater'} value={substrater} converter={listConverter((v : FixMeLater) => { return v.name + ' (' + v.funktion.map(optionsConverter(substratFunktioner, true)).join(',') + ')' })} />
            <LabelledValue showNull={showNull} label='Lever af slægter' value={dietGenus} converter={listConverter((v : FixMeLater) => v.name)} />
            <LabelledValue showNull={showNull} label='Lever af arter' value={dietSpecies} converter={listConverter((v : FixMeLater) => formatSpecies(v))} />
            <Grid item xs={12}>
                <Typography variant='overline'>Gradienter</Typography>
                {gradientConverter(gradienter, gradientQuestions).map((g) => (<IntervalValue backgroundColor={'#f0f0f4'} key={g.label} min={0} max={100} label={g.label} value={g.value} marks={g.marks} />))}
            </Grid>
            <LabelledValue showNull={showNull} label='Levestedskommentar' value={comment} /> 
        </Section>
    )
}

export default F_LevestedsAngivelseView