export const REQUEST_LOGIN = "[login] requested"
export const REQUEST_LOGOUT = "[logout] requested"
export const LOGIN_OK = "[login] ok"
export const LOGIN_FAILED = "[login] failed"
export const LOGIN_UNAUTHORIZED = "[login] unauthorized"
export const LOGOUT_OK = "[logout] ok"
export const LOGOUT_FAILED = "[logout] failed"
export const CHECK_LOGIN = "[login] check"

export const REFRESH_TOKEN = '[login] refresh'
export const SET_USER = '[login] set user'

export const checkLogin = () => ({
    type: CHECK_LOGIN
})

export const login = (username : string, password : string, rememberMe = false) => ({
    type: REQUEST_LOGIN,
    payload: {
        username,
        password, 
        rememberMe
    }
})

export const loginOk = (user : string, rememberMe = false) => ({
    type: LOGIN_OK,
    payload: {
        user,
        rememberMe
    }
})

export const logout = () => ({
    type: REQUEST_LOGOUT
})

export const refreshToken = () => ({
    type: REFRESH_TOKEN
})