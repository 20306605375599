import { FixMeLater } from '../../assessments/components/GlobalErrorMessage';
import { get, put, del, Token } from '../../service/fetchutil';


class CrudProvider {

    baseUrl : string;
    token: Token

    constructor() {
        this.baseUrl = ''
        this.token = undefined
    }

    setBaseUrl(baseUrl : string) {
        this.baseUrl = baseUrl
    }

    setToken(token : Token) {
        this.token = token
    }

    async delete(key : FixMeLater, id : FixMeLater) {
        return del(this.baseUrl, '/admin/' + key + '/' + id, () => this.token)
    }

    async update(key : FixMeLater, id : FixMeLater, patch : FixMeLater) {
        return put(this.baseUrl, '/admin/' + key + '/' + id, patch, () => this.token)
    }
    
    async create(key : FixMeLater, item : FixMeLater) {
        return put(this.baseUrl, '/admin/' + key, item, () => this.token)
    }

    async loadByFilter(key : FixMeLater, filter : FixMeLater) {
        return get(this.baseUrl, '/admin/' + key , filter, () => this.token)
    }

}

const instance = new CrudProvider()
export default instance