import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import {
  VIEW_QA_ASSESSMENTS_PAGE_SET_FILTER,
  VIEW_QA_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
} from './viewQAAssessmentsPageActions'

const initialState = {
  filter: { page: 1, limit: 25, vurderingsrundeId: 2030 },
  assessments: [],
  pages: 1,
  count: 0,
}

const viewAssessmentsPageReducer = (state = initialState, action : FixMeLater) => {
  switch (action.type) {
    case VIEW_QA_ASSESSMENTS_PAGE_SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload.assessments,
        count: action.payload.count,
        pages: action.payload.pages,
      }
    case VIEW_QA_ASSESSMENTS_PAGE_SET_FILTER:
      return { ...state, filter: action.payload }
    default:
      return state
  }
}

export default viewAssessmentsPageReducer
