import React from 'react';
import {
    Typography,
    Button
} from '@material-ui/core';
import { formatUnixtimestamp } from '../../utils/dateUtils'
import XTable from '../../mui/XTable'

const toStatus = publication => {
    const statuslist = []
    if (publication.release) {
        statuslist.push('Aktiv')
    }
    if (publication.beta) {
        statuslist.push('Beta')
    }
    if (publication.keepForever) {
        if (publication.publicationId) {
            statuslist.push('Publiceret')
        } else {
            statuslist.push('Mangler publikationsid')
        }
    }
    if (publication.pending) {
        statuslist.push('Afventer (mulig fejl)')
    }
    if (statuslist.length === 0) {
        statuslist.push('Ikke udgivet')
    }
    return statuslist.join(', ')
}

const tableConfig = {
    id: 'assessmentId',
    properties: [
        { header: 'VurderingsId', property: 'assessmentId' },
        { header: 'Data', property: 'data' }        
    ],
    selectable: false
}


const DialogContent = props => {
    const { publication } = props
    if (publication === null) {
        return null;
    }
    return (
        <React.Fragment>
            <div>
                <Typography variant='body1'>Id: { publication.id}</Typography>
                <Typography variant='body1'>Vurderingsrunde: { publication.vurderingsrunde}</Typography>
                <Typography variant='body1'>Oprettet: { formatUnixtimestamp(publication.date) } </Typography>
                <Typography variant='body1'>Status: { toStatus(publication) }</Typography>
            </div>  
            <div style={{ marginTop: 32 }}>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button fullWidth color='inherit' variant='contained' onClick={() => props.onClose()}>Annuller</Button>
                </div>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button fullWidth color='primary' disabled={!publication.beta || publication.release} variant='contained' onClick={() => props.onRelease()}>Release</Button>
                </div>
            </div>
            <div style={{ marginTop: 32 }}>
                <div style={{display: 'inline-block', width: '33%'}}>
                    <Button fullWidth color='secondary' disabled={publication.keepForever} variant='contained' onClick={() => props.onDelete()}>Slet</Button>
                </div>
            </div>
            { publication.warnings.length > 0 ?
            <XTable
                key={`_${publication.id}`} 
                data={publication.warnings}
                tableConfig={tableConfig}                
            /> : null}
        </React.Fragment>
    )
}

const UpdatePubliceringerDialog = (props) => {
    const { publication } = props
    return (
        <div style={{ minWidth: 320, margin: 48 }}>            
            <DialogContent publication={publication} onClose={props.onClose} onRelease={props.onRelease} onDelete={props.onDelete} onGenerateArchive={props.onGenerateArchive} />
        </div>
    )
}

export default UpdatePubliceringerDialog