import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Slider,
  LinearProgress,
} from "@material-ui/core";

import { LabelledValue } from "../../mui/Section";

import OlLayers from "../../ol/OlLayers";
import { OlTileLayer } from "../../ol/OlLayers";
import OlView from "../../ol/OlView";
import OpenLayersMap, { OlFeature } from "../../ol/OpenLayersMap";
import OlWebGlPointsLayer from "../../ol/OlWebGlPointsLayer";
import OlStaticSource from "../../ol/OlSource";
import {
  cellStyle,
  createFeatureCollection,
  createFeatureCollectionFromOverviewCells,
  createFeatureCollectionFromPolygon,
  emptyFeatureCollection,
  polygonStyle,
} from "../../ol/olUtil";
import { useDispatch, useSelector } from "react-redux";
import { RedlistStore } from "../../redux/reducers";
import OlVectorLayer from "../../ol/OlVectorLayer";
import { BBox, Feature } from "../../ol/OlFeatureCollection";

import SectionTitle from "../../mui/SectionTitle";

import OlRedlistModeControl, {
  RedlistMapMode,
} from "../../ol/OlRedlistModeControl";
import {
  gridCellToFeature,
  pointToAOOGrid,
  simpleWgs84toEpsg25832,
} from "../../utils/gridHelper";
import OlControls from "../../ol/OlControls";
import { ImportStatus, TaxonPositionInfo } from "../../service/arterDkModels";
import { Geometry } from "@turf/helpers";
import TaxonRecordInfoDialog from "./TaxonRecordInfoDialog";
import {
  closeShowRecordDetails,
  fetchRecordsBy2x2Grid,
  fetchRecordsByPosition,
  fetchTaxonStatus,
  geographyAddRecord,
  geographyRemoveCell,
  geographyRemoveRecord,
  geographySetYear,
  geographyStartImportJob,
  selectCell,
  selectPosition,
} from "../../redux/editAssessment/geographyMapActions";
import { DateTime } from "luxon";
import OlRedlistInfoBox from "../../ol/OlRedlistInfoBox";
import { ArterDkHelp } from "../sections/help/geographyHelp";

const mapView = { lat: 56, lon: 10.5, zoom: 7 };

const cellPointStyle = {
  symbol: {
    symbolType: "circle",
    size: ["interpolate", ["linear"], ["zoom"], 5, 6, 10, 10, 17, 18],
    color: "rgb(196,64,255)",
    opacity: ["case", ["==", ["get", "deleted"], 1], 0.3, 0.9],
  },
};

export interface ImportStatusComponentProps {
  importStatus: ImportStatus;
}

const ImportStatusComponent = ({
  importStatus,
}: ImportStatusComponentProps) => {
  let label = "";
  if (importStatus.status === "done") {
    label = "Import kørt";
  } else if (importStatus.status === "started") {
    label = "Import igang";
  } else if (importStatus.status === "failed") {
    label = "Import fejlet";
  } else if (importStatus.status === "aborted") {
    label = "Import afbrudt";
  }
  return (
    <div>
      <LabelledValue
        showNull
        label={label}
        value={importStatus.importStartTime || ""}
        converter={(v: string) => {
          if (!v) {
            return null;
          }
          const d = DateTime.fromISO(v);
          return d.setLocale("da-DK").toLocaleString(DateTime.DATETIME_SHORT);
          //return `${d.day}/${d.month} ${d.year}`
        }}
      />
    </div>
  );
};

type PointMode = 'cells' | 'positionhash'

const GeographyMapComponent = () => {
  const dispatch = useDispatch();
  const arterDkId = useSelector(
    (state: RedlistStore) => state.editAssessment?.speciesInformation?.arterDkId
  );

  const {
    year,
    importStatus,
    loading,
    points,
    cells,
    convexHull,
    showRecordDetails,
    recordDetails,
    cellSize,
  } = useSelector((state: RedlistStore) => state.geographyMap);

  const [localSliderYear, setLocalSliderYear] = useState(year || 1950);
  const [pointMode, setPointMode] = useState<PointMode>("positionhash");

  //const [cellSize, setCellSize] = useState(2);

  const [zoom, setZoom] = useState<number | undefined>();
  const [viewExtent, setViewExtent] = useState<BBox | null>(null);

  //const [ info, setInfo ] = useState<(TaxonRecordDetails[])>([])
  const [mode, setMode] = useState<RedlistMapMode>("info");

  const taxonId: string = arterDkId ? (arterDkId as string) : "";

  useEffect(() => {
    //console.log('hello', taxonId)
    if (taxonId) {
      //console.log('dispatch', taxonId)
      dispatch(fetchTaxonStatus(taxonId));
    }
  }, [taxonId, dispatch]);

  if (arterDkId === null) {
    return null;
  }

  const hull = convexHull
    ? createFeatureCollectionFromPolygon(convexHull.hull)
    : emptyFeatureCollection();

  const buffer = 0.015;
  const tmpPositions =
    cellSize > 0 && ((viewExtent && zoom && zoom > 8.5) || cellSize === 8)
      ? cells.filter((c: TaxonPositionInfo) => {
          if (viewExtent) {
            if (
              c.position.coordinates[0] <
              viewExtent.bottomLeft.lon - buffer
            ) {
              return false;
            }
            if (c.position.coordinates[0] > viewExtent.topRight.lon + buffer) {
              return false;
            }
            if (
              c.position.coordinates[1] <
              viewExtent.bottomLeft.lat - buffer
            ) {
              return false;
            }
            if (c.position.coordinates[1] > viewExtent.topRight.lat + buffer) {
              return false;
            }
          }
          return !c.deleted;
        })
      : [];

  //console.log({cellSize, tmpPositions, cellCount: cells.length})
  const gridcellFeatures: Feature[] = tmpPositions.map(
    (c: TaxonPositionInfo): Feature => {
      const utmCoords = simpleWgs84toEpsg25832(
        c.position.coordinates as number[]
      );
      const utmGeom: Geometry = {
        type: "Point",
        coordinates: utmCoords,
      };
      const gridCell = pointToAOOGrid(utmGeom);
      return gridCellToFeature(gridCell);
    }
  );

  const gcfc = createFeatureCollection(gridcellFeatures);

  const extentOfOccurrence: number | undefined =
    convexHull && convexHull.area ? ~~convexHull.area : undefined;
  const areaOfOccupancy: number | undefined =
    cells.length > 0 ? cells.length * 4 : undefined;

  const handleClick = (coord: number[], features: OlFeature[]) => {
    console.log("click handler active", points.length);
    if (mode === "info") {
      if (features.length === 0) {
        dispatch(closeShowRecordDetails());
      } else {
        if (pointMode === "positionhash") {
          dispatch(
            selectPosition(
              taxonId,
              features[0].getProperties().positionHash,
              year
            )
          );
        } else {
          dispatch(
            selectCell(taxonId, features[0].getProperties().cellId, year)
          );
        }
      }
    } else if (mode === "add") {
      if (features.length > 0) {
        // dispatch(geographyAddRecord(taxonId, coord[0], coord[1]))
      } else {
        dispatch(geographyAddRecord(taxonId, coord[0], coord[1]));
      }
    } else if (mode === "remove") {
      if (features.length === 0) {
        return; // ignore
      }
      const idx = features.findIndex((f) => !f.getProperties().deleted);
      console.log("found idx", idx);
      if (idx >= 0) {
        if (pointMode === "positionhash") {
          dispatch(
            geographyRemoveRecord(
              taxonId,
              features[0].getProperties().positionHash
            )
          );
        } else {
          dispatch(
            geographyRemoveCell(taxonId, features[0].getProperties().cellId)
          );
        }
      }
    }
  };

  let _points = []

  let precision = 5
  if (zoom) {
    if (zoom > 10) {
      precision = 10
    }
    if (zoom > 9.5) {
      precision = 8
    }
    else if (zoom > 8.5) {
      precision = 7
    } 
    else if (zoom > 7.5) {
      precision = 6
    } 
    else if (zoom > 6.8) {
      precision = 5
    } 
  }
  

  let tmp : Record<string, TaxonPositionInfo> = {}
  points.forEach(p => {
    const key = p.positionHash ? p.positionHash.substring(0, precision) : `${p.cellId}`
    if (!tmp[key]) {
      tmp[key] = p
    }
  })
  for (let key in tmp) {
    _points.push(tmp[key])
  }
  _points = _points.filter((c: TaxonPositionInfo) => {
    if (!viewExtent) {
      return true;
    }
      if (
        c.position.coordinates[0] <
        viewExtent.bottomLeft.lon - buffer * 4
      ) {
        return false;
      }
      if (c.position.coordinates[0] > viewExtent.topRight.lon + buffer * 4) {
        return false;
      }
      if (
        c.position.coordinates[1] <
        viewExtent.bottomLeft.lat - buffer * 4
      ) {
        return false;
      }
      if (c.position.coordinates[1] > viewExtent.topRight.lat + buffer * 4) {
        return false;
      }    
    return !c.deleted;
  })


  //console.log({ zoom, pointsLen: points.length, _pointsLen: _points.length })

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <SectionTitle label='Arter.dk data' help={ArterDkHelp} />
      <Typography>
        Kort med data fra arter,dk. Scroll ned for at udfylde felterne om
        geografisk udbredelse
      </Typography>
      <div style={{ marginTop: 48, marginLeft: 32, marginRight: 32 }}>
        <Slider
          track="inverted"
          value={localSliderYear}
          onChangeCommitted={(evt, value) => {
            dispatch(geographySetYear(value as number));
            if (pointMode === "positionhash") {
              dispatch(fetchRecordsByPosition(taxonId, value as number));
            } else {
              dispatch(fetchRecordsBy2x2Grid(taxonId, value as number));
            }
          }}
          onChange={(evt, value) => setLocalSliderYear(value as number)}
          min={1850}
          max={new Date().getFullYear()}
          valueLabelDisplay="on"
        />
      </div>
      <OpenLayersMap
        id="species-occurrence-map"
        mouseWheelZoomSettings="ctrl"
        doubleClickZoom={false}
        left={0}
        right={0}
        top={0}
        height={600}
        onMoveEnd={(extent: BBox, zoom?: number) => {
          setViewExtent(extent);
          setZoom(zoom);
        }}
      >
        <OlView lat={mapView.lat} lon={mapView.lon} zoom={mapView.zoom} />
        <OlControls>
          <OlRedlistModeControl
            position={{ top: 8, right: 8 }}
            onModeChanged={(mode) => setMode(mode)}
          />
          <OlRedlistInfoBox
            position={{ bottom: 8, right: 8 }}
            areaOfOccupancy={areaOfOccupancy}
            extentOfOccurrence={extentOfOccurrence}
          />
        </OlControls>
        <OlLayers>
          <OlTileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <OlVectorLayer visible={true} style={polygonStyle}>
            <OlStaticSource
              featureCollection={hull}
              sourceProjection="EPSG:4326"
              targetProjection="EPSG:3857"
            />
          </OlVectorLayer>
          <OlVectorLayer visible={true} style={cellStyle}>
            <OlStaticSource
              featureCollection={gcfc}
              sourceProjection="EPSG:4326"
              targetProjection="EPSG:3857"
            />
          </OlVectorLayer>
          <OlWebGlPointsLayer
            title="points"
            style={cellPointStyle}
            visible={true}
            onClick={_points.length < 20000 ? handleClick : undefined}
          >
            <OlStaticSource
              featureCollection={createFeatureCollectionFromOverviewCells(
                _points
              )}
              sourceProjection="EPSG:4326"
              targetProjection="EPSG:3857"
            />
          </OlWebGlPointsLayer>
        </OlLayers>
      </OpenLayersMap>
      {importStatus && <ImportStatusComponent importStatus={importStatus} />}
      <Button
        variant="contained"
        disabled={Boolean(loading || !importStatus)}
        onClick={() => {
          setPointMode("cells");
          dispatch(fetchRecordsBy2x2Grid(taxonId, year));
        }}
      >
        Hent 2x2 km
      </Button>
      <Button
        variant="contained"
        disabled={Boolean(loading || !importStatus)}
        onClick={() => {
          setPointMode("positionhash");
          dispatch(fetchRecordsByPosition(taxonId, year));
        }}
      >
        Hent alt
      </Button>
      <Button
        color="secondary"
        variant="contained"
        disabled={loading}
        onClick={() => {
          dispatch(geographyStartImportJob(taxonId));
        }}
      >
        Importér
      </Button>
      {loading ? <LinearProgress variant="indeterminate" /> : null}

      <TaxonRecordInfoDialog
        show={showRecordDetails}
        records={recordDetails}
        onClose={() => dispatch(closeShowRecordDetails())}
      />
    </Box>
  );
};

export default GeographyMapComponent;
