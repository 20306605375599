import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import Select from 'react-select'
import XTable from '../mui/XTable'
import Paging from '../mui/Paging';


import { setTitle } from '../redux/actions/uiActions'

import { statusEnum } from '../service/assessmentStatusEnum';
import { init, setFilter, setPage, nextPage, prevPage } from '../redux/ekspert/viewFagligKommenteringPageActions';

const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Videnskabeligt navn', property: 'speciesInformation.scientificName' },
        { header: 'Almindeligt navn', property: 'speciesInformation.vernacularName' },
        { header: 'Population', property: 'speciesInformation.population' },
        { header: 'Familie', property: 'speciesInformation.speciesFamily' },
        { header: 'Status', property: 'status', func: assessment => statusEnum.fromValue(assessment.status).label}
    ],
    selectable: false
}


const toOption = e => {
    if (!e) {
        return null
    }
    return {id: e.id, value: e.id, label: e.name}
}

const toObject = (id, list) =>  list.find(l => l.id === id)


const ViewFagligKommenteringPage = (props) => {
    const { title, status, path } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const { usergroups } = useSelector(state => state.ekspertEntities)
    const { pages, count, assessments, filter } = useSelector(state => state.viewFagligKommenteringPage)
    const { user } = useSelector(state => state.app)
    const { loading } = useSelector(state => state.ui)

    const updateSelectedUsergroup = (usergroup) => {
        dispatch(setFilter( { ...filter, usergroupId: usergroup?.id }))
    }
    
    useEffect(() => {
        dispatch(setTitle(title))
        dispatch(init(user, status))
     
    }, [dispatch, user, title, status])

    if (!usergroups) {
        return (<div>Loading</div>)
    }
   // console.log(filter)

    return (
        <div>
        <Select
            isClearable={false}
            options={usergroups.map(toOption)}
            onChange={value => { updateSelectedUsergroup(value) }}
            value={toOption(toObject(filter.usergroupId, usergroups))}
          />  
          <div style={{ height: 24 }} />
            <Paging
                count={count}
                pages={pages}
                page={filter.page}
                pageSize={filter.limit}
                onPrev={() => dispatch(prevPage())}
                onNext={() => dispatch(nextPage())}
                onGoto={page => dispatch(setPage(page))}
                />
            <XTable loading={loading} key={JSON.stringify(filter)} size={'medium'} onRowClick={value => history.push(`/${path}/${value.id}`)} data={assessments} tableConfig={tableConfig} />    
        </div>
    )
}

export default ViewFagligKommenteringPage