import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  CircularProgress,
  LinearProgress,
  Avatar
} from '@material-ui/core';
import { mainListItems, secondaryListItems } from './listItems';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useHistory } from 'react-router-dom';
import { hideGoBack } from '../redux/actions/uiActions';
import { useDispatch } from 'react-redux';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperHidden: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0)
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
}));

/* 
 avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  }*/

const nameToInitials = (name) => {
  const parts = name.split(' ')
  if (parts.length === 1) {
    return parts[0].substring(0, 1)
  }
  if (parts.length > 1) {
    return parts[0].substring(0, 1) + parts[parts.length - 1].substring(0, 1)
  }
  return ''
}

export default function Dashboard(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    version,
    appError,
    userrole, 
    userFullname, 
    saving, 
    loading, 
    title, 
    showMenu, 
    goBackEnabled 
  } = props
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const goBack = () => {
    dispatch(hideGoBack())
    history.goBack()
  }

  const initials = nameToInitials(userFullname)
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  //, open && classes.appBarShift
  //, open && classes.menuButtonHidden
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          {goBackEnabled ?
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => goBack()}
              className={clsx(classes.menuButton)}
            >
              <ArrowBackIosIcon />
            </IconButton>
            :
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => toggleDrawer()}
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          }
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>
          { appError ? 
          <IconButton color="secondary">
              <ErrorOutlineIcon />
          </IconButton>
          : null
          }
          {loading ? <CircularProgress size={25} color="inherit" variant='indeterminate' /> : null}
          <Typography variant='caption'>{version}</Typography>
          <Avatar className={classes.avatar}>
            {initials}
          </Avatar>
          { props.notificationCount ? 
          <IconButton color="inherit">
            <Badge badgeContent={props.notificationCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> 
          : null
          }
          <IconButton color="inherit" onClick={props.onSignOut}>
            <PowerSettingsNewRoundedIcon />
          </IconButton>
        </Toolbar>
        <LinearProgress value={100} variant={saving ? 'query' : 'determinate'} />
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, (!open && showMenu) && classes.drawerPaperClose, !showMenu && classes.drawerPaperHidden),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
        </div>
        <Divider />
        <List>{mainListItems(userrole)}</List>
        <Divider />
        {
          userrole === 'ADMIN' && (<List>{secondaryListItems}</List>)
        }
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {props.children}
        </Container>
      </main>
    </div>
  );
}