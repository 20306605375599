import React from 'react'
import {
    Button,
    Grid,
    Typography
} from '@material-ui/core'
import AsyncSelect from 'react-select/async'
import RedlistApi from '../../service/redlistApi'
import { useDispatch, useSelector } from 'react-redux'
import { saveDietSpecies, saveDietGenus } from '../../redux/actions/editLevestedActions'

const speciesToOption = item => ({ id: item.id, scientificName: item.scientificName, label: item.scientificName, value: item.id })
const genusToOption = item => ({ id: item.id, name: item.name, label: item.name, value: item.id })

const loadSpeciesOptions = async (inputValue) => {
    const data = await RedlistApi.loadSpecies(inputValue)
    return Promise.resolve(
        data.items.map(speciesToOption)
    )
}

const loadGenusOptions = async (inputValue) => {
    const data = await RedlistApi.loadSpeciesGenus(inputValue)
    return Promise.resolve(
        data.items.map(genusToOption)
    )
}


const DietSpecies = props => {
    const dispatch = useDispatch()
    const { onProceed } = props
    const { substrater } = useSelector(state => state.ekspertEntities.levestedsDefinition)
    const { assessmentId } = useSelector(state => state.editAssessment)
    const { substrater: selectedSubstrater, dietSpecies, dietGenus } = useSelector(state => state.editLevested)
    const selectedSpecies = dietSpecies.map(speciesToOption)
    const selectedGenus = dietGenus.map(genusToOption)

    const substraterWithLeverAf = selectedSubstrater.filter(s => Boolean(s.funktion.find(f => f === 'lever_af' || f === 'lever_paa' || f === 'lever_i')))
    const _substrater = substraterWithLeverAf.map(ss => substrater.find(s => s.id === ss.id))
    const showDietSpecies = _substrater.filter(s => s.hasDietSpecies).length > 0
    const showDietGenus = _substrater.filter(s => s.hasDietGenus).length > 0
    return (

        <Grid item container xs={12} lg={11} spacing={2}>
            {showDietGenus ? (<React.Fragment>
                <Grid item xs={12}>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>Lever af/på/i slægter</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AsyncSelect
                        value={selectedGenus}
                        isMulti
                        cacheOptions
                        loadOptions={inputValue => loadGenusOptions(inputValue)}
                        defaultOptions
                        isClearable={true}
                        onChange={(value) => { const _v = value || []; dispatch(saveDietGenus(assessmentId, _v)) }}
                    />
                </Grid>
            </React.Fragment>) : null}
            {showDietSpecies ? (<React.Fragment>
                <Grid item xs={12}>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>Lever af/på/i arter</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AsyncSelect
                        value={selectedSpecies}
                        isMulti
                        cacheOptions
                        loadOptions={inputValue => loadSpeciesOptions(inputValue)}
                        defaultOptions
                        isClearable={true}
                        onChange={(value) => { const _v = value || []; dispatch(saveDietSpecies(assessmentId, _v)) }}
                    />
                </Grid></React.Fragment>) : null}
            <Grid item xs={12}>
                <Button
                    style={{ float: 'right' }}
                    onClick={() => onProceed()} color='primary' variant='contained'>Videre</Button>
            </Grid>

        </Grid>
    )
}

export default DietSpecies