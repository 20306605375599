import React, { ReactElement } from 'react'
import 'ol/ol.css'
import { Map } from 'ol'


const OlControls = (props: { children: ReactElement | ReactElement[]; map?: Map }) => {
  const { children, map } = props
  const _children: ReactElement[] = Array.isArray(children) ? children : [children]
  if (!map) {
    return null
  }
  return (
    <React.Fragment>
      {_children.map((c: ReactElement, idx: number) => {
        return React.createElement(c.type, { ...c.props, map: map, key: idx })
      })}
    </React.Fragment>
  )
}

export default OlControls
