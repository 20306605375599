import { LOGIN_FAILED, LOGIN_OK, LOGIN_UNAUTHORIZED, LOGOUT_OK, REQUEST_LOGIN, SET_USER } from "../actions/loginActions"
import RedlistApi from '../../service/redlistApi'
import crudProvider from "../../genericCrud/provider/crudProvider"
import RedlistPublishApi from "../../service/redlistPublishApi"
import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"
import ArterDkApi from "../../service/arterDkApi"

declare var REDLIST_WEB_2 : {
  version: string;
  url: string;
  publishUrl: string;
  arterDkUrl: string;
};

export interface UserType {
  name: string
  username: string
  role: string
}

export interface AppStore {
  version: string
  url: string
  publishUrl: string
  loggedIn: string | boolean
  user: null | UserType
  rememberMe: boolean
  error: null | string
}

const initialState : AppStore = {
  version: REDLIST_WEB_2.version,
  url: REDLIST_WEB_2.url,
  publishUrl: REDLIST_WEB_2.publishUrl,
  loggedIn: 'pending',  
  user: null,
  rememberMe: false,
  error: null
}

ArterDkApi.setBaseUrl(REDLIST_WEB_2.arterDkUrl)

RedlistApi.setBaseUrl(REDLIST_WEB_2.url)
RedlistPublishApi.setBaseUrl(REDLIST_WEB_2.publishUrl)
crudProvider.setBaseUrl(REDLIST_WEB_2.url)
/*
const initialState = {
  url: 'https://test.redlist2.bioskaloe.net/api'
}*/


const app = (state = initialState, action : FixMeLater) : AppStore => {
  //console.log('action', action);
    switch (action.type) {
      case REQUEST_LOGIN:
        return { ...state, error: null }
      case LOGIN_OK:
        return { ...state, loggedIn: true, user: action.payload.user, rememberMe: action.payload.rememberMe, error: null }
      case SET_USER:
        return { ...state, user: action.payload.user, error: null }
        case LOGIN_FAILED:
          return { ...state, user: null, loggedIn: false, error: action.payload.message }
        case LOGIN_UNAUTHORIZED:
          return { ...state, user: null, loggedIn: false, error: 'Forkert brugernavn eller password' }
          case LOGOUT_OK:
        console.log("Logout detected")
        return { ...state, loggedIn: false, user: null, error: null }
      default:
        return state
    }
  }
  
  export default app