import RedlistApi from '../../service/redlistApi'

import {
    showSaving,
    hideSaving,
    showMessage,
    showError
} from "../actions/uiActions";

import {
    REQUEST_SEND_TO_QA
  
} from './editAssessmentActions';
import {
    resetCurrentSection
} from "./editAssessmentMenuActions";
import ArterDkApi from '../../service/arterDkApi'

import {
    apiRequest2
} from '../api/apiRequest'
import {
    put,
    select,
    takeEvery    
} from "redux-saga/effects";
import { RedlistStore } from '../reducers';
import { FixMeLater } from '../../assessments/components/GlobalErrorMessage';
import { EditAssessmentStore } from '../reducers/editAssessmentRed';
import { GeographyMapStore } from '../reducers/geographyMapReducer';
import { SnapshotMetadataResponse } from '../../service/arterDkModels';


function* processSendToQA(action : FixMeLater) {
    try {  
        const {
            assessmentId
        } = action.payload       
        const { category, speciesInformation, assessmentCriteria } : EditAssessmentStore = yield select((state: RedlistStore) => state.editAssessment)
        const geographyMap : GeographyMapStore = yield select((state: RedlistStore) => state.geographyMap)
        if (!category || !category.category || !category.modifiedCategory) {
            yield put(showMessage("Fejl", "Fejl i kategori-beregning, vurderingen kan ikke indsendes.", undefined))
            return
        }
        if (assessmentCriteria === null) {
            yield put(showMessage("Fejl", "Fejl i vurdering. Kunne ikke indsende.", undefined))
            return
        }
        const categoryRequest = {
            category: category.modifiedCategory.name,
            categoryRaw: category.category.name,
            categoryModifier: category.modification,
            categoryRoute: category.routes
        }
        let usesArterDkData = assessmentCriteria.global?.useArterDkData === 'yes'
        yield put(showSaving())
        const taxonId = speciesInformation?.arterDkId
        if (taxonId && geographyMap.importStatus?.status === 'done' && usesArterDkData) {
            const snapshotMeta : (SnapshotMetadataResponse | null) = yield apiRequest2(ArterDkApi, ArterDkApi.takeSnapshot, [ taxonId, geographyMap.year ])
            if (snapshotMeta == null || snapshotMeta.item == null) {
                yield put(hideSaving())        
                yield put(showMessage("Kunne ikke sende vurdering til kvalitetssikring", "Fejl under oprettelse af geografisk snapshot", undefined))
                return
            }
            const geographyPatch = { ...assessmentCriteria.geography, geographySnapshotId: snapshotMeta.item.id }
            yield apiRequest2(RedlistApi, RedlistApi.updateSection, [assessmentId, 'geography', { patch: geographyPatch, category: categoryRequest } ])            
        }
        yield apiRequest2(RedlistApi, RedlistApi.updateCategory, [ assessmentId, categoryRequest ])        
        yield apiRequest2(RedlistApi, RedlistApi.updateStatusNext, [ assessmentId ], {
            "error": showMessage("Fejl", "Kunne ikke sende vurdering til kvalitetssikring", undefined)
        })
        yield put(hideSaving())        
        yield put(showMessage('Ok', 'Vurdering sendt til kvalitetssikring', resetCurrentSection()))
        window.location.hash = "#/vurderinger/"
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error, "Kunne ikke sende vurdering til kvalitetssikring"))
    }
}


export default function* watcher() {
    yield takeEvery(REQUEST_SEND_TO_QA, processSendToQA)
}