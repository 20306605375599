import React from 'react'
import { FixMeLater } from '../../components/GlobalErrorMessage'
import { TextHelpSection, TextHelpParagraph, TextHelpWithTabs } from './help'


const naSection = {
    title: 'NA',
    content: () => (
        <TextHelpSection>
            <TextHelpParagraph>
                Det er kun relevant at rødlistevurde-re arter, som
                er indvandret af sig selv. Der er ikke nogen tidsgrænse for, hvornår en art skal have levet i Danmark,
                førend den kan tages med på rød-listen, så længe man relativt sikkert ved, at arten har levet her
                engang (ulv, brun bjørn, vildsvin, urokse osv.). Således er det f.eks. ikke relevant at vur-dere
                arter, som er tilfældige strejfere, dvs. arter som kun lejlighedsvis yngler under gunstige
                forhold for dernæst at forsvinde efter en kort periode. Det er derimod relevant at bedømme
                regelmæssigt gæstende arter, som f.eks. træk-fugle. For arter, der er indførte, er det udelukkende
                relevant at bedømme dem, hvis de er indførte i bevaringsøjemed. Det gælder f.eks. for arter, som
                regelmæssigt tilføres nye individer udefra for at opretholde en eller flere af bestandene.
                Eksempler herpå er klokkefrø (Bombina bombina) og laks (Salmo salar). Genindførte og genindvandrede,
                førhen forsvundne (RE) arter kan bedømmes, når en del af bestanden i det mindste er i stand til at
                forplante sig uden direkte, supplerende udsætning. Dvs. eghjort (Lucanus cervus) kan rødlistevurderes,
                så snart den har formeret sig naturligt her i landet. Det er ikke relevant at bedømme arter, som er under
                etablering, dvs. arter, som endnu ikke har været her i 10 år eller i 3 generationer. Dvs. det er relevant
                at rødlistevurdere en art som bæver (Castor fiber), som var væk i over 9000 år (Aaris-Sørensen 1988),
                men som i 1999 blev genudsat og som yngler med succes.
            </TextHelpParagraph>
            <TextHelpParagraph>
                Arter, som genkoloniserer landet og tidligere var betragtet som for-svundne (RE), bør generelt rødlistebedømmes,
                når den første forplantning i et år har fundet sted, med mindre den har været væk meget længe, som f.eks. bæveren.
            </TextHelpParagraph>
            <TextHelpParagraph>
                Danmarks grænser er i ovenstående sammenhæng fastlagt af den nuværen- de landegrænse samt udstrækningen af det
                danske søterritorium. Som følge af menneskers langvarige påvirkninger af omgivelserne kan det være svært at
                trække grænsen mellem populationer, der stammer fra indførte organis-mer, og populationer, der stammer fra
                organismer, der selv er indvandret.  Arter, som er indvandret uden direkte menneskelig hjælp (dvs. passivt med transport og lign.)
                og har etableret sig, kan komme i betragtning ved rød-listevurderingen. Det gælder f.eks. strand-bede (Beta maritima),
                der muligvis oprindeligt er indslæbt med ballastsand til havnebyer, hvorfra den har natu-raliseret sig (Hansen & Pedersen 1968).
            </TextHelpParagraph>
        </TextHelpSection>)
}

const reSection = {
    title: 'RE',
    content: () => (
        <TextHelpSection>
            <TextHelpParagraph>
                En art, der er uddød inden for Danmarks grænser,  men som fortsat findes i naturlige populationer udenfor,
                henføres til katego- rien forsvundet (RE). En art defineres som forsvundet, når der ikke hersker  tvivl om,
                at det sidste forplantningsdygtige individ er dødt eller forsvundet  fra landet. Strejfende individer af arter,
                som tidligere er forsvundet, skal  fortsat kategoriseres forsvundet (RE),  så længe en genetableret population
                ikke kan dokumenteres. Danske eksempler er svalehale ( Papilio machaon) og  bjerg-ulvefod (Diphasiastrum alpinum).
                Det kan være svært at afgøre, hvornår eller om det sidste individ af en art er forsvundet fra landet.
                Indenfor dårligt undersøgte grupper behøver den kendsgerning, at en art sidst er observeret i landet i 1927,
                ikke at betyde, at den er forsvundet. Arter, der ikke er registreret siden 1850, vil dog altid blive kategoriseret
                forsvundet (RE). Derudover er det ikke muligt at sætte generelle tidsgrænser for, hvornår arten senest skal være
                observeret, for at den kan kategoriseres forsvundet (RE). Individer af længelevende arter, der er ophørt med at
                reproducere sig i landet på grund af forringede eller manglende levesteder, bør ikke klassificeres som forsvundet (RE).
                Begrundelsen herfor er, at levevilkårene over tid kan ændre sig i gunstig retning, og at de tilbageværende individer
                kan begynde at formere sig på ny.
            </TextHelpParagraph>
        </TextHelpSection>)
}

const ddSection = {
    title: 'DD',
    content: () => (
        <TextHelpSection>
            <TextHelpParagraph>
                P.t. er data ikke tilstrækkelige til at vurdere arten
            </TextHelpParagraph>
            <TextHelpParagraph>
                Hvis datagrundlaget er for ringe eller mangelfult til troværdigt
                at vurdere artens status, skal man vinge denne boks af (se i øvrigt beskrivelse af kategorien DD i afsnittet Lidt om rødlisten).
                Arten henføres i så fald til kategorien utilstrækkelige data (DD). Denne kategori opfattes som noget nyt (siden seneste rødliste),
                som en egentlig rødlistekategori på lige fod med forsvundet (RE), kritisk truet (CR), moderat truet (EN), sårbar (VU) og næsten truet (NT).
                Denne praksis er indført, for at rødlisten skal fremtræde så troværdig som muligt, og for ikke at fremprovokere vurderinger af arter på et
                for spinkelt datagrundlag, som så senere viser sig at burde tilhøre en helt anden rødlistekategori, end de først var henført til.
                Desuden afspejler denne praksis vore nabolandes brug af kategorien DD. En tommelfingerregel er, at hvis eksperten vurderer, at det er
                sandsynligt, at den forekommer på flere end 10 gange så mange lokaliteter, som den er kendt fra (baseret på levestedskrav, spredningsevne osv.),
                bør man svare ja til dette spørgsmål, og dermed henføre arten til kategorien DD. En anden tommelfingerregel er, at denne boks bør vinges af
                hvis eksperten vurderer, at en art ligeså godt kunne ende i LC som i CR baseret på de nuværende data. Hvis eksperten vurderer, at data er
                gode nok til at sige, at arten ender i NT-CR, skal man som udgangspunkt forsøge at placere den i en af disse kategorier ved at udfylde
                nedenstående felter. Disse tommelfingerregler gælder i sagens natur ikke altid. Det er vigtigt at understrege, at datagrundlag i denne
                sammenhæng også omfatter viden om artens levested. Hvis man ved, at artens levested er i (stærk) tilbagegang, kan det i nogle tilfælde
                være nok til at vurdere arten, selvom man i øvrigt har mangelfulde udbredelses-/forekomstdata. Det er meget vigtigt, at man i
                kommentar/dokumen-tationsfeltet angiver, hvor man har forsøgt at finde data om arten, eller på anden måde dokumenterer, at data er mangelfulde
                eller af for dårlig kvalitet.
            </TextHelpParagraph>
        </TextHelpSection>
    )
}

const lcSection = {
    title: 'LC',
    content: () => (
        <TextHelpSection>
            <TextHelpParagraph>
                Arten er helt sikkert ikke truet eller i nærheden af at være truet?
            </TextHelpParagraph>
            <TextHelpParagraph>
                Her svares ja, hvis man er helt sikker på at arten ikke er truet i Danmark, og arten henføres således til kategorien ikke truet (LC).
                Det gælder f.eks. stor nælde (Urtica dioica), elledans-bruskhat (Marasmius oreades), plæne-kransemos (Rhytidiadelphus squarrosus),
                solsort (Turdus merula), muldvarp (Talpa europaea), nældens takvinge (Aglais urticae) m.fl. Hvis man ikke er sikker på, at arten ikke
                er truet eller i nærheden af at være truet, men blot mener det eller tror det, skal man udfylde nedenstående: Det kan stadig medføre,
                at arten kategoriseres som ikke truet (LC), ligeså vel som det kan medføre, at den kommer i en af de andre kategorier.
            </TextHelpParagraph>
        </TextHelpSection>)
}

const neSection = {
    title: 'NE',
    content: () => (
        <TextHelpSection>
            <TextHelpParagraph>
                I enkelte tilfælde er arter tidligere fejlbestemte og nyere undersøgelser viser at arten ikke findes i DK.
                I andre tilfælde kan der være så meget usikkerhed om taksonomien at DD ikke er en mulighed og at det således
                ikke giver mening at vurdere arten. I sådanne tilfælde kan man vælge at henføre en art til kategorien NE.
                Dette valg skal altid foretages efter aftale med projektleder for rødlisten ved DCE.
            </TextHelpParagraph>
        </TextHelpSection>)
}



const sections = [naSection, reSection, ddSection, lcSection, neSection]

export const PreliminaryHelpElement = (props : FixMeLater) => {
    return (
        <TextHelpWithTabs sections={sections}>

        </TextHelpWithTabs>
    )
}