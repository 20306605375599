import React from 'react'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import CheckboxButton from '../../mui/CheckboxButton'
import { ThemeProvider } from 'styled-components'
import Gradienter from './Gradienter'


const buttonTheme = {
    background: '#ff9e80',
    color: '#222',
    borderColor: '#ff8a65',
    checked: {
        background: '#ff6e40',
        color: '#000',
        borderColor: '#ff7043'
    }
}

const buttonLinkTheme = {
    background: '#eeeeee',
    color: '#222',
    borderColor: '#bdbdbd',
    checked: {
        background: '#9e9e9e',
        color: '#000',
        borderColor: '#424242'
    }
}

const renderHabitat = (habitat, selectedHabitats, onChange) => {
    let selected = selectedHabitats.filter(e => {
        return e.id === habitat.id;
    }).length > 0;
    let name = habitat.name;    
    return (
        <Grid key={habitat.id} item xs={12} sm={6} lg={4}>
            <CheckboxButton
                fullwidth
                onChange={checked => onChange(habitat, checked)}
                label={name}
                tooltip={habitat.name + ': ' + habitat.description}
                checked={selected} />
        </Grid>)
}

const Habitater = (props) => {
    let { selectedHabitats, selectedEcosystems, habitater, onSelectedChanged, onProceed } = props;

    let selectionChanged = (habitat, value) => {
        //  console.log(ecosystem, value);
        let _t = selectedHabitats.filter(e => e.id !== habitat.id);
        if (value) {
            _t = _t.concat([habitat]);
        }
        onSelectedChanged(_t);
    }

    const availableHabitats = []
    const availableLinkedHabitats = []
    habitater.forEach(h => {
        if (selectedEcosystems.find(e => e.id === h.ecosystemId)) {
            availableHabitats.push(h)
        } else if (selectedEcosystems.find(e => {
            return h.secondaryEcosystems?.find(se => se === e.id)
        })) {
            availableLinkedHabitats.push(h)
        }
    })

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
                <Grid xs={12} item>
                    <Typography style={{ width: '100%', textAlign: 'left' }} variant='h5'>Angiv habitater</Typography>
                </Grid>
                <ThemeProvider theme={buttonTheme}>
                    {
                        availableHabitats.map(e => renderHabitat(e, selectedHabitats, selectionChanged))
                    }
                </ThemeProvider>
                <ThemeProvider theme={buttonLinkTheme}>
                    {
                        availableLinkedHabitats.map(e => renderHabitat(e, selectedHabitats, selectionChanged))
                    }
                </ThemeProvider>
                <Grid item xs={12}>
                    <Gradienter>

                    </Gradienter>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={() => onProceed()}
                        color='primary'
                        variant='contained'>Videre</Button>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default Habitater