import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeLatest,
    takeEvery,
    select,
    takeLeading
} from 'redux-saga/effects'
import {
    showSpinner,
    hideSpinner,
    showSaving,
    hideSaving,
    showError
} from '../actions/uiActions'
import { 
    MANAGE_ASSESSMENTS_PAGE_SET_FILTER, 
    MANAGE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS, 
    MANAGE_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
    REQUEST_SAVE_ASSESSMENT_METADATA,
    fetchAssessments,
    MANAGE_ASSESSMENTS_PAGE_INIT,
    setPage,
    setFilter,
    MANAGE_ASSESSMENTS_SET_PAGE,
    MANAGE_ASSESSMENTS_NEXT_PAGE,
    MANAGE_ASSESSMENTS_PREV_PAGE,
    REQUEST_DELETE_ASSESSMENT
} from './manageAssessmentsPageActions'
import { fetchAdminEntities } from './adminEntitiesActions'

function* processInit(action) {
    try {
        const filter = yield select(state => state.manageAssessmentsPage.filter)
        yield put(fetchAdminEntities())
        yield put(fetchAssessments(filter))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSaveMetadata(action) {
    const { assessmentIds, metadata } = action.payload
    try {       
        const {
            status,
            vurderingsrunde,
            assessor,
            approver,
            technicalApprover,
            usergroup
        } = metadata
        const patch = {
            assessmentIds,
            patch: {
                status: status?.value,
                vurderingsrundeId: vurderingsrunde?.value,
                assessorId: assessor?.value,
                approverId: approver?.value,
                technicalApproverId: technicalApprover?.value,
                usergroupId: usergroup?.value
            }
        }
        yield put(showSaving())
        yield apply(RedlistApi, RedlistApi.updateAssessmentMetadata, [ patch ])
        const filter = yield select(state => state.manageAssessmentsPage.filter)
        yield put(fetchAssessments(filter))
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(showError(error))
        yield put(hideSaving())
    }
}


function* processDeleteAssessment(action) {
    const { assessmentIds, comment } = action.payload
    try {       
        const patch = {
            assessmentIds,
            comment
        }
        yield put(showSaving())
        console.log('Delete assessments', patch)
        yield apply(RedlistApi, RedlistApi.softDeleteAssessments, [ patch ])
        const filter = yield select(state => state.manageAssessmentsPage.filter)
        yield put(fetchAssessments(filter))
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(showError(error))
        yield put(hideSaving())
    }
}


function* processFetchAssessments(action) {
    try {
        yield put(showSpinner())
        const data = yield apply(RedlistApi, RedlistApi.loadAssessments, [ action.payload.filter ])
        yield put({ type: MANAGE_ASSESSMENTS_PAGE_SET_ASSESSMENTS, payload: { assessments: data.items, pages: data.pages, count: data.count }})
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}

function* processSetNextPage(action) {
    try {
        const filter = yield select(state => state.manageAssessmentsPage.filter)
        yield put(setPage(filter.page + 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPrevPage(action) {
    try {
        const filter = yield select(state => state.manageAssessmentsPage.filter)
        yield put(setPage(filter.page - 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPage(action) {
    try {
        const { filter, pages } = yield select(state => state.manageAssessmentsPage)
        let page = action.payload.page
        if (page > pages) {
            page = pages
        } else if (page < 0) {
            page = 0
        }
        yield put(setFilter({ ...filter, page }))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetFilter(action) {
    try {
        yield put(fetchAssessments(action.payload))
    } catch (error) {
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLeading(MANAGE_ASSESSMENTS_PAGE_INIT, processInit)
    yield takeEvery(MANAGE_ASSESSMENTS_PAGE_SET_FILTER, processSetFilter)
    yield takeLatest(MANAGE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS, processFetchAssessments)
    yield takeEvery(REQUEST_SAVE_ASSESSMENT_METADATA, processSaveMetadata)
    yield takeEvery(REQUEST_DELETE_ASSESSMENT, processDeleteAssessment)
    yield takeLeading(MANAGE_ASSESSMENTS_SET_PAGE, processSetPage)
    yield takeLeading(MANAGE_ASSESSMENTS_NEXT_PAGE, processSetNextPage)
    yield takeLeading(MANAGE_ASSESSMENTS_PREV_PAGE, processSetPrevPage)
}