import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeLatest,
    takeEvery,
    select,
    takeLeading,
    cancelled
} from 'redux-saga/effects'
import {
    showSpinner,
    hideSpinner,
    showSaving,
    hideSaving,
    showError
} from '../actions/uiActions'
import { 
    CREATE_ASSESSMENTS_PAGE_SET_FILTER, 
    CREATE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS, 
    CREATE_ASSESSMENTS_PAGE_SET_ASSESSMENTS,
    fetchAssessments,
    CREATE_ASSESSMENTS_PAGE_INIT,
    setPage,
    setFilter,
    CREATE_ASSESSMENTS_SET_PAGE,
    CREATE_ASSESSMENTS_NEXT_PAGE,
    CREATE_ASSESSMENTS_PREV_PAGE,
    CREATE_ASSESSMENTS,
    CREATE_ASSESSMENTS_OK
} from './createAssessmentsPageActions'
import { fetchAdminEntities } from './adminEntitiesActions'

function* processInit(action) {
    try {
        const filter = yield select(state => state.createAssessmentsPage.filter)
        yield put(fetchAdminEntities())
        yield put(fetchAssessments(filter))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processFetchAssessments(action) {
    const procId = action.type
    try {
        yield put(showSpinner(procId))
        const data = yield apply(RedlistApi, RedlistApi.loadSpeciesWithAssessments, [ action.payload.filter ])
        yield put({ type: CREATE_ASSESSMENTS_PAGE_SET_ASSESSMENTS, payload: { assessments: data.items, pages: data.pages, count: data.count }})
        yield put(hideSpinner(procId))
    } catch (error) {
        console.log(error)
        yield put(hideSpinner(procId))
        yield put(showError(error))
    } finally {
        if (yield cancelled()) {
          yield put(hideSpinner(procId))
        }
      }
}

function* processSetNextPage(action) {
    try {
        const filter = yield select(state => state.createAssessmentsPage.filter)
        yield put(setPage(filter.page + 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPrevPage(action) {
    try {
        const filter = yield select(state => state.createAssessmentsPage.filter)
        yield put(setPage(filter.page - 1))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetPage(action) {
    try {
        const { filter, pages } = yield select(state => state.createAssessmentsPage)
        let page = action.payload.page
        if (page > pages) {
            page = pages
        } else if (page < 0) {
            page = 0
        }
        yield put(setFilter({ ...filter, page }))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetFilter(action) {
    try {
        yield put(fetchAssessments(action.payload))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processCreateAssessments(action) {
    try {
        const { request } = action.payload
        yield put(showSaving())
        yield apply(RedlistApi, RedlistApi.createAssessments, [ request ])
        yield put(hideSaving())
        yield put({ type: CREATE_ASSESSMENTS_OK })
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processCreateAssessmentsOk(action) {
    const { filter } = yield select(state => state.createAssessmentsPage)
    yield put(fetchAssessments(filter))
}

export default function* watcher() {
    yield takeLeading(CREATE_ASSESSMENTS_PAGE_INIT, processInit)
    yield takeEvery(CREATE_ASSESSMENTS_PAGE_SET_FILTER, processSetFilter)
    yield takeLatest(CREATE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS, processFetchAssessments)
    yield takeLeading(CREATE_ASSESSMENTS_SET_PAGE, processSetPage)
    yield takeLeading(CREATE_ASSESSMENTS_NEXT_PAGE, processSetNextPage)
    yield takeLeading(CREATE_ASSESSMENTS_PREV_PAGE, processSetPrevPage)
    yield takeEvery(CREATE_ASSESSMENTS, processCreateAssessments)
    yield takeEvery(CREATE_ASSESSMENTS_OK, processCreateAssessmentsOk)
}