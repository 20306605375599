import React from 'react'
import { useState } from 'react'
import AsyncSelect from 'react-select/async'

import { Grid, Button, Typography, TextField } from '@material-ui/core'
import crudProvider from './provider/crudProvider'

const propertyStateFromItem = (item, properties) => {
  const propertyState = {}
  properties.forEach((p) => {
    if (p.type === 'string') {
      propertyState[p.property] = item[p.property]
    } else if (p.type === 'list') {
      let parent = item[p.reference.parent]
      if (!parent) {
        console.log(item)
        return
      }
      let val = item[p.reference.parent][p.reference.valueProperty]
      let id = item[p.reference.parent][p.reference.idProperty]
      propertyState[p.property] = {
        id: id,
        value: val,
      }
    } else if (p.type === 'multilist') {
        let parent = item[p.reference.parent]
        if (!parent) {
            console.log(item)
            return
          }
        let values = item[p.reference.parent] || []
        propertyState[p.property] = values.map(v => ({
                value: v[p.reference.idProperty],
                label: v[p.reference.valueProperty]
            }
        ))

    }
  })
  return propertyState
}

export const propertyStateToPatch = (propertyState, properties) => {
  const patch = {}
  properties.forEach((p) => {
    if (p.type === 'string') {
      patch[p.property] = propertyState[p.property]
    } else if (p.type === 'list') {
      patch[p.property] = propertyState[p.property].id
    } else if (p.type === 'multilist') {
      const val = propertyState[p.property]
      patch[p.property] = val
        ? val.map((v) => {
            const res = {}
            res[p.reference.idProperty] = v.value
            return res
          })
        : []
    }
  })
  return patch
}

const StringProperty = (props) => {
  const { p, propertyState, setPropertyState } = props
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        label={p.label}
        value={propertyState[p.property]}
        onChange={(evt) => {
          let newPropertyState = { ...propertyState }
          newPropertyState[p.property] = evt.target.value
          setPropertyState(newPropertyState)
        }}
      />
    </Grid>
  )
}

const ListProperty = (props) => {
  const { p, item, propertyState, setPropertyState } = props

  const loadOptions = (defaultId, defaultLabel) => (inputValue, callback) => {
    const filter = {}
    const idProperty = p.reference.idProperty
    const valueProperty = p.reference.valueProperty
    const defaultValue = [
      {
        value: item[p.reference.parent][p.reference.idProperty],
        label: item[p.reference.parent][p.reference.valueProperty],
      },
    ]
    filter[valueProperty] = inputValue
    crudProvider.loadByFilter(p.reference.key, filter).then((data) => {
      console.log('fetched', data)
      callback(data.items.map((i) => ({ value: i[idProperty], label: i[valueProperty] })).concat(defaultValue))
    })
  }
  const value = propertyState[p.property].id
  const label = propertyState[p.property].value
  console.log('selected', value, label)
  return (
    <Grid item xs={12}>
      <AsyncSelect
        value={{ value, label }}
        defaultOptions
        loadOptions={loadOptions('id', 'value')}
        onChange={(e) => {
          const newPropertyState = { ...propertyState }
          newPropertyState[p.property] = {
            id: e.value,
            value: e.label,
          }
          setPropertyState(newPropertyState)
        }}
      />
    </Grid>
  )
}

const MultiValueListProperty = (props) => {
  const { p, propertyState, setPropertyState, validation } = props
  let ok = true
  let message = ''
  if (p.required) {
    ok = validation.ok
    message = validation.message
  }
  const loadOptions = () => (inputValue, callback) => {
    const filter = {}
    const idProperty = p.reference.idProperty
    const valueProperty = p.reference.valueProperty
    filter[valueProperty] = inputValue
    crudProvider.loadByFilter(p.reference.key, filter).then((data) => {
      console.log('fetched', data)
      callback(data.items.map((i) => ({ value: i[idProperty], label: i[valueProperty] })))
    })
  }
  const selected = propertyState[p.property]
  console.log({ selected })
  return (
    <Grid item xs={12}>
      {!ok && (
        <Typography variant="subtitle2" color="error">
          {message}
        </Typography>
      )}
      <AsyncSelect
        value={selected}
        defaultOptions
        loadOptions={loadOptions()}
        isMulti
        onChange={(e) => {
          const newPropertyState = { ...propertyState }
          newPropertyState[p.property] = e
          setPropertyState(newPropertyState)
        }}
      />
    </Grid>
  )
}

const renderProperty = (p, propertyState, setPropertyState, item) => {
  if (p.type === 'string') {
    return <StringProperty key={p.property} p={p} propertyState={propertyState} setPropertyState={setPropertyState} />
  } else if (p.type === 'list') {
    return (
      <ListProperty
        key={p.property}
        item={item}
        p={p}
        propertyState={propertyState}
        setPropertyState={setPropertyState}
      />
    )
  } else if (p.type === 'multilist') {
      return (
          <MultiValueListProperty
          key={p.property}
          item={item}
          p={p}
          propertyState={propertyState}
          setPropertyState={setPropertyState} />
      )
  } else {
    return null
  }
}

const EditDialog = (props) => {
  const { item, properties, onDelete, onClose, onSave } = props
  const [propertyState, setPropertyState] = useState(propertyStateFromItem(item, properties))
  console.log(propertyState)
  return (
    <div style={{ maxWidth: 320, minWidth: 320, margin: 48 }}>
      <Typography variant="h6">Rediger {item.name}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        {properties.map((p) => renderProperty(p, propertyState, setPropertyState, item))}
        <Grid item xs={2}>
          <Button onClick={() => onDelete(item.id)} fullWidth variant="contained" color="default">
            Slet
          </Button>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <Button onClick={() => onClose()} fullWidth variant="contained" color="secondary">
            Luk
          </Button>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => onSave(propertyStateToPatch(propertyState, properties))}
            fullWidth
            variant="contained"
            color="primary"
          >
            Gem
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default EditDialog
