import {
  Categories
} from '../../../service/categoryEngine'

import {
  isArrayNullOrEmpty, oneOf
} from './sectionValidators'

const categoryValuesThatRequiresThreat = [
  Categories.CR.value,
  Categories.EN.value,
  Categories.VU.value,
  Categories.NT.value
]

export const threatValidator = (threat, category) => {  
  const threatsUnknown = threat.meta ? threat.meta.threatsUnknown : null
  if (category && oneOf(category.value, categoryValuesThatRequiresThreat) ) {
    if (isArrayNullOrEmpty(threat.threats) && isArrayNullOrEmpty(threat.overallThreats)) {
      return {
        ok: true,
        warning: true,
        global: true,
        message: 'Der bør angives mindst én trussel'
      }
    }
  }
  if (threatsUnknown && (!isArrayNullOrEmpty(threat.threats) || !isArrayNullOrEmpty(threat.overallThreats))) {
    return {
      ok: false,
      warning: false,
      global: false,
      message: "Der må ikke angives en trussel hvis der er angivet at 'Truslerne for denne art er dårligt kendte'"
    }
  }
  return {
    empty: isArrayNullOrEmpty(threat.threats) && isArrayNullOrEmpty(threat.overallThreats) && !threatsUnknown,
    ok: true
  }
}