import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const CREATE_ASSESSMENTS_PAGE_INIT = '[createAssessmentsPage] init'

export const CREATE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS = '[createAssessmentsPage] fetch assessments'
export const CREATE_ASSESSMENTS_PAGE_SET_ASSESSMENTS = '[createAssessmentsPage] set assessments'

export const CREATE_ASSESSMENTS_SET_PAGE = '[createAssessmentsPage] set page'
export const CREATE_ASSESSMENTS_NEXT_PAGE = '[createAssessmentsPage] set next page'
export const CREATE_ASSESSMENTS_PREV_PAGE = '[createAssessmentsPage] set prev page'

export const CREATE_ASSESSMENTS_PAGE_SET_FILTER = '[createAssessmentsPage] set filter'

export const CREATE_ASSESSMENTS = '[createAssessmentsPage] create';
export const CREATE_ASSESSMENTS_OK = '[createAssessmentsPage] create ok';


export const initCreateAssessmentsPage = (filter : FixMeLater) => ({
    type: CREATE_ASSESSMENTS_PAGE_INIT,
    payload: { filter }
})

export const fetchAssessments = (filter : FixMeLater) => ({
    type: CREATE_ASSESSMENTS_PAGE_FETCH_ASSESSMENTS,
    payload: { filter }
})

export const setFilter = (filter : FixMeLater) => ({
    type: CREATE_ASSESSMENTS_PAGE_SET_FILTER,
    payload: filter
})

export const nextPage = () => ({
    type: CREATE_ASSESSMENTS_NEXT_PAGE
})

export const prevPage = () => ({
    type: CREATE_ASSESSMENTS_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: CREATE_ASSESSMENTS_SET_PAGE,
    payload: { page }
})

export const createAssessments = (request : FixMeLater) => ({
    type: CREATE_ASSESSMENTS,
    payload: {
      request
    }
  })