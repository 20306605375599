import React, { ReactElement } from 'react'
import { useState } from 'react'
import {
    Typography,
    Tab,
    Tabs
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FixMeLater } from '../../components/GlobalErrorMessage';

export const helpStyles = makeStyles((theme : Theme) => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: 800
    },
    section: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: 800
    },
    paragraph: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        maxWidth: 800
    }
}));

export interface TextHelpElementProps {
    title?: string;
    children: ReactElement | ReactElement[] | string
}

export interface TextHelpParagraphProps {
    children: ReactElement | ReactElement[] | string
}

export const TextHelpElement = (props : TextHelpElementProps) => {
    const classes = helpStyles();

    return (<React.Fragment>
        <Typography className={classes.typography} variant='subtitle1'>
            {props.title}
        </Typography>
        <Typography className={classes.paragraph} variant='body2'>
            {props.children}
        </Typography>
    </React.Fragment>)
}

export const TextHelpParagraph = (props : TextHelpParagraphProps) => {
    const classes = helpStyles();
    return (
        <Typography className={classes.paragraph} variant='body2'>
            {props.children}
        </Typography>
    )
}

export const TextHelpSection = (props : TextHelpElementProps) => {
    const classes = helpStyles();
    return (<React.Fragment>
        { props.title && <Typography className={classes.section} variant='subtitle2'>
            {props.title}
        </Typography>}
        {props.children}
    </React.Fragment>)
}

export const TextHelpWithSectionsElement = (props : TextHelpElementProps) => {
    const classes = helpStyles();
    return (<React.Fragment>
        <Typography className={classes.typography} variant='subtitle1'>
            {props.title}
        </Typography>
        {props.children}
    </React.Fragment>)
}

export const TextHelpWithTabs = (props : FixMeLater) => {
    const { sections } = props
    const [ tab, setTab ] = useState(0)
    const classes = helpStyles()
    return (
        <React.Fragment>
            <Tabs value={tab} onChange={(evt, value : number) => setTab(value)}>
                {sections.map((s : FixMeLater, idx : number) => <Tab key={idx} label={s.title} />)}
            </Tabs>
            <div className={classes.typography}>
                {sections[tab].content()}
            </div>
        </React.Fragment>
    )
}