import { FixMeLater } from "../../assessments/components/GlobalErrorMessage"

export const MANAGE_SPECIES_PAGE_INIT = '[manage species] init'
export const MANAGE_SPECIES_PAGE_SET_FILTER = '[manage species] set filter'

export const REQUEST_CREATE_SPECIES = '[manage species] create'
export const MANAGE_SPECIES_PAGE_CREATE_OK = '[manage species] create ok'
export const MANAGE_SPECIES_PAGE_CREATE_ERROR = '[manage species] create error'

export const DELETE_SPECIES = '[manage species] delete'

export const REQUEST_UPDATE_SPECIES = '[manage species] update'
export const MANAGE_SPECIES_PAGE_UPDATE_OK = '[manage species] update ok'
export const MANAGE_SPECIES_PAGE_UPDATE_ERROR = '[manage species] update error'

export const REQUEST_SET_SPECIES_GROUP_FOR_SPECIES_LIST = '[manage species] request set group for list'
export const MANAGE_SPECIES_SET_SPECIES_GROUP_FOR_SPECIES_LIST_OK = '[manage species] set group for list ok'
export const MANAGE_SPECIES_SET_SPECIES_GROUP_FOR_SPECIES_LIST_ERROR = '[manage species] set group for list error'

export const MANAGE_SPECIES_PAGE_SET_SPECIES = '[manage species] set species'
export const MANAGE_SPECIES_PAGE_SET_SELECTED_SPECIES = '[manage species] set selected species'

export const MANAGE_SPECIES_PAGE_SHOW_CREATE_DIALOG = '[manage species] show create'
export const MANAGE_SPECIES_PAGE_HIDE_CREATE_DIALOG = '[manage species] hide create'

export const MANAGE_SPECIES_PAGE_SHOW_EDIT_DIALOG = '[manage species] show edit'
export const MANAGE_SPECIES_PAGE_HIDE_EDIT_DIALOG = '[manage species] hide edit'

export const MANAGE_SPECIES_PAGE_NEXT_PAGE = '[manage species] next page'
export const MANAGE_SPECIES_PAGE_PREV_PAGE = '[manage species] prev page'
export const MANAGE_SPECIES_PAGE_SET_PAGE = '[manage species] set page'

export const manageSpeciesPageInit = () => ({
    type: MANAGE_SPECIES_PAGE_INIT
})

export const setFilter = (filter : FixMeLater) => ({
    type: MANAGE_SPECIES_PAGE_SET_FILTER,
    payload: {
        filter
    }
})

export const createSpecies = (species : FixMeLater) => ({
    type: REQUEST_CREATE_SPECIES,
    payload: {
        species
    }
})

export const updateSpecies = (id : FixMeLater, species : FixMeLater) => ({
    type: REQUEST_UPDATE_SPECIES,
    payload: {
        id,
        species
    }
})

export const setSpeciesGroupForSpeciesList = (ids : number[], speciesGroupId : number) => ({
    type: REQUEST_SET_SPECIES_GROUP_FOR_SPECIES_LIST,
    payload: {
        ids,
        speciesGroupId
    }
})

export const deleteSpecies = (id : FixMeLater) => ({
    type: DELETE_SPECIES,
    payload: {
        id        
    }
})

export const showCreateDialog = () => ({
    type: MANAGE_SPECIES_PAGE_SHOW_CREATE_DIALOG
})

export const hideCreateDialog = () => ({
    type: MANAGE_SPECIES_PAGE_HIDE_CREATE_DIALOG
})

export const showEditDialog = (species : FixMeLater) => ({
    type: MANAGE_SPECIES_PAGE_SHOW_EDIT_DIALOG,
    payload: {
        species: species
    }
})

export const hideEditDialog = () => ({
    type: MANAGE_SPECIES_PAGE_HIDE_EDIT_DIALOG
})

export const nextPage = () => ({
    type: MANAGE_SPECIES_PAGE_NEXT_PAGE
})

export const prevPage = () => ({
    type: MANAGE_SPECIES_PAGE_PREV_PAGE
})

export const setPage = (page : number) => ({
    type: MANAGE_SPECIES_PAGE_SET_PAGE,
    payload: { page }
})