import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  HashRouter as Router
} from "react-router-dom";
import store from './redux/store';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles'
import { blueAmber } from './mui/themes';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <ThemeProvider theme={createTheme(blueAmber)}>
        <App />
      </ThemeProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
