import RedlistApi from '../../service/redlistApi'

import {
    put,
    takeLeading,
    select
} from 'redux-saga/effects'
import {
    showSpinner,
    hideSpinner,
    showMessage,
    showError
} from '../actions/uiActions'
import { 
    fetchAssessorsForUser,
    fetchThreatDefinition,
    fetchLevestedsDefinition,
    REQUEST_FETCH_ASSESSORS,
    SET_ASSESSORS,
    SET_USERGROUPS,
    EKSPERT_ENTITIES_INIT,
    REQUEST_FETCH_THREAT_DEFINITION,
    SET_THREAT_DEFINITION,
    REQUEST_FETCH_LEVESTEDS_DEFINTION,
    SET_LEVESTEDS_DEFINITION,
    EKSPERT_ENTITIES_LOADED
} from './ekspertEntitiesActions'
import { apiRequest, apiRequest2 } from '../api/apiRequest'

function* processInit(action) {
    try {
        const { loaded } = yield select(state => state.ekspertEntities)
        if (loaded) {
            return
        }        
        yield put(fetchLevestedsDefinition())
        yield put(fetchAssessorsForUser())
        yield put(fetchThreatDefinition())
    } catch (error) {
        yield put(showError(error))
    }
}

function* processFetchAssessors(action) {
    try {
        const { user } = yield select(state => state.app)
        yield put(showSpinner())
        const data = yield apiRequest2(RedlistApi, RedlistApi.loadAssessorsByUserId, [ user.id ])
        if (!data) {
            yield put(showMessage('Fejl', 'Kunne ikke hente assessors'))
            return
        }
        yield put({ type: SET_ASSESSORS, payload: { assessors: data.items }})
        const usergroups = yield apiRequest(RedlistApi, RedlistApi.loadUsergroupsForUser, [ ])
        if (!usergroups) {
            yield put(showMessage('Fejl', 'Kunne ikke hente usergroups'))
            return
        }        
        yield put({ type: SET_USERGROUPS, payload: { usergroups: usergroups.items }})
        yield put({ type: EKSPERT_ENTITIES_LOADED })
        
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}

function* processFetchThreatDefinition(action) {
    try {
        //console.log('loading threats')
        yield put(showSpinner())
        const data = yield apiRequest(RedlistApi, RedlistApi.loadThreatDefinition, [])
        yield put({ type: SET_THREAT_DEFINITION, payload: { threatDefinition: data.item }})
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}


function* processFetchLevestedsDefinition(action) {
    try {
        yield put(showSpinner())
        const data = yield apiRequest(RedlistApi, RedlistApi.loadLevestedsDefinition, [])
        yield put({ type: SET_LEVESTEDS_DEFINITION, payload: { levestedsDefinition: data.item }})
        yield put(hideSpinner())
    } catch (error) {
        console.log(error)
        yield put(hideSpinner())
        yield put(showError(error))
    }
}


export default function* watcher() {
    yield takeLeading(EKSPERT_ENTITIES_INIT, processInit)
    yield takeLeading(REQUEST_FETCH_ASSESSORS, processFetchAssessors)
    yield takeLeading(REQUEST_FETCH_THREAT_DEFINITION, processFetchThreatDefinition)
    yield takeLeading(REQUEST_FETCH_LEVESTEDS_DEFINTION, processFetchLevestedsDefinition)
}