import React from 'react';
import { useState } from 'react'
import {
    Button,
    LinearProgress,
    Typography
} from '@material-ui/core';
import Select from 'react-select'
import { useSelector } from 'react-redux';

const vurderingsrundeOptions = [
    { value: '2010', label: '2010' },
    { value: '2019', label: '2019' },
    { value: '2030', label: '2030' }
]

const PublishProgress = props => {
    const { progress } = props

    if (!progress || !progress.stage || progress.stage === 'ready') {
        return null;
    }

    return (
        <div style={{ marginTop: 16 }}>
            <Typography variant='body1'>{progress.stage} - {progress.completed} / {progress.total}</Typography>
            <LinearProgress variant='determinate' value={100 * progress.completed / progress.total} />
        </div>
    )
}

const DialogContent = props => {
    const { open } = props

    const [ vurderingsrundeId, setVurderingsrundeId ] = useState(null)
    const { progress } = useSelector(state => state.managePubliceringerPage)

    if (!open) {
        return null;
    }
    
    const stage = progress ? progress.stage || 'ready' : 'ready'

    return (
        <React.Fragment>
            <div>
                <Select isClearable value={vurderingsrundeId} onChange={value => setVurderingsrundeId(value)} options={vurderingsrundeOptions} />
            </div>  
            <div style={{ marginTop: 32 }}>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button disabled={stage !== 'ready'} fullWidth color='inherit' variant='contained' onClick={() => props.onClose()}>Annuller</Button>
                </div>
                <div style={{display: 'inline-block', width: '50%'}}>
                    <Button 
                        disabled={vurderingsrundeId === null || stage !== 'ready'} 
                        fullWidth 
                        color='primary' 
                        variant='contained' 
                        onClick={() => props.onStart(vurderingsrundeId.value)}>Publicer</Button>
                </div>
            </div>            
            <PublishProgress progress={progress} />
        </React.Fragment>
    )
}

const CreatePubliceringerDialog = (props) => {
    const { open } = props
    return (
        <div style={{ minWidth: 320, margin: 48 }}>            
            <DialogContent open={open} onClose={props.onClose} onStart={props.onStart} />
        </div>
    )
}

export default CreatePubliceringerDialog