import {
    applyMiddleware,
    createStore,
    compose
} from "redux";
import rootSaga from './sagas/rootSaga'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'   

import {
    editAssessmentMenuMdl
} from './middleware/editAssessmentMenuMdl';
import {
    categoryMdl
} from './middleware/categoryMdl'
import {
    uiMdl
} from './middleware/uiMdl'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            sagaMiddleware,
            ...uiMdl,
            ...editAssessmentMenuMdl, 
            ...categoryMdl),
    )
);

sagaMiddleware.run(rootSaga)

export default store;