import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Grid,
    Typography
} from '@material-ui/core'

import TextAreaReference from '../components/TextAreaReference'
import TextfieldWithHelp from '../components/TextfieldWithHelp'
import BooleanRadioGroup from '../components/BooleanRadioGroup'
import RadioGroupReference from '../components/RadioGroupReference'
import {
    ProcentvisTilbagegangHelpElement,
    KlarReversibelHelpElement,
    KlarlagteHelpElement,
    ObserveretHelpElement,
    OphorteHelpElement,
    TilbagegangPeriodeHelpElement,
    TilbagegangBaseretPaaHelpElement
} from './help/reductionHelp'
import { reductionQuestions } from './questions'

import {
    extractPropertyValidationResult
} from './validators/sectionValidators'

import { oddRowStyle } from '../components/styles'
import { updateReduction } from '../../redux/editAssessment/editAssessmentActions'
import Footer from '../components/Footer'
import GlobalErrorMessage from '../components/GlobalErrorMessage'

import { reductionTimespanEnum, reductionDerivedFromEnum } from './enums/enums'

const A_TilbagegangIBestand = (props) => {
    const dispatch = useDispatch()
    //const assessmentId = useSelector(state => state.editAssessment.assessmentId)
    const currentCriteria = useSelector(state => state.editAssessment.assessmentCriteria)
    const currentReduction = (currentCriteria && currentCriteria.reduction) || {}
    const { referenceArt, onNext, onClose } = props
    const referenceReduction = referenceArt && referenceArt.assessmentCriteria && referenceArt.assessmentCriteria.reduction

    const reductionInPercent = currentReduction.reductionInPercent || ''
    const {
        causesOfReductionAreReversible,
        causesOfReductionAreKnown,
        causesOfReductionHaveStopped,
        reductionHasBeenObserved
    } = currentReduction

    const reductionTimespan = currentReduction.reductionTimespan || reductionTimespanEnum.getNullValue().value
    const reductionIsDerivedFrom = currentReduction.reductionIsDerivedFrom || reductionDerivedFromEnum.getNullValue().value
    const reductionRemark = currentReduction.reductionRemark || ''

    console.log(reductionIsDerivedFrom)

    const validationResult = useSelector(state => state.editAssessment.criteriaValidationResult?.reduction)
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5'>Tilbagegang i bestand</Typography>
                <GlobalErrorMessage validationResult={validationResult} />
            </Grid>
            <Grid item xs={12}>
                <TextfieldWithHelp
                    type='number'
                    validation={extractPropertyValidationResult(validationResult, 'reductionInPercent')}
                    referenceValue={referenceReduction && referenceReduction.reductionInPercent}
                    help={() => <ProcentvisTilbagegangHelpElement />}
                    value={reductionInPercent}
                    onChange={value => dispatch(updateReduction('reductionInPercent', value))}
                    onUseReferenceValue={() => dispatch(updateReduction('reductionInPercent', referenceReduction.reductionInPercent))}
                    title={reductionQuestions.reductionInPercent.title}
                    label={reductionQuestions.reductionInPercent.label}
                />
                {
                    validationResult.valid === false && validationResult.properties.reductionInPercent
                    ?
                    <div>{validationResult.properties.reductionInPercent.message}</div>
                    :
                    null
                }
            </Grid>

            <Grid style={oddRowStyle} item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'causesOfReductionAreReversible')}
                    ghost={referenceReduction ? referenceReduction.causesOfReductionAreReversible : null}
                    label={reductionQuestions.causesOfReductionAreReversible.label}
                    value={causesOfReductionAreReversible}
                    onChange={value => dispatch(updateReduction('causesOfReductionAreReversible', value))}
                    help={() => <KlarReversibelHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    validation={extractPropertyValidationResult(validationResult, 'causesOfReductionAreKnown')}
                    ghost={referenceReduction ? referenceReduction.causesOfReductionAreKnown : null}
                    label={reductionQuestions.causesOfReductionAreKnown.label}
                    value={causesOfReductionAreKnown}
                    onChange={value => dispatch(updateReduction('causesOfReductionAreKnown', value))}
                    help={() => <KlarlagteHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <BooleanRadioGroup
                    ghost={referenceReduction ? referenceReduction.causesOfReductionHaveStopped : null}
                    label={reductionQuestions.causesOfReductionHaveStopped.label}
                    value={causesOfReductionHaveStopped}
                    onChange={value => dispatch(updateReduction('causesOfReductionHaveStopped', value))}
                    validation={extractPropertyValidationResult(validationResult, 'causesOfReductionHaveStopped')}
                    help={() => <OphorteHelpElement />}
                />
            </Grid>
            <Grid item xs={12}>
                <BooleanRadioGroup
                    ghost={referenceReduction ? referenceReduction.reductionHasBeenObserved : null}
                    value={reductionHasBeenObserved}
                    onChange={value => dispatch(updateReduction('reductionHasBeenObserved', value))}
                    validation={extractPropertyValidationResult(validationResult, 'reductionHasBeenObserved')}
                    label={reductionQuestions.reductionHasBeenObserved.label}
                    help={() => <ObserveretHelpElement />}
                />
            </Grid>
            <Grid style={oddRowStyle} item xs={12}>
                <RadioGroupReference
                    label={reductionQuestions.reductionTimespan.label}
                    help={() => <TilbagegangPeriodeHelpElement />}
                    referenceValue={referenceReduction ? referenceReduction.reductionTimespan : null}
                    name="periode"
                    value={reductionTimespan}
                    validation={extractPropertyValidationResult(validationResult, 'reductionTimespan')}
                    onChange={value => dispatch(updateReduction('reductionTimespan', value))}
                    values={reductionTimespanEnum.asList()} />
            </Grid>
            <Grid item xs={12}>
                <RadioGroupReference
                    label={reductionQuestions.reductionIsDerivedFrom.label}
                    help={() => <TilbagegangBaseretPaaHelpElement />}
                    referenceValue={referenceReduction ? referenceReduction.reductionIsDerivedFrom : null}
                    value={reductionIsDerivedFrom}
                    onChange={value => {
                        console.log('value', value, typeof(value))
                        dispatch(updateReduction('reductionIsDerivedFrom', value))}
                    }
                    validation={extractPropertyValidationResult(validationResult, 'reductionIsDerivedFrom')}
                    values={reductionDerivedFromEnum.asList()} />
            </Grid>
            <TextAreaReference
                value={reductionRemark}
                onChange={value => dispatch(updateReduction('reductionRemark', value))}
                rowStyle={oddRowStyle}
                title={reductionQuestions.reductionRemark.title}
                validation={extractPropertyValidationResult(validationResult, 'reductionRemark')}
                referenceTitle='Referenceart'
                referenceValue={referenceReduction && referenceReduction.reductionRemark}
            />
            <Footer
                onNext={() => {
                    onNext()
                }}
                onClose={() => onClose()} />        </Grid>
    )
}

export default A_TilbagegangIBestand