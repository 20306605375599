const intervalMessage = (min, max) => {
    if (min === false) {
        return `Der skal indtastes et tal mindre end eller lig med ${max}`
    }
    if (max === false) {
        return `Der skal indtastes et tal større end eller lig med ${min}`
    }
    return  `Der skal indtastes et tal mellem ${min} og ${max}`
}


export const integerInterval = (min, max, nullable) => value => {
    if (value === null || value === '') {
        if (nullable) {
            return { ok: true }
        } else {
            return { ok: false, message: 'Der skal indtastes en værdi' }
        }
    }
    let number = parseInt(value)
    if (isNaN(number)) {
        return { ok: false, message: intervalMessage(min, max)}
    }
    if ((min === false || number >= min) && (max === false || number <= max)) {
        return { ok: true }
    } else {
        return { ok: false, message:  intervalMessage(min, max)}
    }
}

export const floatInterval = (min, max, nullable) => value => {
    if (value === null || value === '') {
        if (nullable) {
            return { ok: true }
        } else {
            return { ok: false, message: 'Der skal indtastes en værdi' }
        }
    }
    let number = parseFloat(value)
    if (isNaN(number)) {
        return { ok: false, message: intervalMessage(min, max)}
    }
    if ((min === false || number >= min) && (max === false || number <= max)) {
        return { ok: true }
    } else {
        return { ok: false, message:  intervalMessage(min, max)}
    }
}

export const booleanValidator = (valid, message) => value => {
    if (valid) {
        return { ok: true }
    } else {
        return { ok: false, message }
    }
}