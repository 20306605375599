import { Base64 } from 'js-base64';
import { FixMeLater } from '../assessments/components/GlobalErrorMessage';
import { del, delWithBody, get, patch, post, put } from './fetchutil'
import { CreateAssessorRequest } from '../redux/admin/manageAssessorsPageActions';

export interface PutResponse {
    message?: string
    id?: number
}

export interface RefreshUserResponse {
    item: Redlist2User | null;
}

export interface Redlist2User {
    assessorIds: number[];
    email: string;
    id: number;
    name: string;
    role: string;
    shareableToken: string;
    token: string;
    username: string;
}

class RedlistApi {

    baseUrl: string;
    token: (string | undefined);

    constructor() {
        this.baseUrl = ''
        this.token = undefined
    }

    setBaseUrl(baseUrl : string) {
        this.baseUrl = baseUrl
    }

    setToken(token : (string | undefined)) {
        this.token = token
    }

    async loadThreatDefinition() {
        return get(this.baseUrl, '/definitions/threats', null, () => this.token)
    }

    async loadLevestedsDefinition() {
        return get(this.baseUrl, '/definitions/levesteder', null, () => this.token)
    }

    async loadSpeciesWithAssessments(filter : FixMeLater) {
        return get(this.baseUrl, '/admin/species', filter, () => this.token)
    }

    async loadSpeciesByFilter(filter : (null | Record<string, any>)) {
        return get(this.baseUrl, '/admin/species/simple', filter, () => this.token)
    }

    async adminCreateSpecies(species : FixMeLater) {
        return put(this.baseUrl, '/admin/species/simple', species, () => this.token)
    }
    
    async adminUpdateSpecies(id : FixMeLater, patch : FixMeLater) {
        return put(this.baseUrl, '/admin/species/simple/' + id, patch, () => this.token)
    }

    async adminBulkUpdateSpeciesGroup(body : { speciesIds : number[], speciesGroupId: (number | null)}) {
        return patch(this.baseUrl, '/admin/species/simple', body, () => this.token)
    }

    async adminDeleteSpecies(id : FixMeLater, patch : FixMeLater) {
        return del(this.baseUrl, '/admin/species/simple/' + id, () => this.token)
    }
    async loadSpecies(scientificName : string) {
        const queryOptions = { scientificName }
        return get(this.baseUrl, '/species/simple', queryOptions, () => this.token)
    }

    async loadSpeciesGenus(name : string) {
        const queryOptions = { name } 
        return get(this.baseUrl, '/species/genus', queryOptions, () => this.token)
    }

    async loadSpeciesFamiliesByName(name : string) {
        const queryOptions = {
            name        
        }        
        return get(this.baseUrl, '/admin/speciesfamily', queryOptions, () => this.token)
    }

    async loadSpeciesFamilies(name : string, assessorId : FixMeLater, vurderingsrundeId: FixMeLater, speciesName: string) {
        const queryOptions = {
            name, 
            assessorId,
            vurderingsrundeId,
            speciesName
        }        
        return get(this.baseUrl, '/admin/speciesfamily', queryOptions, () => this.token)
    }

    async loadAssessorsByUserId(userId : FixMeLater) {
        return get(this.baseUrl, '/user/' + encodeURIComponent(userId) + '/assessor', {}, () => this.token)
    }

    async loadUsergroups() {
        return get(this.baseUrl, '/admin/usergroups', null, () => this.token)
    }

    async loadAssessors() {
        return get(this.baseUrl, '/admin/assessors', null, () => this.token)
    }

    async loadAssessorById(assessorId : FixMeLater) {
        return get(this.baseUrl, '/admin/assessors/' + encodeURIComponent(assessorId), null, () => this.token)
    }

    async updateAssessor(assessorId : FixMeLater, patch : FixMeLater) {
        return put(this.baseUrl, '/admin/assessors/' + encodeURIComponent(assessorId), patch, () => this.token)
    }

    async createAssessor(assessor : CreateAssessorRequest) : Promise<PutResponse> {
        console.log('createAssessor', assessor)
        return post(this.baseUrl, '/admin/assessors', assessor, () => this.token)
    }

    async loadUsergroupsForUser() {
        return get(this.baseUrl, '/usergroup', null, () => this.token)
    }

    async loadAdminEntities(entity : string) {
        console.log('loadAdminEntity', entity)
        return get(this.baseUrl, '/admin/' + encodeURIComponent(entity), null, () => this.token)
    }

    async loadSpeciesGroups(name? : string) {
        return get(this.baseUrl, '/admin/speciesGroup', { limit: 15, name}, () => this.token)

    }

    async updateAssessmentMetadata(patch : FixMeLater) {
        return put(this.baseUrl, '/admin/assessment/metadata', patch, () => this.token)
    }
    
    async softDeleteAssessments(body : FixMeLater) {
        return delWithBody(this.baseUrl, '/admin/assessment', body, () => this.token)
    }

    async createAssessments(request : FixMeLater) {
        return put(this.baseUrl, "/admin/assessment", request, () => this.token)
    }

    async loadAssessments(_filter : FixMeLater, sortOrder? : string) {
        const statusList = _filter.statusList && _filter.statusList.length > 0 ? _filter.statusList.join(',') : null
        const filter = {
            ..._filter,
            statusList,
            orderBy: sortOrder
        }
        return await get(this.baseUrl, '/assessment', filter, () => this.token)
    }

    async loadAssessmentsForGodkendelse(filter : FixMeLater) {        
        return await get(this.baseUrl, '/assessment/godkendelse', filter, () => this.token)
    }

    async loadAssessmentsForQa(filter : FixMeLater) {        
        return await get(this.baseUrl, '/assessment/qa', filter, () => this.token)
    }

    async loadAssessmentById(id : number) {
        return get(this.baseUrl, "/assessment/" + encodeURIComponent(id), null, () => this.token)        
    }

    async loadAssessmentBySpeciesId(speciesId : FixMeLater, limit : number = 20) {
        return this.loadAssessments({ limit, speciesId },)
    }

    async loadUserByUsername(username : string) {
        return get(this.baseUrl, "/admin/users/" + encodeURIComponent(username), null, () => this.token)
    }

    async loadUsers(limit : number = 200) {
        return get(this.baseUrl, "/admin/users", null, () => this.token)
    }

    async updateUser(name : string, email : string, password : string) {
        return put(this.baseUrl, "/user/", { name, email, password }, () => this.token)
    }

    async createUser(user : FixMeLater) {
        return put(this.baseUrl, "/admin/users", user, () => this.token)
    }

    async adminUpdateUser(username : string, patch : FixMeLater) {
        return put(this.baseUrl, "/admin/users/" + encodeURIComponent(username), patch, () => this.token)
    }

    async fetchCommentsForAssessment(assessmentId : number) {
        return get(this.baseUrl, "/qa/" + encodeURIComponent(assessmentId), null, () => this.token)
    }

    async createAssessmentComment(assessmentId : number, phase : string, comment : FixMeLater) {
        return put(this.baseUrl, "/qa/" + encodeURIComponent(assessmentId) + '/' + encodeURIComponent(phase), comment, () => this.token)
    }

    async updateAssessmentComment(assessmentId : number, commentId : FixMeLater, comment : FixMeLater) {
        const path = '/qa/' + encodeURIComponent(assessmentId) + '/comment/' + encodeURIComponent(commentId)
        const data = { comment }
        return put(this.baseUrl, path, data, () => this.token)
    }

    async deleteAssessmentComment(assessmentId : number, comment : FixMeLater) {
        return del(this.baseUrl, "/qa/" + encodeURIComponent(assessmentId) + '/comment/' + encodeURIComponent(comment), () => this.token)
    }

    async updateStatusNext(assessmentId : number) {
        return put(this.baseUrl, `/assessment/${assessmentId}/status/next`, {}, () => this.token)
    }

    async updateStatusReject(assessmentId : number) {
        return put(this.baseUrl, `/assessment/${assessmentId}/status/reject`, {}, () => this.token)  
    }

    async updateSection(assessmentId : number, section : string , body : FixMeLater) {
        return put(this.baseUrl, '/assessment/' + assessmentId + '/section/' + encodeURI(section), body, () => this.token)
    }

    async updateLevested(assessmentId : number, levested : string, values : FixMeLater) {
        return put(this.baseUrl, '/assessment/' + assessmentId + '/levested/' + levested, values, () => this.token)
    }
    
    async updateLevestedComment(assessmentId : number, comment : string) {
        return put(this.baseUrl, '/assessment/' + assessmentId + '/levested/comment', { 
            comment
        }, () => this.token)
    }

    async updateDietGenus(assessmentId : number, dietGenusIds : FixMeLater) {
        return put(this.baseUrl, '/assessment/' + assessmentId + '/levested/dietGenus', dietGenusIds, () => this.token)
    }

    async updateDietSpecies(assessmentId : number, dietSpeciesIds : FixMeLater) {
        return put(this.baseUrl, '/assessment/' + assessmentId + '/levested/dietSpecies', dietSpeciesIds, () => this.token)
    }

    async updateGradient(assessmentId : number, gradient : string, minValue : FixMeLater, maxValue : FixMeLater) {
        return put(this.baseUrl, `/assessment/${assessmentId}/levested/gradient/${gradient}`, { minValue, maxValue },() => this.token)
    }

    async updateThreatMeta(assessmentId : number, threatMeta : { threatsUnknown : (boolean | null)}) {
        return put(this.baseUrl, `/assessment/${assessmentId}/threats/threatmeta`, threatMeta, () => this.token)
    }

    async updateThreats(assessmentId : number, threatIds : FixMeLater) {
        return put(this.baseUrl, `/assessment/${assessmentId}/threats/threats`, threatIds, () => this.token)
    }
    
    async updateOverallThreats(assessmentId : number, overallThreatIds : FixMeLater) {
        return put(this.baseUrl, `/assessment/${assessmentId}/threats/overallThreats`, overallThreatIds, () => this.token)
    }

    async updateSupplerendeOplysninger(assessmentId : number, value : FixMeLater) {
        return put(this.baseUrl, '/assessment/' + encodeURIComponent(assessmentId) + '/supplerendeOplysninger', value, () => this.token)
    }

    async updateCategoryChange(assessmentId : number, vurderingsrundeId : number, value : FixMeLater) {
        const data = { categoryChangeReason: { ...value, vurderingsrundeId }}
        const path = '/assessment/' + encodeURIComponent(assessmentId) + '/categorychange';
        return put(this.baseUrl, path, data, () => this.token)
    }

    async deleteCategoryChange(assessmentId : number, vurderingsrundeId : number) {
        const path = '/assessment/' + encodeURIComponent(assessmentId) + '/categorychange/' + encodeURIComponent(vurderingsrundeId);
        return del(this.baseUrl, path, () => this.token)
    }

    async updateCategory(assessmentId : number, category : FixMeLater) {
        const path = '/assessment/' + encodeURIComponent(assessmentId) + '/category';
        return put(this.baseUrl, path, category, () => this.token)
    }    

    async login(username :string, password : string) : Promise<SingleItemResponseWithStatus<LoginItem>> {
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + Base64.encode(username + ":" + password));
        const response = await fetch(this.baseUrl + "/user/login", {
            method: 'GET',
            headers
        })        
        const json : SingleItemResponse<LoginItem> = await response.json()
        return new Promise((resolve, reject) => {
            resolve({
                status: response.status,
                body: json
            })
        })
    }

    async refreshToken() : Promise<RefreshUserResponse> {
        return get(this.baseUrl, '/user/refresh', null, () => this.token)
    }

    async logout() {
        return put(this.baseUrl, "/user/logout", null, () => this.token)
    }
}

export interface SingleItemResponse<T> {
    item: T
}

export interface SingleItemResponseWithStatus<T> {
    status: number
    body: SingleItemResponse<T>
}

export interface LoginItem {
    "id": number;
    "username": string;
    "shareableToken": string;
    "name": string;
    "email": string | null
    "token": string | null
    "role": string
    "assessorIds": number[]
}

const instance = new RedlistApi()
export default instance