const zeropad = (value : number) : string => {
    if (value < 10) {
        return '0' + value
    } else {
        return '' + value
    }
}

export const formatUnixtimestamp = (timestamp : number) : string => {
    if (!timestamp) {
        return ''        
    }    
    // If timestamp is in seconds convert to ms
    if (timestamp < 10000000000) {
        timestamp = timestamp * 1000;
    }
    var date = new Date(timestamp);
    const year = (date as any).getYear() + 1900
    const month = zeropad(date.getMonth() + 1)
    const day = zeropad(date.getDate())
    const hours = zeropad(date.getHours())
    const minutes = zeropad(date.getMinutes())
    //const seconds = zeropad(date.getSeconds())
  //  console.log('year', year)
    return `${day}/${month}/${year} ${hours}:${minutes}`
}