import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { ReactElement } from 'react'
import { TaxonRecordDetails } from '../../service/arterDkModels'
import XTable from '../../mui/XTable'
import { DateTime } from 'luxon'

export interface TaxonRecordInfoBoxProps {
  show: boolean;
  onClose: () => void
  records: TaxonRecordDetails[]
}

export interface ShowInfoProps {
  info: TaxonRecordDetails
}

/*
recordId: string
  positionHash: string
  point: Geometry
  cellId: number
  validationStatus: ValidationStatusEnum | null
  observationAt: string
  modifiedAt: string
  taxonId: string
  assessedTaxonId: string
  deleted: boolean
  observationSource: ObservationSource
  year: number*/

const tableConfig = {
  id: 'recordId',
  properties: [
    { header: 'Kilde', property: 'observationSource' },
    { header: 'Status', property: 'validationStatus' },
    {
      header: 'Observeret',
      property: 'observationAt',
      func: (record: TaxonRecordDetails) => DateTime.fromISO(record.observationAt).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: 'Usikkerhed',
      property: 'uncertaintyInMeters',
      func: (record: TaxonRecordDetails) =>
        record.uncertaintyInMeters ? `${record.uncertaintyInMeters}m` : 'Ikke angivet',
    },
    { header: 'Id', property: 'recordId' },
  ],
  selectable: false,
}
//      { header: 'Link', property: 'link', func: (record : TaxonRecordDetails) => `https://arter.dk/observation/record-details/${record.recordId}`}

const TaxonRecordInfoDialog = (props: TaxonRecordInfoBoxProps): ReactElement => {
  const { show, records } = props

//  console.log({records})

  return (
    <Dialog maxWidth={'lg'} onClose={() => props.onClose()} open={show}>
      <DialogTitle>Fund på lokation</DialogTitle>
      <DialogContent>
        <XTable
          onRowClick={(row: TaxonRecordDetails) =>
            row.observationSource === 'arter.dk' &&
            window.open(`https://arter.dk/observation/record-details/${row.recordId}`, '_blank', 'noopener,noreferrer')
          }
          loading={false}
          size={'medium'}
          data={records}
          tableConfig={tableConfig}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Luk</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TaxonRecordInfoDialog
