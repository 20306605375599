import { ReactElement } from 'react'
//import PanToolIcon from '@material-ui/icons/PanTool'; 
import { LabelledValue } from '../mui/Section'
import { isNullOrEmpty } from '../assessments/sections/validators/sectionValidators'
//import '../controls/DrawControl.css'

type Position = {
    top?: number
    right?: number
    bottom?: number
}



export interface RedlistInfoBoxProps {
    position: Position
    areaOfOccupancy?: number;
    extentOfOccurrence?: number;
}


const OlRedlistInfoBox = (props: RedlistInfoBoxProps) : ReactElement => {
    const { position, extentOfOccurrence, areaOfOccupancy } = props
    
    const style : Record<string, any> = {
        padding: 8,
        paddingRight: 32,
        paddingBottom: 0,
        borderRadius: 3,
        borderColor: '#ccc',
        borderStyle: 'solid',
        borderWidth: 1,
        backgroundColor: 'rgba(240, 240, 240, 0.8)',
        position: 'absolute',
        cursor: 'pointer',        
    }
    if (!isNullOrEmpty(position.top)) {
        style.top = position.top
    }
    if (!isNullOrEmpty(position.right)) {
        style.right = position.right
    }
    if (!isNullOrEmpty(position.bottom)) {
        style.bottom = position.bottom
    }

    return (
        <div style={style}>            
            <LabelledValue fullWidth showNull={false} label='Ikke-justeret udbredelsesareal' value={extentOfOccurrence} />
            <LabelledValue fullWidth showNull={false} label='Ikke-justeret forekomstsareal' value={areaOfOccupancy} />
        </div>
    )
}

export default OlRedlistInfoBox