import {
    put,
    takeLeading
} from 'redux-saga/effects'

import {
    AUTH_ERROR
} from './apiActions'

import {logout} from "../actions/loginActions";

function* processAuthError(action) {
    yield put(logout())
}

export default function* watcher() {
    yield takeLeading(AUTH_ERROR, processAuthError)
}
