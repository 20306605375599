import React from "react"
import withIcon, { IconProps } from './withIcon'
const Icon = (props : IconProps) => {
    const title = props.title || 'Toggle feature'
    const { style, width, height } = props
    return (
        <svg id={title} aria-label={title} width={width}
            height={height}
            style={style}
            data-name={title} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.99 15.92">
            <path d="M15.99 14.55l-4.12-4.13 1.82-1.8-8.64-3.57 3.59 8.7 1.9-1.91 4.07 4.07zm-5.68-8.41A5.2 5.2 0 10.7 7.81a5.11 5.11 0 005.45 2.49 1.84 1.84 0 01-.09-.25c-.06-.34-.25-.42-.59-.39a4.58 4.58 0 01-1.17-.07 4.48 4.48 0 115.38-4.24c0 .58 0 .58.54.78zm-2.5-1a2.58 2.58 0 00-2.83-2.55 2.63 2.63 0 00-2.38 3 2.51 2.51 0 002.52 2.22c-.14-.37-.24-.71-.71-.87a1.81 1.81 0 01-1.09-1.96 1.92 1.92 0 011.55-1.65 1.83 1.83 0 012 1.08 1.11 1.11 0 00.94.7z" fill="#fff" />
            <path d="M15.99 14.55l-1.38 1.36-4.07-4.08-1.9 1.91-3.59-8.69 8.63 3.57-1.81 1.8z" />
            <path d="M10.31 6.14h-.09c-.55-.2-.55-.2-.54-.78A4.47 4.47 0 104.3 9.6a4.58 4.58 0 001.17.07c.34 0 .53.05.59.39a1.84 1.84 0 00.09.25A5.11 5.11 0 01.7 7.81a5.2 5.2 0 119.61-1.67z" />
            <path d="M7.81 5.11a1.11 1.11 0 01-.9-.7 1.83 1.83 0 00-2.04-1.08 1.92 1.92 0 00-1.55 1.65 1.81 1.81 0 001.09 1.93c.47.16.57.5.71.87A2.51 2.51 0 012.6 5.57a2.63 2.63 0 012.38-3 2.58 2.58 0 012.83 2.54z" />
        </svg>
    )
}
export default withIcon(Icon)