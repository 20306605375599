import RedlistApi from '../../service/redlistApi'

import {
    put,
    apply,
    takeEvery,
    takeLeading,
    takeLatest,
} from 'redux-saga/effects'
import {
    showSaving,
    hideSaving,
    showError
} from '../actions/uiActions'
import { 
    REQUEST_CREATE_USER,
    MANAGE_USER_PAGE_CREATE_OK,
    MANAGE_USER_PAGE_CREATE_ERROR,
    MANAGE_USER_PAGE_INIT,
    hideCreateDialog,
    manageUserPageInit,
    MANAGE_USER_PAGE_SHOW_EDIT_DIALOG,
    MANAGE_USER_PAGE_SET_SELECTED_USER,
    MANAGE_USER_PAGE_UPDATE_OK,
    MANAGE_USER_PAGE_UPDATE_ERROR,
    hideEditDialog,
    REQUEST_UPDATE_USER
} from './manageUserPageActions'

import {
    fetchAdminEntities, fetchUsers
} from './adminEntitiesActions'

function* processCreateUser(action) {
    try {
        yield put(showSaving())
        const data = yield apply(RedlistApi, RedlistApi.createUser, [ action.payload.user ])
        console.log(data)
        yield put({ type: MANAGE_USER_PAGE_CREATE_OK})
        yield put(manageUserPageInit())
        yield put(hideCreateDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({ type: MANAGE_USER_PAGE_CREATE_ERROR, payload: 'Fejl'})
        yield put(showError(error))
    }
}

function* processUpdateUser(action) {
    try {
        yield put(showSaving())
        const { usergroupIds, assessorIds, email, name, password, role} = action.payload.user        
        let _assessorIds = (assessorIds && assessorIds.length) ? assessorIds.filter(a => a).map(a => a.id) : []
        let _usergroupIds = (usergroupIds && usergroupIds.length) ? usergroupIds.filter(a => a).map(a => a.id) : []
        console.log(_assessorIds)
        const patch = {
            assessorIds: _assessorIds,            
            usergroupIds: _usergroupIds,
            email: email || null,
            name: name || null,
            password: password || null,
            role: role || null
        }
        const data = yield apply(RedlistApi, RedlistApi.adminUpdateUser, [ action.payload.username, patch ])
        console.log(data)
        yield put({ type: MANAGE_USER_PAGE_UPDATE_OK})
        yield put(manageUserPageInit())
        yield put(hideEditDialog())
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put({ type: MANAGE_USER_PAGE_UPDATE_ERROR, payload: 'Fejl'})
        yield put(showError(error))
    }
}

function* processUpdateOk() {
    try {
        yield put(fetchUsers())
    } catch (error) {
        yield put(showError(error))
    }
}

function* processShowEditUser(action) {
    try {
        const {user} = action.payload
        const data = yield apply(RedlistApi, RedlistApi.loadUserByUsername, [ user.username ])
        yield put({ type: MANAGE_USER_PAGE_SET_SELECTED_USER, payload: { user: data.item }})
    } catch (error) {
        yield put(showError(error))
    }
}

function* processInit(action) {
    try {
        yield put(fetchAdminEntities())
    } catch (error) {
        console.log(error)
        yield put(hideSaving())
        yield put(showError(error))
    }
}

export default function* watcher() {
    yield takeLeading(MANAGE_USER_PAGE_SHOW_EDIT_DIALOG, processShowEditUser)
    yield takeEvery(REQUEST_CREATE_USER, processCreateUser)
    yield takeLeading(REQUEST_UPDATE_USER, processUpdateUser)
    yield takeLeading(MANAGE_USER_PAGE_INIT, processInit)
    yield takeLatest(MANAGE_USER_PAGE_UPDATE_OK, processUpdateOk)
}