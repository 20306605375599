import React from 'react'
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core'
import SectionTitle from '../../mui/SectionTitle'
import { makeStyles } from '@material-ui/core/styles'
import { FixMeLater } from './GlobalErrorMessage';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 0,
        width: '100%'
    }
}));


const RadioGroupReference = (props : FixMeLater) => {
    const { disabled, name, label, help, values, value, referenceValue, onChange, validation } = props    
    const ghostStyle = { backgroundColor: 'rgba(1,0,200,0.1)' }
    const classes = useStyles()

    return (
        <FormControl disabled={Boolean(disabled)} className={classes.root} component="fieldset">
            <SectionTitle label={label} help={help} />
            { validation && validation.ok === false ? 
                <Typography style={{ marginTop: -12 }} color='error'>{validation.message}</Typography>
            : null}
            <RadioGroup className={classes.root} aria-label={name} name={name} value={value} onChange={evt => onChange(evt.target.value)}>
                {
                    values.map((v : FixMeLater) => {
                        let style = v.value === referenceValue ? ghostStyle : undefined;
                        return <FormControlLabel key={v.value} style={style} value={v.value} control={<Radio color='primary' />} label={v.label} />
                    })
                }
            </RadioGroup>
        </FormControl>
    )
}

export default RadioGroupReference