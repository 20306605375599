import React from 'react'
import { useEffect } from 'react'
import { setTitle } from '../../redux/actions/uiActions'
import { useDispatch, useSelector } from 'react-redux'

//import { Typography } from '@material-ui/core'
import XTable from '../../mui/XTable'
import Paging from '../../mui/Paging'
import { useState } from 'react'
import { Button, Drawer, Grid, Paper } from '@material-ui/core'
import { statusEnum } from '../../service/assessmentStatusEnum'


import ManageAssessmentsDialog from './ManageAssessmentsDialog'
import ManageAssessmentsHeader from './ManageAssessmentsHeader'
import ManageAssessmentsConfirmDelete from './ManageAssessmentsConfirmDelete'

import {
    saveAssessmentMetadata,
    deleteAssessments,
    initManageAssessmentsPage,
    prevPage,
    nextPage,
    setPage
} from '../../redux/admin/manageAssessmentsPageActions'


const assessorIdToAssessorLabel = (id, assessors) => {
    if (id !== 0 && !id) {
        return ''
    }
    const tmp = assessors.find(a => a.id === id)
    if (!tmp) {
        return ''
    }
    return tmp.name
}

const usergroupIdToLabel = (id, usergroups) => {
    if (id !== 0 && !id) {
        return ''
    }
    const tmp = usergroups.find(a => a.id === id)
    if (!tmp) {
        return ''
    }
    return tmp.name
}



const ManageAssessmentsPage = (props) => {
    const { loading } = useSelector(state => state.ui)
    const { statuses, assessors, vurderingsrunder, usergroups } = useSelector(state => state.adminEntities);
    const { pages, count, filter, assessments } = useSelector(state => state.manageAssessmentsPage)
    const dispatch = useDispatch()
    const [showDrawer, setShowDrawer] = useState(false)
    const [selectedAssessments, setSelectedAssessments] = useState([])


    const tableConfig = {
        id: 'id',
        properties: [
            { header: 'ID', property: 'id' },
            { header: 'Runde', property: 'vurderingsrundeId' },
            { header: 'Videnskabeligt navn', property: 'speciesInformation.scientificName' },
            { header: 'Almindeligt navn', property: 'speciesInformation.vernacularName' },
            { header: 'Population', property: 'speciesInformation.population' },
            { header: 'Familie', property: 'speciesInformation.speciesFamily' },
            { header: 'Gruppe', property: 'usergroupId', func: (value) => usergroupIdToLabel(value.usergroupId, usergroups) },
            { header: 'Vurderet af', property: 'assessorId', func: (value) => assessorIdToAssessorLabel(value.assessorId, assessors) },
            { header: 'Technical approver', property: 'technicalApproverId', func: (value) => assessorIdToAssessorLabel(value.technicalApproverId, assessors) },
            { header: 'Approver', property: 'approverId', func: (value) => assessorIdToAssessorLabel(value.approverId, assessors) },
            { header: 'Status', property: 'status' }
        ],
        selectable: true
    }

    useEffect(() => {
        dispatch(setTitle('Rediger Vurderinger'))
        dispatch(initManageAssessmentsPage())
    }, [dispatch])

    if (!assessments) {
        return (<div></div>)
    }

    console.log({vurderingsrunder})

    return (
        <React.Fragment>
            <Drawer open={Boolean(showDrawer)} anchor='right' onClose={() => { setShowDrawer(false) }} >
                {showDrawer === 'edit' &&
                    <ManageAssessmentsDialog
                        onSave={(value) => { dispatch(saveAssessmentMetadata(selectedAssessments, value)); setShowDrawer(false) }}
                        onClose={() => setShowDrawer(false)}
                        assessors={assessors}
                        usergroups={usergroups}
                        runder={vurderingsrunder}
                        statuses={statuses.filter(s => s !== statusEnum.values().slettet)} />
                }
                {showDrawer === 'delete' &&
                    <ManageAssessmentsConfirmDelete 
                        onConfirm={(value) => {setShowDrawer(false); dispatch(deleteAssessments(selectedAssessments, value))}}
                        onClose={() => { setShowDrawer(false) }} />
                }
            </Drawer>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Paper>
                        <Grid container spacing={2}>
                            <ManageAssessmentsHeader
                                onFilterChanged={() => setSelectedAssessments([])}
                                vurderingsrunder={vurderingsrunder}
                                statuses={statuses}
                                usergroups={usergroups}
                                assessors={assessors}
                            />
                        </Grid>                        
                    </Paper>                    
                </Grid>
                <Grid item xs={false} lg={4}></Grid>
                <Grid style={{ paddingBottom: 16, marginTop: 8 }} item xs={8} sm={6} md={4} lg={2}>
                    <Button fullWidth disabled={selectedAssessments.length === 0} variant='contained' color='primary' onClick={() => { setShowDrawer('edit'); }}>Rediger</Button>
                </Grid>
                <Grid style={{ paddingBottom: 16, marginTop: 8 }} item xs={8} sm={6} md={4} lg={2}>
                    <Button fullWidth disabled={selectedAssessments.length === 0} variant='contained' color='secondary' onClick={() => setShowDrawer('delete')}>Slet</Button>
                </Grid>
                <Grid xs={12} item container>
                    <Paging
                        count={count}
                        pageSize={filter.limit}
                        pages={pages}
                        page={filter.page}
                        onNext={() => dispatch(nextPage())} onPrev={() => dispatch(prevPage())}
                        onGoto={(page) => dispatch(setPage(page))} />
                </Grid>
                <Grid xs={12} item>
                    <XTable loading={loading} size='small' key={JSON.stringify(filter) + '.' + JSON.stringify(assessors.length + usergroups.length)} onSelectionChanged={(value) => setSelectedAssessments(value)} data={assessments} tableConfig={tableConfig} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ManageAssessmentsPage
