import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    useParams, Redirect
} from 'react-router-dom'

import {
    Drawer
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import EditMenuItem from './components/EditMenuItem'

import categoryView from '../service/categoryView'

import ReferenceArt from './sections/ReferenceArt'

import ReadOnlyAssessmentPage from './ReadOnlyAssessmentPage'

import IndledendeVurdering from './sections/1_IndledendeVurdering'
import TilbagegangIBestand from './sections/A_TilbagegangIBestand'
import GeografiskUdbredelse from './sections/B_GeografiskUdbredelse'
import Bestandsstorrelse from './sections/C_Bestandsstorrelse'
import KvantitativAnalyse from './sections/D_KvantitativAnalyse'
import OpNedKategorisering from './sections/E_OpNedKategorisering'
import Levestedsangivelse from './levesteder/Levestedsangivelse'
import Trusler from './sections/G_Trusler'
import SupplerendeOplysninger from './sections/H_SupplerendeOplysninger'
import Kvittering from './sections/I_Kvittering'

import CommentViewDialog from './components/CommentViewDialog'

import { initEkspertEntities } from '../redux/ekspert/ekspertEntitiesActions'
import { 
    initEditAssessmentPage,
    createCommentResponse
} from '../redux/editAssessment/editAssessmentActions'
import { setTitle, hideMenu, showMenu, showGoBack } from '../redux/actions/uiActions'
import { setCurrentSection } from '../redux/editAssessment/editAssessmentMenuActions'
import XCategoryChangeReason from './sections/X_CategoryChangeReason'


const drawerWidth = 280

const Content = (props) => {
    return (
        <div style={{ marginTop: 24, marginLeft: props.marginLeft, width: 'calc(100% - 240px)' }}>
            {props.children({ ...props })}
        </div>
    )
}


const sectionToComponent = {
    'IndledendeVurdering': (referenceArt, onNext, onClose) => <IndledendeVurdering referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'TilbagegangIBestand': (referenceArt, onNext, onClose) => <TilbagegangIBestand referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'GeografiskUdbredelse': (referenceArt, onNext, onClose) => <GeografiskUdbredelse referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'Bestandsstorrelse': (referenceArt, onNext, onClose) => <Bestandsstorrelse referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'KvantitativAnalyse': (referenceArt, onNext, onClose) => <KvantitativAnalyse referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'OpNedKategorisering': (referenceArt, onNext, onClose) => <OpNedKategorisering referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'Levestedsangivelse': (referenceArt, onNext, onClose) => <Levestedsangivelse referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'Trusler': (referenceArt, onNext, onClose) => <Trusler referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'SupplerendeOplysninger': (referenceArt, onNext, onClose) => <SupplerendeOplysninger referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'XCategoryChangeReason2010': (referenceArt, onNext, onClose) => <XCategoryChangeReason vurderingsrundeId={2010} referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'XCategoryChangeReason2019': (referenceArt, onNext, onClose) => <XCategoryChangeReason vurderingsrundeId={2019} referenceArt={referenceArt} onNext={onNext} onClose={onClose} />,
    'Kvittering': (referenceArt, onNext, onClose) => <Kvittering referenceArt={referenceArt} onNext={onNext} onClose={onClose} />
}

const validationResultForSection = (criteriaValidationResult, section) => {
    if (!section.validationProperty) {
        return { ok: true }
    }
    if (!criteriaValidationResult) {
        return { ok: true }
    }
    const result = criteriaValidationResult[section.validationProperty]
    return result || { ok : true }
}

const EditAssessmentPage = (props) => {
    //const url = useSelector(state => state.app.url)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.app)
    const { assessors } = useSelector(state => state.ekspertEntities)
    const sections = useSelector(state => state.editAssessmentMenu.sections)
    const selectedSection = useSelector(state => state.editAssessmentMenu.currentSection)
    const params = useParams()

    const [referenceArt, setReferenceArt] = useState()
    const speciesInformation = useSelector(state => state.editAssessment.speciesInformation)
    const { 
        assessmentId, 
        assessorId, 
        category, 
        phase, 
        comments,
        permissions 
    } = useSelector(state => state.editAssessment)
    //console.log({permissions})
    const loading = useSelector(state => state.ui.loading)
    const currentSectionComponent = selectedSection && sectionToComponent[selectedSection.component]
    const criteriaValidationResult = useSelector(state => state.editAssessment.criteriaValidationResult)
    const categoryChangeValidationResult = useSelector(state => state.editAssessment.categoryChangeValidationResult)
    const validationResult = { ...criteriaValidationResult, ...categoryChangeValidationResult }    
    //  console.log('vr', validationResult)
    const large = useMediaQuery('(min-width:1500px)');

    const readOnly = permissions === 'READ_ONLY'

    useEffect(() => {
        dispatch(initEkspertEntities())
        dispatch(initEditAssessmentPage(params.id))
        dispatch(showGoBack())
    }, [dispatch, params.id])

    useEffect(() => {
        if (speciesInformation) {
            const suffix = readOnly ? ' - Read Only' : ''
            const danskNavn = speciesInformation.vernacularName ? ` (${speciesInformation.vernacularName}) ` : ''
            dispatch(setTitle('Vurdering af ' + speciesInformation.scientificName + danskNavn + suffix))
        }
    }, [dispatch, speciesInformation, readOnly])

    useEffect(() => {
        if (speciesInformation && category) {
            const suffix = readOnly ? ' - Read Only' : ''
            const danskNavn = speciesInformation.vernacularName ? ` (${speciesInformation.vernacularName}) ` : ''            
            dispatch(setTitle('Vurdering af ' + speciesInformation.scientificName +  danskNavn + ' - ' + categoryView.format(category) + suffix))
        }
    }, [dispatch, speciesInformation, category, readOnly])

    useEffect(() => {
        dispatch(hideMenu())
        return function cleanup() {
            dispatch(showMenu())
        }
    }, [dispatch])

    const onNext = () => {
        dispatch(setCurrentSection(selectedSection.id + 1));
        window.scrollTo(0, 0)
    }
    const onClose = () => { }

    if (parseInt(params.id) !== assessmentId) {
        return null
    }
    if (!assessors || !assessorId) {
        return null
    }
    if (user.role !== "ADMIN" && assessors.filter(a => a.id === assessorId).length === 0) {
        return (
            <Redirect to='/vurderinger' />
        )
    }
    if (permissions === 'READ_ONLY') {
        return (<ReadOnlyAssessmentPage  />)
    }

    if (!currentSectionComponent) {
        return null
    }

    console.log({comments})

    return (
        <div>
            <Drawer variant="permanent" anchor="left">
                <div style={{ width: drawerWidth, marginTop: 80 }}>
                    {sections.map(s => {
                        //console.log(s.id, comments && comments[s.id])   
                        const hasComments = s.section ? Boolean(comments && comments[s.section]) : Boolean(comments && comments["general"])
                        return (
                            <EditMenuItem
                                submenuComponent={s.submenuComponent}
                                key={s.id}
                                active={s === selectedSection}
                                value={s.id}
                                title={s.title}
                                validation={validationResultForSection(validationResult , s)}
                                hasComments={hasComments}
                                onClick={() => {
                                    dispatch(setCurrentSection(s.id))
                                }}
                            />)
                    })}
                </div>
            </Drawer>
            <div style={{ zIndex: 1299, width: 360, position: 'fixed', top: 80, right: 20 }}>
                <ReferenceArt onChange={value => setReferenceArt(value)} />
            </div>
            {large ? null : <div style={{ width: 300, height: 50 }}></div>}
            <Content marginLeft={drawerWidth}>
                {loading ? () => (<div />) : () => {
                    return (
                        <React.Fragment>
                            {currentSectionComponent(referenceArt, onNext, onClose)}
                            { !loading && <CommentViewDialog 
                                    key={selectedSection.section} 
                                    section={selectedSection.section} 
                                    onCreateComment={comment => { console.log('create comment', comment); dispatch(createCommentResponse(assessmentId, phase, comment))}}
                                    comments={comments && (selectedSection.section ? comments[selectedSection.section] : comments["general"])} />}
                        </React.Fragment>
                    )}
                }                
            </Content>
        </div>
    )
}

export default EditAssessmentPage