import {
    preliminaryValuesAssessed,
    preliminaryValuesNull
} from "../1_IndledendeVurdering"

export const preliminaryValidator = criteria => {
    if (!criteria) {
        return {
            ok: true
        }
    }
    const preliminary = criteria.preliminary
    const {
        preliminaryAssessment,
        preliminaryAssessmentRemark
    } = preliminary

    if (!preliminaryAssessment || preliminaryAssessment === preliminaryValuesNull) {
        return {
            ok: false,
            global: true,
            message: 'Indledende vurdering skal udfyldes'
        }
    }
    if (preliminaryAssessment !== preliminaryValuesAssessed && !preliminaryAssessmentRemark) {
        return {
            ok: false,
            properties: {
                preliminaryAssessmentRemark: {
                    message: 'Dokumentation skal udfyldes når arten ikke vurderes'
                }
            }
        }
    }
    return {
        ok: true, 
        empty: false
    }
}